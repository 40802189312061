import React, { useEffect, useState } from 'react'
import {
  TokenMarketType,
  SortPairCriteria,
  SortPairs,
  ExchangeCoinName,
  DaysFromFirstDeal,
} from '../TokenData/TokenData'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

const ExchangeCoinPlaceInTopIndex = 2
const First_N_Pair = 5

export default function MarketOverview({ t, enrichedTradablePairs, goToTokenPage }) {
  const [marketsData, setMarketsData] = useState([
    { type: TokenMarketType.Popular, pairs: [] },
    { type: TokenMarketType.Hot, pairs: [] },
    { type: TokenMarketType.NewListing, pairs: [] },
  ])

  const updateMarketData = (marketDataType, newPairs) => {
    setMarketsData((prevMarketsType) =>
      prevMarketsType.map((market) => (market.type === marketDataType ? { ...market, pairs: newPairs } : market)),
    )
  }

  const processTopPairs = () => {
    const allPairsExcludeExchangeTokenPair = enrichedTradablePairs.filter((pair) => {
      const [firstCurrency, secondCurrency] = pair.currencyPair.code.split('_')
      return firstCurrency !== ExchangeCoinName && secondCurrency !== ExchangeCoinName
    })
    const exchangeTokenPair = enrichedTradablePairs.filter((pair) => {
      const [firstCurrency, secondCurrency] = pair.currencyPair.code.split('_')
      return firstCurrency === ExchangeCoinName || secondCurrency === ExchangeCoinName
    })

    const sortPairByLastTradedPrice = SortPairs(
      allPairsExcludeExchangeTokenPair,
      SortPairCriteria.Volume24H,
      First_N_Pair,
    )

    const insertExchangeTokenPair = (sortedPairs, exchangeTokenPair, insertIndex, maxLength) => {
      const result = [...sortedPairs.slice(0, insertIndex), ...exchangeTokenPair, ...sortedPairs.slice(insertIndex)]
      return result.slice(0, maxLength)
    }

    const finalSortedPairsForTop = insertExchangeTokenPair(
      sortPairByLastTradedPrice,
      exchangeTokenPair,
      ExchangeCoinPlaceInTopIndex,
      First_N_Pair,
    )

    updateMarketData(TokenMarketType.Popular, finalSortedPairsForTop)
  }

  const processNewListings = () => {
    const newListings = enrichedTradablePairs.filter((pair) => {
      const firstTradeDate = new Date(pair.firstTradeDate)
      const fourteenDaysAgo = new Date(Date.now() - DaysFromFirstDeal * 24 * 60 * 60 * 1000)
      return firstTradeDate >= fourteenDaysAgo
    })

    const sortedNewListingsPair = SortPairs(newListings, SortPairCriteria.Volume24H, First_N_Pair)

    updateMarketData(TokenMarketType.NewListing, sortedNewListingsPair)
  }

  const processHot = () => {
    const displayOrderedPairs = enrichedTradablePairs.filter((pair) => pair.marketPageDisplayOrder !== 0)
    const sortedHotPair = SortPairs(displayOrderedPairs, SortPairCriteria.MarketPageDisplayOrder, First_N_Pair, 'asc')

    updateMarketData(TokenMarketType.Hot, sortedHotPair)
  }

  useEffect(() => {
    if (!enrichedTradablePairs || enrichedTradablePairs.length === 0) return

    processTopPairs()
    processNewListings()
    processHot()
  }, [enrichedTradablePairs])

  return (
    <React.Fragment>
      <div className='markets__section fixed_layout'>
        <div className='markets__main'>
          <div className='markets__list'>
            {marketsData.map((market) => (
              <div key={market.type} className='markets__item market fixed_layout__item'>
                <div className='market__header'>
                  <span className='markets__title'>{t(market.type)}</span>
                </div>
                <div className='market__main fixed_layout__content'>
                  <div className='market__table table'>
                    <div className='table__header'>
                      <ul className='table__list'>
                        <li className='table__item'>
                          <span className='table__title'>{t('TokenPairs')}</span>
                        </li>
                        <li className='table__item'>
                          <span className='table__title'>
                            <span className='table__subtitle'>{t('TokenLastTraidedPrice')}</span>
                          </span>
                        </li>
                        <li className='table__item'>
                          <span className='table__title'>{t('Token24HChange')}</span>
                        </li>
                      </ul>

                      <div className='table__main fixed_layout__table-content'>
                        {market?.pairs?.map((pair, index) => (
                          <ul key={`${market.type}-${pair.currencyPair.code}-${index}`} className='table__list'>
                            <li
                              className='table__item'
                              onClick={() => goToTokenPage(pair.currencyCode)}
                              style={{ cursor: 'pointer' }}
                            >
                              <span className='table__ico'>
                                <CurrencyIcon size={70} currencyCode={pair.currencyCode} />
                              </span>
                              <span className='table__text'>{pair.currencyPair?.getDisplayName}</span>
                            </li>
                            <li className='table__item'>
                              <span className='table__text'>{pair.price}</span>
                            </li>
                            <li className='table__item'>
                              <span className='table__text table__text_secondary'>
                                {Number(pair.priceChangePercentage24h).toFixed(2)}%
                              </span>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
