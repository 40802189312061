import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'

export default function Statistics({ t, navigationFunctions }) {
  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__main'>
            <div className='layout__statistics statistics'>
              <ul className='statistics__list'>
                <li className='statistics__item layout__box layout__box_secondary'>
                  <span className='statistics__title layout__tag'>{t('NumberOfProjects')}:</span>
                  <span className='statistics__text'>200</span>
                </li>
                <li className='statistics__item layout__box layout__box_secondary'>
                  <span className='statistics__title layout__tag'>{t('Capitalization')}:</span>
                  <span className='statistics__text'>$2M+</span>
                </li>
                <li className='statistics__item layout__box layout__box_secondary'>
                  <span className='statistics__title layout__tag'>{t('DiscordCommunity')}:</span>
                  <span className='statistics__text'>10k</span>
                </li>
                <li className='statistics__item layout__box layout__box_secondary'>
                  <span className='statistics__title layout__tag'>{t('TelegramCommunity')}:</span>
                  <span className='statistics__text'>2k</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
