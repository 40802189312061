import React, { useState } from 'react'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
// import Checkbox from 'baseComponents/Checkbox/Checkbox'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './ApiKeysModal.module.scss'
import modal from '../../../baseComponents/Modal/Modal.module.scss'
import api from 'api'
import { toast } from 'react-toastify'
import Input from '../../../components/form/Input/Input'
import Checkbox from '../../../components/form/Checkbox/Checkbox'
import Button from '../../../components/form/Button/Button'
export default function ApiKeysModal({ setOpened, loadKeys }) {
  const { t } = useTranslation()
  const [apiCheck1, setApiCheck1] = useState(false)
  const [apiCheck2, setApiCheck2] = useState(false)
  const [apiCheck3, setApiCheck3] = useState(false)
  const [description, setDescription] = useState('')
  const [ipAddress, setIPAddress] = useState('')
  const [res, setRes] = useState({})
  const [isDisabled, setDisabled] = useState(false)

  const handelSubmit = async (e) => {
    const data = {
      description: description,
      ipAddress: ipAddress,
      allowReceivingUserInfo: apiCheck1,
      allowTrading: apiCheck2,
      allowWithdrawal: apiCheck3,
    }

    try {
      setDisabled(true)
      const res = await api.createApiKey(data)
      toast.success(t('ApiKey_Creation_Success'))
      setRes(res)
      loadKeys()
    } catch (e) {
      setDisabled(false)
      toast.error(t('ApiKey_Creation_Fail'))
    }
  }
  return (
    <React.Fragment>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('CreateNewAPIkey')}</h2>
        </div>
        <div className='formular'>
          <div className='form-item'>
            <div className='form-item__header'>
              <span className='form-item__title'>{t('Description')}</span>
            </div>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Input
                  value={description}
                  inputCallback={(value) => setDescription(value)}
                  placeholder={description}
                  addClasses={['w-100']}
                />
              </div>
            </div>
          </div>
          <div className='form-item'>
            <div className='form-item__header'>
              <span className='form-item__title'>{t('IPAddress')}</span>
            </div>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Input
                  value={ipAddress}
                  inputCallback={(value) => setIPAddress(value)}
                  placeholder={ipAddress}
                  addClasses={['w-100']}
                />
              </div>
            </div>
          </div>
          <div className='form-item'>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Checkbox
                  id={'api1'}
                  type={'checkbox'}
                  name={'api'}
                  text={t('APIModalAllow1')}
                  checkboxCallback={() => setApiCheck1((prev) => !prev)}
                  checked={apiCheck1}
                />
              </div>
              <div className='form-item__field'>
                <Checkbox
                  id={'api2'}
                  type={'checkbox'}
                  name={'api'}
                  text={t('APIModalAllow2')}
                  checkboxCallback={() => setApiCheck2((prev) => !prev)}
                  checked={apiCheck2}
                />
              </div>
              <div className='form-item__field'>
                <Checkbox
                  id={'api3'}
                  type={'checkbox'}
                  name={'api'}
                  text={t('APIModalAllow3')}
                  checkboxCallback={() => setApiCheck3((prev) => !prev)}
                  checked={apiCheck3}
                />
              </div>
            </div>
          </div>
        </div>
        {Object.keys(res).length > 0 && (
          <div className='wysiwyg'>
            <p>
              {t('PublicKey')}: <span className={styles.key}>{res.publicKey}</span>
            </p>
            <p>
              {t('PrivateKey')}: <span className={styles.key}>{res.privateKey}</span>
            </p>
          </div>
        )}
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Button buttonCallback={() => handelSubmit()} addClasses={['btn w-100']} text={t('Create')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
