import React from 'react'
import Main from 'pages/common/components/Main/Main'
import banner__01 from './img/banner__img01.png'
import img01 from './img/img01.png'
import img02 from './img/img02.png'
import img03 from './img/img03.png'
import img04 from './img/img04.png'
import { useTranslation } from 'react-i18next'
export default function PurchaseAtCostPage() {
  const { t } = useTranslation()
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__banner banner'>
                <div className='row row_m-revers'>
                  <div className='col col_6 col_mob-12'>
                    <div className='banner__layout'>
                      <div className='banner__main'>
                        <div className='wysiwyg'>
                          <h1>{t('FinancialCooperation')}</h1>
                          <p>{t('WeAreLookingForMarketMakers')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_6 col_mob-12'>
                    <div className='banner__media'>
                      <img src={banner__01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('InteractionOptions')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row justify-between'>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img01} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('MajorArbitration')}</h2>
                            <ul>
                              <li>{t('IfYouNeedHelpOrganizingComplexArbitrageSchemes')}</li>
                              <li>{t('WeAreLookingForPeopleWhoWillPlace')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img02} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('MarketMakers')}</h2>
                            <ul>
                              <li>{t('PeopleWhoWillNotWithdrawMoney')}</li>
                              <li>{t('WeProvideSpecialConditionsForSuchInteraction')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img03} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('ExchangeOffices')}</h2>
                            <ul>
                              <li>{t('IfWeHaveApplicationsForIssuingOrAcceptingCashInYourCity')}</li>
                              <li>{t('YouCanConnectToOurGlassAndAutomatically')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img04} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('InternationalPaymentsAndLegalEntities')}</h2>
                            <ul>
                              <li>{t('OfficesOfOurPartnersInTheEUAsia')}</li>
                              <li>{t('ANetworkOfLegalEntitiesInTheEUDubai')}</li>
                              <li>{t('WorkingCapitalThatCanBeUsedInCertainFinancialTransactions')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
