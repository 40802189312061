const BenefitsOfBasicVerification = ({ t, currencyType }) => {
  return (
    <div className='verification__box box'>
      <div className='box__list'>
        <div className='box__item'>
          <div className='box__header'>
            <div className='wysiwyg'>
              <h3>{t(currencyType)}</h3>
            </div>
          </div>
          <div className='box__main'>
            <div className='box__data data'>
              <div className='data__list'>
                <div className='data__item'>
                  <span className='data__text'>{t('TopUp')}:</span>
                  <span className='data__value'>{t('Unlimited')}</span>
                </div>
                <div className='data__item'>
                  <span className='data__text'>{t('WithdrawSecondary')}:</span>
                  <span className='data__value'>{t('Unlimited')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const BenefitsOfAdvancedVerification = ({ t }) => {
  return (
    <div className='verification__box box'>
      <div className='box__list box__list_secondary'>
        <div className='box__item'>
          <div className='box__header'>
            <div className='wysiwyg'>
              <h3>{t('Advantages')}</h3>
            </div>
          </div>
          <div className='box__main'>
            <div className='wysiwyg'>
              <p>1. {t('AccessToP2PPlatform')}.</p>
              <p>2. {t('ReplenishmentAndWithdrawalFromBankCards')}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { BenefitsOfBasicVerification, BenefitsOfAdvancedVerification }
