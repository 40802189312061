import React, { useEffect, useState, useRef } from 'react'
import Header from '../../common/components/Header/Header.jsx'
import Footer from '../../common/components/Footer/Footer.jsx'
import '../../secondaryLayout/scss/main.sass'
import { useTranslation } from 'react-i18next'
import Statistics from './Statistics'
import Roadmap from './Roadmap'
import Investments from './Investments'
import ExistingTokens from './ExistingTokens.jsx'
import TokensMarketOverview from './TokensMarketOverview.jsx'
import About from './About'
import Steps from './Steps'
import Cooperation from './Cooperation'
import Categories from './Categories'
import Banner from './Banner'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'auth/auth.js'
import useCurrencyData from '../TokenData/useCurrencyData.jsx'
import CurrencyListingStatuses from 'data/CurrencyListingStatuses.js'

export default function TokenLandingPage() {
  const { t } = useTranslation()
  const history = useHistory()
  let auth = useAuth()

  const [isUserAuth, setIsUserAuth] = useState(auth.isSignedIn)
  const { enrichedTradablePairs } = useCurrencyData()

  const goToTokensPage = (currencyCode) => history.push(`/${i18n.language}/tokens/view/${currencyCode}`)
  const goToTokensTradePage = (symbol) => history.push(`/${i18n.language}/exchange?symbol=${symbol}`)
  const goToTokensRoadMapPage = () => history.push(`/${i18n.language}/tokens/roadmap`)
  const goToTokensCreatePage = () => history.push(`/${i18n.language}/tokens/create`)
  const goToTokensMarketsPage = () => history.push(`/${i18n.language}/tokens/markets`)
  const goToRegistrationPage = () => history.push(`/${i18n.language}/register`)

  const handleAuthAction = (authenticatedAction, unauthenticatedAction = goToRegistrationPage) => {
    if (isUserAuth) {
      authenticatedAction()
    } else {
      unauthenticatedAction()
    }
  }

  const navigationFunctions = {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  }

  useEffect(() => {
    if (isUserAuth === auth.isSignedIn) return
    setIsUserAuth(auth.isSignedIn)
  }, [auth])

  return (
    <React.Fragment>
      <div className='layout_Tokens'>
        <Header />
        <main className='layout__main'>
          <Investments t={t} navigationFunctions={navigationFunctions} />
          <Statistics t={t} />
          <ExistingTokens
            t={t}
            navigationFunctions={navigationFunctions}
            enrichedTradablePairs={enrichedTradablePairs}
          />
          <TokensMarketOverview
            t={t}
            navigationFunctions={navigationFunctions}
            enrichedTradablePairs={enrichedTradablePairs}
          />
          <About t={t} o navigationFunctions={navigationFunctions} />
          <Steps t={t} navigationFunctions={navigationFunctions} />
          <Cooperation t={t} navigationFunctions={navigationFunctions} />
          <Categories t={t} navigationFunctions={navigationFunctions} />
          <Roadmap t={t} navigationFunctions={navigationFunctions} />
          <Statistics t={t} navigationFunctions={navigationFunctions} />
          <Banner t={t} navigationFunctions={navigationFunctions} />
        </main>
        <Footer />
      </div>
    </React.Fragment>
  )
}
