import React from 'react'
import { useTranslation } from 'react-i18next'
import Main from '../common/components/Main/Main'
const UserAgreement = () => {
  const { t } = useTranslation(['legalDocumentsTranslation'])
  const htmlContext = t("UserAgreementPage")
  return (
    <Main>
      <div dangerouslySetInnerHTML={{ __html: htmlContext }} />
    </Main>
  )
}
export default UserAgreement