import React, { useEffect, useState } from 'react'
import investments__img01 from '../../secondaryLayout/img/investments__img01.png'
import '../../secondaryLayout/scss/main.sass'

export default function Investments({ t, navigationFunctions }) {
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }
  return (
    <React.Fragment>
      <section className='layout__section section pb-0'>
        <div className='container'>
          <div className='section__main'>
            <div className='layout__investments investments'>
              <div className='investments__layout'>
                <div className='investments__main layout__box'>
                  <h1 className='investments__title'>
                    {t('InvestmentsInDigital')}
                    <strong>
                      &nbsp; {t('For')}&nbsp;
                      <ul className='animet-text'>
                        <li>
                          <span>{t('Everyone')}</span>
                        </li>
                        <li>
                          <span>{t('Investors')}</span>
                        </li>
                        <li>
                          <span>{t('Bloggers')}</span>
                        </li>
                        <li>
                          <span>{t('Traders')}</span>
                        </li>
                        <li>
                          <span>{t('Startups')}</span>
                        </li>
                        <li>
                          <span>{t('Creators')}</span>
                        </li>
                      </ul>
                    </strong>
                  </h1>
                  <span className='investments__text'>{t('CreateYourOwnUniqueToken')}</span>
                </div>
                <div className='investments__footer'>
                  <a className='btn btn_large' onClick={() => handleGoToPage()}>
                    <span className='btn__text'>{t('CreateAToken')}</span>
                  </a>
                  <a className='btn btn_large btn_tertiary' onClick={() => goToTokensMarketsPage()}>
                    <span className='btn__text'>{t('SelectExisting')}</span>
                  </a>
                </div>
              </div>
              <div className='investments__media layout__box'>
                <img className='table__ico' src={investments__img01} alt='image description' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
