var config = {}
const useLocalEndpoints = false

if (useLocalEndpoints) {
  config.ApiUrl = 'https://localhost:5102'
  config.ApiAuthUrl = 'https://localhost:5352'
} else {
  config.ApiUrl = 'https://internal-api.blazarex.com'
  config.ApiAuthUrl = 'https://identity.blazarex.com'
}

module.exports = config
