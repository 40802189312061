import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import max from 'lodash/max'
import min from 'lodash/min'
import store from '../store'
import { cssClass } from 'helpers/helper'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './Orderbook.module.scss'
import stylesPhone from '../MarketLimitPhone/MarketLimitPhone.module.scss'

const Orderbook = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={cssClass(styles.title, styles.titleFirst)}>
        <h3>
          {t('Sell')} {store.symbolQuote}
        </h3>
      </div>
      <Asks />
      <div className={cssClass(styles.tableItem, styles.titleSecond)}>
        <h3>{t('Price')}</h3>
        <h3>{t('Volume')}</h3>
        <h3>{t('Type')}</h3>
        <h3>{t('Total')}</h3>
      </div>
      <Bids />
      <div className={cssClass(styles.title, styles.titleLast)}>
        <h3>
          {t('Buy')} {store.symbolBase}
        </h3>
      </div>
    </>
  )
}

const Asks = () => {
  return (
    <div className={styles.windowFirst}>
      <OrderbookSide
        isBid={false}
        verticalClass={baseComponents.scrollbarRight}
        dragClass={baseComponents.scrollbarRedDrag}
        startFromEnd
      />
    </div>
  )
}

const Bids = () => {
  return (
    <div className={styles.windowSecond}>
      <OrderbookSide
        isBid={true}
        verticalClass={baseComponents.scrollbarRight}
        dragClass={baseComponents.scrollbarDragGreen}
      />
    </div>
  )
}

const OrderbookSide = observer(
  ({
    selected,
    isBid,
    isPhone = false,
    verticalClass,
    dragClass,
    className = baseComponents.swiperWrapper,
    startFromEnd = false,
  }) => {
    const [progressAs1, setProgressAs1] = useState(startFromEnd)
    //console.log(`OrderbookSide isBid:${isBid}, `, orders)
    const orders = store.orders
    const isPriceAscending = isPhone && !isBid
    const { digitsAmount, digitsPrice, digitsTotal } = store.getPairDigits(store.symbol)

    const price1Pairs = ['USDC_USDT', 'USDC_USD', 'USDT_USD']
    let averagePrice = 1 // for some pairs always use 1
    if (orders && !price1Pairs.includes(store.symbol)) {
      const bestBidPrice = max(orders.filter((o) => o.isBid).map((o) => o.price))
      const bestAskPrice = min(orders.filter((o) => !o.isBid).map((o) => o.price))
      averagePrice = (bestBidPrice + bestAskPrice) / 2
    }

    return (
      <Swiper
        direction={'vertical'}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={{
          verticalClass: verticalClass,
          dragClass: dragClass,
          draggable: true,
          snapOnRelease: true,
          enabled: true,
        }}
        mousewheel={{ sensitivity: 0.5, releaseOnEdges: true, forceToAxis: true }}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        className={className}
        onResize={(e) => startFromEnd && e.setProgress(1)}
        onInit={(e) => startFromEnd && e.setProgress(1)}
        onScroll={(e) => {
          //если скролбар должен быть внизу, будем следить за прогрессом
          if (startFromEnd) {
            if (e.progress !== 1) setProgressAs1(false)
            else setProgressAs1(true)
          }
        }}
        watchSlidesProgress={true}
      >
        {orders &&
          orders
            .filter((_) => _.isBid === isBid)
            .sort((a, b) => (isPriceAscending ? a.price - b.price : b.price - a.price))
            .map((o, index) => {
              const key = `${o.isBid}_${o.price}`
              const total = (o.price * o.amount).toFixed(digitsTotal)
              const amount = o.amount.toFixed(digitsAmount)
              const price = o.price.toFixed(digitsPrice)

              return (
                <SwiperSlide key={key}>
                  <OrderbookSideRow
                    data={{
                      selected,
                      index,
                      key,
                      total,
                      amount,
                      price,
                      isBid,
                      isPhone,
                      averagePrice,
                      startFromEnd,
                      progressAs1,
                    }}
                  />
                </SwiperSlide>
              )
            })}
      </Swiper>
    )
  },
)

const OrderbookSideRow = ({ data }) => {
  const { selected, index, key, total, amount, price, isBid, isPhone, averagePrice, startFromEnd, progressAs1 } = data
  const swiper = useSwiper()

  const priceDiff = ((price - averagePrice) / averagePrice) * 100
  const showDecimals = priceDiff < 1000
  const priceDiffStr = `${priceDiff < 0 ? '' : '+'}${priceDiff.toFixed(showDecimals ? 2 : 0)}%`

  useEffect(() => {
    swiper.update()
    //для Asks на десктопе необходимо скроллить в конец контейнера
    //при появлении новой строки учитывается текущий прогресс. начинается с 1, если пользователь менял прогресс, то возврата в конец
    //не произойдет. также после использования прокрутки вновь вернуться в конец - такая позиция сохранится при появлении новых строк
    if (startFromEnd && progressAs1) {
      swiper.setProgress(1)
    }
  }, [])
  return (
    <>
      {isPhone && (
        <React.Fragment key={key}>
          {index > 0 ? <div className={stylesPhone.grayLine}></div> : null}
          <div className={cssClass(stylesPhone.cellRow, selected && stylesPhone.cellRowSelected)}>
            <span>{amount}</span>
            <span>{priceDiffStr}</span>
            {selected && <span>{total}</span>}
            <span>{price}</span>
          </div>
        </React.Fragment>
      )}
      {!isPhone && (
        <React.Fragment key={key}>
          {index ? <div className={styles.line}></div> : null}
          <div className={styles.tableItem}>
            <span>{price}</span>
            <span>{amount}</span>
            <span>{priceDiffStr}</span>
            <span>{total}</span>
          </div>
        </React.Fragment>
      )}
    </>
  )
}

export default Orderbook
export { OrderbookSide }
