import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'
import { observer } from 'mobx-react'
import store from '../store'
import api from 'api'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './Deals.module.scss'

const tabs = ['RecentDeals', 'MyDeals', 'MyApplications']

export default function Deals() {
  const [tabId, setTabId] = useState(0)
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={cssClass(styles.phoneVNavButtonsWrapper)}>
        {tabs.map((element, index) => {
          const activityClass = index === tabId ? styles.phoneVNavButtonActive : null
          return (
            <button
              key={index}
              onClick={() => setTabId(index)}
              className={cssClass(styles.phoneVNavButton, activityClass)}
            >
              <span>{t(element)}</span>
            </button>
          )
        })}
      </div>
      <div className={styles.borderWrapper}>
        <div className={cssClass(styles.navWrapper, styles.phoneVHidden)}>
          {tabs.map((element, index) => {
            const activityClass = index === tabId ? styles.navButtonActive : null
            return (
              <button key={index} onClick={() => setTabId(index)} className={cssClass(styles.navButton, activityClass)}>
                <span>{t(element)}</span>
              </button>
            )
          })}
          <div></div>
        </div>
        <DealsList
          tabId={tabId}
          verticalClass={baseComponents.scrollbarRight3px}
          dragClass={baseComponents.scrollbarDragPurple}
        />
      </div>
    </div>
  )
}

const DealsList = observer(({ tabId, verticalClass, dragClass, className = baseComponents.swiperWrapper }) => {
  const { t } = useTranslation()

  const onCancel = (id) => {
    store.deleteOrder(id)
  }

  let deals = []
  if (tabId === 0) deals = store.deals || []
  else if (tabId === 1) deals = store.userDeals || []
  else if (tabId === 2)
    deals =
      store.userOrders?.map((o) => ({
        id: o.id,
        price: o.price,
        volume: o.amount,
        dealDateUtc: o.date,
        currencyPairCode: o.currencyPairCode,
        isBuy: o.isBid,
      })) || []

  const isCancelable = tabId === 2
  return (
    <div className={styles.content}>
      <div className={cssClass(styles.row, isCancelable && styles.row7, styles.title)}>
        <h3>{t('Price')}</h3>
        {/* <h3 className={styles.mobileHidden}>{t('TypeOfPrice')}</h3> */}
        <h3 className={styles.mobileHidden}>{t('TypeOfDeal')}</h3>
        <h3 className={cssClass(styles.mobileOnly, styles.phoneHidden)}>{t('Type')}</h3>
        <h3 className={styles.phoneHidden}>
          {t('Amount')} {store.symbolBase}
        </h3>
        <h3 className={styles.phoneHidden}>
          {t('Amount')} {store.symbolQuote}
        </h3>
        <h3 className={styles.phoneHOnly}>{t('Volume')}</h3>
        <h3 className={styles.phoneOnly}>{t('AmountShort')}</h3>
        <h3>{t('Time')}</h3>
        {isCancelable && <h3 className={styles.phoneHidden}>{t('Action')}</h3>}
      </div>
      <div className={styles.borderRadius}>
        <div className={styles.viewport}>
          <Swiper
            direction={'vertical'}
            slidesPerView={'auto'}
            freeMode={true}
            scrollbar={{
              verticalClass: verticalClass,
              dragClass: dragClass,
              draggable: true,
              snapOnRelease: true,
              enabled: true,
            }}
            mousewheel={{ sensitivity: 0.5, releaseOnEdges: true, forceToAxis: true }}
            modules={[FreeMode, Scrollbar, Mousewheel]}
            className={className}
          >
            {deals.map((element, index) => {
              return (
                <SwiperSlide key={index}>
                  <Deal
                    colored={!(index % 2) && styles.coloredRow}
                    data={element}
                    isCancelable={isCancelable}
                    onCancel={onCancel}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
})

const Deal = ({ colored, data, isCancelable, onCancel }) => {
  const swiper = useSwiper()
  const { price, volume, dealDateUtc, isBuy } = data
  const { t } = useTranslation()
  const type = isBuy ? 'Buy' : 'Sell'
  const activityClass = isBuy ? styles.green : styles.red
  const pairCode = data.currencyPairId || data.currencyPairCode
  const { digitsAmount, digitsPrice, digitsTotal } = store.getPairDigits(pairCode)
  const total = (price * volume).toFixed(digitsTotal)

  useEffect(() => {
    swiper.update()
  })

  return (
    <div className={cssClass(styles.row, isCancelable && styles.row7, activityClass, colored)}>
      <span className={styles.price}>{price.toFixed(digitsPrice)}</span>
      {/* <span className={styles.mobileHidden}>+1.5%</span> */}
      <span className={styles.type}>{t(type)}</span>
      <span className={styles.phoneHOnly}>{total}</span>
      <span>{volume.toFixed(digitsAmount)}</span>
      <span className={styles.phoneHidden}>{total}</span>
      <div className={styles.elementWrapper}>
        <span>{format(new Date(dealDateUtc), 'dd.MM.yyyy')}</span>
        <span>{format(new Date(dealDateUtc), 'HH:mm')}</span>
      </div>
      {isCancelable && (
        <>
          <span className={cssClass(styles.phoneHidden, styles.cancelBtn)} onClick={() => onCancel(data.id)}>
            {t('Cancel')}
          </span>
          <img
            onClick={() => onCancel(data.id)}
            className={cssClass(styles.phoneOnly, styles.cancelImage)}
            src='/images/buttons/cancel.svg'
            alt='Cancel'
          />
        </>
      )}
    </div>
  )
}
