import { useTranslation } from 'react-i18next'
import Modal from '../../../../components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'

export default function SelectPaymentMethodsModal({
  opened,
  setOpened,
  paymentRequisites,
  selectedPaymentRequisites,
  selectedOtcAsset,
  onPaymentMethodChange,
}) {
  const { t } = useTranslation()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)
  const [localSelectedPaymentRequisites, setLocalSelectedPaymentRequisites] = useState([])

  const history = useHistory()
  const goToUserPage = () => {
    history.push({
      pathname: `/${i18n.language}/${'P2P/user'}`,
    })
  }

  const handleConfirm = () => {
    onPaymentMethodChange(localSelectedPaymentRequisites)
    setOpened(false)
  }
  const handleCancel = () => {
    setLocalSelectedPaymentRequisites(selectedPaymentRequisites)
    onPaymentMethodChange(selectedPaymentRequisites)
    setOpened(false)
  }
  const handleSelectedPaymentMethodChange = (methodId) => {
    const selectedCount = localSelectedPaymentRequisites.filter((method) => method.selected).length

    const updatedPaymentMethods = localSelectedPaymentRequisites.map((method) => {
      if (method.id === methodId) {
        if (method.selected || selectedCount < 5) {
          return { ...method, selected: !method.selected }
        }
        return method
      }
      return method
    })
    setLocalSelectedPaymentRequisites(updatedPaymentMethods)

    const isAnyMethodSelected = updatedPaymentMethods.some((method) => method.selected)
    setIsConfirmDisabled(!isAnyMethodSelected)
  }

  useEffect(() => {
    const paymentRequisitesOtcAsset = paymentRequisites.filter(
      (method) => method.paymentMethodCurrencyCode === selectedOtcAsset,
    )
    setLocalSelectedPaymentRequisites(paymentRequisitesOtcAsset)
    if (paymentRequisites.length === 0) return

    const isAnyMethodSelected = selectedPaymentRequisites.some((method) => method.selected)
    setIsConfirmDisabled(!isAnyMethodSelected)
  }, [selectedPaymentRequisites])

  useEffect(() => {
    if (paymentRequisites.length === 0) return

    const newPaymentMethods = paymentRequisites
      .filter((method) => method.paymentMethodCurrencyCode === selectedOtcAsset)
      .map((method) => {
        const existingMethod = selectedPaymentRequisites.find((m) => m.id === method.id)
        return { ...method, selected: existingMethod?.selected || false }
      })

    if (newPaymentMethods.length === 0) {
      setLocalSelectedPaymentRequisites(newPaymentMethods)
      return
    }

    const uniqueMethods = Array.from(new Map(newPaymentMethods.map((method) => [method.id, method])).values())
    setLocalSelectedPaymentRequisites(uniqueMethods)
  }, [paymentRequisites])

  useEffect(() => {
    const localSelectedPaymentRequisites = selectedPaymentRequisites.filter(
      (method) => method.paymentMethodCurrencyCode === selectedOtcAsset,
    )
    onPaymentMethodChange(localSelectedPaymentRequisites)
  }, [selectedOtcAsset])

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('ManagePaymentMethods')}</h2>
          <p>
            <strong>{t('PleaseSelectUpTo5PaymentMethods')}</strong>
          </p>
        </div>
        {Array.isArray(localSelectedPaymentRequisites) &&
          localSelectedPaymentRequisites.map((method) => (
            <div key={method.id} className='modal__methods methods'>
              <div className='methods__list modal__margin_bottom'>
                <div className='methods__item'>
                  <label className='methods__label'>
                    <div className='methods__header'>
                      <div className='methods__item' key={method.id}>
                        <Checkbox
                          type='checkbox'
                          id={method.id}
                          text={`${method?.paymentSystem?.name} (${method?.paymentMethodCurrencyCode})`}
                          checked={method.selected}
                          checkboxCallback={() => handleSelectedPaymentMethodChange(method.id)}
                        />
                      </div>
                    </div>
                    <div className='methods__main'>
                      <div className='data'>
                        <div className='data__list data__list_tertiary data__list_column'>
                          <div className='row'>
                            <div className='col col_3 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('FirstName')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.ownerName}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_5 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankAccountNumber')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.cardNumber}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_4 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankBranch')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.bankBranch || '--'}</span>
                                </div>
                              </div>
                            </div>
                            <div className='col col_4 col_mob-6'>
                              <div className='data__item'>
                                <div className='data__header data__header_secondary'>
                                  <span className='data__title'>{t('BankName')}</span>
                                </div>
                                <div className='data__main'>
                                  <span className='data__text'>{method.bankName || '--'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className='modal__footer'>
        <div className='row'>
          <div className='col col_6 col_mob-12'>
            <div className='form-item' onClick={() => handleCancel()}>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <button className='btn btn_revert w-100'>
                    <span className='btn__text'>{t('Cancel')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col col_6 col_mob-12'>
            <div className='form-item'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <button className='btn w-100' onClick={handleConfirm} disabled={isConfirmDisabled}>
                    <span className='btn__text'>{t('Confirm')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col col_12 cursor-pointer' onClick={goToUserPage}>
            <div className='wysiwyg colorQuartyty500 text-center'>
              <p>
                <strong>{t('P2PHeadToTheUserCenterToAddPaymentMethods')}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
