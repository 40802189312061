import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import Button from '../../../components/form/Button/Button'
export default function SuccessPWModal({ setOpened }) {
  const { t } = useTranslation()
  let successIco = (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'>
      <path
        d='M0 24.5C0 37.7265 10.7735 48.5 24 48.5C37.2265 48.5 48 37.7265 48 24.5C48 11.2735 37.2265 0.5 24 0.5C10.7735 0.5 0 11.2735 0 24.5ZM36.5332 21.0332L21.3333 36.2868L11.4668 26.3667L15.2 22.6335L21.3333 28.7132L32.8002 17.3L36.5332 21.0332Z'
        fill='#202639'
      />
    </svg>
  )
  return (
    <>
      <div className='modal__main'>
        <div className='row'>
          <div className='col col_12'>
            <div className='wysiwyg text-center'>
              {successIco}
              <p>
                <strong>{t('YouWillReceiveAlink')}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='row justify-center'>
          <div className='col col_6 col_mobp-8'>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Button buttonCallback={() => setOpened} addClasses={['btn w-100']} text={t('Close')} type={'Submit'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
