import React from 'react'

import Logo from './Logo/Logo'
import Navigation from './Navigation/Navigation'
import Socials from './Socials/Socials'
import Copyright from './Copyright/Copyright'
import Contact from './Contact/Contact'
export default function Hedear() {
  return (
    <footer id='layout__footer' className='layout__footer footer'>
      <div className='container'>
        <div className='footer__group'>
          <Logo />
        </div>
        <div className='footer__group'>
          <div className='row row_t-revers'>
            <div className='col col_9 col_desktop-8 col_tab-12'>
              <Navigation />
            </div>
            <div className='col col_3 col_desktop-4 col_tab-12'>
              <Contact />
            </div>
          </div>
        </div>
        <div className='footer__group'>
          <Socials />
        </div>
        <div className='footer__group'>
          <Copyright />
        </div>
      </div>
    </footer>
  )
}
