import React from 'react'
export default function FooterCopyright() {
  const copyrightText = '© 2024. «BLAZAREX YAZILIM VE DANIŞMANLIK TİCARET LİMİTED ŞİRKETİ». Официальный сайт.'
  return (
    <div className='footer__copyright'>
      <div className='wysiwyg'>
        <p>{copyrightText}</p>
      </div>
    </div>
  )
}
