import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'
import { useTranslation } from 'react-i18next'

const Datepicker = (props) => {
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null })

  const handleDateChange = (e) => {
    setDateRange(e.value)
    if (props.DatepickerCallback && e.value) {
      let [startDate, endDate] = e.value
      if (!endDate) {
        endDate = new Date(startDate)
        endDate.setDate(endDate.getDate() + 1)
      }
      props.DatepickerCallback({ startDate, endDate })
    }
  }
  const arrow = (
    <svg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'>
      <path
        d='M7.71271 1.90735e-06C8.27424 1.90735e-06 8.66441 0.266523 8.8832 0.799567C9.10199 1.33261 9.0134 1.80522 8.61741 2.21739L5.37296 5.6087C5.24817 5.73913 5.11299 5.83696 4.9674 5.90217C4.82182 5.96739 4.66584 6 4.49946 6C4.33307 6 4.17709 5.96739 4.03151 5.90217C3.88592 5.83696 3.75074 5.73913 3.62595 5.6087L0.381503 2.21739C-0.0136538 1.80435 -0.101836 1.33131 0.116956 0.798263C0.335749 0.26522 0.725498 -0.000867367 1.28621 1.90735e-06H7.71271Z'
        fill='#202639'
      />
    </svg>
  )
  addLocale('ru', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота', 'воскресенье'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ],
    monthNamesShort: ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сент', 'окт', 'нояб', 'дек'],
    today: 'Hoy',
    clear: 'Limpiar',
  })
  addLocale('en', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'november',
      'December',
    ],
    monthNamesShort: ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Hoy',
    clear: 'Limpiar',
  })
  addLocale('tr', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['Pazartesi, Salı, Çarşamba, Perşembe, Cuma, Cumartesi, Pazar'],
    dayNamesShort: ['Paz', 'Paz', 'Sal', 'Çar', 'Per', 'Cum', 'Cum'],
    dayNamesMin: ['Paz', 'Paz', 'Sal', 'Çar', 'Per', 'Cum', 'Cum'],
    monthNames: [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'kasım',
      'Aralık',
    ],
    monthNamesShort: [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ],
    today: 'Hoy',
    clear: 'Limpiar',
  })

  useEffect(() => {
    if (props.dateRange == null || (props.dateRange.startDate === null && props.dateRange.endDate === null)) {
      setDateRange({ startDate: null, endDate: null })
    }
  }, [props])

  return (
    <React.Fragment>
      <Calendar
        className={props.addClasses}
        value={dateRange}
        onChange={handleDateChange}
        selectionMode={props.selectionMode || 'single'}
        locale={i18n.language}
      />
    </React.Fragment>
  )
}
export default Datepicker
