import React, { useState, useEffect } from 'react'
import api from 'api'
import { useTranslation } from 'react-i18next'

import styles from './BalanceBlock.module.scss'

export default function BalanceBlock() {
  const { t } = useTranslation()
  const [balance, setBalance] = useState(null)
  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    api.fetchBalance().then((data) => {
      if (ignore) return
      setBalance(data)
    })
    return () => {
      ignore = true
    }
  }, [])

  const balanceTry = balance ? balance.totalTry.toFixed(balance.digitsTry) : ''
  const balanceUsdt = balance ? balance.totalUsdt.toFixed(balance.digitsUsdt) : ''
  return (
    <div className={styles.balanceBlock}>
      <span className={styles.subTitle}>{t('Balance')}:</span>
      <span className={styles.number}>{balanceUsdt /* TODO use USD */}</span>
      <span className={styles.headlines}>USD</span>
      <span className={styles.tilda}>|</span>
      <span className={styles.number}>{balanceUsdt}</span>
      <span className={styles.headlines}>USDT</span>
    </div>
  )
}
