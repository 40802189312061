import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'
import TokenIcons from '../TokenData/TokenIcons'
import { SortPairCriteria, SortPairs } from '../TokenData/TokenData'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

export default function TradingPairs({ t, enrichedTradablePairs, goToTokenPage, goToTokenTradePage }) {
  const history = useHistory()
  const [currentItems, setCurrentItems] = useState([])
  const [totalPages, setTotalPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const [currentSortCriteria, setCurrentSortCriteria] = useState(SortPairCriteria.Change24H)
  const [currentSortDirection, setCurrentSortDirection] = useState('desc')

  const formatVolume = (volume) => {
    if (volume >= 1e9) {
      return (volume / 1e9).toFixed(2) + 'B'
    } else if (volume >= 1e6) {
      return (volume / 1e6).toFixed(2) + 'M'
    } else {
      return volume.toFixed(2)
    }
  }

  const handleSort = (sortPairCriteria) => {
    if (!enrichedTradablePairs) return

    if (sortPairCriteria === currentSortCriteria) {
      const sortDirection = currentSortDirection === 'asc' ? 'desc' : 'asc'
      setCurrentSortDirection(sortDirection)
    } else {
      setCurrentSortCriteria(sortPairCriteria)
    }
  }

  useEffect(() => {
    if (!enrichedTradablePairs || enrichedTradablePairs.length === 0) return
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const sortedeEnrichedTradablePairs = SortPairs(
      enrichedTradablePairs,
      currentSortCriteria,
      enrichedTradablePairs.length,
      currentSortDirection,
    )
    const currentItems = sortedeEnrichedTradablePairs.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentItems(currentItems)

    const totalPages = Math.ceil(enrichedTradablePairs.length / itemsPerPage)
    setTotalPages(totalPages)
  }, [enrichedTradablePairs, currentPage, currentSortCriteria, currentSortDirection])

  return (
    <React.Fragment>
      <div className='market__main'>
        <div className='market__table table'>
          <div className='table__header'>
            <ul className='table__list table__list_secondary'>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.TokenTradingPairs)}>
                <span className='table__title'>{t('TokenTrandingPairs')}</span> <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.LastTradedPrice)}>
                <span className='table__title'>
                  <span className='table__subtitle'>{t('TokenLastTraidedPrice')}</span>
                </span>
                <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.Change24H)}>
                <span className='table__title'>{t('Token24HChange')}</span>
                <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.High24H)}>
                <span className='table__title'>{t('Token24HHigh')}</span>
                <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.Low24H)}>
                <span className='table__title'>{t('Token24HLow')}</span>
                <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item' onClick={() => handleSort(SortPairCriteria.Volume24H)}>
                <span className='table__title'>{t('Token24HTrandingVolume')}</span>
                <TokenIcons name={'sortIco'} />
              </li>
              <li className='table__item'>
                <span className='table__title'>{t('TokenTrade')}</span>
              </li>
            </ul>
          </div>
          <div className='table__main'>
            {currentItems.map((pair, index) => (
              <ul key={index} className='table__list table__list_secondary'>
                <li
                  className='table__item'
                  onClick={() => goToTokenPage(pair.currencyCode)}
                  style={{ cursor: 'pointer' }}
                >
                  <span className='table__ico'>
                    <CurrencyIcon size={70} currencyCode={pair.currencyCode} />
                  </span>
                  <span className='table__text'>{pair.currencyPair?.getDisplayName}</span>
                  <span className='table__tooltip'>0 fees</span>
                </li>
                <li className='table__item'>
                  <span className='table__text'>{pair.price}</span>
                </li>
                <li className='table__item'>
                  <span className='table__text table__text_secondary'>{pair.priceChangePercentage24h}%</span>
                </li>
                <li className='table__item'>
                  <span className='table__text'>{pair.high24h}</span>
                </li>
                <li className='table__item'>
                  <span className='table__text'>{pair.low24h}</span>
                </li>
                <li className='table__item'>
                  <span className='table__text'>{formatVolume(pair.volume24h)} (USDT)</span>
                </li>
                <li className='table__item'>
                  <button
                    className='form__btn btn btn_transparent btn_tiny'
                    onClick={() => goToTokenPage(pair.currencyCode)}
                  >
                    <span className='btn__text'>{t('TokenDetails')}</span>
                  </button>
                  <button className='form__btn btn btn_tiny' onClick={() => goToTokenTradePage(pair.currencyPair.code)}>
                    <span className='btn__text'>{t('TokenTrade')}</span>
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </div>

        {/* Pagination */}
        <div className='market__footer'>
          <div className='layout__nav nav'>
            <ul className='nav__list'>{RenderPaginationItems(totalPages, currentPage, setCurrentPage)}</ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const RenderPaginationItems = (totalPages, currentPage, setCurrentPage) => {
  let items = []
  const maxVisiblePages = 5

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber)
    }
  }

  items.push(
    <li key='prev' className={`nav__item ${currentPage === 1 ? 'nav__item_disabled' : ''}`}>
      <a
        className='nav__link'
        href='#'
        onClick={(e) => {
          e.preventDefault()
          paginate(currentPage - 1)
        }}
      >
        <TokenIcons name={'arrowLeftIco'} className='nav__ico' />
      </a>
    </li>,
  )

  if (totalPages <= maxVisiblePages) {
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li key={i} className={`nav__item ${currentPage === i ? 'nav__item_active' : ''}`}>
          <a
            className='nav__link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              paginate(i)
            }}
          >
            {i}
          </a>
        </li>,
      )
    }
  } else {
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1)
    }

    if (startPage > 1) {
      items.push(
        <li key={1} className={`nav__item ${currentPage === 1 ? 'nav__item_active' : ''}`}>
          <a
            className='nav__link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              paginate(1)
            }}
          >
            1
          </a>
        </li>,
      )
      if (startPage > 2) {
        items.push(
          <li key='ellipsis1' className='nav__item'>
            <span className='nav__link'>...</span>
          </li>,
        )
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <li key={i} className={`nav__item ${currentPage === i ? 'nav__item_active' : ''}`}>
          <a
            className='nav__link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              paginate(i)
            }}
          >
            {i}
          </a>
        </li>,
      )
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(
          <li key='ellipsis2' className='nav__item'>
            <span className='nav__link'>...</span>
          </li>,
        )
      }
      items.push(
        <li key={totalPages} className={`nav__item ${currentPage === totalPages ? 'nav__item_active' : ''}`}>
          <a
            className='nav__link'
            href='#'
            onClick={(e) => {
              e.preventDefault()
              paginate(totalPages)
            }}
          >
            {totalPages}
          </a>
        </li>,
      )
    }
  }

  items.push(
    <li key='next' className={`nav__item ${currentPage === totalPages ? 'nav__item_disabled' : ''}`}>
      <a
        className='nav__link'
        href='#'
        onClick={(e) => {
          e.preventDefault()
          paginate(currentPage + 1)
        }}
      >
        <TokenIcons name={'arrowRightMarketsIco'} className='nav__ico' />
      </a>
    </li>,
  )

  return items
}
