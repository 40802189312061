import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from '../../../../components/layout/Modal/Modal'
export default function ModalNotification({ opened, setOpened }) {
  const { t } = useTranslation()
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('SettingUpNotifications')}</h2>
        </div>
        <div className='modal__notifications notifications'>
          <div className='notifications__groups groups'>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('Orders')}</h3>
                  <p>{t('ToReceiveNotificationsAbout')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('PushInTheApplication')}</h3>
                          <p>{t('MakeSurePushNotifications')}</p>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('SMSMessages')}</h3>
                          <p>{t('TheSMSNotificationService')}</p>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('Appeals')}</h3>
                  <p>{t('ToReceiveNotificationsAbout')}</p>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('PushInTheApplication')}</h3>
                          <p>{t('MakeSurePushNotifications')}</p>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('EmailMail')}</h3>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('SMSMessages')}</h3>
                          <p>{t('TheSMSNotificationService')}</p>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='notifications__group group'>
              <div className='group__header'>
                <div className='wysiwyg'>
                  <h3>{t('All')}</h3>
                </div>
              </div>
              <div className='group__main'>
                <div className='notifications__list'>
                  <div className='notifications__item'>
                    <label className='notifications__label'>
                      <div className='notifications__main'>
                        <div className='wysiwyg'>
                          <h3>{t('SendNotificationsAboutAllActions')}</h3>
                          <p>{t('WhenYouDisableThisFeature')}</p>
                        </div>
                      </div>
                      <div className='notifications__action'>
                        <div className='notifications__switcher switcher'>
                          <input className='switcher__input' type='checkbox' name='toggle' />
                          <span className='switcher__text'></span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item' onClick={() => setOpened(false)}>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100'>
                      <span className='btn__text'>{t('Cancel')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100'>
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
