import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Button from 'components/form/Button/Button'
import ModalNotifications from './Modals/ModalNotifications'
import MoreDataModal from './Modals/MoreDataModal'
import api from 'api'
import UserRequisites from './UserRequisites'
import UserReviews from './UserReviews'
import UserBlockList from './UserBlockList'
import UserIcons from './UserIcons'
import { STATUS_VERIFICATION_USER, USER_VERIFICATION_STAGE } from '../../verification/Verification/VerificationData'

export default function User() {
  const { t } = useTranslation()
  const [tabs, setTabs] = useState(0)
  const tabsList = ['ConverterPaymentMethod', 'Reviews', 'BlackList']

  const [modalNotifications, setModalNotifications] = useState(false)
  const [isMoreDataModalOpen, setIsMoreDataModalOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [ratingDescription, setRatingDescription] = useState('')

  const [isUserAdvancedVerification, setIsUserAdvancedVerification] = useState(false)

  const {
    userNickname = '',
    completedRecentDealsCount = 0,
    allCompletedDealsCount = 0,
    completedBuysCount = 0,
    completedSellsCount = 0,
    completedRecentDealsPercentage = 0,
    likesCount = 0,
    dislikesCount = 0,
    ratingBasedOnReviews = 0,
    ratingBasedOnDealsAfterDisputes = 0,
  } = userInfo || {}

  useEffect(() => {
    const loadData = () => {
      api
        .fetchP2PUserInfo()
        .then((data) => {
          setUserInfo(data)
        })
        .catch((error) => {
          console.error('Error loading data', error)
        })
    }
    loadData()
  }, [])

  useEffect(() => {
    api.fetchVerification().then((data) => {
      setIsUserAdvancedVerification(
        data.verificationStage === USER_VERIFICATION_STAGE.AdvancedVerification &&
          data.status === STATUS_VERIFICATION_USER.Success,
      )
    })
  }, [])

  useEffect(() => {
    const ratingDescription =
      ratingBasedOnDealsAfterDisputes <= 0.8
        ? 'Low'
        : ratingBasedOnDealsAfterDisputes > 0.8 && ratingBasedOnDealsAfterDisputes <= 0.9
        ? 'Mid'
        : 'High'
    setRatingDescription(ratingDescription)
  }, [userInfo])

  return (
    <React.Fragment>
      <div className='p2p__user user'>
        <div className='section'>
          <div className='section__main'>
            <div className='container'>
              <div className='user__database database'>
                <div className='database__header'>
                  <div className='row'>
                    <div className='col col_6 col_tab-12'>
                      <div className='database__about about'>
                        <div className='about__avatar'>
                          <UserIcons name={'avatar'} />
                        </div>
                        <div className='about__main'>
                          <div className='about__title title'>
                            <span className='title__text'>{userNickname}</span>
                          </div>
                          <div className='about__data data'>
                            <div className='data__list'>
                              <div className='data__item'>
                                <div className='data__media'>
                                  {' '}
                                  <UserIcons name={'ico01'} />
                                </div>
                                <div className='data__header'>
                                  <span className='data__title'>{t('Email')}</span>
                                </div>
                              </div>
                              <div className='data__item '>
                                <div className='data__media'>
                                  {' '}
                                  <UserIcons name={isUserAdvancedVerification ? 'ico01' : 'ico02'} />
                                </div>
                                <div className='data__header '>
                                  <span className='data__title'>{t('Verification')}</span>
                                </div>
                              </div>
                              <div className='data__item data__item_secondary'>
                                <div className='data__media'>
                                  {' '}
                                  <UserIcons name={'ico02'} />
                                </div>
                                <div className='data__header'>
                                  <span className='data__title'>{t('DepositUserPage')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_tab-12'>
                      <div className='database__action action'>
                        <div className='action__list'>
                          {/* <div className='action__item'>
                            <Button addClasses={['btn btn_secondary-padding w-100']} text={t('VerifiedMerchant')} />
                          </div> */}
                          <div className='action__item'>
                            <Button
                              buttonCallback={() => setModalNotifications(true)}
                              addClasses={['btn btn_secondary-padding w-100']}
                              text={t('NotificationSettings')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='database__main'>
                  <div className='database__groups groups'>
                    <div className='groups__list'>
                      <div className='row'>
                        <div className='col col_4 col_mob-6'>
                          <div className='groups__item group'>
                            <div className='group__header'>
                              <div className='group__title title'>
                                <span className='title__text'>{t('ExecutedDealsWithin30Days')}</span>
                              </div>
                            </div>
                            <div className='group__main'>
                              <div className='group__value value'>
                                <span className='data__text'>
                                  {completedRecentDealsCount} {t('Deal')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col col_4 col_mob-6'>
                          <div className='groups__item group'>
                            <div className='group__header'>
                              <div className='group__title title'>
                                <span className='title__text'>{t('CompletedIn30Days')}</span>
                              </div>
                            </div>
                            <div className='group__main'>
                              <div className='group__value value'>
                                <span className='data__text'>{(completedRecentDealsPercentage * 100).toFixed(2)}%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col col_4 col_mob-12 mob-order-5'>
                          <div className='groups__item group'>
                            <div className='group__header'>
                              <div className='group__title title'>
                                <span className='title__text'>{t('VerifiedName')}</span>
                              </div>
                              <div className='group__value value'>
                                <span className='data__text'>{userNickname}</span>
                                <br /> <br /> <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col col_4 col_mob-6'>
                          <div className='groups__item group'>
                            <div className='group__header'>
                              <div className='group__title title'>
                                <span className='title__text'>{t('AllExecutedDeals')}</span>
                              </div>
                              <div className='group__data data'>
                                <div className='data__list'>
                                  <div className='data__item data__item_buy'>
                                    <span className='data__text'>Buy: {completedBuysCount}</span>
                                  </div>
                                  <div className='data__item data__item_sell'>
                                    <span className='data__text'>Sell: {completedSellsCount}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='group__main'>
                              <div className='group__value value'>
                                <span className='data__text'>
                                  {allCompletedDealsCount} {t('Deal')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col col_4 col_mob-6'>
                          <div className='groups__item group'>
                            <div className='group__header'>
                              <div className='group__title title'>
                                <span className='title__text'>
                                  {t(`P2PCurrentRating`)} - {t(`P2P${ratingDescription}`)} (%)
                                </span>
                              </div>
                              <div className='group__data data'>
                                <div className='data__list'>
                                  <div className='data__item data__item_buy'>
                                    <span className='data__text'>
                                      <UserIcons name={'ThumbUpIco'} /> {likesCount}{' '}
                                    </span>
                                  </div>
                                  <div className='data__item data__item_sell'>
                                    <span className='data__text'>
                                      <UserIcons name={'ThumbDownIco'} /> {dislikesCount}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='group__main'>
                              <div className='group__value value'>
                                <span className='data__text'>
                                  {(ratingBasedOnDealsAfterDisputes * 100).toFixed(2)}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col col_4 col_mob-12 mob-order-6'>
                          <div className='groups__action'>
                            <Button
                              buttonCallback={() => setIsMoreDataModalOpen(true)}
                              addClasses={['btn btn_secondary-padding w-100']}
                              text={t('MoreData')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tabs'>
                <div className='tabs__header'>
                  <div className='tabs__list'>
                    {tabsList.map((element, index) => {
                      return (
                        <div
                          className={`tabs__item ${element === tabsList[tabs] && 'tabs__item_active'}`}
                          onClick={() => setTabs(index)}
                          key={index}
                        >
                          <span className='tabs__title'>{t(element)}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='tabs__main'>
                  <div className='tabs__list'>
                    <div className='tabs__item'>
                      {tabs === 0 && <UserRequisites />}
                      {tabs === 1 && <UserReviews />}
                      {tabs === 2 && <UserBlockList />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalNotifications
        opened={modalNotifications}
        setOpened={() => {
          setModalNotifications(false)
        }}
      />
      <MoreDataModal
        opened={isMoreDataModalOpen}
        setOpened={setIsMoreDataModalOpen}
        userInfo={userInfo}
        ratingDescription={ratingDescription}
      />
    </React.Fragment>
  )
}
