import React, { useEffect, useState } from 'react'
import { cssClass } from 'helpers/helper'
import style from './style/ProjectPage.module.scss'
import header from './img/header.jpg'
import logo from './img/logo.png'
import status from './img/status.png'
import description from './img/description.jpg'
import schedule from './img/schedule.jpg'
import '../../style/main.scss'

const arrowTop = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M8.11561 6.64001H15.9514L4.93059 17.6609C4.85136 17.7401 4.7882 17.8293 4.74112 17.9245C4.61416 18.1811 4.60409 18.4808 4.71092 18.7443C4.75974 18.8646 4.83296 18.9774 4.93059 19.0751C5.32111 19.4656 5.95428 19.4656 6.3448 19.0751L17.3656 8.05422V15.89C17.3656 16.4423 17.8133 16.89 18.3656 16.89C18.9179 16.89 19.3656 16.4423 19.3656 15.89V5.64001C19.3656 5.60549 19.3639 5.57138 19.3605 5.53776C19.3104 5.04486 18.9024 4.65778 18.4004 4.6406C18.3889 4.64021 18.3773 4.64001 18.3656 4.64001H8.11561C7.56333 4.64001 7.11562 5.08772 7.11562 5.64001C7.11561 6.19229 7.56333 6.64001 8.11561 6.64001Z' />
    </svg>
  ),
  arrowBottom = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M17.3601 8.11561V15.9514L6.33926 4.93059C6.26006 4.85136 6.17086 4.7882 6.07566 4.74112C5.81906 4.61416 5.51936 4.60409 5.25586 4.71092C5.13556 4.75974 5.02276 4.83296 4.92506 4.93059C4.53456 5.32111 4.53456 5.95428 4.92506 6.3448L15.9459 17.3656H8.11016C7.55786 17.3656 7.11016 17.8133 7.11016 18.3656C7.11016 18.9179 7.55786 19.3656 8.11016 19.3656H18.3601C18.3946 19.3656 18.4287 19.3639 18.4624 19.3605C18.9553 19.3104 19.3423 18.9024 19.3595 18.4004C19.3599 18.3889 19.3601 18.3773 19.3601 18.3656V8.11561C19.3601 7.56333 18.9124 7.11562 18.3601 7.11562C17.8078 7.11561 17.3601 7.56333 17.3601 8.11561Z' />
    </svg>
  ),
  arrowLeft = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 12' fill='none'>
      <path
        d='M5.9699 1.72205L1.60943 6.08256L5.9699 10.443C6.23025 10.7034 6.23025 11.1255 5.9699 11.3858C5.70955 11.6462 5.28744 11.6462 5.02709 11.3858L0.195227 6.55396C0.0701606 6.42889 -3.93487e-05 6.25936 -3.9341e-05 6.08256C-3.93333e-05 5.90576 0.0701606 5.73616 0.195227 5.61116L5.02709 0.779246C5.05963 0.746699 5.09471 0.718226 5.13167 0.693819C5.39044 0.522966 5.74209 0.551439 5.9699 0.779246C6.23025 1.03959 6.23025 1.46171 5.9699 1.72205Z'
        fill-opacity='0.5'
      />
    </svg>
  ),
  tagsIco01 = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
      <path
        d='M21.41 8.64C21.41 8.64 21.41 8.64 21.41 8.59C20.7053 6.66623 19.4269 5.00529 17.7474 3.83187C16.068 2.65845 14.0687 2.02917 12.02 2.02917C9.9712 2.02917 7.97189 2.65845 6.29246 3.83187C4.61304 5.00529 3.33455 6.66623 2.62995 8.59C2.62995 8.59 2.62995 8.59 2.62995 8.64C1.84308 10.8109 1.84308 13.1891 2.62995 15.36C2.62995 15.36 2.62995 15.36 2.62995 15.41C3.33455 17.3338 4.61304 18.9947 6.29246 20.1681C7.97189 21.3416 9.9712 21.9708 12.02 21.9708C14.0687 21.9708 16.068 21.3416 17.7474 20.1681C19.4269 18.9947 20.7053 17.3338 21.41 15.41C21.41 15.41 21.41 15.41 21.41 15.36C22.1968 13.1891 22.1968 10.8109 21.41 8.64ZM4.25995 14C3.91318 12.6892 3.91318 11.3108 4.25995 10H6.11995C5.95998 11.3285 5.95998 12.6715 6.11995 14H4.25995ZM5.07995 16H6.47995C6.71467 16.8918 7.05016 17.7541 7.47995 18.57C6.49925 17.9019 5.67945 17.0241 5.07995 16ZM6.47995 8H5.07995C5.67082 6.97909 6.48014 6.10147 7.44995 5.43C7.03051 6.24725 6.70509 7.10942 6.47995 8ZM11 19.7C9.77172 18.7987 8.9091 17.4852 8.56995 16H11V19.7ZM11 14H8.13995C7.95334 12.6732 7.95334 11.3268 8.13995 10H11V14ZM11 8H8.56995C8.9091 6.51477 9.77172 5.20132 11 4.3V8ZM18.92 8H17.52C17.2852 7.10816 16.9497 6.24594 16.52 5.43C17.5007 6.09807 18.3205 6.97594 18.92 8ZM13 4.3C14.2282 5.20132 15.0908 6.51477 15.43 8H13V4.3ZM13 19.7V16H15.43C15.0908 17.4852 14.2282 18.7987 13 19.7ZM15.86 14H13V10H15.86C16.0466 11.3268 16.0466 12.6732 15.86 14ZM16.55 18.57C16.9797 17.7541 17.3152 16.8918 17.55 16H18.95C18.3505 17.0241 17.5307 17.9019 16.55 18.57ZM19.74 14H17.88C17.9613 13.3365 18.0014 12.6685 18 12C18.0011 11.3315 17.961 10.6636 17.88 10H19.74C20.0867 11.3108 20.0867 12.6892 19.74 14Z'
        fill='#202639'
        fillOpacity='0.5'
      />
    </svg>
  ),
  tagsIco02 = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 24' fill='none'>
      <path
        d='M9.5 10H10.5C10.7652 10 11.0196 9.89464 11.2071 9.70711C11.3946 9.51957 11.5 9.26522 11.5 9C11.5 8.73478 11.3946 8.48043 11.2071 8.29289C11.0196 8.10536 10.7652 8 10.5 8H9.5C9.23478 8 8.98043 8.10536 8.79289 8.29289C8.60536 8.48043 8.5 8.73478 8.5 9C8.5 9.26522 8.60536 9.51957 8.79289 9.70711C8.98043 9.89464 9.23478 10 9.5 10ZM9.5 12C9.23478 12 8.98043 12.1054 8.79289 12.2929C8.60536 12.4804 8.5 12.7348 8.5 13C8.5 13.2652 8.60536 13.5196 8.79289 13.7071C8.98043 13.8946 9.23478 14 9.5 14H15.5C15.7652 14 16.0196 13.8946 16.2071 13.7071C16.3946 13.5196 16.5 13.2652 16.5 13C16.5 12.7348 16.3946 12.4804 16.2071 12.2929C16.0196 12.1054 15.7652 12 15.5 12H9.5ZM20.5 8.94C20.4896 8.84813 20.4695 8.75763 20.44 8.67V8.58C20.3919 8.47718 20.3278 8.38267 20.25 8.3L14.25 2.3C14.1673 2.22222 14.0728 2.15808 13.97 2.11C13.9402 2.10576 13.9099 2.10576 13.88 2.11C13.7784 2.05174 13.6662 2.01434 13.55 2H7.5C6.70435 2 5.94129 2.31607 5.37868 2.87868C4.81607 3.44129 4.5 4.20435 4.5 5V19C4.5 19.7956 4.81607 20.5587 5.37868 21.1213C5.94129 21.6839 6.70435 22 7.5 22H17.5C18.2956 22 19.0587 21.6839 19.6213 21.1213C20.1839 20.5587 20.5 19.7956 20.5 19V9C20.5 9 20.5 9 20.5 8.94ZM14.5 5.41L17.09 8H15.5C15.2348 8 14.9804 7.89464 14.7929 7.70711C14.6054 7.51957 14.5 7.26522 14.5 7V5.41ZM18.5 19C18.5 19.2652 18.3946 19.5196 18.2071 19.7071C18.0196 19.8946 17.7652 20 17.5 20H7.5C7.23478 20 6.98043 19.8946 6.79289 19.7071C6.60536 19.5196 6.5 19.2652 6.5 19V5C6.5 4.73478 6.60536 4.48043 6.79289 4.29289C6.98043 4.10536 7.23478 4 7.5 4H12.5V7C12.5 7.79565 12.8161 8.55871 13.3787 9.12132C13.9413 9.68393 14.7044 10 15.5 10H18.5V19ZM15.5 16H9.5C9.23478 16 8.98043 16.1054 8.79289 16.2929C8.60536 16.4804 8.5 16.7348 8.5 17C8.5 17.2652 8.60536 17.5196 8.79289 17.7071C8.98043 17.8946 9.23478 18 9.5 18H15.5C15.7652 18 16.0196 17.8946 16.2071 17.7071C16.3946 17.5196 16.5 17.2652 16.5 17C16.5 16.7348 16.3946 16.4804 16.2071 16.2929C16.0196 16.1054 15.7652 16 15.5 16Z'
        fill='#202639'
        fillOpacity='0.5'
      />
    </svg>
  ),
  information = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none'>
      <g clip-path='url(#clip0_12_1542)'>
        <rect width='18' height='18' rx='9' fill='white' />
        <path
          d='M9 8.25C8.80109 8.25 8.61033 8.32902 8.46967 8.46967C8.32902 8.61032 8.25 8.80109 8.25 9V12C8.25 12.1989 8.32902 12.3897 8.46967 12.5303C8.61033 12.671 8.80109 12.75 9 12.75C9.19892 12.75 9.38968 12.671 9.53033 12.5303C9.67099 12.3897 9.75 12.1989 9.75 12V9C9.75 8.80109 9.67099 8.61032 9.53033 8.46967C9.38968 8.32902 9.19892 8.25 9 8.25ZM9.285 5.31C9.10241 5.23499 8.8976 5.23499 8.715 5.31C8.62294 5.3457 8.53883 5.39922 8.4675 5.4675C8.40126 5.5404 8.34798 5.62411 8.31 5.715C8.26802 5.80401 8.24747 5.90162 8.25 6C8.24943 6.0987 8.26835 6.19655 8.30568 6.28793C8.343 6.37931 8.39799 6.46242 8.4675 6.5325C8.5404 6.59875 8.62411 6.65202 8.715 6.69C8.82863 6.73668 8.95198 6.75474 9.07421 6.74258C9.19645 6.73043 9.31383 6.68844 9.41604 6.6203C9.51825 6.55216 9.60215 6.45996 9.66039 6.3518C9.71862 6.24364 9.74939 6.12284 9.75 6C9.74724 5.80142 9.66955 5.61123 9.5325 5.4675C9.46118 5.39922 9.37707 5.3457 9.285 5.31ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98492 1.5 9 1.5ZM9 15C7.81332 15 6.65328 14.6481 5.66658 13.9888C4.67989 13.3295 3.91085 12.3925 3.45673 11.2961C3.0026 10.1997 2.88378 8.99334 3.11529 7.82946C3.3468 6.66557 3.91825 5.59647 4.75736 4.75736C5.59648 3.91824 6.66558 3.3468 7.82946 3.11529C8.99335 2.88378 10.1997 3.0026 11.2961 3.45672C12.3925 3.91085 13.3295 4.67988 13.9888 5.66658C14.6481 6.65327 15 7.81331 15 9C15 10.5913 14.3679 12.1174 13.2426 13.2426C12.1174 14.3679 10.5913 15 9 15Z'
          fill='#202639'
        />
      </g>
      <defs>
        <clipPath id='clip0_12_1542'>
          <rect width='18' height='18' rx='9' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  socialIco = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <g id='guidlines' />
      <g id='FB' />
      <g id='ig' />
      <g id='yt' />
      <g id='twitter' />
      <g id='snapchat' />
      <g id='WA' />
      <g id='Pinterrest' />
      <g id='Layer_9' />
      <g id='Layer_10' />
      <g id='Layer_11'>
        <path
          class='st13'
          d='M4,13.9c2.1,0,5.3,0,7.1,0c0.9,0,1.6,0.6,1.9,1.4c0.9,2.6,2.9,8.3,5.2,12.2c3.1,5.1,5.1,7,6.4,6.8   c1.3-0.3,0.9-3.7,0.9-6.4s0.3-7.3-1-9.4l-2-2.9c-0.5-0.7,0-1.6,0.8-1.6h11.4c1.1,0,2,0.9,2,2v14.5c0,0,0.5,2.6,3.3-0.1   c2.8-2.7,5.8-7.7,8.3-12.8l1-2.4c0.3-0.7,1-1.2,1.8-1.2h7.4c1.4,0,2.4,1.4,1.9,2.7l-0.8,2.1c0,0-2.7,5.4-5.5,9.2   c-2.8,3.9-3.4,4.8-3,5.8c0.4,1,7.6,7.7,9.4,10.9c0.5,0.9,0.9,1.7,1.3,2.4c0.7,1.3-0.3,3-1.8,3l-8.4,0c-0.7,0-1.4-0.4-1.7-1   l-0.8-1.3c0,0-5.1-6-8.2-7.9c-3.2-1.8-3.1,0.8-3.1,0.8v5.3c0,2.2-1.8,4-4,4h-2c0,0-11,0-19.8-13.1C5.1,26.7,2.8,20.1,2,16.3   C1.8,15.1,2.7,13.9,4,13.9z'
        />
      </g>
      <g id='Layer_12' />
      <g id='Layer_13' />
      <g id='Layer_14' />
      <g id='Layer_15' />
      <g id='Layer_16' />
      <g id='Layer_17' />
    </svg>
  ),
  favorite = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M20.16 5.00017C19.1 3.93737 17.6948 3.28871 16.1983 3.17134C14.7019 3.05397 13.2128 3.47563 12 4.36017C10.7277 3.4138 9.14399 2.98468 7.56792 3.1592C5.99185 3.33373 4.54044 4.09894 3.50597 5.30075C2.47151 6.50256 1.93082 8.05169 1.9928 9.63618C2.05478 11.2207 2.71482 12.7228 3.84 13.8402L10.05 20.0602C10.57 20.5719 11.2704 20.8588 12 20.8588C12.7296 20.8588 13.43 20.5719 13.95 20.0602L20.16 13.8402C21.3276 12.6654 21.9829 11.0764 21.9829 9.42017C21.9829 7.76389 21.3276 6.1749 20.16 5.00017ZM18.75 12.4602L12.54 18.6702C12.4693 18.7415 12.3852 18.7982 12.2925 18.8368C12.1999 18.8755 12.1004 18.8954 12 18.8954C11.8996 18.8954 11.8001 18.8755 11.7075 18.8368C11.6148 18.7982 11.5307 18.7415 11.46 18.6702L5.25 12.4302C4.46576 11.6285 4.02661 10.5516 4.02661 9.43017C4.02661 8.3087 4.46576 7.23182 5.25 6.43017C6.04916 5.64115 7.12697 5.19873 8.25 5.19873C9.37303 5.19873 10.4508 5.64115 11.25 6.43017C11.343 6.52389 11.4536 6.59829 11.5754 6.64906C11.6973 6.69983 11.828 6.72596 11.96 6.72596C12.092 6.72596 12.2227 6.69983 12.3446 6.64906C12.4664 6.59829 12.577 6.52389 12.67 6.43017C13.4692 5.64115 14.547 5.19873 15.67 5.19873C16.793 5.19873 17.8708 5.64115 18.67 6.43017C19.465 7.22132 19.9186 8.29236 19.9335 9.41385C19.9485 10.5353 19.5236 11.6181 18.75 12.4302V12.4602Z' />
    </svg>
  ),
  comment = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25'>
      <path d='M19 2.5H5C4.20435 2.5 3.44129 2.81607 2.87868 3.37868C2.31607 3.94129 2 4.70435 2 5.5V15.5C2 16.2956 2.31607 17.0587 2.87868 17.6213C3.44129 18.1839 4.20435 18.5 5 18.5H16.59L20.29 22.21C20.3834 22.3027 20.4943 22.376 20.6161 22.4258C20.7379 22.4755 20.8684 22.5008 21 22.5C21.1312 22.5034 21.2613 22.476 21.38 22.42C21.5626 22.345 21.7189 22.2176 21.8293 22.0539C21.9396 21.8901 21.999 21.6974 22 21.5V5.5C22 4.70435 21.6839 3.94129 21.1213 3.37868C20.5587 2.81607 19.7956 2.5 19 2.5ZM20 19.09L17.71 16.79C17.6166 16.6973 17.5057 16.624 17.3839 16.5742C17.2621 16.5245 17.1316 16.4992 17 16.5H5C4.73478 16.5 4.48043 16.3946 4.29289 16.2071C4.10536 16.0196 4 15.7652 4 15.5V5.5C4 5.23478 4.10536 4.98043 4.29289 4.79289C4.48043 4.60536 4.73478 4.5 5 4.5H19C19.2652 4.5 19.5196 4.60536 19.7071 4.79289C19.8946 4.98043 20 5.23478 20 5.5V19.09Z' />
    </svg>
  ),
  chevronTop = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'>
      <path d='M9.85903 6.38775L5.49853 2.02727L1.13806 6.38775C0.877726 6.64809 0.455592 6.64809 0.195259 6.38775C-0.0650749 6.12739 -0.0650749 5.70529 0.195259 5.44494L5.02713 0.613074C5.15219 0.488008 5.32173 0.417808 5.49853 0.417808C5.67533 0.417808 5.84493 0.488008 5.96993 0.613074L10.8018 5.44494C10.8344 5.47748 10.8629 5.51255 10.8873 5.54952C11.0581 5.80829 11.0296 6.15994 10.8018 6.38775C10.5415 6.64809 10.1194 6.64809 9.85903 6.38775Z' />
    </svg>
  ),
  edit = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        d='M1.67082e-05 10.7619V15.0003C1.67082e-05 15.2655 0.105335 15.5197 0.292804 15.7072C0.480272 15.8947 0.734533 16 0.999653 16H5.23811C5.36967 16.0007 5.50009 15.9755 5.62188 15.9258C5.74367 15.876 5.85445 15.8027 5.94785 15.7101L12.8653 8.78261L15.7043 6.00362C15.798 5.91069 15.8724 5.80013 15.9231 5.67831C15.9739 5.5565 16 5.42584 16 5.29387C16 5.16191 15.9739 5.03125 15.9231 4.90944C15.8724 4.78762 15.798 4.67706 15.7043 4.58413L11.4658 0.295691C11.3729 0.201996 11.2624 0.127629 11.1405 0.076879C11.0187 0.0261287 10.8881 0 10.7561 0C10.6241 0 10.4935 0.0261287 10.3717 0.076879C10.2499 0.127629 10.1393 0.201996 10.0464 0.295691L7.22739 3.12466L0.289911 10.0521C0.197264 10.1456 0.123965 10.2563 0.0742185 10.3781C0.024472 10.4999 -0.000743908 10.6303 1.67082e-05 10.7619ZM10.7561 2.41492L13.5851 5.24389L12.1656 6.66338L9.33662 3.8344L10.7561 2.41492ZM1.99929 11.1717L7.92714 5.24389L10.7561 8.07286L4.82826 14.0007H1.99929V11.1717Z'
        fill='#202639'
        fill-opacity='0.8'
      />
    </svg>
  )

export default function ProjectPage() {
  const [tabs, setTabs] = useState(1)
  const [darkmode, setDarkmode] = useState(false)
  const [detail, setDetail] = useState(false)

  return (
    <div className={cssClass(style.project, darkmode ? cssClass(style.project_dark) : null)}>
      <picture className={cssClass(style.project__picture)}>
        <img className={cssClass(style.project__img)} src={header} />
      </picture>
      <header className={cssClass(style.project__header)}>
        <div className={cssClass(style.container)}>
          <div className={cssClass(style.project__data, style.data)}>
            <div className={cssClass(style.data__list)}>
              <div className={cssClass(style.data__item, style.data__item_secondary)}>
                <div className={cssClass(style.data__media)}>
                  <img src={logo} />
                </div>
                <div className={cssClass(style.data__header)}>
                  <div className={cssClass(style.data__status, style.status)}>
                    <img src={status} />
                    <span className={cssClass(style.status__text)}>Verified</span>
                  </div>
                </div>
                <div className={cssClass(style.data__main)}>
                  <span className={cssClass(style.data__subtitle)}>Название проекта:</span>
                  <span className={cssClass(style.data__title)}>Linus Tech Tips</span>
                </div>
                <div className={cssClass(style.data__footer)}>
                  <a className={cssClass(style.btn)} href='#'>
                    <span className={cssClass(style.btn__text)}>Купить токен</span>
                    {arrowTop}
                  </a>
                  <a className={cssClass(style.data__btn)} href='#'>
                    {edit}
                  </a>
                </div>
              </div>
              <div className={cssClass(style.data__item)}>
                <div className={cssClass(style.data__main)}>
                  <span className={cssClass(style.data__subtitle)}>Токен:</span>
                  <span className={cssClass(style.data__title)}>LTT</span>
                </div>
              </div>
              <div className={cssClass(style.data__item)}>
                <div className={cssClass(style.data__main)}>
                  <span className={cssClass(style.data__subtitle)}>Цена токена:</span>
                  <span className={cssClass(style.data__title)}>$0,0423</span>
                </div>
              </div>
              <div className={cssClass(style.data__item)}>
                <div className={cssClass(style.data__main)}>
                  <span className={cssClass(style.data__subtitle)}>Дата создания токена:</span>
                  <span className={cssClass(style.data__title)}>Март, 2023</span>
                </div>
              </div>
              <div className={cssClass(style.data__item)}>
                <div className={cssClass(style.data__main)}>
                  <span className={cssClass(style.data__subtitle)}>Изменение цены:</span>
                  <span className={cssClass(style.data__title, style.data__title_secondary)}>+25,39%</span>
                </div>
              </div>
            </div>
          </div>
          <div className={cssClass(style.project__tags, style.tags)}>
            <ul className={cssClass(style.tags__list)}>
              <li
                className={cssClass(style.tags__item)}
                onClick={() => {
                  setDarkmode(true)
                }}
              >
                {tagsIco01}
                <span className={cssClass(style.tags__text)}>Официальный сайт</span>
              </li>
              <li
                className={cssClass(style.tags__item)}
                onClick={() => {
                  setDarkmode(false)
                }}
              >
                {tagsIco02}
                <span className={cssClass(style.tags__text)}>Белый лист</span>
              </li>
            </ul>
          </div>
          <div className={cssClass(style.project__tabs, style.tabs)}>
            <div className={cssClass(style.tabs__header)}>
              <ul className={cssClass(style.tabs__list)}>
                <li
                  className={cssClass(style.tabs__item, tabs === 1 ? cssClass(style.tabs__item_active) : null)}
                  onClick={() => {
                    setTabs(1)
                  }}
                >
                  <span className={cssClass(style.tabs__text)}>Основная информация</span>
                </li>
                <li
                  className={cssClass(style.tabs__item, tabs === 2 ? cssClass(style.tabs__item_active) : null)}
                  onClick={() => {
                    setTabs(2)
                  }}
                >
                  <span className={cssClass(style.tabs__text)}>Обновления</span>
                  <span className={cssClass(style.tabs__count)}>5</span>
                </li>
                <li
                  className={cssClass(style.tabs__item, tabs === 3 ? cssClass(style.tabs__item_active) : null)}
                  onClick={() => {
                    setTabs(3)
                  }}
                >
                  <span className={cssClass(style.tabs__text)}>Комментарии</span>
                  <span className={cssClass(style.tabs__count)}>324</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <main className={cssClass(style.project__main)}>
        <div className={cssClass(style.project__tabs, style.tabs)}>
          <div className={cssClass(style.tabs__main)}>
            <div className={cssClass(style.tabs__list)}>
              {tabs === 1 && (
                <div className={cssClass(style.tabs__item)}>
                  <div className={cssClass(style.project__information, style.information)}>
                    <div className={cssClass(style.container)}>
                      <main className={cssClass(style.information__main)}>
                        <div className={cssClass(style.information__section, style.section)}>
                          <div className={cssClass(style.information__graphic, style.graphic)}>
                            <div className={cssClass(style.graphic__header)}>
                              <div className={cssClass(style.graphic__ico)}>
                                <img src={logo} />
                              </div>
                              <div className={cssClass(style.graphic__title)}>
                                <span className={cssClass(style.graphic__text)}>Курс Linus Tech Tips </span>
                              </div>
                              <div className={cssClass(style.graphic__subtitle)}>
                                <span className={cssClass(style.graphic__text)}>(LTT) </span>
                              </div>
                              <div className={cssClass(style.graphic__sellect)}>
                                <select className={cssClass(style.comments__select)}>
                                  <option value=''>USDT $</option>
                                  <option value=''>Тест 2</option>
                                  <option value=''>Тест 3</option>
                                </select>
                              </div>
                            </div>
                            <div className={cssClass(style.graphic__cost, style.cost)}>
                              <span className={cssClass(style.cost__title)}>$0,0423</span>
                              <span className={cssClass(style.cost__subtitle)}>+25,39%</span>
                            </div>
                            <div className={cssClass(style.graphic__main)}>
                              <div className={cssClass(style.graphic__schedule, style.schedule)}>
                                <div className={cssClass(style.schedule__calendar, style.calendar)}>
                                  <ul className={cssClass(style.calendar__list)}>
                                    <li className={cssClass(style.calendar__item, style.calendar__item_active)}>
                                      <span className={cssClass(style.calendar__text)}>1 дн.</span>
                                    </li>
                                    <li className={cssClass(style.calendar__item)}>
                                      <span className={cssClass(style.calendar__text)}>7 дн.</span>
                                    </li>
                                    <li className={cssClass(style.calendar__item)}>
                                      <span className={cssClass(style.calendar__text)}>1 мес.</span>
                                    </li>
                                    <li className={cssClass(style.calendar__item)}>
                                      <span className={cssClass(style.calendar__text)}>3 мес.</span>
                                    </li>
                                    <li className={cssClass(style.calendar__item)}>
                                      <span className={cssClass(style.calendar__text)}>1 г.</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className={cssClass(style.schedule__cost, style.cost)}>
                                  <span className={cssClass(style.cost__title)}>USDT $</span>
                                  <ul className={cssClass(style.cost__list)}>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>40,4k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>40k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>39,4k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>39k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>38,4k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>38k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>37,4k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>37k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>36,4k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>36k</span>
                                    </li>
                                    <li className={cssClass(style.cost__item)}>
                                      <span className={cssClass(style.cost__text)}>35,4k</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className={cssClass(style.schedule__media)}>
                                  <img src={schedule} />
                                </div>
                                <div className={cssClass(style.schedule__times, style.times)}>
                                  <ul className={cssClass(style.times__list)}>
                                    <li className={cssClass(style.times__item)}>
                                      <span className={cssClass(style.times__text)}>3:00 AM</span>
                                    </li>
                                    <li className={cssClass(style.times__item)}>
                                      <span className={cssClass(style.times__text)}>6:00 AM</span>
                                    </li>
                                    <li className={cssClass(style.times__item)}>
                                      <span className={cssClass(style.times__text)}>9:00 AM</span>
                                    </li>
                                    <li className={cssClass(style.times__item)}>
                                      <span className={cssClass(style.times__text)}>12:00 AM</span>
                                    </li>
                                    <li className={cssClass(style.times__item)}>
                                      <span className={cssClass(style.times__text)}>3:00 PM</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(style.graphic__footer)}>
                              <span className={cssClass(style.graphic__text)}>
                                Последнее обновление: 2024-03-31 09:01 (UTC+0)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={cssClass(style.information__section, style.section)}>
                          <div className={cssClass(style.information__description, style.description)}>
                            <div className={cssClass(style.description__head)}>
                              <span className={cssClass(style.description__title)}>Описание проекта:</span>
                              <span className={cssClass(style.description__subtitle)}>Linus Tech Tips</span>
                            </div>
                            <picture className={cssClass(style.description__media)}>
                              <img src={description} />
                            </picture>
                            <header className={cssClass(style.description__header)}>
                              <span className={cssClass(style.description__title)}>Thoughtful Sizing</span>
                              <span className={cssClass(style.description__subtitle)}>
                                Large on Paint Space and Storage Yet Low Profile and Portable
                              </span>
                            </header>
                            <main className={cssClass(style.description__main)}>
                              <p className={cssClass(style.description__text)}>
                                The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                keeping the integrated storage compartment large enough to hold the mixing tray, texture
                                palette, or tools as well as a selection of standard length paint brushes. The air vent
                                is located just inside the storage compartment on the Exemplar GO to save space and
                                features!
                              </p>
                            </main>
                          </div>
                        </div>
                      </main>
                      <aside className={cssClass(style.information__sidebar, style.sidebar)}>
                        <div className={cssClass(style.sidebar__group, style.group)}>
                          <div className={cssClass(style.group__header)}>
                            <span className={cssClass(style.group__title)}>Цель проекта:</span>
                            <span className={cssClass(style.group__subtitle)}>Linus Tech Tips</span>
                          </div>
                          <div className={cssClass(style.group__main)}>
                            <ul className={cssClass(style.group__list)}>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Условия инвестирования:</span>
                                <span className={cssClass(style.group__subtitle)}>
                                  Пожертвования
                                  <div className={cssClass(style.group__tooltip, style.tooltip)}>
                                    <div className={cssClass(style.tooltip__ico)}>{information}</div>
                                    <div className={cssClass(style.tooltip__dropdown)}>
                                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, sit!</p>
                                    </div>
                                  </div>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className={cssClass(style.sidebar__group, style.group)}>
                          <div className={cssClass(style.group__header)}>
                            <span className={cssClass(style.group__title)}>Общая информация проекта:</span>
                            <span className={cssClass(style.group__subtitle)}>Linus Tech Tips</span>
                          </div>
                          <div className={cssClass(style.group__main)}>
                            <ul className={cssClass(style.group__list)}>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Капитализация проекта:</span>
                                <span className={cssClass(style.group__subtitle)}>$0,0423</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Владельцы токена:</span>
                                <span className={cssClass(style.group__subtitle)}>25</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Кол-во токенов всего:</span>
                                <span className={cssClass(style.group__subtitle)}>2</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Цена/Стоимость 1 токена:</span>
                                <span className={cssClass(style.group__subtitle)}>$0,0423</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Категория проекта:</span>
                                <span className={cssClass(style.group__subtitle)}>Недвижимость</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Локация проекта:</span>
                                <span className={cssClass(style.group__subtitle)}>Москва, Россия</span>
                              </li>
                              <li className={cssClass(style.group__item)}>
                                <span className={cssClass(style.group__title)}>Соцсети проекта:</span>
                                <span className={cssClass(style.group__subtitle)}>Linus Tech Tips</span>
                                <div className={cssClass(style.socials)}>
                                  <ul className={cssClass(style.socials__list)}>
                                    <li className={cssClass(style.socials__item)}>
                                      <a className={cssClass(style.socials__link)} href='#'>
                                        {socialIco}
                                      </a>
                                    </li>
                                    <li className={cssClass(style.socials__item)}>
                                      <a className={cssClass(style.socials__link)} href='#'>
                                        {socialIco}
                                      </a>
                                    </li>
                                    <li className={cssClass(style.socials__item)}>
                                      <a className={cssClass(style.socials__link)} href='#'>
                                        {socialIco}
                                      </a>
                                    </li>
                                    <li className={cssClass(style.socials__item)}>
                                      <a className={cssClass(style.socials__link)} href='#'>
                                        {socialIco}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              )}
              {tabs === 2 && (
                <div className={cssClass(style.tabs__item)}>
                  {detail === false && (
                    <div className={cssClass(style.container, style.container_secondary)}>
                      <div className={cssClass(style.project__articles, style.articles)}>
                        <div className={cssClass(style.articles__list)}>
                          <div className={cssClass(style.articles__item, style.article)}>
                            <div className={cssClass(style.article__header)}>
                              <div className={cssClass(style.article__tag, style.tag)}>
                                <span className={cssClass(style.tag__text)}>Обновление #5</span>
                              </div>
                              <div className={cssClass(style.article__date, style.date)}>
                                <span className={cssClass(style.date__text)}>7 April, 2024</span>
                              </div>
                            </div>
                            <div className={cssClass(style.article__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <h2>Hex Grid Triumphs and the Path to Square Grids 🌟</h2>
                                <h3>Large on Paint Space and Storage Yet Low Profile and Portable</h3>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.article__footer)}>
                              <div className={cssClass(style.article__data, style.data)}>
                                <ul className={cssClass(style.data__list)}>
                                  <li className={cssClass(style.data__item)}>
                                    {favorite}
                                    <span className={cssClass(style.data__text)}>55</span>
                                  </li>
                                  <li className={cssClass(style.data__item)}>
                                    {comment}
                                    <span className={cssClass(style.data__text)}>44</span>
                                  </li>
                                </ul>
                              </div>
                              <div className={cssClass(style.article__detail)}>
                                <span
                                  className={cssClass(style.btn, style.btn_secondary, style.btn_transparent)}
                                  onClick={() => {
                                    setDetail(true)
                                  }}
                                >
                                  <span className={cssClass(style.btn__text)}>Читать подробнее</span>
                                  {arrowTop}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={cssClass(style.articles__item, style.article)}>
                            <div className={cssClass(style.article__header)}>
                              <div className={cssClass(style.article__tag, style.tag)}>
                                <span className={cssClass(style.tag__text)}>Обновление #5</span>
                              </div>
                              <div className={cssClass(style.article__date, style.date)}>
                                <span className={cssClass(style.date__text)}>7 April, 2024</span>
                              </div>
                            </div>
                            <div className={cssClass(style.article__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <h2>Hex Grid Triumphs and the Path to Square Grids 🌟</h2>
                                <h3>Large on Paint Space and Storage Yet Low Profile and Portable</h3>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.article__footer)}>
                              <div className={cssClass(style.article__data, style.data)}>
                                <ul className={cssClass(style.data__list)}>
                                  <li className={cssClass(style.data__item)}>
                                    {favorite}
                                    <span className={cssClass(style.data__text)}>55</span>
                                  </li>
                                  <li className={cssClass(style.data__item)}>
                                    {comment}
                                    <span className={cssClass(style.data__text)}>44</span>
                                  </li>
                                </ul>
                              </div>
                              <div className={cssClass(style.article__detail)}>
                                <span
                                  className={cssClass(style.btn, style.btn_secondary, style.btn_transparent)}
                                  onClick={() => {
                                    setDetail(true)
                                  }}
                                >
                                  <span className={cssClass(style.btn__text)}>Читать подробнее</span>
                                  {arrowTop}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={cssClass(style.articles__item, style.article)}>
                            <div className={cssClass(style.article__header)}>
                              <div className={cssClass(style.article__tag, style.tag)}>
                                <span className={cssClass(style.tag__text)}>Обновление #5</span>
                              </div>
                              <div className={cssClass(style.article__date, style.date)}>
                                <span className={cssClass(style.date__text)}>7 April, 2024</span>
                              </div>
                            </div>
                            <div className={cssClass(style.article__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <h2>Hex Grid Triumphs and the Path to Square Grids 🌟</h2>
                                <h3>Large on Paint Space and Storage Yet Low Profile and Portable</h3>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.article__footer)}>
                              <div className={cssClass(style.article__data, style.data)}>
                                <ul className={cssClass(style.data__list)}>
                                  <li className={cssClass(style.data__item)}>
                                    {favorite}
                                    <span className={cssClass(style.data__text)}>55</span>
                                  </li>
                                  <li className={cssClass(style.data__item)}>
                                    {comment}
                                    <span className={cssClass(style.data__text)}>44</span>
                                  </li>
                                </ul>
                              </div>
                              <div className={cssClass(style.article__detail)}>
                                <span
                                  className={cssClass(style.btn, style.btn_secondary, style.btn_transparent)}
                                  onClick={() => {
                                    setDetail(true)
                                  }}
                                >
                                  <span className={cssClass(style.btn__text)}>Читать подробнее</span>
                                  {arrowTop}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {detail === true && (
                    <React.Fragment>
                      <div className={cssClass(style.container)}>
                        <div className={cssClass(style.project__return, style.return)}>
                          <span
                            className={cssClass(style.return__link)}
                            onClick={() => {
                              setDetail(false)
                            }}
                          >
                            {arrowLeft}
                            <span className={cssClass(style.return__text)}>Все обновления</span>
                          </span>
                        </div>
                      </div>

                      <div className={cssClass(style.container, style.container_secondary)}>
                        <div className={cssClass(style.project__articles, style.articles)}>
                          <div className={cssClass(style.articles__item, style.article)}>
                            <div className={cssClass(style.article__header)}>
                              <div className={cssClass(style.article__tag, style.tag)}>
                                <span className={cssClass(style.tag__text)}>Обновление #5</span>
                              </div>
                              <div className={cssClass(style.article__date, style.date)}>
                                <span className={cssClass(style.date__text)}>7 April, 2024</span>
                              </div>
                            </div>
                            <div className={cssClass(style.article__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <h2>Hex Grid Triumphs and the Path to Square Grids 🌟</h2>
                                <h3>Large on Paint Space and Storage Yet Low Profile and Portable</h3>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                                <img src={description} />

                                <h3>Large on Paint Space and Storage Yet Low Profile and Portable</h3>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.article__footer)}>
                              <div className={cssClass(style.article__data, style.data)}>
                                <ul className={cssClass(style.data__list)}>
                                  <li className={cssClass(style.data__item)}>
                                    {favorite}
                                    <span className={cssClass(style.data__text)}>55</span>
                                  </li>
                                  <li className={cssClass(style.data__item)}>
                                    {comment}
                                    <span className={cssClass(style.data__text)}>44</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className={cssClass(style.project__comments, style.comments)}>
                            <div className={cssClass(style.comments__header)}>
                              <span className={cssClass(style.comments__title)}>Комментарии обновления:</span>
                              <span className={cssClass(style.comments__subtitle)}>3 комментария</span>
                              <select className={cssClass(style.comments__select)}>
                                <option value=''>Сортировать</option>
                                <option value=''>Тест 2</option>
                                <option value=''>Тест 3</option>
                              </select>
                            </div>
                            <div className={cssClass(style.comments__main)}>
                              <div className={cssClass(style.comments__list)}>
                                <div className={cssClass(style.comments__item, style.comment)}>
                                  <div className={cssClass(style.comment__media)}>
                                    <img src={description} />
                                  </div>
                                  <div className={cssClass(style.comment__header)}>
                                    <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                    <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                  </div>
                                  <div className={cssClass(style.comment__main)}>
                                    <div className={cssClass(style.wysiwyg)}>
                                      <p>
                                        The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling
                                        more than 41 sq. in. of painting area with a compact outer dimension of 5.6" x
                                        9.8" x 1.3" whole keeping the integrated storage compartment large enough to
                                        hold the mixing tray, texture palette, or tools as well as a selection of
                                        standard length paint brushes. The air vent is located just inside the storage
                                        compartment on the Exemplar GO to save space and features!
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(style.comment__footer)}>
                                    <div className={cssClass(style.comment__favorite, style.favorite)}>
                                      {favorite}
                                      <span className={cssClass(style.favorite__text)}>55</span>
                                    </div>
                                    <div className={cssClass(style.comment__answer, style.answer)}>
                                      <span className={cssClass(style.answer__text)}>Ответить</span>
                                    </div>
                                  </div>
                                  <div className={cssClass(style.comment__dropdown, style.dropdown)}>
                                    <div className={cssClass(style.dropdown__header)}>
                                      {chevronTop}
                                      <span className={cssClass(style.dropdown__text)}>2 ответа</span>
                                    </div>
                                    <div className={cssClass(style.dropdown__main)}>
                                      <div className={cssClass(style.comments__list)}>
                                        <div className={cssClass(style.comments__item, style.comment)}>
                                          <div className={cssClass(style.comment__media)}>
                                            <img src={description} />
                                          </div>
                                          <div className={cssClass(style.comment__header)}>
                                            <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                            <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                          </div>
                                          <div className={cssClass(style.comment__main)}>
                                            <div className={cssClass(style.wysiwyg)}>
                                              <p>
                                                The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base
                                                totaling more than 41 sq. in. of painting area with a compact outer
                                                dimension of 5.6" x 9.8" x 1.3" whole keeping the integrated storage
                                                compartment large enough to hold the mixing tray, texture palette, or
                                                tools as well as a selection of standard length paint brushes. The air
                                                vent is located just inside the storage compartment on the Exemplar GO
                                                to save space and features!
                                              </p>
                                            </div>
                                          </div>
                                          <div className={cssClass(style.comment__footer)}>
                                            <div className={cssClass(style.comment__favorite, style.favorite)}>
                                              {favorite}
                                              <span className={cssClass(style.favorite__text)}>55</span>
                                            </div>
                                            <div className={cssClass(style.comment__answer, style.answer)}>
                                              <span className={cssClass(style.answer__text)}>Ответить</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={cssClass(style.comments__item, style.comment)}>
                                          <div className={cssClass(style.comment__media)}>
                                            <img src={description} />
                                          </div>
                                          <div className={cssClass(style.comment__header)}>
                                            <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                            <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                          </div>
                                          <div className={cssClass(style.comment__main)}>
                                            <div className={cssClass(style.wysiwyg)}>
                                              <p>
                                                The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base
                                                totaling more than 41 sq. in. of painting area with a compact outer
                                                dimension of 5.6" x 9.8" x 1.3" whole keeping the integrated storage
                                                compartment large enough to hold the mixing tray, texture palette, or
                                                tools as well as a selection of standard length paint brushes. The air
                                                vent is located just inside the storage compartment on the Exemplar GO
                                                to save space and features!
                                              </p>
                                            </div>
                                          </div>
                                          <div className={cssClass(style.comment__footer)}>
                                            <div className={cssClass(style.comment__favorite, style.favorite)}>
                                              {favorite}
                                              <span className={cssClass(style.favorite__text)}>55</span>
                                            </div>
                                            <div className={cssClass(style.comment__answer, style.answer)}>
                                              <span className={cssClass(style.answer__text)}>Ответить</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={cssClass(style.comments__item, style.comment)}>
                                  <div className={cssClass(style.comment__media)}>
                                    <img src={description} />
                                  </div>
                                  <div className={cssClass(style.comment__header)}>
                                    <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                    <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                  </div>
                                  <div className={cssClass(style.comment__main)}>
                                    <div className={cssClass(style.wysiwyg)}>
                                      <p>
                                        The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling
                                        more than 41 sq. in. of painting area with a compact outer dimension of 5.6" x
                                        9.8" x 1.3" whole keeping the integrated storage compartment large enough to
                                        hold the mixing tray, texture palette, or tools as well as a selection of
                                        standard length paint brushes. The air vent is located just inside the storage
                                        compartment on the Exemplar GO to save space and features!
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(style.comment__footer)}>
                                    <div className={cssClass(style.comment__favorite, style.favorite)}>
                                      {favorite}
                                      <span className={cssClass(style.favorite__text)}>55</span>
                                    </div>
                                    <div className={cssClass(style.comment__answer, style.answer)}>
                                      <span className={cssClass(style.answer__text)}>Ответить</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}

              {tabs === 3 && (
                <div className={cssClass(style.tabs__item)}>
                  <div className={cssClass(style.container, style.container_secondary)}>
                    <div className={cssClass(style.project__comments, style.comments)}>
                      <div className={cssClass(style.comments__header)}>
                        <span className={cssClass(style.comments__title)}>Комментарии обновления:</span>
                        <span className={cssClass(style.comments__subtitle)}>3 комментария</span>
                        <select className={cssClass(style.comments__select)}>
                          <option value=''>Сортировать</option>
                          <option value=''>Тест 2</option>
                          <option value=''>Тест 3</option>
                        </select>
                      </div>
                      <div className={cssClass(style.comments__main)}>
                        <div className={cssClass(style.comments__list)}>
                          <div className={cssClass(style.comments__item, style.comment)}>
                            <div className={cssClass(style.comment__media)}>
                              <img src={description} />
                            </div>
                            <div className={cssClass(style.comment__header)}>
                              <span className={cssClass(style.comment__title)}>@author’s_name</span>
                              <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                            </div>
                            <div className={cssClass(style.comment__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.comment__footer)}>
                              <div className={cssClass(style.comment__favorite, style.favorite)}>
                                {favorite}
                                <span className={cssClass(style.favorite__text)}>55</span>
                              </div>
                              <div className={cssClass(style.comment__answer, style.answer)}>
                                <span className={cssClass(style.answer__text)}>Ответить</span>
                              </div>
                            </div>
                            <div className={cssClass(style.comment__dropdown, style.dropdown)}>
                              <div className={cssClass(style.dropdown__header)}>
                                {chevronTop}
                                <span className={cssClass(style.dropdown__text)}>2 ответа</span>
                              </div>
                              <div className={cssClass(style.dropdown__main)}>
                                <div className={cssClass(style.comments__list)}>
                                  <div className={cssClass(style.comments__item, style.comment)}>
                                    <div className={cssClass(style.comment__media)}>
                                      <img src={description} />
                                    </div>
                                    <div className={cssClass(style.comment__header)}>
                                      <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                      <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                    </div>
                                    <div className={cssClass(style.comment__main)}>
                                      <div className={cssClass(style.wysiwyg)}>
                                        <p>
                                          The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling
                                          more than 41 sq. in. of painting area with a compact outer dimension of 5.6" x
                                          9.8" x 1.3" whole keeping the integrated storage compartment large enough to
                                          hold the mixing tray, texture palette, or tools as well as a selection of
                                          standard length paint brushes. The air vent is located just inside the storage
                                          compartment on the Exemplar GO to save space and features!
                                        </p>
                                      </div>
                                    </div>
                                    <div className={cssClass(style.comment__footer)}>
                                      <div className={cssClass(style.comment__favorite, style.favorite)}>
                                        {favorite}
                                        <span className={cssClass(style.favorite__text)}>55</span>
                                      </div>
                                      <div className={cssClass(style.comment__answer, style.answer)}>
                                        <span className={cssClass(style.answer__text)}>Ответить</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(style.comments__item, style.comment)}>
                                    <div className={cssClass(style.comment__media)}>
                                      <img src={description} />
                                    </div>
                                    <div className={cssClass(style.comment__header)}>
                                      <span className={cssClass(style.comment__title)}>@author’s_name</span>
                                      <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                                    </div>
                                    <div className={cssClass(style.comment__main)}>
                                      <div className={cssClass(style.wysiwyg)}>
                                        <p>
                                          The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling
                                          more than 41 sq. in. of painting area with a compact outer dimension of 5.6" x
                                          9.8" x 1.3" whole keeping the integrated storage compartment large enough to
                                          hold the mixing tray, texture palette, or tools as well as a selection of
                                          standard length paint brushes. The air vent is located just inside the storage
                                          compartment on the Exemplar GO to save space and features!
                                        </p>
                                      </div>
                                    </div>
                                    <div className={cssClass(style.comment__footer)}>
                                      <div className={cssClass(style.comment__favorite, style.favorite)}>
                                        {favorite}
                                        <span className={cssClass(style.favorite__text)}>55</span>
                                      </div>
                                      <div className={cssClass(style.comment__answer, style.answer)}>
                                        <span className={cssClass(style.answer__text)}>Ответить</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={cssClass(style.comments__item, style.comment)}>
                            <div className={cssClass(style.comment__media)}>
                              <img src={description} />
                            </div>
                            <div className={cssClass(style.comment__header)}>
                              <span className={cssClass(style.comment__title)}>@author’s_name</span>
                              <span className={cssClass(style.comment__subtitle)}>Апрель 3, 2024</span>
                            </div>
                            <div className={cssClass(style.comment__main)}>
                              <div className={cssClass(style.wysiwyg)}>
                                <p>
                                  The Exemplar GO features 4.5" x 9" (115mm x 230mm) wet palette base totaling more than
                                  41 sq. in. of painting area with a compact outer dimension of 5.6" x 9.8" x 1.3" whole
                                  keeping the integrated storage compartment large enough to hold the mixing tray,
                                  texture palette, or tools as well as a selection of standard length paint brushes. The
                                  air vent is located just inside the storage compartment on the Exemplar GO to save
                                  space and features!
                                </p>
                              </div>
                            </div>
                            <div className={cssClass(style.comment__footer)}>
                              <div className={cssClass(style.comment__favorite, style.favorite)}>
                                {favorite}
                                <span className={cssClass(style.favorite__text)}>55</span>
                              </div>
                              <div className={cssClass(style.comment__answer, style.answer)}>
                                <span className={cssClass(style.answer__text)}>Ответить</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={cssClass(style.comments__footer)}>
                        <div className={cssClass(style.comments__addComment, style.addComment)}>
                          <div className={cssClass(style.addComment__header)}>
                            <span className={cssClass(style.comments__title)}>Комментарии обновления:</span>
                            <span className={cssClass(style.comments__subtitle)}>3 комментария</span>
                            <select className={cssClass(style.comments__select)}>
                              <option value=''>Сортировать</option>
                              <option value=''>Тест 2</option>
                              <option value=''>Тест 3</option>
                            </select>
                          </div>
                          <div className={cssClass(style.addComment__main)}>
                            <img className={cssClass(style.comments__media)} src={description} />
                            <textarea
                              className={cssClass(style.comments__text)}
                              type='text'
                              placeholder='Написать комментарий...'
                            ></textarea>
                          </div>
                          <div className={cssClass(style.addComment__footer)}>
                            <div className={cssClass(style.addComment__action, style.action)}>
                              <ul className={cssClass(style.action__list)}>
                                <li className={cssClass(style.action__item)}>
                                  <button
                                    type='submit'
                                    className={cssClass(style.btn, style.btn_tiny, style.btn_tertiary)}
                                    disabled
                                  >
                                    <span className={cssClass(style.btn__text)}>Отменить</span>
                                  </button>
                                </li>
                                <li className={cssClass(style.action__item)}>
                                  <button type='submit' className={cssClass(style.btn, style.btn_tiny)} disabled>
                                    <span className={cssClass(style.btn__text)}>Оставить комментарий</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <footer className={cssClass(style.project__footer)}>
        <div className={cssClass(style.container)}>
          <div className={cssClass(style.project__action, style.action)}>
            <ul className={cssClass(style.action__list)}>
              <li className={cssClass(style.action__item)}>
                <a className={cssClass(style.btn)} href='#'>
                  <span className={cssClass(style.btn__text)}>Купить токен</span>
                  {arrowTop}
                </a>
              </li>
              <li className={cssClass(style.action__item)}>
                <a className={cssClass(style.btn, style.btn_secondary)} href='#'>
                  <span className={cssClass(style.btn__text)}>Комментарии</span>
                  {arrowBottom}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}
