import React from 'react'
import Main from 'pages/common/components/Main/Main'
import Button from 'components/form/Button/Button'
import img01 from './img/img01.png'
import img__number01 from './img/number__01.png'
import img__number02 from './img/number__02.png'
import img__number03 from './img/number__03.png'
import img__number04 from './img/number__04.png'
import img__number05 from './img/number__05.png'
import img__service01 from './img/service__img01.png'
import img__service02 from './img/service__img02.png'
import img__service03 from './img/service__img03.png'
import img__service04 from './img/service__img04.png'
import img__service05 from './img/service__img05.png'
import img__background01 from './img/background__img01.png'
import img__background02 from './img/background__img02.png'
import { useTranslation } from 'react-i18next'
export default function TokenizationPage() {
  const { t } = useTranslation()
  function scrollToFooter() {
    let footer = document.getElementById('layout__footer')
    footer.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>
                  {t('CreateCryptocurrency')} <strong>BLAZAREX</strong>
                </h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='row row_m-revers'>
                <div className='col col_6 col_mob-12'>
                  <div className='section__content'>
                    <div className='layout__groups groups'>
                      <div className='groups__list'>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('HighLiquidity')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(1)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('HighLiquidityContent')}</p>
                            </div>
                          </div>
                        </div>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('Safety')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(2)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('SafetyContent')}</p>
                            </div>
                          </div>
                        </div>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('WideSelection')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(3)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('WideSelectionContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col_6 col_mob-12'>
                  <div className='section__content'>
                    <div className='wysiwyg'>
                      <img src={img01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('ExchangeServices')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='section__content'>
                <div className='layout__groups groups'>
                  <div className='groups__list'>
                    <div className='row'>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__header'>
                            <div className='groups__media'>
                              <img src={img__service01} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('Listing')}</h2>
                              <p>{t('ListingContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__header'>
                            <div className='groups__media'>
                              <img src={img__service02} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('MarketingDesign')}</h2>
                              <p>{t('MarketingDesignContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__header'>
                            <div className='groups__media'>
                              <img src={img__service03} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('MarketMakingServices')}</h2>
                              <p>{t('MarketMakingServicesContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__header'>
                            <div className='groups__media'>
                              <img src={img__service04} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('TechnicalDevelopment')}</h2>
                              <p>{t('TechnicalDevelopmentContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__header'>
                            <div className='groups__media'>
                              <img src={img__service05} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('IRServices')}</h2>
                              <p>{t('IRServicesContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size'>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <h2>{t('Consultation')}</h2>
                              <p>{t('ConsultationContent')}</p>
                            </div>
                          </div>
                          <div className='groups__footer mt-auto'>
                            <div className='groups__action'>
                              <Button
                                buttonCallback={() => scrollToFooter()}
                                addClasses={['btn w-100 btn_large']}
                                text={t('Contacts')}
                                after={true}
                                ico={
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                    fill='none'
                                  >
                                    <path
                                      d='M3.61561 2.13965H11.4514L0.430587 13.1605C0.351357 13.2397 0.288197 13.3289 0.241117 13.4241C0.114157 13.6807 0.104087 13.9804 0.210917 14.2439C0.259737 14.3642 0.332957 14.477 0.430587 14.5747C0.821107 14.9652 1.45428 14.9652 1.8448 14.5747L12.8656 3.55386V11.3896C12.8656 11.9419 13.3133 12.3896 13.8656 12.3896C14.4179 12.3896 14.8656 11.9419 14.8656 11.3896V1.13965C14.8656 1.10513 14.8639 1.07102 14.8605 1.0374C14.8104 0.544498 14.4024 0.157418 13.9004 0.140238C13.8889 0.139848 13.8773 0.139648 13.8656 0.139648H3.61561C3.06333 0.139648 2.61562 0.587358 2.61562 1.13965C2.61561 1.69193 3.06333 2.13965 3.61561 2.13965Z'
                                      fill='white'
                                    />
                                  </svg>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('FurtherCommunication')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='section__content'>
                <div className='layout__groups groups'>
                  <div className='groups__background groups__background_top-right'>
                    <img src={img__background01} alt='image description' />
                  </div>
                  <div className='groups__list'>
                    <div className='row justify-start'>
                      <div className='col col_8 col_mob-12'>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('ConclusionCooperation')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(1)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ConclusionCooperationContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row justify-center'>
                      <div className='col col_8 col_mob-12'>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('AnalysisProject')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(2)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('AnalysisProjectContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row justify-end'>
                      <div className='col col_8 col_mob-12'>
                        <div className='groups__item'>
                          <div className='groups__header'>
                            <div className='row'>
                              <div className='col col_10'>
                                <div className='wysiwyg'>
                                  <h2>{t('PreparationListing')}</h2>
                                </div>
                              </div>
                              <div className='col col_2'>
                                <div className='wysiwyg text-end font-italic'>
                                  <h2>
                                    <strong>
                                      <i>(3)</i>
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('PreparationListingContent')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='groups__background groups__background_bottom-left'>
                    <img src={img__background02} alt='image description' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('ListingOnTheStock')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='section__content'>
                <div className='layout__groups groups'>
                  <div className='groups__list'>
                    <div className='row'>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size groups__item_second-color'>
                          <div className='groups__header'>
                            <div className='groups__media groups__media_tiny'>
                              <img src={img__number01} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ListingHelps')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size groups__item_second-color'>
                          <div className='groups__header'>
                            <div className='groups__media groups__media_tiny'>
                              <img src={img__number02} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ListingInvestors')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size groups__item_second-color'>
                          <div className='groups__header'>
                            <div className='groups__media groups__media_tiny'>
                              <img src={img__number03} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ListingPurchasing')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='groups__item groups__item_second-size groups__item_second-color'>
                          <div className='groups__header'>
                            <div className='groups__media groups__media_tiny'>
                              <img src={img__number04} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ListingBuy')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col col_12'>
                        <div className='groups__item groups__item_second-size groups__item_second-color'>
                          <div className='groups__header'>
                            <div className='groups__media groups__media_tiny'>
                              <img src={img__number05} alt='image description' />
                            </div>
                          </div>
                          <div className='groups__main'>
                            <div className='wysiwyg'>
                              <p>{t('ListingEmails')}</p>
                            </div>
                          </div>
                          <div className='groups__footer mt-auto'>
                            <div className='groups__action'>
                              <Button
                                buttonCallback={() => scrollToFooter()}
                                addClasses={['btn w-100 btn_large']}
                                text={t('Contacts')}
                                after={true}
                                ico={
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                    fill='none'
                                  >
                                    <path
                                      d='M3.61561 2.13965H11.4514L0.430587 13.1605C0.351357 13.2397 0.288197 13.3289 0.241117 13.4241C0.114157 13.6807 0.104087 13.9804 0.210917 14.2439C0.259737 14.3642 0.332957 14.477 0.430587 14.5747C0.821107 14.9652 1.45428 14.9652 1.8448 14.5747L12.8656 3.55386V11.3896C12.8656 11.9419 13.3133 12.3896 13.8656 12.3896C14.4179 12.3896 14.8656 11.9419 14.8656 11.3896V1.13965C14.8656 1.10513 14.8639 1.07102 14.8605 1.0374C14.8104 0.544498 14.4024 0.157418 13.9004 0.140238C13.8889 0.139848 13.8773 0.139648 13.8656 0.139648H3.61561C3.06333 0.139648 2.61562 0.587358 2.61562 1.13965C2.61561 1.69193 3.06333 2.13965 3.61561 2.13965Z'
                                      fill='white'
                                    />
                                  </svg>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
