import React, { useEffect, useState, useRef } from 'react'
import Header from '../../common/components/Header/Header.jsx'
import Footer from '../../common/components/Footer/Footer.jsx'
import '../../secondaryLayout/scss/main.sass'
import MarketOverview from './MarketOverview.jsx'
import TrandingPairs from './TrandingPairs.jsx'
import { useTranslation } from 'react-i18next'
import TokenIcons from '../TokenData/TokenIcons.js'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'
import useCurrencyData from '../TokenData/useCurrencyData.jsx'
import CurrencyListingStatuses from 'data/CurrencyListingStatuses.js'

export default function TokenMarketsPage() {
  const history = useHistory()
  const { t } = useTranslation()
  const searchContainerRef = useRef(null)

  const { enrichedTradablePairs } = useCurrencyData()

  const [textToSearch, setTextToSearch] = useState('')
  const [foundPairs, setFoundPairs] = useState([])

  const goToTokenPage = (currencyCode) => {
    history.push(`/${i18n.language}/tokens/view/${currencyCode}`)
  }
  const goToTokenTradePage = (symbol) => {
    history.push(`/${i18n.language}/exchange?symbol=${symbol}`)
  }

  useEffect(() => {
    if (!enrichedTradablePairs || enrichedTradablePairs.length === 0 || textToSearch.trim() === '') {
      setFoundPairs([])
      return
    }

    let filteredPairs = enrichedTradablePairs.filter((pair) =>
      pair.currencyCode.toLowerCase().includes(textToSearch.toLowerCase()),
    )

    filteredPairs = filteredPairs.map((pair) => ({
      ...pair,
      currencyCodeShort: pair.currencyCode.replace('/', '').replace(/USDT$/, ''),
    }))

    setFoundPairs(filteredPairs)
  }, [textToSearch, enrichedTradablePairs])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setTextToSearch('')
        setFoundPairs([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='layout_Tokens layout_Tokens_secodnary'>
      <Header />
      <main className='layout__main'>
        <section className='layout__section section'>
          <div className='container'>
            <div className='section__header section__header_with-form'>
              <h2 className='section__title'>{t('TokenMarkets')}</h2>
              <form className='section__form form' action='#' ref={searchContainerRef}>
                <div className='form__item'>
                  <div className='form__main'>
                    <label className='form__field form__field_tiny'>
                      <TokenIcons name={'searchIco'} />
                      <input
                        className='form__input'
                        type='text'
                        value={textToSearch}
                        placeholder={t('Search')}
                        onChange={(e) => setTextToSearch(e.target.value)}
                      />
                      <span className='close-icon' onClick={() => setTextToSearch('')}>
                        <TokenIcons name={'closeIco'} />
                      </span>
                    </label>
                  </div>
                  {foundPairs.length > 0 && (
                    <div className='form__dropdown dropdown'>
                      <div className='market__table table'>
                        <ul className='table__list table__list_quarty'>
                          {foundPairs.map((pair) => (
                            <React.Fragment key={pair.currencyCode}>
                              <li className='table__item'>
                                <a className='table__link' onClick={() => goToTokenPage(pair.currencyCode)}>
                                  {pair.logoImageUrl ? (
                                    <img className='table__ico' src={pair.logoImageUrl} alt='logo image' />
                                  ) : (
                                    <span className='table__ico'>
                                      <TokenIcons name={'blurredTokenIco'} />
                                    </span>
                                  )}
                                  <span className='table__text'>
                                    {pair.tokenName} ({pair.currencyCodeShort})
                                  </span>
                                </a>
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className='section__main'>
              <div className='layout__markets markets'>
                <MarketOverview t={t} enrichedTradablePairs={enrichedTradablePairs} goToTokenPage={goToTokenPage} />
                <div className='markets__section'>
                  <div className='markets__header'>
                    <div className='markets__filters filters'>
                      <div className='filters__list'>
                        {/* <div className='filters__item filter'>
                          <ul className='filter__list'>
                            <li className='filter__item filter__item_active'>
                              <span className='filter__text'>All</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__text'>USDT</span>
                            </li>
                          </ul>
                          <ul className='filter__list  filter__list_secodnary'>
                            <li className='filter__item filter__item_active'>
                              <span className='filter__btn'>1h</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>6h</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>24h</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>7d</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>30d</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>All</span>
                            </li>
                          </ul>
                        </div> */}
                        {/* <div className='filters__item filter'>
                          <ul className='filter__list'>
                            <li className='filter__item filter__item_active'>
                              <span className='filter__btn'>All</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>0 fees</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>Adventures Zone</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>SOL Ecosystem</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>ETH Ecosystem</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>BTC Ecosystem</span>
                            </li>

                            <li className='filter__item'>
                              <span className='filter__btn'>AI</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>Modular-BCs</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>DePIN</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>LSD</span>
                            </li>
                            <li className='filter__item'>
                              <span className='filter__btn'>GameFi</span>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className='markets__main'>
                    <div className='markets__item market'>
                      <TrandingPairs
                        t={t}
                        enrichedTradablePairs={enrichedTradablePairs}
                        goToTokenPage={goToTokenPage}
                        goToTokenTradePage={goToTokenTradePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
