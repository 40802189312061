import React, { useEffect, useState } from 'react'
import SwiperWrapper from 'baseComponents/SwiperWrapper/SwiperWrapper'
import MarketLimit from '../MarketLimit/MarketLimit'
import { OrderbookSide } from '../Orderbook/Orderbook'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './MarketLimitPhone.module.scss'

export const MarketLimitPhone = ({}) => {
  const [expanded, setExpanded] = useState(false)
  const [type, setType] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {}, [type])
  return (
    <div className={styles.wrapper}>
      <MarketLimitPhoneTable />

      {expanded ? (
        <MarketLimit typeAction={type} close={() => setExpanded(false)} />
      ) : (
        <div className={styles.buttonsWrapper}>
          <button
            onClick={() => {
              setExpanded(true)
              setType('buy')
            }}
            className={styles.green}
          >
            {t('Buy')}
          </button>
          <button
            onClick={() => {
              setExpanded(true)
              setType('sell')
            }}
            className={styles.red}
          >
            {t('Sell')}
          </button>
        </div>
      )}
    </div>
  )
}

const CellFirstRow = ({ selected }) => {
  const { t } = useTranslation()
  return (
    <div className={cssClass(styles.cellRow, styles.cellFirstRow, selected && styles.cellRowSelected)}>
      <span>{t('Volume')}</span>
      <span>{t('Type')}</span>
      {selected && <span>{t('Total')}</span>}
      <span>{t('Price')}</span>
    </div>
  )
}

export const MarketLimitPhoneTable = () => {
  const { t } = useTranslation()

  const filters = { all: 0, buy: 1, sell: 2 } // what parts of orderbook to show
  const [filter, setFilter] = useState(filters.all)

  return (
    <div className={styles.borderWrapper}>
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <h3>{t('Buy')}</h3>
          <div className={styles.filterBlock}>
            <button
              onClick={() => setFilter(filters.all)}
              className={filter === filters.all ? styles.filterActive : null}
            >
              <img src='/images/filter 1.svg' alt='Filter All' />
            </button>
            <button
              onClick={() => setFilter(filters.buy)}
              className={filter === filters.buy ? styles.filterActive : null}
            >
              <img src='/images/filter 2.svg' alt='Filter Buy' />
            </button>
            <button
              onClick={() => setFilter(filters.sell)}
              className={filter === filters.sell ? styles.filterActive : null}
            >
              <img src='/images/filter 3.svg' alt='Filter Sell'></img>
            </button>
          </div>
          <h3>{t('Sell')}</h3>
        </div>
        <div className={cssClass(styles.table, filter !== filters.all && styles.tableSelected)}>
          {(filter === filters.all || filter === filters.buy) && (
            <div className={styles.left}>
              <CellFirstRow selected={filter !== filters.all} />
              <div className={styles.gradientLine}></div>
              <div className={styles.containerWrapper}>
                <OrderbookSide
                  selected={filter !== filters.all}
                  isBid={true}
                  isPhone={true}
                  verticalClass={baseComponents.scrollbarLeft}
                  dragClass={baseComponents.scrollbarDragGreen}
                />
              </div>
            </div>
          )}
          {(filter === filters.all || filter === filters.sell) && (
            <div className={styles.right}>
              <CellFirstRow selected={filter !== filters.all} />
              <div className={styles.gradientLine}></div>
              <div className={styles.containerWrapper}>
                <OrderbookSide
                  selected={filter !== filters.all}
                  isBid={false}
                  isPhone={true}
                  verticalClass={baseComponents.scrollbarRight}
                  dragClass={baseComponents.scrollbarRedDrag}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
