import React from 'react'
import { NewsImage, NewsText } from '../NewsItem/NewsItem'

import styles from './NewsSection.module.scss'

export const NewsLeftSection = ({ data }) => {
  const sections = []

  for (let i = 0; i < Math.min(3, data.length); i += 3) {
    const main = data[i]
    const firstSide = data[i + 1]
    const secondSide = data[i + 2]

    const section = (
      <section key={main?.id} className={`${styles.section} ${styles.mainLeftSection}`}>
        <div className={styles.mainImage}>
          <NewsImage data={main} />
        </div>
        <div className={styles.mainText}>
          <NewsText data={main} />
        </div>
        {firstSide && (
          <>
            <div className={styles.firstSideImage}>
              <NewsImage data={firstSide} />
            </div>
            <div className={styles.firstSideText}>
              <NewsText data={firstSide} />
            </div>
          </>
        )}
        {secondSide && (
          <>
            <div className={styles.secondSideImage}>
              <NewsImage data={secondSide} />
            </div>
            <div className={styles.secondSideText}>
              <NewsText data={secondSide} />
            </div>
          </>
        )}
      </section>
    )

    sections.push(section)
  }

  return sections
}

export const NewsRightSection = ({ data }) => {
  const sections = []

  for (let i = 0; i < data.length; i += 3) {
    const main = data[i]
    const firstSide = data[i + 1]
    const secondSide = data[i + 2]
    const section = (
      <section key={main.id} className={`${styles.section} ${styles.mainRightSection}`}>
        <div className={styles.mainImage}>
          <NewsImage data={main} />
        </div>
        {firstSide && (
          <div className={styles.firstSideImage}>
            <NewsImage data={firstSide} />
          </div>
        )}
        {secondSide && (
          <div className={styles.secondSideImage}>
            <NewsImage data={secondSide} />
          </div>
        )}
        <div className={styles.mainText}>
          <NewsText data={main} />
        </div>
        {firstSide && (
          <div className={styles.firstSideText}>
            <NewsText data={firstSide} />
          </div>
        )}
        {secondSide && (
          <div className={styles.secondSideText}>
            <NewsText data={secondSide} />
          </div>
        )}
      </section>
    )
    sections.push(section)
  }
  return sections
}
