import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import '../../../secondaryLayout/scss/main.sass'
import TokenInput from '../../TokenData/TokenInput.jsx'
import TokenIcons from '../../TokenData/TokenIcons.js'

export default function Links({ onInputChange, tokenData }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div id='tokens__links' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>{t('TokenLinks')}:</span>
        </div>
        <div className='group__main'>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.officialLink || ''}
                  validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                  inputCallback={(value) => {
                    onInputChange('officialLink', value)
                  }}
                  placeholder={t('TokenAddLinkOfYourWebsite')}
                  onFocus={(e) => {
                    onInputChange('officialLink', e.target.value.trim())
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterYourWebSiteLink')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.whitePaperLink || ''}
                  validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                  inputCallback={(value) => {
                    onInputChange('whitePaperLink', value)
                  }}
                  placeholder={t('TokenHttpsWhitePaperProject')}
                  onFocus={(e) => {
                    onInputChange('whitePaperLink', e.target.value.trim())
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterYourWhitePaperLink')}</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
