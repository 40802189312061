import { useState, useCallback } from 'react'

export default function useModalState() {
  const initialState = {
    isConfirmSendPaymentModalOpen: false,
    isConfirmPaymentModalOpen: false,
    isConfirmPaymentWarningModalOpen: false,
    isCancelOrderWarningModalOpen: false,
    isNeededHelpModalOpen: false,
    isHelpNotPaymentOrNotCoinsModalOpen: false,
    isHelpAmountLessOrMoreModalOpen: false,
    isHelpOtherModalOpen: false,
    isDealCommentModalOpen: false,
    isDeleteOrEditDealCommentWarningModalOpen: false,
    isApplicationCreatedModalOpen: false,
    isCancelAppealWarningModalOpen: false,
    isPassVerificationModalOpen: false,
    isCancelMyOrderWarningModalOpen: false,
    isDealCancelDisputeModalOpen: false,
  }

  const [modals, setModals] = useState(initialState)

  const openModal = useCallback((modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: true }))
  }, [])

  const closeModal = useCallback((modalName) => {
    setModals((prev) => ({ ...prev, [modalName]: false }))
  }, [])

  return { modals, openModal, closeModal }
}
