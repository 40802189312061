import React from 'react'
import Button from '../../../components/form/Button/Button'

const NavigationButtons = ({
  t,
  onBackStage,
  onNextStage,
  onSend,
  showBackButton = false,
  showNextButton = false,
  showSendButton = false,
  isBackButtonDisabled = false,
  isNextButtonDisabled = false,
  isSendButtonDisabled = false,
}) => {
  return (
    <React.Fragment>
      <div className='section__footer'>
        <div className='container'>
          <div className='formular'>
            <div className='row'>
              {showBackButton && (
                <div className='col col_3 col_tab-4 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Button
                          buttonCallback={onBackStage}
                          addClasses={['btn btn_revert w-100']}
                          disabled={isBackButtonDisabled}
                          text={t('Back')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showNextButton && (
                <div className='col col_3 col_tab-4 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Button
                          buttonCallback={onNextStage}
                          addClasses={['btn w-100']}
                          disabled={isNextButtonDisabled}
                          text={t('Next')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showSendButton && (
                <div className='col col_3 col_tab-4 col_mob-6'>
                  <div className='form-item'>
                    <div className='form-item__main'>
                      <div className='form-item__field'>
                        <Button
                          buttonCallback={onSend}
                          addClasses={['btn w-100']}
                          disabled={isSendButtonDisabled}
                          text={t('Send')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default NavigationButtons
