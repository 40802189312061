import React from 'react'
import checkImg from './images/check.svg'

function ProgressSteps({ t, currentStageIndex }) {
  const stepsList = ['AdTypeAndPrice', 'AmountAndPaymentMethod', 'TermsOfTheDealAnd']
  return (
    <React.Fragment>
      <div className='steps__header'>
        <div className='steps__list'>
          {stepsList.map((element, index) => {
            return (
              <div className={`steps__item ${currentStageIndex >= index ? 'steps__item_active' : ''}`} key={index}>
                <div className='steps__index index'>
                  {currentStageIndex <= index && <span className='index__text'>{index + 1}</span>}
                  {currentStageIndex > index && <img src={checkImg} alt='Check' />}
                </div>
                <div className='steps__title title'>
                  <span className='title__text'>{t(element)}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressSteps
