import Main from 'pages/common/components/Main/Main'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import authApi from 'auth/api'

import styles from '../pages.module.scss'
import style from './ResetPWPage.module.scss'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cssClass } from '../../helpers/helper'
import FormElement from '../account/components/FormElement/FormElement'
import formListIcon from '../account/components/Account/formListIcon.png'
import BaseComponents from '../../baseComponents/BaseComponents.module.scss'

export default function ResetPWPage() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const emailParam = searchParams.get('email')

  useEffect(() => {
    setEmail(emailParam)
  }, [])

  const changePassword = async (e) => {
    if (newPassword !== confirmPassword) {
      toast.error(t('PW_DoNotMatch'))
      return
    }
    try {
      await authApi.resetPassword(email, newPassword, confirmPassword, code)
      setEmail('')
      setNewPassword('')
      setConfirmPassword('')
      toast.success(t('ResetPW_Success'))
      history.push('/register')
    } catch (e) {
      toast.error(t('ResetPW_Fail'))
    }
  }

  return (
    <Main>
      <div className={styles.flow}>
        <h1 className={styles.title}>{t('ResetPW')}</h1>
        <PasswordResetViaEmail
          email={email}
          setEmail={setEmail}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          changePassword={changePassword}
          isReset
        />
      </div>
    </Main>
  )
}

export const PasswordResetViaEmail = ({
  email,
  setEmail,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  changePassword,
  isReset,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <form className={style.form}>
        <div className={cssClass(style.labelWrapper, style.labelWrapperAlt)}>
          <FormElement
            title='Email'
            placeholder=''
            type='email'
            value={email}
            setValue={setEmail}
          />
          <FormElement
            title='NewPassword'
            placeholder=''
            type='password'
            value={newPassword}
            setValue={setNewPassword}
            isPassword
          />
          <FormElement
            title='ConfirmPassword'
            placeholder=''
            type='password'
            value={confirmPassword}
            setValue={setConfirmPassword}
            isPassword
          />
        </div>
        <ul className={style.text}>
          <li className={cssClass(style.paragraph, style.paragraphFirst)}>
            {t('PasswordRequirements')}:
          </li>
          <li className={cssClass(style.paragraph, style.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeast8characters')}
          </li>
          <li className={cssClass(style.paragraph, style.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeastOneUppercase')}
          </li>
          <li className={cssClass(style.paragraph, style.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeastOneDigit')}
          </li>
          <li className={cssClass(style.paragraph, style.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('ValidCharacters')}
          </li>
        </ul>
        <button
          onClick={changePassword}
          type='button'
          className={cssClass(BaseComponents.button, style.saveButton)}
        >
          {t(isReset ? 'Reset' : 'Save')}
        </button>
      </form>
    </>
  )
}
