import about__img01 from '../../secondaryLayout/img/about__img01.png'
import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'

export default function About({ t, navigationFunctions }) {
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__main'>
            <div className='layout__about about'>
              <div className='about__banner banner'>
                <div className='banner__background'>
                  <img className='banner__img' src={about__img01} alt='image description' />
                </div>
                <div className='banner__header'>
                  <h2 className='banner__title'>{t('WeAreTheFirstTokenization')}</h2>
                </div>
                <div className='banner__footer'>
                  <a className='btn btn_tertiary' onClick={() => goToTokensMarketsPage()}>
                    <span className='btn__text'>{t('StartEarningMoney')}</span>
                  </a>
                </div>
              </div>

              <div className='about__main'>
                <ul className='about__list'>
                  <li className='about__item layout__box layout__box_secondary'>
                    <span className='about__title layout__tag'>{t('FreeEntry')}</span>
                    <span className='about__text'>{t('CreateYourOwnTokenAbsolutelyFree')}</span>
                  </li>
                  <li className='about__item layout__box layout__box_secondary'>
                    <span className='about__title layout__tag'>{t('Investments')}</span>
                    <span className='about__text'>{t('InvestInOtherProjects')}</span>
                  </li>

                  <li className='about__item layout__box layout__box_secondary'>
                    <span className='about__title layout__tag'>{t('Verification')}</span>
                    <span className='about__text'>{t('ExploreCompleteInformationAbout')}</span>
                  </li>
                  <li className='about__item layout__box layout__box_secondary'>
                    <span className='about__title layout__tag'>{t('Convenience')}</span>
                    <span className='about__text'>{t('BecomeAnInvestorAndOwner')}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
