import { BuySellTabIndex } from './PostingData'

const InitializeStageData = (order, t) => {
  if (!order) {
    return {
      isEnableSelection: true,
      currentStageIndex: 0,
      selectedBuySellTabIndex: BuySellTabIndex.Buy,
      selectedExchangeAsset: 'USDT',
      otcAssetList: ['Loading'].map((key) => t(key)),
      selectedOtcAsset: '',
      userSelectedTradeRate: 0,
      balanceOfSelectedExchangeAsset: null,
      selectedPaymentRequisites: [],
      limitMin: 10,
      limitMax: 2000,
      selectedPaymentDuration: 30,
      amountOfSelectedExchangeAsset: 0,
      amountOfSelectedOtcAsset: 0,
      commissionAmount: 0,
      commissionPercent: 0,
      isKYCPassed: false,
      selectedMinDaysSinceRegistration: 0,
      selectedMinOrdersRequired: 0,
      selectedMinOrderCompletionRate: 0,
      termsTextarea: '',
    }
  }

  return {
    isEnableSelection: false,
    currentStageIndex: 0,
    selectedBuySellTabIndex: order.isBuyingOnExchange ? BuySellTabIndex.Buy : BuySellTabIndex.Sell,
    selectedExchangeAsset: order.exchangeCurrencyCode,
    otcAssetList: [order.paymentCurrencyCode],
    selectedOtcAsset: order.paymentCurrencyCode,
    userSelectedTradeRate: order.price,
    balanceOfSelectedExchangeAsset: null,
    selectedPaymentRequisites: order?.requisites.map((method) => ({ ...method, selected: true })) || [],
    limitMin: order.minDealAmount,
    limitMax: order.maxDealAmount,
    selectedPaymentDuration: order.maxTimeMinutes,
    amountOfSelectedExchangeAsset: order.totalOrderAmount,
    amountOfSelectedOtcAsset: order.totalOrderAmount * order.price,
    commissionAmount: 0,
    commissionPercent: 0,
    isKYCPassed: order.isKYCPassed,
    selectedMinDaysSinceRegistration: order.minDaysSinceRegistration,
    selectedMinOrdersRequired: order.minOrdersRequired,
    selectedMinOrderCompletionRate: order.minOrderCompletionRate,
    termsTextarea: order.description,
  }
}
const MapToCreateOrderModel = (stageData) => {
  return {
    IsBuyingOnExchange: stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy,
    ExchangeCurrencyCode: stageData.selectedExchangeAsset,
    PaymentCurrencyCode: stageData.selectedOtcAsset,
    Price: stageData.userSelectedTradeRate,
    TotalOrderAmount: stageData.amountOfSelectedExchangeAsset,
    MinDealAmount: stageData.limitMin,
    MaxDealAmount: stageData.limitMax,
    Description: stageData.termsTextarea,
    MaxTimeMinutes: stageData.selectedPaymentDuration,
    IsKYCPassed: stageData.isKYCPassed,
    MinDaysSinceRegistration: stageData.selectedMinDaysSinceRegistration,
    MinOrdersRequired: stageData.selectedMinOrdersRequired,
    MinOrderCompletionRate: stageData.selectedMinOrderCompletionRate,
    RequisiteIds: stageData.selectedPaymentRequisites.map((pm) => pm.id),
  }
}
export { InitializeStageData, MapToCreateOrderModel }
