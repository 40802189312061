import {cssClass} from 'helpers/helper'
import {Fragment, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {format} from 'date-fns'
import api from 'api'
import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import pages from '../../pages.module.scss'
import styles from './Referrals.module.scss'
import arrowLeftIimg from './images/arrowLeft.svg'
import arrowLeftListIimg from './images/arrowLeftList.svg'
import {use} from 'i18next'

const tabs = ['Information', 'Statistics', 'Referrals'];
const referralTitles = [];
const referralCashTitles = ['DateAndTime', 'TransactionAmount', 'Bonus'];
const turnoverTitles = ['Number', 'GenelBbonus', 'RegistrationDate'];

export default function Referral() {
    const {t} = useTranslation();

    const [tab, setTab] = useState(tabs[0]);
    const [data, setData] = useState([]);
    const [hiddenList, setHiddenList] = useState([]); //при получении данных заполняется массивом из true - флаг скрытости всей информации сделки на мобильной версии
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(68);
    const [cashActiveCount, setCashActiveCount] = useState(null);

    useEffect(() => {
        api.countActiveCashOperations().then((res) => {
            setCashActiveCount(res)
        })
    }, []);


    const testElement = [];
    const referralElement = ['16.03.2023 13:33', '1 901 263.67', '1 901 263.67'];
    const turnoverElement = ['653790', '1 901 263.67', '16.03.2023 13:35'];

    const CreateArray = (element) => {
        const newArray = [];
        for (let i = 0; i < 20; i++) {
            newArray.push({array: element})
        }
        return newArray
    };

    const testPageArray = CreateArray(testElement);
    const referralPageArray = CreateArray(referralElement);
    const turnoverPageArray = CreateArray(turnoverElement);

    const currentData = () => {
        switch (tab) {
            case tabs[0]:
                return {
                    titles: referralTitles,
                    rows: data, //данные изменяются при запросе новой страницы
                    customClass: styles.rowReferral,
                };
            case tabs[1]:
                return {
                    titles: referralCashTitles,
                    rows: referralPageArray, //данные изменяются при запросе новой страницы
                    customClass: styles.rowTabSecondary,
                };
            case tabs[2]:
                return {
                    titles: turnoverTitles,
                    rows: turnoverPageArray, //данные изменяются при запросе новой страницы
                    customClass: styles.rowTabTertiary,
                }
        }
    };

    const reloadData = () => {
        setData([])

    };

    const cancelOperation = (isDeposit, operationId) => {
        const action = isDeposit
            ? api.cancelCashDeposit(operationId)
            : api.cancelCashWithdrawal(operationId);

        action
            .then((res) => {
                toast.success(t('ActionWasSuccessful'));
                reloadData()
            })
            .catch((err) => {
                console.log('cancelOperation error', err);
                toast.error(t('ErrorTryLater'))
            })
    };

    useEffect(() => {
        reloadData()
    }, [tab])

    return (
        <>
            <section className={pages.blockWrapper}>
                <div className={cssClass(styles.section)}>
                    <div className={cssClass(styles.section__header)}>
                        <div className={cssClass(styles.section__title)}>
                            {t('TitleReferals')}
                        </div>
                    </div>
                    <div className={cssClass(styles.section__main)}>
                        <div className={cssClass(styles.section__subsection)}>
                            <div className={cssClass(styles.reference)}>
                                <div className={cssClass(styles.reference__list)}>
                                    <div className={cssClass(styles.reference__item)}>
                                        <div className={cssClass(styles.reference__header)}>
                                            {t('YourLink')}
                                        </div>
                                        <div className={cssClass(styles.reference__layout)}>
                                            <div className={cssClass(styles.reference__main)}>
                                            <textarea className={cssClass(styles.reference__field)}>
                                                {t('ReferalsCopyMain')}
                                            </textarea>
                                            </div>
                                            <div className={cssClass(styles.reference__footer)}>
                                                <div className={cssClass(styles.reference__action)}>
                                                    <svg className={cssClass(styles.reference__ico)}
                                                         xmlns="http://www.w3.org/2000/svg" width="13" height="15"
                                                         viewBox="0 0 13 15" fill="none">
                                                        <path
                                                            d="M1.52941 15C1.10882 15 0.748648 14.853 0.448883 14.559C0.149119 14.265 -0.000508506 13.912 1.29831e-06 13.5V3H1.52941V13.5H9.94118V15H1.52941ZM4.58824 12C4.16765 12 3.80747 11.853 3.50771 11.559C3.20794 11.265 3.05831 10.912 3.05882 10.5V1.5C3.05882 1.0875 3.20871 0.734251 3.50847 0.440251C3.80824 0.146251 4.16816 -0.000498727 4.58824 1.27335e-06H11.4706C11.8912 1.27335e-06 12.2514 0.147001 12.5511 0.441001C12.8509 0.735001 13.0005 1.088 13 1.5V10.5C13 10.9125 12.8501 11.2658 12.5504 11.5598C12.2506 11.8538 11.8907 12.0005 11.4706 12H4.58824Z"
                                                            fill="url(#paint0_radial_3360_15121)"/>
                                                        <defs>
                                                            <radialGradient id="paint0_radial_3360_15121" cx="0" cy="0"
                                                                            r="1" gradientUnits="userSpaceOnUse"
                                                                            gradientTransform="translate(16.0115 15) rotate(-137.82) scale(26.435 20.4823)">
                                                                <stop stop-color="#003629"/>
                                                                <stop offset="1" stop-color="#7F00E2"/>
                                                            </radialGradient>
                                                        </defs>
                                                    </svg>
                                                    <span
                                                        className={cssClass(styles.reference__text)}>{t('Copy')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cssClass(styles.reference__item)}>
                                        <div className={cssClass(styles.reference__header)}>
                                            {t('YourLinkText')}
                                        </div>
                                        <div className={cssClass(styles.reference__layout)}>
                                            <div className={cssClass(styles.reference__main)}>
                                            <textarea
                                                className={cssClass(styles.reference__field, styles.reference__field_second)}>
                                                {t('ReferalsCopySecond')}
                                            </textarea>
                                            </div>
                                            <div className={cssClass(styles.reference__footer)}>
                                                <div className={cssClass(styles.reference__action)}>
                                                    <svg className={cssClass(styles.reference__ico)}
                                                         xmlns="http://www.w3.org/2000/svg" width="13" height="15"
                                                         viewBox="0 0 13 15" fill="none">
                                                        <path
                                                            d="M1.52941 15C1.10882 15 0.748648 14.853 0.448883 14.559C0.149119 14.265 -0.000508506 13.912 1.29831e-06 13.5V3H1.52941V13.5H9.94118V15H1.52941ZM4.58824 12C4.16765 12 3.80747 11.853 3.50771 11.559C3.20794 11.265 3.05831 10.912 3.05882 10.5V1.5C3.05882 1.0875 3.20871 0.734251 3.50847 0.440251C3.80824 0.146251 4.16816 -0.000498727 4.58824 1.27335e-06H11.4706C11.8912 1.27335e-06 12.2514 0.147001 12.5511 0.441001C12.8509 0.735001 13.0005 1.088 13 1.5V10.5C13 10.9125 12.8501 11.2658 12.5504 11.5598C12.2506 11.8538 11.8907 12.0005 11.4706 12H4.58824Z"
                                                            fill="url(#paint0_radial_3360_15121)"/>
                                                        <defs>
                                                            <radialGradient id="paint0_radial_3360_15121" cx="0" cy="0"
                                                                            r="1" gradientUnits="userSpaceOnUse"
                                                                            gradientTransform="translate(16.0115 15) rotate(-137.82) scale(26.435 20.4823)">
                                                                <stop stop-color="#003629"/>
                                                                <stop offset="1" stop-color="#7F00E2"/>
                                                            </radialGradient>
                                                        </defs>
                                                    </svg>
                                                    <span
                                                        className={cssClass(styles.reference__text)}>{t('Copy')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cssClass(styles.section__subsection)}>
                            <ReferralTabs
                                tab={tab}
                                setTab={setTab}
                                setCurrentPage={setCurrentPage}
                                cashActiveCount={cashActiveCount}
                            />
                            <BeforeTable tab={tab}/>
                            <TableDesktop data={currentData()} cancelOperation={cancelOperation}/>

                        </div>
                    </div>
                </div>
            </section>
            {currentData().rows.length > 1 && (<Pagination currentPage={currentPage} setCurrentPage={setCurrentPage}
                                                           lastPage={Math.round(currentData().rows.length / 30)}/>)}
        </>
    )
}

const ReferralTabs = ({tab, setTab, setCurrentPage, cashActiveCount}) => {
    const {t} = useTranslation()
    return (
        <div className={styles.tabWrapper}>
            {tabs.map((element, index) => {
                return (
                    <div key={index}>
                        <button
                            className={cssClass(
                                baseComponents.button,
                                tab === element ? baseComponents.buttonActive : null,
                            )}
                            onClick={() => {
                                setTab(element)
                                setCurrentPage(1)
                            }}
                        >
                            {t(element)}
                        </button>
                        {index === 1 && cashActiveCount > 0 && (
                            <div
                                className={cssClass(
                                    styles.notification1Figure,
                                    cashActiveCount >= 10 ? styles.notificationMoreThan1Figure : null,
                                )}
                            >
                                {cashActiveCount}
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}
const BeforeTable = ({tab}) => {
    return (
        <div className={styles.optionsWrapper}>
            {tab === tabs[0] &&
            <div className={cssClass(styles.wysiwyg)}>
                <p>
                    1. Вы будете получать 33% от комиссии реферала.<br/>
                    2. Программа действует только для обмена криптовалютных и рублевых пар.<br/>
                    3. Вы можете пригласить неограниченное количество участников! Программа является бессрочной при
                    наличии реферальной сети из 100 участников.<br/>
                    4. Реферал, зарегистрированный по вашей ссылке, получит приветственный бонус на счет AFF (в рамках
                    маркетингового предложения).<br/>
                    5. С подробными условиями Вы можете ознакомиться, перейдя по следующей ссылке:
                    https://Blazarex.com/rules.
                </p>
            </div>
            }
            {tab === tabs[1] && <div></div>}
            {tab === tabs[2] && <div></div>}
        </div>
    )
};
const TableDesktop = ({data, cancelOperation}) => {
    const {t} = useTranslation()
    const {titles, rows, customClass} = data
    return (
        <>
            <div className={cssClass(styles.table, styles.desktopOnly)}>
                <div className={cssClass(styles.row, styles.titleRow, customClass)}>
                    {titles.map((element, index) => {
                        if (element) {
                            return (
                                <div key={index} className={cssClass(styles.cell, styles.tableTitle)}>
                                    {t(element)}
                                </div>
                            )
                        }
                    })}
                </div>
                {rows.length > 1 && (<div className={styles.line}></div>)}
                {rows.map((row, indexRow) => {
                    const isDeposit = row.type === 'Deposit'
                    return (
                        <div
                            key={indexRow}
                            className={cssClass(
                                styles.row,
                                (indexRow % 2) - 1 && styles.cellColoredBg,
                                customClass,
                            )}
                        >
                            {row.array.map((element, indexElement) => {
                                if (element) {
                                    return (
                                        <div
                                            key={indexElement}
                                            className={cssClass(
                                                styles.cell,
                                                titles[indexElement] === 'TxID' && styles.TxID,
                                                element === 'Canceled' && styles.cancelled,
                                                element === 'Credited' && styles.credited,
                                            )}
                                        >
                                            {titles[indexElement] === 'Type' ||
                                            titles[indexElement] === 'ReplenishmentAccount'
                                                ? t(element)
                                                : titles[indexElement] === 'Status'
                                                    ? t(`FinanceItemStatus_${element}`)
                                                    : element}
                                            {element === 'Waiting' && (
                                                <>
                                                    <span>/</span>
                                                    <button
                                                        className={styles.cancelButton}
                                                        onClick={() => cancelOperation(isDeposit, row.txId)}
                                                    >
                                                        {t('CancelVerb')}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )
                })}
            </div>
        </>
    )
};

const Pagination = ({currentPage, setCurrentPage, lastPage}) => {
    return (
        <div className={styles.paginationWrapper}>
            <button
                onClick={() => currentPage - 1 > 0 && setCurrentPage((prev) => prev - 1)}
                className={cssClass(baseComponents.buttonOutlined, styles.arrowButton)}
            >
                <img src={arrowLeftIimg} alt='Arrow left'/>
            </button>
            <div className={styles.paginationCenterWrapper}>
                {currentPage > 3 && (
                    <span onClick={() => setCurrentPage(1)} className={styles.pageNumber}>
            1
          </span>
                )}
                {currentPage > 3 && lastPage > 4 && (
                    <EllipsisPage currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                )}
                {lastPage > 5 && (
                    <>
                        {currentPage === 3 && (
                            <span onClick={() => setCurrentPage(currentPage - 2)} className={styles.pageNumber}>
                {currentPage - 2}
              </span>
                        )}
                        {currentPage - 1 !== 0 && (
                            <span onClick={() => setCurrentPage(currentPage - 1)} className={styles.pageNumber}>
                {currentPage - 1}
              </span>
                        )}
                        <span className={cssClass(styles.pageNumber, styles.pageNumberActive)}>
              {currentPage}
            </span>
                        {lastPage - currentPage > 1 && (
                            <span onClick={() => setCurrentPage(currentPage + 1)} className={styles.pageNumber}>
                {currentPage + 1}
              </span>
                        )}
                        {lastPage - currentPage === 2 && (
                            <span onClick={() => setCurrentPage(currentPage + 2)} className={styles.pageNumber}>
                {currentPage + 2}
              </span>
                        )}
                    </>
                )}
                {lastPage - currentPage > 2 && lastPage > 5 && (
                    <EllipsisPage currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                )}
                {currentPage !== lastPage && lastPage - currentPage !== 2 && (
                    <span onClick={() => setCurrentPage(lastPage)} className={styles.pageNumber}>
            {lastPage}
          </span>
                )}
            </div>

            <button
                onClick={() => lastPage - currentPage > 0 && setCurrentPage((prev) => prev + 1)}
                className={cssClass(baseComponents.button, styles.arrowButton, styles.arrowRight)}
            >
                <img src={arrowLeftIimg} alt='Arrow left'/>
            </button>
        </div>
    )
};
const EllipsisPage = ({currentPage, setCurrentPage}) => {
    const [value, setValue] = useState('...');
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                setCurrentPage(parseInt(e.target.value))
            }
        })
    });
    return (
        <span className={cssClass(styles.pageNumber, styles.ellipsisWrapper)}>
      <input
          ref={refInput}
          className={cssClass(baseComponents.input, baseComponents.buttonOutlined, styles.ellipsis)}
          type='text'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onClick={() => setValue('')}
          onBlur={() => setValue('...')}
      />
    </span>
    )
};
