import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'

import searchIcon from './searchIcon.svg'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './Search.module.scss'

export default function Search() {
  const { t } = useTranslation()
  return (
    <div className={cssClass(styles.wrapper)}>
      <input className={cssClass(baseComponents.input, styles.input)} placeholder={t('Search')}></input>
      <img className={styles.image} src={searchIcon} alt='Search icon' />
    </div>
  )
}
