import React from 'react'
import { cssClass } from 'helpers/helper'
import style from './style/TokenPage.module.scss'
import TokenIcons from './TokenData/TokenIcons'

export default function TokenFooter({ t, onGoToTokenTradePage, onClickTokenComments }) {
  return (
    <div className={cssClass(style.container)}>
      <div className={cssClass(style.project__action, style.action)}>
        <ul className={cssClass(style.action__list)}>
          <li className={cssClass(style.action__item)} onClick={onGoToTokenTradePage}>
            <a className={cssClass(style.btn)} href='#'>
              <span className={cssClass(style.btn__text)}>{t('TokenBuyToken')}</span>
              <TokenIcons name={'arrowTop'} />
            </a>
          </li>
          <li className={cssClass(style.action__item)}>
            <a className={cssClass(style.btn, style.btn_secondary)} onClick={onClickTokenComments}>
              <span className={cssClass(style.btn__text)}>{t('TokenComments')}</span>
              <TokenIcons name={'arrowBottom'} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
