import steps__img01 from '../../secondaryLayout/img/steps__img01.png'
import steps__img02 from '../../secondaryLayout/img/steps__img02.png'
import steps__img03 from '../../secondaryLayout/img/steps__img03.png'
import steps__img04 from '../../secondaryLayout/img/steps__img04.png'
import steps__img05 from '../../secondaryLayout/img/steps__img05.png'
import steps__img06 from '../../secondaryLayout/img/steps__img06.png'
import steps__img07 from '../../secondaryLayout/img/steps__img07.png'
import steps__img08 from '../../secondaryLayout/img/steps__img08.png'
import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'

export default function Steps({ t, navigationFunctions }) {
  const [selectedTab, setSelectedTab] = useState(1)
  const [buttonTexts, setButtonTexts] = useState('')
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }

  useEffect(() => {
    if (!isUserAuth) {
      setButtonTexts('CreateAccount')
      return
    }
    setButtonTexts('CreateAToken')
  }, [isUserAuth])

  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__header'>
            <div className='section__tabs tabs'>
              <ul className='tabs__list'>
                <li
                  className={`tabs__item ${selectedTab == 1 ? 'tabs__item_active' : ''}`}
                  onClick={() => setSelectedTab(1)}
                >
                  {t('IWantToCreateCoin')}
                </li>
                <li
                  className={`tabs__item ${selectedTab == 2 ? 'tabs__item_active' : ''}`}
                  onClick={() => setSelectedTab(2)}
                >
                  {t('IWantToStartInvesting')}
                </li>
              </ul>
            </div>
            {selectedTab === 1 ? <h2 className='section__title'>{t('CreateYourCoinAndStartAttracting')}</h2> : null}
            {selectedTab === 2 ? <h2 className='section__title'>{t('Become4SimpleSteps')}</h2> : null}
          </div>
          <div className='section__main'>
            <div className='layout__steps steps'>
              <div className='steps__list'>
                {selectedTab === 1 && (
                  <React.Fragment>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('FirstStep')}</span>
                      <span className='steps__text'>{t('RegisterAnAccountAndGoThroughVerification')}</span>
                      <img className='steps__img' src={steps__img01} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('SecondStep')}</span>
                      <span className='steps__text'>{t('CreateYourCoinByFillingOut')}</span>
                      <img className='steps__img' src={steps__img02} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('ThirdStep')}</span>
                      <span className='steps__text'>{t('PromoteTheCoinPage')}</span>
                      <img className='steps__img' src={steps__img03} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('FourthStep')}</span>
                      <span className='steps__text'>{t('EarnFromYourPartnersInvestments')}</span>
                      <img className='steps__img' src={steps__img04} alt='image description' />
                    </div>
                  </React.Fragment>
                )}
                {selectedTab === 2 && (
                  <React.Fragment>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('FirstStep')}</span>
                      <span className='steps__text'>{t('RegisterAnAccountAndGoThroughVerification')}</span>
                      <img className='steps__img' src={steps__img05} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('SecondStep')}</span>
                      <span className='steps__text'>{t('SelectACoinAndStudyTheProjectInformation')}</span>
                      <img className='steps__img' src={steps__img06} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('ThirdStep')}</span>
                      <span className='steps__text'>{t('TopUpYourAccountWith')}</span>
                      <img className='steps__img' src={steps__img07} alt='image description' />
                    </div>
                    <div className='steps__item layout__box layout__box_secondary'>
                      <span className='steps__title layout__tag'>{t('FourthStep')}</span>
                      <span className='steps__text'>{t('BuyCoinSharesStarting')}</span>
                      <img className='steps__img' src={steps__img08} alt='image description' />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className='steps__action'>
              <a className='btn' onClick={() => handleGoToPage()}>
                <span className='btn__text'>{t(buttonTexts)}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
