import React from 'react'
import ConfirmSendPaymentModal from './ConfirmSendPaymentModal'
import ConfirmPaymentModal from './ConfirmPaymentModal'
import ConfirmPaymentWarningModal from './ConfirmPaymentWarningModal'
import IsNeededHelpModal from './IsNeededHelpModal'
import { DEAL_STATUS } from '../../Order/OrderAndDealData'
import HelpNotPaymentOrNotCoinsModal from './HelpNotPaymentOrNotCoinsModal'
import HelpAmountLessOrMoreModal from './HelpAmountLessOrMoreModal'
import HelpOtherModal from './HelpOtherModal'
import CancelOrderWarningModal from './CancelOrderWarningModal'
import DealCommentModal from './DealCommentModal'
import DeleteDealCommentWarningModal from './DeleteDealCommentWarningModal'
import ApplicationCreatedModal from './ApplicationCreatedModal'
import CancelAppealWarningModal from './CancelAppealWarningModal'
import DealCancelDisputeModal from './DealCancelDisputeModal'
const checkboxToModalMapping = {
  P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins: 'isHelpNotPaymentOrNotCoinsModalOpen',
  IHaveNotReceivedAnyPayments: 'isHelpNotPaymentOrNotCoinsModalOpen',
  P2PCauseIPaidMoreThanRequired: 'isHelpAmountLessOrMoreModalOpen',
  P2PCauseTheCounterpartyPaidLessThanRequired: 'isHelpAmountLessOrMoreModalOpen',
  Other: 'isHelpOtherModalOpen',
}
export default function CommonModals({ onConfirmCommonModals, modals, closeModal, openModal, deal, data }) {
  const { isDirectionDealBuying } = deal || {}

  const openSelectedModal = (selectedCheckbox) => {
    const modalId = checkboxToModalMapping[selectedCheckbox]
    if (modalId) {
      openModal(modalId)
    }
  }

  return (
    <>
      <ConfirmSendPaymentModal
        opened={modals.isConfirmSendPaymentModalOpen}
        onClose={() => closeModal('isConfirmSendPaymentModalOpen')}
        deal={deal}
        onConfirmConfirmSendPaymentModal={(actionType, payload) => {
          onConfirmCommonModals(actionType, payload)
        }}
      />
      <ConfirmPaymentModal
        opened={modals.isConfirmPaymentModalOpen}
        onClose={() => closeModal('isConfirmPaymentModalOpen')}
        deal={deal}
        onConfirmConfirmPaymentModal={() => openModal('isConfirmPaymentWarningModalOpen')}
      />
      <ConfirmPaymentWarningModal
        opened={modals.isConfirmPaymentWarningModalOpen}
        onClose={() => closeModal('isConfirmPaymentWarningModalOpen')}
        onConfirmPaymentWarning={(actionType, payload) => {
          closeModal('isConfirmPaymentModalOpen')
          closeModal('isConfirmPaymentWarningModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
      />
      <IsNeededHelpModal
        opened={modals.isNeededHelpModalOpen}
        onClose={() => closeModal('isNeededHelpModalOpen')}
        isDirectionDealBuying={isDirectionDealBuying}
        onConfirmIsNeededHelpModal={(selectedCheckbox) => openSelectedModal(selectedCheckbox)}
      />
      <HelpNotPaymentOrNotCoinsModal
        opened={modals.isHelpNotPaymentOrNotCoinsModalOpen}
        onClose={() => closeModal('isHelpNotPaymentOrNotCoinsModalOpen')}
        deal={deal}
        onConfirmHelpNotPaymentOrNotCoinsModal={(actionType, payload) => {
          closeModal('isHelpNotPaymentOrNotCoinsModalOpen')
          closeModal('isNeededHelpModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
      />
      <HelpAmountLessOrMoreModal
        opened={modals.isHelpAmountLessOrMoreModalOpen}
        onClose={() => closeModal('isHelpAmountLessOrMoreModalOpen')}
        deal={deal}
        onConfirmHelpAmountLessOrMoreModal={(actionType, payload) => {
          closeModal('isHelpAmountLessOrMoreModalOpen')
          closeModal('isNeededHelpModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
      />
      <HelpOtherModal
        opened={modals.isHelpOtherModalOpen}
        onClose={() => {
          closeModal('isHelpOtherModalOpen')
        }}
        deal={deal}
        onConfirmOtherModal={(actionType, payload) => {
          closeModal('isHelpOtherModalOpen')
          closeModal('isNeededHelpModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
      />
      <CancelOrderWarningModal
        opened={modals.isCancelOrderWarningModalOpen}
        onClose={() => closeModal('isCancelOrderWarningModalOpen')}
        deal={deal}
        onConfirmCancel={(actionType) => {
          closeModal('isCancelOrderWarningModalOpen')
          onConfirmCommonModals(actionType)
        }}
      />
      <DealCommentModal
        opened={modals.isDealCommentModalOpen}
        onClose={() => closeModal('isDealCommentModalOpen')}
        deal={deal}
        onConfirmDealCommentModal={(actionType, payload) => {
          closeModal('isDealCommentModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
        commentData={data}
      />
      <DeleteDealCommentWarningModal
        opened={modals.isDeleteOrEditDealCommentWarningModalOpen}
        onClose={() => closeModal('isDeleteOrEditDealCommentWarningModalOpen')}
        deal={deal}
        onConfirmDelete={(actionType, payload) => {
          closeModal('isDeleteOrEditDealCommentWarningModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
        commentData={data}
      />
      <ApplicationCreatedModal
        opened={modals.isApplicationCreatedModalOpen}
        onClose={() => closeModal('isApplicationCreatedModalOpen')}
        deal={deal}
        onConfirmDelete={(actionType, payload) => {
          closeModal('isApplicationCreatedModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
        commentData={data}
      />
      <CancelAppealWarningModal
        opened={modals.isCancelAppealWarningModalOpen}
        onClose={() => closeModal('isCancelAppealWarningModalOpen')}
        deal={deal}
        onConfirmCancel={(actionType) => {
          closeModal('isCancelAppealWarningModalOpen')
          onConfirmCommonModals(actionType)
        }}
      />
      <DealCancelDisputeModal
        opened={modals.isDealCancelDisputeModalOpen}
        onClose={() => closeModal('isDealCancelDisputeModalOpen')}
        deal={deal}
        onConfirmDealCancelDisputeModal={(actionType, payload) => {
          closeModal('isDealCancelDisputeModalOpen')
          onConfirmCommonModals(actionType, payload)
        }}
      />
    </>
  )
}
