import React, { useState, useEffect } from 'react'
import Icon from './ProgressIcons'
import { DEAL_STATUS } from '../Order/OrderAndDealData'
import useModalState from './Modals/useModalState'
import CommonModals from './Modals/CommonsMadals'
import moment from 'moment/moment'

const ProgressFeedbackOnTrading = ({ onConfirmProgressFeedbackOnTrading, t, deal, isDisabledBtn }) => {
  const { isDealCreator, dealUserId, order, status } = deal || {}
  const [ownerComment, setOwnerComment] = useState({})
  const { modals, openModal, closeModal } = useModalState()
  const [editDealComment, setEditDealComment] = useState({})
  const handleDealComment = (type, feedback) => {
    onConfirmProgressFeedbackOnTrading(type, feedback)
  }

  useEffect(() => {
    const ownerComment = deal?.comments
      ? deal?.comments.find(
          (comment) =>
            (isDealCreator && comment.fromUserId === dealUserId) ||
            (!isDealCreator && comment.fromUserId === order.userId),
        )
      : {}

    setOwnerComment(ownerComment)
    setEditDealComment(null)
  }, [deal.comments])

  if (status !== DEAL_STATUS.CryptoReleased && status !== DEAL_STATUS.Canceled) {
    return null
  }
  return (
    <React.Fragment>
      <div className='group'>
        <div className='wysiwyg'>
          <h2>{t('MyMark')}</h2>
          <div className='group__main'>
            {status === DEAL_STATUS.CryptoReleased || status === DEAL_STATUS.Canceled}
            {ownerComment ? (
              <OwnerComment
                openModal={openModal}
                comment={ownerComment}
                t={t}
                setEditDealComment={setEditDealComment}
              />
            ) : (
              <ReviewForm openModal={openModal} t={t} isDisabledBtn={isDisabledBtn} />
            )}
          </div>
        </div>
      </div>
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        deal={deal}
        onConfirmCommonModals={(type, feedback) => handleDealComment(type, feedback)}
        data={editDealComment}
      />
    </React.Fragment>
  )
}

export default ProgressFeedbackOnTrading

function OwnerComment({ openModal, comment, t, setEditDealComment }) {
  return (
    <div className='form-item__switch '>
      <label className='switch__layout comment_label'>
        <div className='switch__media'>
          <Icon name={comment.isPositive ? 'upIco' : 'downIco'} />
        </div>
        <span className='switch__title'>
          <span className='switch__text'>{t(comment.isPositive ? 'Fine' : 'Badly')}</span>
        </span>
        <div className='chat__control control comment_btn'>
          <div className='comment_btn_list'>
            <div className='control__media' style={{ cursor: 'pointer' }}>
              <span
                onClick={() => {
                  setEditDealComment(comment)
                  openModal('isDealCommentModalOpen')
                }}
              >
                <Icon name='editIco' />
              </span>
            </div>
            <div className='control__media' style={{ cursor: 'pointer' }}>
              <span
                onClick={() => {
                  setEditDealComment(comment)
                  openModal('isDeleteOrEditDealCommentWarningModalOpen')
                }}
              >
                <Icon name='deleteIco' />
              </span>
            </div>
          </div>
        </div>
      </label>
      <div className='comment_text'>
        <span className='data__text'>{comment.comment}</span>
      </div>
      <div className='comment_footer'>
        <span className='comment_date'>{moment(comment.dateCreated).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
    </div>
  )
}

function ReviewForm({ t, openModal, isDisabledBtn }) {
  return (
    <div className='p2p__reviews reviews'>
      <div className='reviews__header'>
        <div className='wysiwyg'>
          <p>{t('HowWasExperience')}</p>
        </div>
      </div>
      <div className='formular'>
        <div className='row'>
          <ReviewOption type='up' t={t} openModal={openModal} isDisabledBtn={isDisabledBtn} />
          <ReviewOption type='down' t={t} openModal={openModal} isDisabledBtn={isDisabledBtn} />
        </div>
      </div>
    </div>
  )
}

function ReviewOption({ type, t, openModal, isDisabledBtn }) {
  return (
    <div className={`col col_6  col_mob-12`}>
      <div className='form-item'>
        <div className='form-item__main'>
          <div className='form-item__field'>
            <div className={`form-item__switch form-item__switch_${type} switch`}>
              <label className='switch__label' onClick={() => !isDisabledBtn && openModal('isDealCommentModalOpen')}>
                <input className='switch__input' type='radio' name='reviewModal' disabled={isDisabledBtn} />
                <div className='switch__layout'>
                  <div className='switch__media'>
                    <Icon name={`${type}Ico`} />
                  </div>
                  <span className='switch__title'>
                    <span className='switch__text'>{t(type === 'up' ? 'Fine' : 'Badly')}</span>
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
