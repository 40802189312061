import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './ConverterSection.module.scss'
import store from '../../trading/store'
import Modal from 'components/layout/Modal/Modal'
import { showCurrencyIcon, TimerMaxSeconds } from './ConverterCommon'
import api from 'api'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

export default function ConverterCartModal({
  isOpened,
  setOpened,
  currencyTop,
  currencyBottom,
  amountTop,
  amountBottom,
  rate,
  digitsPrice,
  isAmountSufficient,
  cartModalSeconds,
  convertBtnDisabled,
  addOrder,
}) {
  const { t } = useTranslation()
  const isValid = isAmountSufficient && amountTop > 0 && amountBottom > 0

  const secondsStr = cartModalSeconds >= 0 && cartModalSeconds <= TimerMaxSeconds ? `(${cartModalSeconds})s` : ''

  return (
    <Modal opened={isOpened} modalCallback={setOpened} blockStyle={'modal480'}>
      <div className={cssClass(stylesGlobal.modal__layout)}>
        <div className={cssClass(stylesGlobal.modal__header)}>
          <span className={cssClass(stylesGlobal.modal__title)}>{t('ConverterConfirmation')}</span>
        </div>
        <div className={cssClass(stylesGlobal.modal__main)}>
          <div className={cssClass(styles.converter, stylesGlobal.p_0)}>
            <div className={cssClass(styles.converter__cart, styles.cart)}>
              <div className={cssClass(styles.cart__list)}>
                <div className={cssClass(styles.cart__item)}>
                  <div className={cssClass(styles.cart__media)}>
                    <CurrencyIcon currencyCode={currencyTop} />
                  </div>
                  <div className={cssClass(styles.cart__main)}>
                    <span className={cssClass(styles.cart__text)}>{amountTop}</span>
                    <span className={cssClass(styles.cart__text)}>{currencyTop}</span>
                  </div>
                </div>
                <div className={cssClass(styles.cart__revert)}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='21' viewBox='0 0 18 21' fill='none'>
                    <path
                      d='M2.43401 12.0138L7.97475 17.5545L7.97478 1.96872C7.97476 1.8567 7.99318 1.74896 8.0272 1.64836C8.11887 1.37714 8.32367 1.1581 8.58553 1.04732C8.70512 0.996771 8.83665 0.968783 8.97477 0.968734C9.52704 0.968748 9.97476 1.41647 9.97477 1.96873L9.97474 17.5545L15.5154 12.0138C15.906 11.6233 16.5391 11.6233 16.9297 12.0138C17.3202 12.4043 17.3202 13.0375 16.9297 13.428L9.68185 20.6758C9.65744 20.7002 9.63211 20.7232 9.60594 20.7445C9.22198 21.0576 8.65977 21.0428 8.29266 20.7C8.28425 20.6922 8.27591 20.6841 8.26763 20.6758L1.0198 13.428C0.629281 13.0375 0.629281 12.4043 1.01981 12.0138C1.41032 11.6233 2.04349 11.6233 2.43401 12.0138Z'
                      fill='#202639'
                    />
                  </svg>
                </div>
                <div className={cssClass(styles.cart__item)}>
                  <div className={cssClass(styles.cart__media)}>
                    <CurrencyIcon currencyCode={currencyBottom} />
                  </div>
                  <div className={cssClass(styles.cart__main)}>
                    <span className={cssClass(styles.cart__text)}>{amountBottom}</span>
                    <span className={cssClass(styles.cart__text)}>{currencyBottom}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={cssClass(styles.converter__checkout, styles.checkout)}>
              <div className={cssClass(styles.checkout__list)}>
                <div className={cssClass(styles.checkout__item)}>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterRate')}</span>
                  <span className={cssClass(styles.checkout__text)}>
                    1 {currencyTop} = {rate} {currencyBottom}
                  </span>
                </div>
                <div className={cssClass(styles.checkout__item)}>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterReverseRate')}</span>
                  <span className={cssClass(styles.checkout__text)}>
                    1 {currencyBottom} = {rate ? (1 / rate).toFixedTrimmed(digitsPrice) : ''} {currencyTop}
                  </span>
                </div>
                <div className={cssClass(styles.checkout__item)}>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterPaymentMethod')}</span>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterSpotWallet')}</span>
                </div>
                <div className={cssClass(styles.checkout__item)}>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterTransactionCosts')}</span>
                  <span className={cssClass(styles.checkout__text, styles.checkout__text_second)}>
                    {t('ConverterNoFee')}
                  </span>
                </div>
                <div className={cssClass(styles.checkout__item, styles.checkout__item_second)}>
                  <span className={cssClass(styles.checkout__text)}>{t('ConverterYouReceive')}</span>
                  <span className={cssClass(styles.checkout__text)}>
                    {amountBottom} {currencyBottom}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cssClass(stylesGlobal.modal__footer)}>
          {!isAmountSufficient && (
            <div className={cssClass(stylesGlobal.tooltip)}>
              <div className={cssClass(stylesGlobal.tooltip__media)}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
                  <path
                    d='M12 17.9414C12.2833 17.9414 12.521 17.8454 12.713 17.6534C12.905 17.4614 13.0007 17.2241 13 16.9414C13 16.6581 12.904 16.4204 12.712 16.2284C12.52 16.0364 12.2827 15.9407 12 15.9414C11.7167 15.9414 11.479 16.0374 11.287 16.2294C11.095 16.4214 10.9993 16.6587 11 16.9414C11 17.2247 11.096 17.4624 11.288 17.6544C11.48 17.8464 11.7173 17.9421 12 17.9414ZM12 13.9414C12.2833 13.9414 12.521 13.8454 12.713 13.6534C12.905 13.4614 13.0007 13.2241 13 12.9414V8.94141C13 8.65807 12.904 8.42041 12.712 8.22841C12.52 8.03641 12.2827 7.94074 12 7.94141C11.7167 7.94141 11.479 8.03741 11.287 8.22941C11.095 8.42141 10.9993 8.65874 11 8.94141V12.9414C11 13.2247 11.096 13.4624 11.288 13.6544C11.48 13.8464 11.7173 13.9421 12 13.9414ZM12 22.9414C10.6167 22.9414 9.31667 22.6787 8.1 22.1534C6.88333 21.6281 5.825 20.9157 4.925 20.0164C4.025 19.1164 3.31267 18.0581 2.788 16.8414C2.26333 15.6247 2.00067 14.3247 2 12.9414C2 11.5581 2.26267 10.2581 2.788 9.04141C3.31333 7.82474 4.02567 6.76641 4.925 5.86641C5.825 4.96641 6.88333 4.25407 8.1 3.72941C9.31667 3.20474 10.6167 2.94207 12 2.94141C13.3833 2.94141 14.6833 3.20407 15.9 3.72941C17.1167 4.25474 18.175 4.96707 19.075 5.86641C19.975 6.76641 20.6877 7.82474 21.213 9.04141C21.7383 10.2581 22.0007 11.5581 22 12.9414C22 14.3247 21.7373 15.6247 21.212 16.8414C20.6867 18.0581 19.9743 19.1164 19.075 20.0164C18.175 20.9164 17.1167 21.6291 15.9 22.1544C14.6833 22.6797 13.3833 22.9421 12 22.9414Z'
                    fill='#FF004C'
                  />
                </svg>
              </div>
              <div className={cssClass(stylesGlobal.tooltip__main)}>
                <div className={cssClass(stylesGlobal.wysiwyg)}>
                  <p>
                    {t('ConverterInsufficientBalance')}
                    <br /> {t('ConverterPleaseTopUpBalance')}
                  </p>
                </div>
              </div>
            </div>
          )}
          {isValid && (
            <span
              className={cssClass(stylesGlobal.btn)}
              onClick={() => {
                if (!convertBtnDisabled) addOrder()
              }}
            >
              <span className={cssClass(stylesGlobal.btn__text)}>
                {t('ConverterConvert')} {secondsStr}
              </span>
            </span>
          )}
        </div>
      </div>
    </Modal>
  )
}
