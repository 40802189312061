export const GetOpenOrderLimitKey = (key, directionIndex = 0) => {
  switch (key) {
    case 'canOpenNewOrderAtSelectedTradingPair':
      return `P2PCanOpenNewOrderAtSelectedTradingPair_${directionIndex === 0 ? 'Buy' : 'Sell'}`
    case 'canOpenOrderWithSelectedPriceAndRequisites':
      return 'P2PCanOpenOrderWithSelectedPriceAndRequisites'
    case 'canOpenOrderWithinAppealLimits':
      return 'P2PCanOpenOrderWithinAppealLimits'
    case 'canOpenOrderWithinTotalLimits':
      return 'P2PCanOpenOrderWithinTotalLimits'
  }
}

export const PostingStage = {
  STAGE_1_TYPE_AND_PRICE: 0,
  STAGE_2_PAYMENT_REQUISITES: 1,
  STAGE_3_ADDITIONAL_CONDITIONS: 2,
  STAGE_4_CONFIRM: 3,
}
export const NextStageChecker = (currentStageIndex, nextButtonStageDisabled) => {
  const stageToStateMap = {
    [PostingStage.STAGE_1_TYPE_AND_PRICE]: nextButtonStageDisabled.stage1,
    [PostingStage.STAGE_2_PAYMENT_REQUISITES]: nextButtonStageDisabled.stage2 || nextButtonStageDisabled.limits,
    [PostingStage.STAGE_3_ADDITIONAL_CONDITIONS]: nextButtonStageDisabled.stage3,
    [PostingStage.STAGE_4_CONFIRM]: nextButtonStageDisabled.posting,
  }
  const result = stageToStateMap[currentStageIndex]
  return result ?? true
}

export const BuySellTabIndex = {
  Buy: 0,
  Sell: 1,
}
