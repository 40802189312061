import i18n from 'i18next'
import CommissionTypes from 'data/CommissionTypes'

export const cssClass = (...args) => args.filter((style) => style).join(' ')

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const isDevEnv = process.env.NODE_ENV == 'development'
export const isProdEnv = process.env.NODE_ENV == 'production'

export const findCommission = (commissionsArr, commType, currency, adapterCode = null) => {
  return (
    commissionsArr?.filter(
      (_) => _.commissionTypeCode === commType && _.currencyCode === currency && _.currencyAdapterCode === adapterCode,
    )[0] ?? null
  )
}

export const calculateCommission = (
  commissionsArr,
  amount,
  commType,
  currency,
  adapterCode = null,
  userNoTradeCommissions = false,
) => {
  const comm = findCommission(commissionsArr, commType, currency, adapterCode)
  if (!comm) return { commAmount: null, percent: null, minimum: null }
  let { percent, minimum } = comm
  let commAmount = (amount * percent) / 100
  if (commAmount < minimum) commAmount = minimum

  if (userNoTradeCommissions && (commType == CommissionTypes.DealAsk || commType == CommissionTypes.DealBid)) {
    commAmount = 0
    percent = 0
    minimum = 0
  }
  return { commAmount, percent, minimum }
}

export const getCommissionInfoString = (commissionsArr, commType, currency, adapterCode = null) => {
  const comm = findCommission(commissionsArr, commType, currency, adapterCode)
  return getCommissionInfoStringFromObj(comm)
}

export const getCommissionInfoStringFromObj = (commission) => {
  if (!commission) return ''
  const { currencyCode, percent, minimum, commissionCurrencyCode } = commission
  let commString = ''
  if (!percent && !minimum) return '0%'
  if (percent) commString += `${percent}%`
  if (percent && minimum) commString += ` ${i18n.t('or')} `
  if (minimum) commString += `${minimum} ${commissionCurrencyCode || currencyCode}`
  return commString
}

export function hideJivoChat() {
  var targets = document.querySelectorAll('jdiv')
  for (var i = 0; i < targets.length; i++) {
    targets[i].style.display = 'none'
  }
  if (targets.length === 0) setTimeout(hideJivoChat, 100)
  else console.log('jivoChat was hidden')
}
