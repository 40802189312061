import Main from '../common/components/Main/Main'
import { Route } from 'react-router-dom'
import i18n from 'i18next'
import P2pHeader from './P2pHeader/P2pHeader'
import P2pNotification from './P2pNotification/P2pNotification'
import P2pBanner from './P2pBanner/P2pBanner'
import Posting from './Posting/Posting'
import Order from './Order/Order'
import Home from './Home/Home'
import User from './User/User'
import React, { useEffect, useState } from 'react'
import Progress from './Progress/Progress'
import PrivateRoute from '../../auth/PrivateRoute'
import { useAuth } from 'auth/auth'
import SellerDealCancelDisputeModal from './DealDisputes/SellerDealCancelDisputeModal'
export default function P2pPage() {
  const auth = useAuth()

  const [selectedExchangeCurrency, setSelectedExchangeCurrency] = useState('USDT')
  const [selectedPaymentCurrency, setSelectedPaymentCurrency] = useState('TRY')
  return (
    <Main>
      <div className='p2p'>
        <Route exact path={`/${i18n.language}/P2P/`}>
          <P2pBanner
            selectedExchangeCurrency={selectedExchangeCurrency}
            selectedPaymentCurrency={selectedPaymentCurrency}
          />
        </Route>
        <P2pHeader />
        {auth.isSignedIn && <SellerDealCancelDisputeModal />}
        {auth.isSignedIn && <P2pNotification />}
        <div className='p2p__main'>
          <Route
            exact
            path={`/${i18n.language}/P2P/`}
            render={(props) => (
              <Home
                {...props}
                selectedExchangeCurrency={selectedExchangeCurrency}
                setSelectedExchangeCurrency={setSelectedExchangeCurrency}
                selectedPaymentCurrency={selectedPaymentCurrency}
                setSelectedPaymentCurrency={setSelectedPaymentCurrency}
              />
            )}
          />
          <PrivateRoute exact path={`/${i18n.language}/P2P/posting/:orderId?`} component={Posting} />
          <PrivateRoute exact path={`/${i18n.language}/P2P/progress/:dealId`} component={Progress} />
          <PrivateRoute exact path={`/${i18n.language}/P2P/progress`} component={Order} />
          <PrivateRoute exact path={`/${i18n.language}/P2P/order`} component={Order} />
          <PrivateRoute exact path={`/${i18n.language}/P2P/user`} component={User} />
        </div>
      </div>
    </Main>
  )
}
