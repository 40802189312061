import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'

export default function ConfirmPaymentModal({ onConfirmConfirmPaymentModal, opened, onClose, deal }) {
  const { t } = useTranslation()
  const { fiatAmount, cryptoAmount, order } = deal || {}
  const { paymentCurrencyCode, exchangeCurrencyCode } = order || {}
  const { bankName, cardNumber, paymentSystem, ownerName } = deal?.requisite || {}
  const { name } = paymentSystem || {}
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(true)

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('ConfirmTransfer')}</h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#ebfbf7' }}>
          <div className='data  mb-0'>
            <div className='data__list data__list_row'>
              <div className='data__item'>
                <div className='data__header'>
                  <span className='data__title'>{t('Volume')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text colorQuartyty500'>
                    {fiatAmount} {paymentCurrencyCode}
                  </span>
                </div>
              </div>
              <div className='data__item'>
                <div className='data__header'>
                  <span className='data__title'>{t('Amount')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text'>
                    {cryptoAmount} {exchangeCurrencyCode}
                  </span>
                </div>
              </div>
              <div className='data__item'>
                <div className='data__header'>
                  <span className='data__title'>{t('PaymentMethod')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text'>
                    {name} ({bankName})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='form-item'>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Checkbox
                  id={'IMadeAPayment'}
                  type={'radio'}
                  name={'payment'}
                  text={t('IDidntReceive')}
                  checkboxCallback={() => {
                    setIsDisableConfirmButton(true)
                  }}
                />
              </div>
              <div className='form-item__field'>
                <Checkbox
                  id={'IMadeAPayment'}
                  type={'radio'}
                  name={'payment'}
                  text={t('IGot')}
                  checkboxCallback={() => {
                    setIsDisableConfirmButton(false)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='wysiwyg'>
            <h2>
              <strong>{t('Recommendations')}</strong>
            </h2>
            <small>{t('PaymentPromptText1')}</small>
            <small>{t('PaymentPromptText2')}</small>
            <small>{t('PaymentPromptText3')}</small>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6 col_mob-12'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn w-100'
                      disabled={isDisableConfirmButton}
                      onClick={() => onConfirmConfirmPaymentModal()}
                    >
                      <span className='btn__text'>{t('ConfirmTransferSecond')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
