import React, { useEffect, useState } from 'react'
import ApiKeysModal from '../ApiKeysModal/ApiKeysModal'
import Modal from '../../../components/layout/Modal/Modal'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'

import pages from '../../pages.module.scss'
import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './ApiKeys.module.scss'
import api from 'api'
import moment from 'moment'
import { toast } from 'react-toastify'

export default function ApiKeys() {
  const { t } = useTranslation()
  const [keys, setKeys] = useState([])
  const [refetch, setRefetch] = useState(false)

  const [modalApiKey, setModalApiKey] = useState(false)

  useEffect(() => {
    loadKeys()
  }, [])

  const loadKeys = () => {
    api.fetchApiKeys().then((res) => {
      setKeys(res)
    })
  }

  const convertDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  const deleteKey = async (publicKey) => {
    try {
      await api.deleteApiKey(publicKey)
      toast.success(t('ApiKey_Delete_Success'))
      loadKeys()
    } catch (e) {
      toast.error(t('ApiKey_Delete_Fail'))
    }
  }

  return (
    <section className={pages.blockWrapper}>
      <div className={styles.firstRow}>
        <h1 className={styles.title}>{t('APIkeys')}</h1>
        <button onClick={() => setModalApiKey(true)} className={baseComponents.button}>
          {t('Create')}
        </button>
      </div>
      <div className={styles.table}>
        <div className={styles.titleBg}></div>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>{t('PublicKey')}</h3>
        <h3 className={cssClass(styles.cell, styles.titleCell, styles.phoneVHidden)}>
          {t('DateOfCreation1')}
          <br />
          {t('DateOfCreation2')}
        </h3>
        <h3 className={cssClass(styles.cell, styles.titleCell, styles.phoneVHidden)}>{t('Description')}</h3>
        <h3 className={cssClass(styles.cell, styles.titleCell, styles.phoneHidden)}>
          {t('AllowReceivingUserInformation1')}
          <br />
          {t('AllowReceivingUserInformation2')}
          <br />
          {t('AllowReceivingUserInformation3')}
          <br />
          {t('AllowReceivingUserInformation4')}
        </h3>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>
          {t('AllowTrading1')}
          <br />
          {t('AllowTrading2')}
        </h3>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>
          {t('AllowWithdrawal1')}
          <br />
          {t('AllowWithdrawal2')}
        </h3>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>{t('Action')}</h3>
        {keys.map((row, rowIndex) => {
          return (
            <React.Fragment key={rowIndex}>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{row.publicKey}</div>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{convertDate(row.dateCreated)}</div>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{row.description}</div>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{row.allowReceivingUserInfo ? '+' : '-'}</div>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{row.allowTrading ? '+' : '-'}</div>
              <div className={cssClass(styles.cell, styles.phoneVHidden)}>{row.allowWithdrawal ? '+' : '-'}</div>

              <button className={styles.cell} onClick={() => deleteKey(row.publicKey)}>
                {t('Delete')}
              </button>
              <div className={styles.line}></div>
            </React.Fragment>
          )
        })}
      </div>
      <Modal
        opened={modalApiKey}
        blockStyle={'modal600'}
        modalCallback={() => setModalApiKey((prev) => setModalApiKey(false))}
      >
        <ApiKeysModal setOpened={setModalApiKey} loadKeys={loadKeys} />
      </Modal>
    </section>
  )
}
