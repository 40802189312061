import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import axios from 'axios'
import ICU from 'i18next-icu'

let lang,
  locationUrl = location.href
if (locationUrl.indexOf('/ru') !== -1) lang = 'ru'
if (locationUrl.indexOf('/tr') !== -1) lang = 'tr'
if (locationUrl.indexOf('/en') !== -1) lang = 'en'
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ICU)
  .init({
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['localStorage'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true, // suspense is needed while localization is loading
    },
    pluralSeparator: ',',
  })

if (!lang) {
  const url =
    `${i18n.language || 'en'}/` +
    window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] +
    (window.location.search || '')

  console.log(`lang is missing from url. window.location.search: ${window.location.search}, new url ${url}`)
  window.history.pushState(null, null, window.location.origin + '/' + url)
}

// set header before each request to send language to backend
axios.interceptors.request.use((config) => {
  config.headers['accept-language'] = i18n.language
  return config
})

export default i18n
