import Main from 'pages/common/components/Main/Main'
import ConverterSection from '../landing/ConverterSection/ConverterSection'
import { cssClass } from 'helpers/helper'
import stylesGlobal from '../landing/LandingPage.module.scss'
import React from 'react'

export default function LandingPage() {
  return (
      <div className={cssClass(stylesGlobal.layout)}>
      <header></header>
        <div className={cssClass(stylesGlobal.layout__main)}>
        <Main>
          <section className={cssClass(stylesGlobal.layout__section)}>
            <div className={cssClass(stylesGlobal.section__main)}>
              <div className={cssClass(stylesGlobal.container, stylesGlobal.container_second)}>
                <ConverterSection />
              </div>
            </div>
          </section>
        </Main>
      </div>
      <footer></footer>
    </div>
  )
}
