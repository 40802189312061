import banner__img01 from '../../secondaryLayout/img/banner__img01.png'
import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'

export default function Banner({ t, navigationFunctions }) {
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage())
  }
  return (
    <React.Fragment>
      <section className='layout__section layout__section_secondary section'>
        <div className='container'>
          <div className='section__main'>
            <div className='layout__banner banner'>
              <div className='banner__media'>
                <img src={banner__img01} alt='image description' />
              </div>
              <div className='banner__header'>
                <h3 className='banner__title'>{t('StartUsingTheEasiestWay')}</h3>
              </div>
              <div className='banner__footer'>
                <a className='btn btn_tertiary' onClick={() => handleGoToPage()}>
                  <span className='btn__text'>{t('StartEarningMoney')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
