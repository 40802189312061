import Accordion from 'components/sections/Accordion/Accordion'
import { ProcessBottomActions, ProcessUpActions } from './ProgressActions'
import ProgressFeedbackOnTrading from './ProgressFeedbackOnTrading'
import ProgressHeader from './ProgressHeader'
import ProgressInfo from './ProgressInfo'
import ProgressPaymentMethod from './ProgressPaymentMethod'
import ProgressTradeDetails from './ProgressTradeDetails'
import ProgressTracker from './ProgressTracker'
import ProcessChat from './Chats/ProcessChat'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import api from 'api'
import { useAuth } from 'auth/auth'
const signalR = require('@microsoft/signalr')
import {
  StatusToStageIndex,
  BuyerFAQStage1,
  BuyerFAQStage2,
  BuyerFAQStage3,
  BuyerFAQStage4,
  SellerFAQStage1,
  SellerFAQStage2,
  SellerFAQStage3,
  SellerFAQStage4,
} from './ProgressData'
import { DEAL_STATUS } from '../Order/OrderAndDealData'

const faqData = {
  BuyerFAQStage1,
  BuyerFAQStage2,
  BuyerFAQStage3,
  BuyerFAQStage4, //cancel
  SellerFAQStage1,
  SellerFAQStage2,
  SellerFAQStage3,
  SellerFAQStage4, //cancel
}
export default function Progress() {
  const { t } = useTranslation()
  const auth = useAuth()
  const [signalRTimerId, setSignalRTimerId] = useState(null)
  const { dealId } = useParams()
  const [deal, setDeal] = useState(null)
  const [previousStatus, setPreviousStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isFeedbackLoading, setIsFeedbackIsLoading] = useState(false)
  const [FAQList, setFAQList] = useState([])
  const [isDisabledBtnProgressActionUp, setIsDisabledBtnProgressActionUp] = useState(false)
  const [isDisabledBtnCancel, setIsDisabledBtnCancel] = useState(false)
  const [chatUpdateNeeded, setChatUpdateNeeded] = useState(false)

  const history = useHistory()
  const goToPage = (title) => {
    const routePath =
      title === ('P2PSellerViewOrderGuideStage3' || title === 'P2PBuyerViewOrderGuideStage3')
        ? 'P2P/'
        : title === 'P2PSellerViewAssetsAfterCompletionStage3' || title === 'P2PBuyerViewAssetsAfterCompletionStage3'
        ? 'finance'
        : ''
    if (routePath !== '') {
      history.push({
        pathname: `/${i18n.language}/${routePath}`,
      })
    }
  }
  const handleData = (actionType, payload) => {
    switch (actionType) {
      case 'cancelDeal':
        cancelDeal()
        break
      case 'updateStatus':
        updateDealStatus(payload)
        break
      case 'onSubmitAnAppeal':
        submitAnAppeal(payload)
        break
      case 'cancelAppeal':
        cancelAppeal()
        break
      case 'createDealComment':
        createDealComment(payload)
        break
      case 'updateDealComment':
        updateDealComment(payload)
        break
      case 'deleteDealComment':
        deleteDealComment(payload)
        break
      case 'openCancellationDispute':
        openCancellationDisputeAndCancelDeal(payload)
        break
      case 'onContact':
        //ignore
        break

      default:
        console.log(`Progress - handleData. Неизвестное действие. actionType: ${actionType}, payload: ${payload}`)
    }
  }
  function createDealComment(payload) {
    setIsFeedbackIsLoading(true)
    const dealCommentDto = {
      dealId,
      isPositive: payload.isPositive,
      comment: payload.comment,
      isAnonymously: payload.isAnonymously,
    }

    api
      .createP2PDealComments(dealCommentDto)
      .then((newComment) => {
        setDeal((prevDeal) => ({
          ...prevDeal,
          comments: [...prevDeal.comments, newComment],
        }))
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsFeedbackIsLoading(false))
  }

  function updateDealComment(payload) {
    setIsFeedbackIsLoading(true)
    const dealCommentDto = {
      dealId,
      isPositive: payload.isPositive,
      comment: payload.comment,
      isAnonymously: payload.isAnonymously,
    }
    api
      .updateP2PDealComments(dealCommentDto)
      .then((updatedComment) => {
        setDeal((prevDeal) => ({
          ...prevDeal,
          comments: prevDeal.comments.map((comment) => (comment.id === updatedComment.id ? updatedComment : comment)),
        }))
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsFeedbackIsLoading(false))
  }

  function deleteDealComment(dealCommentDto) {
    setIsFeedbackIsLoading(true)
    api
      .deleteP2PDealComments(dealCommentDto.id)
      .then(() => {
        setDeal((prevDeal) => ({
          ...prevDeal,
          comments: prevDeal.comments.filter((comment) => comment.id !== dealCommentDto.id),
        }))
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsFeedbackIsLoading(false))
  }

  function submitAnAppeal(payload) {
    const model = {
      dealId,
      cause: payload,
    }
    api
      .createP2PAppeal(model)
      .then(() => {
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsDisabledBtnProgressActionUp(false))
  }

  function cancelAppeal() {
    api
      .cancelP2PAppeal(deal.id)
      .then(() => {
        toast.success(t('ActionWasSuccessful'))
      })
      .catch(() => {
        toast.error(t('ErrorTryLater'))
      })
  }

  function cancelDeal() {
    setIsDisabledBtnProgressActionUp(true)
    api
      .cancelP2PDeal(deal.id)
      .then((data) => {
        setDeal(data)
        toast.success(t('ActionWasSuccessful'))
      })
      .catch((e) => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsDisabledBtnProgressActionUp(false))
  }

  function updateDealStatus(newStatus) {
    setIsDisabledBtnProgressActionUp(true)
    api
      .updateP2PDealStatus(deal.id, newStatus)
      .then((data) => {
        setDeal(data)
        toast.success(t('ActionWasSuccessful'))
      })
      .catch((e) => {
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => setIsDisabledBtnProgressActionUp(false))
  }

  const fetchDealById = (dealId, ignore = false) => {
    setIsLoading(true)
    const data = api
      .fetchP2PDeal(dealId)
      .then((data) => {
        if (ignore) return
        if (data.status !== previousStatus) {
          setDeal(data)
          setPreviousStatus(data.status)
        }
      })
      .catch((e) => {
        if (ignore) return
        console.error('Error fetchP2PDeal ' + e)
      })
      .finally(() => {
        if (ignore) return
        setIsLoading(false)
      })
  }
  const openCancellationDisputeAndCancelDeal = (model) => {
    setIsDisabledBtnCancel(true)
    api
      .createP2PDealCancelDispute(model)
      .then((data) => {
        cancelDeal()
      })
      .catch((e) => {
        console.error('Error createP2PDealCancelDispute ' + e)
      })
      .finally(() => {
        setIsDisabledBtnCancel(false)
      })
  }

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/p2p`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    hubConnection
      .start()
      .then(() => {
        if (!location.pathname.toLowerCase().includes('/p2p/progress/')) {
          disconnectSignalR() // signalR has stuck, disconnect
          console.log('P2PProgress SignalR has stuck, disconnect')
          return
        }
        hubConnection.on('P2PDeal', (data) => {
          fetchDealById(dealId)
        })
        hubConnection.on('P2PChat', (data) => {
          setChatUpdateNeeded(true)
        })
        hubConnection.on('P2PSellerDealCancelDispute', () => {})

        const keepAlivePeriod = 60 * 1000 // 1 minute
        const timerId = setInterval(function () {
          hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
        }, keepAlivePeriod)
        setSignalRTimerId(timerId)
      })
      .catch((err) => {
        console.log('configureSignalR error', err)
      })
  }

  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
    }
  }, [])

  useEffect(() => {
    if (!dealId) return
    fetchDealById(dealId)
  }, [])

  useEffect(() => {
    if (!deal) return

    const currentStage = StatusToStageIndex[deal.status] + 1
    const isDirectionDealBuying = deal.isDirectionDealBuying ? 'Buyer' : 'Seller'
    const currentFAQListName = isDirectionDealBuying + 'FAQStage' + currentStage
    const currentFAQList = faqData[currentFAQListName]
    setFAQList(currentFAQList)
  }, [deal])

  if (isLoading && !deal) {
    return (
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <div>Loading...</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <>
              {deal && (
                <>
                  <ProgressHeader t={t} deal={deal} />
                  <ProgressTracker t={t} deal={deal} />
                  <ProgressInfo
                    t={t}
                    deal={deal}
                    onProgressInfo={(actionType, payload) => handleData(actionType, payload)}
                  />
                  <ProcessUpActions
                    onProcessUpActions={(actionType, payload) => handleData(actionType, payload)}
                    t={t}
                    deal={deal}
                    isDisabledBtn={isDisabledBtnProgressActionUp}
                  />
                  <div className='steps__main'>
                    <div className='row'>
                      <div className='col col_8 col_tab-12'>
                        <div className='wrapper'>
                          <ProgressTradeDetails t={t} deal={deal} />
                          <ProgressPaymentMethod t={t} deal={deal} />
                          <ProcessBottomActions
                            onProcessBottomActions={(actionType, payload) => handleData(actionType, payload)}
                            t={t}
                            deal={deal}
                            isDisabledBtn={isDisabledBtnCancel}
                          />
                          <ProgressFeedbackOnTrading
                            onConfirmProgressFeedbackOnTrading={(actionType, feedback) =>
                              handleData(actionType, feedback)
                            }
                            t={t}
                            deal={deal}
                            isDisabledBtn={isFeedbackLoading}
                          />
                        </div>
                      </div>
                      <div className='col col_4 col_tab-12'>
                        <ProcessChat
                          t={t}
                          deal={deal}
                          needUpdateMessages={chatUpdateNeeded}
                          setNeedUpdateMessages={setChatUpdateNeeded}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='section__header'>
          <div className='container'>
            <div className='wysiwyg'>
              <h1>FAQ</h1>
            </div>
          </div>
        </div>
        <div className='section__main'>
          <div className='container'>
            <Accordion accordionList={FAQList} searchSymbol={'>'} onHighlightedTextClick={(title) => goToPage(title)} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
