import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ProgressIcons from '../ProgressIcons'
import api from 'api'

export const ChatFooter = ({ deal }) => {
  const { t } = useTranslation()
  const fileInputRef = useRef(null)
  const inputTextRef = useRef(null)
  const [fileErrors, setFileErrors] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [textMessage, setTextMessage] = useState(null)
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)

  const handleKeyDown = (event) => {
    if (!selectedFile && (!textMessage || textMessage.trim() === '')) return

    if (event.key === 'Enter') {
      handleSendMessageClick()
    }
  }
  const handleAttachClick = () => {
    fileInputRef.current.click()
  }
  const handleInputChange = (event) => {
    setTextMessage(event.target.value)
  }

  const handleFileChange = (event) => {
    if (!event.target.files[0]) return
    const file = event.target.files[0]
    const fileType = file.type
    const fileSize = file.size

    let maxSize
    const errors = []

    if (['image/jpeg', 'image/png', 'image/jpg'].includes(fileType)) {
      maxSize = 10 * 1024 * 1024 // 10 MB
    } else if (['video/mp4', 'audio/mp3', 'video/quicktime'].includes(fileType)) {
      maxSize = 100 * 1024 * 1024 // 100 MB
    } else {
      errors.push($`{file.name} is not a supported file type.`)
      setFileErrors(errors)
      return
    }

    if (fileSize > maxSize) {
      errors.push($`{file.name} exceeds the size limit.`)
      setFileErrors(errors)
      return
    }
    setSelectedFile(file)
    setTextMessage(null)
    inputTextRef.current.value = null
    event.target.value = null
  }

  const resetMessageAndFile = () => {
    inputTextRef.current.value = null
    fileInputRef.current.value = ''
    setSelectedFile(null)
    setTextMessage(null)
    setIsSendButtonDisabled(false)
  }

  const handleSendMessageClick = () => {
    setIsSendButtonDisabled(true)
    if (textMessage) {
      sendTextMessage()
    } else if (selectedFile && selectedFile.length !== 0) {
      sendFileMessage()
    } else {
      console.log('No message or files to send')
    }
    resetMessageAndFile()
  }
  const sendFileMessage = async () => {
    console.log('Sending file message', selectedFile)
    if (!selectedFile) {
      console.error('No file selected')
      return
    }
    const formData = new FormData()
    formData.append('dealId', deal.id)
    formData.append('file', selectedFile, selectedFile.name)

    api.createP2PMessageWithFile(formData).catch((error) => {
      console.error('Error sending chat file message', error)
    })
  }

  const sendTextMessage = async () => {
    const dto = {
      dealId: deal.id,
      text: textMessage,
    }

    api.createP2PMessage(dto).catch((error) => {
      console.log('Error sending chat message', error)
    })
  }

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.addEventListener('change', handleFileChange)
    }
    return () => {
      if (fileInputRef.current) {
        fileInputRef.current.removeEventListener('change', handleFileChange)
      }
    }
  }, [fileInputRef])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })
  return (
    <div className='chat__footer'>
      <div className='chat__attach attach'>
        <div className={`attach__preview ${selectedFile ? 'file__selected' : ''}`} onClick={handleAttachClick}>
          <ProgressIcons name='clipIco' />
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept='image/jpeg,image/png,video/mp4,audio/mp3,video/quicktime'
            // multiple
          />
        </div>
        <div className='attach__dropdown dropdown'>
          <div className='dropdown__list'>
            {selectedFile && selectedFile.length !== 0 ? (
              <SelectedFileInfo t={t} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
            ) : (
              <>
                <LimitVideo t={t} />
                <LimitPhoto t={t} />
              </>
            )}
          </div>
        </div>
      </div>
      {/* Errors display */}
      {fileErrors.map((error, index) => (
        <div key={index} style={{ color: 'red' }}>
          {error}
        </div>
      ))}
      <div className='chat__field'>
        <input
          ref={inputTextRef}
          onChange={handleInputChange}
          className='chat__input'
          type='text'
          placeholder={selectedFile && selectedFile.length !== 0 ? t('Send') : t('P2PYourMessage')}
          disabled={selectedFile && selectedFile.length !== 0}
        />
      </div>
      <button
        className={`chat__action ${
          selectedFile || (textMessage && textMessage.trim() !== '') ? 'action__selected' : ''
        }`}
        onClick={handleSendMessageClick}
        disabled={isSendButtonDisabled}
      >
        <ProgressIcons name='sendMessageIco' />
      </button>
    </div>
  )
}

export default ChatFooter

const LimitPhoto = ({ t }) => {
  return (
    <div className='dropdown__item'>
      <div className='dropdown__header'>
        <div className='wysiwyg'>
          <h3>{t('P2PPhoto')}</h3>
        </div>
      </div>
      <div className='dropdown__main'>
        <div className='wysiwyg'>
          <dl>
            <dt>{t('P2PFormat')}</dt>
            <dd>JPG, PNG, JPEG</dd>
          </dl>
          <dl>
            <dt>{t('P2PDownloadLimit')}</dt>
            <dd>10 MB</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

const LimitVideo = ({ t }) => {
  return (
    <div className='dropdown__item'>
      <div className='dropdown__header'>
        <div className='wysiwyg'>
          <h3>{t('P2PVideo')}</h3>
        </div>
      </div>
      <div className='dropdown__main'>
        <div className='wysiwyg'>
          <dl>
            <dt>{t('P2PFormat')}</dt>
            <dd>MP4</dd>
          </dl>
          <dl>
            <dt>{t('P2PDownloadLimit')}</dt>
            <dd>100 MB</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

const SelectedFileInfo = ({ t, selectedFile, setSelectedFile }) => {
  return (
    <div className='dropdown__item'>
      <div className='dropdown__header'>
        <div className='wysiwyg'>
          <h3>{t('P2PSelectedFile')}</h3>
        </div>
      </div>
      <div className='dropdown__main'>
        <div className='wysiwyg'>
          <dl>
            <dt>{t('P2PName')}:</dt>
            <dd>
              {selectedFile?.name.length > 10
                ? `${selectedFile?.name.substring(0, 5)}...${selectedFile?.name.substring(
                    selectedFile?.name.length - 9,
                  )}`
                : selectedFile?.name}
            </dd>
          </dl>
          <dl>
            <dt>{t('P2PSize')}:</dt>
            <dd>{Math.round(selectedFile?.size / 1024) + 'KB'}</dd>
          </dl>
        </div>
        <dl>
          <div className='delete__button' onClick={() => setSelectedFile(null)}>
            <button>{t('Delete')}</button>
          </div>
        </dl>
      </div>
    </div>
  )
}
