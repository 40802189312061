import moment from 'moment'
import { reaction } from 'mobx'
import api from 'api'

export const Tabs = {
  BasicInformation: 1,
  NewsArticles: 2,
  Comments: 3,
  CreateOrEditNewsArticle: 4,
}

export const GetTokenUSDTPair = (exchangeInstruments) => {
  const tokenUSDTPair = exchangeInstruments.find(({ currencyPair }) => currencyPair.code.includes('USDT'))
  return tokenUSDTPair
}

export const SocialIconNames = {
  twitter: 'twitterIco',
  facebook: 'facebookIco',
  instagram: 'instagramIco',
  youtube: 'youtubeIco',
  t: 'telegramIco',
  discord: 'discordIco',
  reddit: 'redditIco',
  vk: 'vkIco',
}

export const FormatedDate = (dateCreated) => {
  const formattedDate = moment(dateCreated).format('D MMMM YYYY')
  return formattedDate
}
export const LikesCount = (likes) => {
  if (!likes) return ''
  const likesCount = likes?.length
  return likesCount === 0 ? '' : likesCount
}

export const ImageType = {
  Header: 'header',
  Logo: 'logo',
  Project: 'project',
}

export const TokenMarketType = { Popular: 'Popular', Hot: 'Hot', NewListing: 'New Listing' }

export const SortPairCriteria = {
  LastTradedPrice: 'lastTradedPrice',
  Change24H: '24hChange',
  High24H: '24hHigh',
  Low24H: '24hLow',
  Volume24H: '24hVolume',
  MarketPageDisplayOrder: 'MarketPageDisplayOrder',
  TokenTradingPairs: 'TokenTradingPairs',
}

export const ExchangeCoinName = 'BLZ'
export const DaysFromFirstDeal = 14

export const SortPairs = (pairs, criteria, first_N_Pair, direction = 'desc') => {
  if (!pairs) return null

  const sortMultiplier = direction === 'asc' ? -1 : 1

  return [...pairs]
    .sort((a, b) => {
      let result
      switch (criteria) {
        case SortPairCriteria.LastTradedPrice:
          result = (b.price ?? 0) - (a.price ?? 0)
          break
        case SortPairCriteria.Change24H:
          result = b.priceChangePercentage24h - a.priceChangePercentage24h
          break
        case SortPairCriteria.High24H:
          result = (b.high24h ?? 0) - (a.high24h ?? 0)
          break
        case SortPairCriteria.Low24H:
          result = (b.low24h ?? 0) - (a.low24h ?? 0)
          break
        case SortPairCriteria.Volume24H:
          result = b.volume24h - a.volume24h
          break
        case SortPairCriteria.MarketPageDisplayOrder:
          result = b.marketPageDisplayOrder - a.marketPageDisplayOrder
          break
        case SortPairCriteria.TokenTradingPairs:
          result = a.currencyPair.getDisplayName.localeCompare(b.currencyPair.getDisplayName)
          break
        default:
          return 0
      }
      return result * sortMultiplier
    })
    .slice(0, first_N_Pair)
}

export const CompressImage = (file, quality = 0.7, sizePixel = 70) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target.result
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = sizePixel
        canvas.height = sizePixel

        const size = Math.min(img.width, img.height)
        const startX = (img.width - size) / 2
        const startY = (img.height - size) / 2

        ctx.drawImage(img, startX, startY, size, size, 0, 0, sizePixel, sizePixel)

        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }),
            )
          },
          'image/jpeg',
          quality,
        )
      }
      img.onerror = reject
    }
    reader.onerror = reject
  })
}
