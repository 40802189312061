import React from 'react'
import Main from 'pages/common/components/Main/Main'
import Button from 'components/form/Button/Button'
import banner__01 from './img/banner__img01.png'
import img01 from './img/img01.png'
import img02 from './img/img02.png'
import img03 from './img/img03.png'
import background__img01 from './img/backgrond__img01.png'
import backgrond__img02 from './img/backgrond__img02.png'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

export default function PurchaseAtCostPage() {
  const { t } = useTranslation()
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__banner banner'>
                <div className='row row_m-revers'>
                  <div className='col col_7 col_mob-12'>
                    <div className='banner__layout'>
                      <div className='banner__main'>
                        <div className='wysiwyg'>
                          <h1>
                            {' '}
                            {t('ExchangeSecondary')} <strong>BLAZAREX</strong> {t('AdvantagesTitle')}
                          </h1>
                          <p>{t('ControlYourFinancialFreedom')}</p>
                        </div>
                      </div>
                      <div className='banner__footer'>
                        <div className='banner__action'>
                          <div className='row'>
                            <div className='col col_9 col_mob-12'>
                              <NavLink to={`/${i18n.language}/convert`} className='btn w-100 btn_large'>
                                <span className='btn__text'>{t('AllowTrading2')}</span>
                                <span className='btn__ico'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                    fill='none'
                                  >
                                    <path
                                      d='M3.61561 2.13965H11.4514L0.430587 13.1605C0.351357 13.2397 0.288197 13.3289 0.241117 13.4241C0.114157 13.6807 0.104087 13.9804 0.210917 14.2439C0.259737 14.3642 0.332957 14.477 0.430587 14.5747C0.821107 14.9652 1.45428 14.9652 1.8448 14.5747L12.8656 3.55386V11.3896C12.8656 11.9419 13.3133 12.3896 13.8656 12.3896C14.4179 12.3896 14.8656 11.9419 14.8656 11.3896V1.13965C14.8656 1.10513 14.8639 1.07102 14.8605 1.0374C14.8104 0.544498 14.4024 0.157418 13.9004 0.140238C13.8889 0.139848 13.8773 0.139648 13.8656 0.139648H3.61561C3.06333 0.139648 2.61562 0.587358 2.61562 1.13965C2.61561 1.69193 3.06333 2.13965 3.61561 2.13965Z'
                                      fill='white'
                                    />
                                  </svg>
                                </span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_5 col_mob-12'>
                    <div className='banner__media'>
                      <img src={banner__01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('HowIsWorking')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row justify-between'>
                    <div className='col col_5 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header text-center'>
                          <div className='wysiwyg'>
                            <h2>{t('YouExchangeOffice')}</h2>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('YouHaveUSD')}</p>
                          </div>
                        </div>
                        <div className='groups__footer mt-auto'>
                          <div className='wysiwyg text-center'>
                            <h4>{t('YouLost')}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_2 col_mob-12'>
                      <div className='groups__item groups__item_unset'>
                        <div className='groups__main'>
                          <div className='wysiwyg text-center'>
                            <img src={background__img01} alt='image description' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_5 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='wysiwyg text-center'>
                            <h2>{t('ExchangeOfficeSecondary')}</h2>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('JohnHaveUSD')}</p>
                          </div>
                        </div>
                        <div className='groups__footer mt-auto'>
                          <div className='wysiwyg text-center'>
                            <h4>{t('JohnLost')}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>
                  {t('HowWorking')} <strong>BLAZAREX</strong>?
                </h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row justify-center'>
                    <div className='col col_4 col_mob-6'>
                      <div className='groups__item groups__item_second-radius'>
                        <div className='groups__header text-center'>
                          <div className='wysiwyg'>
                            <h2>{t('User')} 1</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_2 col_mob-12'>
                      <div className='groups__item groups__item_unset'>
                        <div className='groups__main'>
                          <div className='wysiwyg text-center'>
                            <img src={backgrond__img02} alt='image description' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_4 col_mob-6'>
                      <div className='groups__item groups__item_second-radius'>
                        <div className='groups__header text-center'>
                          <div className='wysiwyg'>
                            <h2>{t('User')} 2</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row'>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item groups__item_second-size'>
                        <div className='groups__header'>
                          <div className='row'>
                            <div className='col col_10'>
                              <div className='wysiwyg'>
                                <h2>{t('Steps')}</h2>
                              </div>
                            </div>
                            <div className='col col_2'>
                              <div className='wysiwyg text-end font-italic'>
                                <h2>
                                  <strong>
                                    <i>(1)</i>
                                  </strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('StepsOrder')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item groups__item_second-size'>
                        <div className='groups__header'>
                          <div className='row'>
                            <div className='col col_10'>
                              <div className='wysiwyg'>
                                <h2>{t('Steps')}</h2>
                              </div>
                            </div>
                            <div className='col col_2'>
                              <div className='wysiwyg text-end font-italic'>
                                <h2>
                                  <strong>
                                    <i>(2)</i>
                                  </strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('StepsUser')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item groups__item_second-size'>
                        <div className='groups__header'>
                          <div className='row'>
                            <div className='col col_10'>
                              <div className='wysiwyg'>
                                <h2>{t('Steps')}</h2>
                              </div>
                            </div>
                            <div className='col col_2'>
                              <div className='wysiwyg text-end font-italic'>
                                <h2>
                                  <strong>
                                    <i>(3)</i>
                                  </strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('StepsСommissions')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item groups__item_second-size'>
                        <div className='groups__header'>
                          <div className='row'>
                            <div className='col col_10'>
                              <div className='wysiwyg'>
                                <h2>{t('Steps')}</h2>
                              </div>
                            </div>
                            <div className='col col_2'>
                              <div className='wysiwyg text-end font-italic'>
                                <h2>
                                  <strong>
                                    <i>(4)</i>
                                  </strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <p>{t('StepsIncome')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>
                  {t('ExchangeSecondary')} <strong>BLAZAREX</strong> - {t('This')}
                </h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row'>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img01} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Saving')}</h2>
                            <p>{t('SavingText')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img02} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Benefit')}</h2>
                            <p>{t('BenefitText')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media'>
                            <img src={img03} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Honesty')}</h2>
                            <p>{t('HonestyText')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Stability')}</h2>
                            <p>{t('StabilityText')}</p>
                          </div>
                        </div>
                        <div className='groups__footer mt-auto'>
                          <div className='groups__action'>
                            <NavLink to={`/${i18n.language}/convert`} className='btn w-100 btn_large'>
                              <span className='btn__text'>{t('AllowTrading2')}</span>
                              <span className='btn__ico'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='15'
                                  height='15'
                                  viewBox='0 0 15 15'
                                  fill='none'
                                >
                                  <path
                                    d='M3.61561 2.13965H11.4514L0.430587 13.1605C0.351357 13.2397 0.288197 13.3289 0.241117 13.4241C0.114157 13.6807 0.104087 13.9804 0.210917 14.2439C0.259737 14.3642 0.332957 14.477 0.430587 14.5747C0.821107 14.9652 1.45428 14.9652 1.8448 14.5747L12.8656 3.55386V11.3896C12.8656 11.9419 13.3133 12.3896 13.8656 12.3896C14.4179 12.3896 14.8656 11.9419 14.8656 11.3896V1.13965C14.8656 1.10513 14.8639 1.07102 14.8605 1.0374C14.8104 0.544498 14.4024 0.157418 13.9004 0.140238C13.8889 0.139848 13.8773 0.139648 13.8656 0.139648H3.61561C3.06333 0.139648 2.61562 0.587358 2.61562 1.13965C2.61561 1.69193 3.06333 2.13965 3.61561 2.13965Z'
                                    fill='white'
                                  />
                                </svg>
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
