import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
const Accordion = ({ accordionList, searchSymbol, onHighlightedTextClick }) => {
  return (
    <React.Fragment>
      <div className='accordion'>
        <div className='accordion__list'>
          {Array.isArray(accordionList) &&
            accordionList.length > 0 &&
            accordionList.map((accordionItem, index) => (
              <AccordionItem
                data={accordionItem}
                searchSymbol={searchSymbol}
                key={index}
                onHighlightedTextClick={onHighlightedTextClick}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}

const AccordionItem = ({ data, searchSymbol, onHighlightedTextClick }) => {
  const accordionIco = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path d='M12.5 0L12.5 24M0.5 12L24.5 12' stroke='#A717FF' />
    </svg>
  )
  const { t } = useTranslation()
  const [status, setStatus] = useState(false)

  const highlightLastSentence = (text, symbol) => {
    if (symbol && text.endsWith(symbol)) {
      const lastDotIndex = text.lastIndexOf('.')
      const beforeLastSentence = text.substring(0, lastDotIndex + 1)
      const lastSentence = text.substring(lastDotIndex + 1)
      return (
        <>
          {beforeLastSentence}
          <span style={{ color: 'gold', cursor: 'pointer' }} onClick={() => onHighlightedTextClick(data.title)}>
            {lastSentence}
          </span>
        </>
      )
    }
    return text
  }

  return (
    <React.Fragment>
      <div className={`accordion__item ${status === true ? 'accordion__item_active' : ''}`}>
        <div className='accordion__header' onClick={() => setStatus(!status)}>
          <div className='accordion__title title'>
            <span className='title__text'>{t(data.title)}</span>
          </div>
          <div className='accordion__action'>{accordionIco}</div>
        </div>
        <div className='accordion__main'>
          <div className='wysiwyg'>
            <p>{highlightLastSentence(t(data.text), searchSymbol)}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Accordion
