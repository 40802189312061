export const TabNames = {
  AllOrders: 'AllOrders',
  InProcess: 'InProcess',
  P2PMyAdsOnListing: 'P2PMyAdsOnListing',
  P2PMyAdsAll: 'P2PMyAdsAll',
}

export const FilterOptions = {
  P2PAllCurrency: 'P2PAllCurrency',
  BuySell: 'BuySell',
  P2PDealStatus_AllStatuses: 'AllStatuses',
  Buy: 'Buy',
  Sell: 'Sell',
}

export const TabIndex = {
  InProcess: 0,
  AllOrders: 1,
  P2PMyAdsOnListing: 2,
  P2PMyAdsAll: 3,
}

export const ORDER_STATUS = {
  Active: 10,
  Closed: 20,
  ClosedCryptoUnfrozen: 30,
}
export const GenerateOrderStatusTextToNumberMap = (t) => {
  return {
    [t('P2POrderStatus_Active')]: ORDER_STATUS.Active,
    [t('P2POrderStatus_Closed')]: ORDER_STATUS.Closed,
  }
}

export const DEAL_STATUS = {
  CreatedAwaitingPaymentSystem: 10,
  PaymentSystemSent: 20,
  PaymentSystemConfirmed: 40,
  CryptoReleased: 50,
  Canceled: 60,
  CanceledCryptoUnfrozen: 80,

  //these statuses have been added to the deal for ease of use
  AppealedForActiveDeal: 1000,
  AppealedAfterFinish: 1010,
}

export const DealStatusesActive = [DEAL_STATUS.CreatedAwaitingPaymentSystem, DEAL_STATUS.PaymentSystemSent]

export const DealStatusNumberToTextMap = {
  10: 'CreatedAwaitingPaymentSystem',
  20: 'PaymentSystemSent',
  40: 'PaymentSystemConfirmed',
  50: 'CryptoReleased',
  60: 'Canceled',
  80: 'CanceledCryptoUnfrozen',
  1000: 'AppealedForActiveDeal',
  1010: 'AppealedAfterFinish',
}

export const DealStatusTranslateTextToDealStatusText = (t) => {
  return {
    [t('P2PDealStatus_CreatedAwaitingPaymentSystem')]: 'CreatedAwaitingPaymentSystem',
    [t('P2PDealStatus_PaymentSystemSent')]: 'PaymentSystemSent',
    [t('P2PDealStatus_PaymentSystemConfirmed')]: 'PaymentSystemConfirmed',
    [t('P2PDealStatus_CryptoReleased')]: 'CryptoReleased',
    [t('P2PDealStatus_Canceled')]: 'Canceled',
    [t('P2PDealStatus_CanceledCryptoUnfrozen')]: 'CanceledCryptoUnfrozen',
    [t('P2PDealStatus_AppealedForActiveDeal')]: 'AppealedForActiveDeal',
    [t('P2PDealStatus_AppealedAfterFinish')]: 'AppealedAfterFinish',
  }
}

export const GetDealAppealStatus = (deal) => {
  if (deal.isAppealed) {
    return DEAL_STATUS.AppealedForActiveDeal
  } else if (deal.isAppealedAfterFinish) {
    return DEAL_STATUS.AppealedAfterFinish
  } else {
    return 0
  }
}

export const GetAppealType = (deal) => {
  if (deal.isAppealed) {
    return APPEAL_TYPES.appealedForActiveDeal
  } else if (deal.isAppealedAfterFinish) {
    return APPEAL_TYPES.appealedAfterFinish
  } else {
    return APPEAL_TYPES.noAppeal
  }
}

export const APPEAL_STATUS = {
  InProgress: 10,
  SetPaymentSystemConfirmed: 20,
  SetCanceled: 30,
}

export const APPEAL_TYPES = {
  noAppeal: 0,
  appealedForActiveDeal: 10,
  appealedAfterFinish: 20,
}

export const SYSTEM_MESSAGE_TYPE = {
  NO_SYSTEM: 'NO_SYSTEM',
  MEGAPHONE: 'MEGAPHONE',
  WARNING: 'WARNING',
  ADMIN: 'ADMIN',
}

export const SystemMessageTypeToText = {
  10: 'NO_SYSTEM',
  20: 'MEGAPHONE',
  30: 'WARNING',
  40: 'ADMIN',
}
