import { useHistory } from 'react-router-dom'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './InstructionsSection.module.scss'
import { useAuth } from 'auth/auth'
import React, { useState } from 'react'
import i18n from 'i18next'

export default function InstructionsSection() {
  const history = useHistory()
  const { t } = useTranslation()
  const redirectToRegister = () => {
    history.push(`/${i18n.language}/register`)
  }
  const redirectToConvert = () => {
    history.push(`/${i18n.language}/convert`)
  }
  const [tabCurrent, setTabCurrent] = useState(1)
  let auth = useAuth()
  return (
    <section className={cssClass(stylesGlobal.layout__section)}>
      <div className={cssClass(stylesGlobal.section__header)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(stylesGlobal.wysiwyg, stylesGlobal.textCenter)}>
            <h1>
              {t('InstructionsTitle1')} <strong>{t('InstructionsTitle2')}</strong> {t('InstructionsTitle3')}
            </h1>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__main)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(styles.instructions)}>
            <div className={cssClass(stylesGlobal.tabs)}>
              <div className={cssClass(stylesGlobal.tabs__header)}>
                <div className={cssClass(stylesGlobal.row, stylesGlobal.justifyCenter)}>
                  <div className={cssClass(stylesGlobal.col, stylesGlobal.col_5, stylesGlobal.col_mob_12)}>
                    <div className={cssClass(stylesGlobal.tabs__list)}>
                      <div
                        className={cssClass(
                          stylesGlobal.tabs__item,
                          tabCurrent === 1 ? stylesGlobal.tabs__item_active : stylesGlobal.tabs__item_disabled,
                        )}
                        onClick={() => setTabCurrent(1)}
                      >
                        <span className={cssClass(stylesGlobal.tabs__title)}>{t('InstructionsTab1')}</span>
                      </div>
                      <div
                        className={cssClass(
                          stylesGlobal.tabs__item,
                          tabCurrent === 2 ? stylesGlobal.tabs__item_active : stylesGlobal.tabs__item_disabled,
                        )}
                        onClick={() => setTabCurrent(2)}
                      >
                        <span className={cssClass(stylesGlobal.tabs__title)}>{t('InstructionsTab2')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cssClass(stylesGlobal.tabs__body)}>
                <div className={cssClass(stylesGlobal.tabs__list)}>
                  {tabCurrent === 1 && (
                    <div className={cssClass(stylesGlobal.tabs__item)}>
                      <div className={cssClass(stylesGlobal.boxes)}>
                        <div className={cssClass(stylesGlobal.boxes__list)}>
                          <div className={cssClass(stylesGlobal.row)}>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle1')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(1)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText1')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle2')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(2)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText1')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle3')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(3)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText3')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tabCurrent === 2 && (
                    <div className={cssClass(stylesGlobal.tabs__item)}>
                      <div className={cssClass(stylesGlobal.boxes)}>
                        <div className={cssClass(stylesGlobal.boxes__list)}>
                          <div className={cssClass(stylesGlobal.row)}>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle4')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(1)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText4')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle5')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(2)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText5')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.col, stylesGlobal.col_4, stylesGlobal.col_mob_12)}>
                              <div className={cssClass(stylesGlobal.boxes__item)}>
                                <div className={cssClass(stylesGlobal.boxes__header)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <h2>{t('InstructionsSubtitle6')}</h2>
                                  </div>
                                  <div className={cssClass(stylesGlobal.boxes__number)}>
                                    <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(3)</span>
                                  </div>
                                </div>
                                <div className={cssClass(stylesGlobal.boxes__main)}>
                                  <div className={cssClass(stylesGlobal.wysiwyg)}>
                                    <p>{t('InstructionsText6')}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__footer)}>
        <div className={cssClass(stylesGlobal.container)}>
          {auth.token ? (
            <span className={cssClass(stylesGlobal.btn)} onClick={redirectToConvert}>
              <span className={cssClass(stylesGlobal.btn__text)}>{t('Trade')}</span>
              <svg
                className={cssClass(stylesGlobal.btn__ico)}
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewboxes='0 0 25 25'
                fill='none'
              >
                <path
                  d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                  fill='white'
                />
              </svg>
            </span>
          ) : (
            <span className={cssClass(stylesGlobal.btn)} onClick={redirectToRegister}>
              <span className={cssClass(stylesGlobal.btn__text)}>{t('Registration')}</span>
              <svg
                className={cssClass(stylesGlobal.btn__ico)}
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewboxes='0 0 25 25'
                fill='none'
              >
                <path
                  d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                  fill='white'
                />
              </svg>
            </span>
          )}
        </div>
      </div>
    </section>
  )
}
