import api from 'api'

const DownloadFile = async (chatFileId) => {
  try {
    const chatFile = await api.fetchP2PChatFile(chatFileId)
    if (chatFile && chatFile.data) {
      const base64Data = chatFile.data.split(',')[1] ? chatFile.data.split(',')[1] : chatFile.data

      const byteString = atob(base64Data)
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      const blob = new Blob([ab], { type: 'application/octet-stream' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${chatFile.fileName}`)
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }
  } catch (error) {
    console.error('Error loading chat file', error)
  }
}

export default DownloadFile
