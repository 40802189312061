import Main from 'pages/common/components/Main/Main'
import Sidebar from 'baseComponents/Sidebar/Sidebar'
import Referrals from './Referrals/Referrals'
import {useTranslation} from 'react-i18next'

import styles from '../pages.module.scss'

export default function ReferralsPage() {
    const {t} = useTranslation()
    return (
        <Main>
            <div className={styles.flow}>
                <h1 className={styles.title}>{t('PersonalCabinet')}</h1>
                <div className={styles.wrapper}>
                    <Sidebar/>
                    <div className={styles.content}>
                        <Referrals/>
                    </div>
                </div>
            </div>
        </Main>
    )
}

