import { useEffect, useRef } from 'react'
import styles from './Modal.module.scss'
import { cssClass } from 'helpers/helper'

export default function Modal({ opened, setOpened, blockStyle = null, children }) {
  const blurRef = useRef(null)
  const blockRef = useRef(null)
  const sizesClasses = ['modal1200px', 'modal794px']

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        blurRef.current &&
        blurRef.current.contains(event.target) &&
        blockRef.current &&
        !blockRef.current.contains(event.target)
      ) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <>
      {opened && (
        <div ref={blurRef} className={styles.modalBlurBg}>
          <div className={cssClass(styles.modalBorderWrapper, blockStyle)}>
            <div ref={blockRef} className={`${styles.modal} p2p__modal`}>
              <button onClick={() => setOpened(false)} className={styles.exitButton}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M6 6L18 18" stroke="#202639" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
