import React, { useState, useEffect } from 'react'

const ProgressTimer = ({ initialMinutes, dateCreated }) => {
  const [timeLeft, setTimeLeft] = useState({ minutesLeft: null, secondsLeft: 0 })

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date()
      const dateCreatedDate = new Date(dateCreated)
      const maxTimeMs = initialMinutes * 60 * 1000
      const timePassedMs = now - dateCreatedDate
      const timeLeftMs = maxTimeMs - timePassedMs
      if (timeLeftMs < 0) {
        clearInterval(interval)
        return { minutesLeft: 0, secondsLeft: 0 }
      }

      const minutesLeft = Math.floor(timeLeftMs / (60 * 1000))
      const secondsLeft = Math.floor((timeLeftMs % (60 * 1000)) / 1000)

      return { minutesLeft, secondsLeft }
    }

    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(interval)
  }, [dateCreated, initialMinutes])

  if (!timeLeft?.minutesLeft) return null

  return (
    <div className='p2p__head'>
      <div className='head__footer'>
        <div className='head__clock clock'>
          <span className='clock__item'>{String(timeLeft.minutesLeft).padStart(2, '0')}</span>
          <span className='clock__delivery'>:</span>
          <span className='clock__item'>{String(timeLeft.secondsLeft).padStart(2, '0')}</span>
        </div>
      </div>
    </div>
  )
}

const ShouldButtonBeDisabled = (dateCreated, timeUntilUnlocking) => {
  const dateCreatedDate = new Date(dateCreated)
  const unlockDate = new Date(dateCreatedDate.getTime() + timeUntilUnlocking * 60000)
  return new Date() < unlockDate
}

export { ProgressTimer, ShouldButtonBeDisabled }
