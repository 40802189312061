import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import api from 'api'
import store from '../store'
import styles from './Chart.module.scss'

const signalR = require('@microsoft/signalr')

/* Highcharts config */
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
import HighchartsTheme from 'highcharts/themes/brand-light'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'
HighchartsTheme(Highcharts)

const BarRanges = {
  Year: 1,
  Month: 2,
  Day: 3,
  Hour4: 4,
  Hour: 5,
  Minutes30: 6,
  Minutes15: 7,
  Minutes5: 8,
  Minutes3: 9,
  Minute: 10,
}

const groupingUnits = [
  // TODO is it needed?
  ['day', [1]],
  ['week', [1]],
  ['month', [1, 2, 3, 4, 6]],
]

const mapBar = (b) => ({
  x: new Date(b.date).getTime(),
  open: b.open,
  high: b.max,
  low: b.min,
  close: b.close,
  volume: b.volume,
  //volumeBase: b.volumeBase,
})

const ChartElement = observer(({ isPhone = false }) => {
  const [chart, setChart] = useState(null)
  const [barRange, setBarRange] = useState(BarRanges.Minutes3)
  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState(null)
  const [chartTime, setChartTime] = useState(new Date())

  useEffect(() => {
    // load initial data
    let ignore = false // flag to prevent race conditions

    console.log('useEffect fetchBars', store.symbol, barRange)
    setChartData([])
    if (!store.symbol || !barRange) return
    //if (chart) chart.showLoading('Loading data from server...')

    api
      .fetchBars(barRange, store.symbol, new Date(2023, 3, 0), new Date())
      .then((data) => {
        if (ignore) return
        // Add a null value for the end date
        //data.push([Date.UTC(2023, 6, 0, 0), null, null, null, null])

        console.log('data', data)
        setChartData(data.map(mapBar))
        //if (chart) chart.hideLoading()
      })
      .catch((error) => console.error(error.message))

    return () => {
      ignore = true
    }
  }, [store.symbol, barRange])

  useEffect(() => {
    // set and update chart options on data change
    console.log('new chartData', chartData)
    setChartOptions({
      chart: {
        height: 491,
        backgroundColor: 'white',
      },
      xAxis: {
        events: { afterSetExtremes: afterSetExtremes },
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false,
        positioner: function (width, height, point) {
          var chart = this.chart,
            position

          if (point.isHeader) {
            position = {
              x: Math.max(
                // Left side limit
                chart.plotLeft,
                Math.min(
                  point.plotX + chart.plotLeft - width / 2,
                  // Right side limit
                  chart.chartWidth - width - chart.marginRight,
                ),
              ),
              y: point.plotY,
            }
          } else {
            position = {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop,
            }
          }

          return position
        },
      },
      series: [
        {
          // https://www.highcharts.com/demo/stock/areaspline
          // https://api.highcharts.com/highcharts/series.areaspline
          type: 'areaspline',
          threshold: null,
          id: 'stock-ohlc',
          name: `${store.symbol} Stock Price`,
          data: chartData.map((d) => [d.x, d.close]),
          dataGrouping: {
            units: groupingUnits,
          },
          color: '#858AE8',
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#ABAEEF'],
              [1, Highcharts.color('#ABAEEF').setOpacity(0).get('rgba')],
            ],
          },
        },
      ],
      rangeSelector: {
        //selected: 1,
        buttons: [
          {
            type: 'day',
            count: 1,
            text: '1d',
            title: 'View 1 day',
          },
          {
            type: 'week',
            count: 1,
            text: '1w',
            title: 'View 1 week',
          },
          {
            type: 'month',
            count: 1,
            text: '1m',
            title: 'View 1 month',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
            title: 'View 3 months',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
            title: 'View 6 months',
          },
          {
            type: 'ytd',
            text: 'YTD',
            title: 'View year to date',
          },
          {
            type: 'year',
            count: 1,
            text: '1y',
            title: 'View 1 year',
          },
          {
            type: 'all',
            text: 'All',
            title: 'View all',
          },
        ],
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 800,
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false,
              },
            },
          },
        ],
      },
    })
  }, [chartData])

  useEffect(() => {
    if (store.signalRConn == null) return
    // establish signalR connection for bar updates
    let subscribeHubName = null
    let newSubscribeHubName = `chartUpdate_${store.symbol}_${barRange}`
    store.signalRConn
      .invoke('ChartSubscribe', {
        GroupName: newSubscribeHubName,
        PrevGroupName: subscribeHubName,
      })
      .catch((err) => console.log({ message: err, title: 'ChartSubscribe Error', level: 'error' }))
    console.log('ChartSubscribed', newSubscribeHubName)
    store.signalRConn.on('chartUpdate', applyBarUpdate)
  }, [store.signalRConn])

  useEffect(() => {
    // update time in chart title
    let timerId = setInterval(() => {
      setChartTime(new Date())
    }, 10 * 1000)
    return () => clearInterval(timerId)
  }, [])

  function afterSetExtremes(e) {
    return // reloads too often
    console.log('afterSetExtremes', e, e.min, e.max)
    if (!e.min || !e.max) return
    const diff = e.max - e.min
    const hour = 1000 * 60 * 60
    const day = hour * 24
    let newBarRange = BarRanges.Minute
    if (diff > 120 * day) newBarRange = BarRanges.Day
    else if (diff > 10 * day) newBarRange = BarRanges.Hour4
    else if (diff > 4 * day) newBarRange = BarRanges.Hour
    else if (diff > day) newBarRange = BarRanges.Minutes15
    else if (diff > 3 * hour) newBarRange = BarRanges.Minutes5
    else newBarRange = BarRanges.Minute
    console.log('afterSetExtremes done', newBarRange)
    setBarRange(newBarRange)
  }

  function applyBarUpdate(lastBar) {
    console.log('applyBarUpdate', lastBar)
    const mappedBar = mapBar(lastBar)
    console.log('mappedBar', mappedBar)
    setChartData((prev) => {
      console.log('setChartData prev', prev)
      let result
      // data not loaded yet, skip
      if (prev.length == 0) result = prev
      // update last bar
      else if (mappedBar.x === prev[prev.length - 1].x) result = [...prev.slice(0, -1), mappedBar]
      // or add new bar
      else result = [...prev, mappedBar]
      console.log('setChartData result', result)
      return result
    })
  }

  return (
    <div className={styles.chart}>
      <div className={styles.firstRowWrapper}>
        <div className={styles.elementWrapper}>
          {store.symbolBase && (
            <CurrencyIcon size={30} className={styles.elementIcon} currencyCode={store.symbolBase} />
          )}
          <span>{store.symbolBase}</span>
          <img src='/images/currencies/arrows.svg' alt='Arrows' />
          <span>{store.symbolQuote}</span>
          {store.symbolQuote && (
            <CurrencyIcon size={30} className={styles.elementIcon} currencyCode={store.symbolQuote} />
          )}
        </div>
        <div className={styles.elementWrapper}>
          <span>{format(chartTime, 'dd.MM.yyyy')}</span>
          <span>{format(chartTime, 'HH:mm')}</span>
        </div>
      </div>
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          callback={(chart) => {
            setChart(chart)
          }}
          options={chartOptions}
        />
      )}
    </div>
  )
})

export default ChartElement
