import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import { DEAL_STATUS } from 'pages/p2p/Order/OrderAndDealData'

export default function ConfirmSendPaymentModal({ onConfirmConfirmSendPaymentModal, opened, onClose, deal }) {
  const { t } = useTranslation()
  const { price, fiatAmount, cryptoAmount, userNickname, order } = deal || {}
  const { paymentCurrencyCode, exchangeCurrencyCode } = order || {}
  const { bankName, cardNumber, paymentSystem, ownerName } = deal?.requisite || {}
  const { name } = paymentSystem || []
  const [isDisableConfirmButton, setIsDisableConfirmButton] = useState(true)
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('PaymentConfirmation')}</h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#ebfbf7' }}>
          <div className='wysiwyg'>
            <p>
              <strong>{t('ConfirmThatYouTransferredMoney')}</strong>
            </p>
          </div>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='row'>
            <div className='col col_4 col_mob-12'>
              <div className='tags tags_secondary'>
                <div className='tags__list'>
                  <div className='tags__item'>
                    <span className='tags__text' style={{ backgroundColor: '#ebfbf7' }}></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_8 col_mob-12'>
              <div className='data'>
                <div className='data__list data__list_row'>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('FirstName')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{ownerName}</span>
                    </div>
                  </div>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('AccountNumber')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>{cardNumber}</span>
                    </div>
                  </div>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('BankName')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {name} ({bankName})
                      </span>
                    </div>
                  </div>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Price')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {' '}
                        {price} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Volume')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text colorQuartyty500'>
                        {fiatAmount} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                  <div className='data__item justify-space-between'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Amount')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {cryptoAmount} {exchangeCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col_12'>
            <div className='wysiwyg'>
              <h2>
                <strong>{t('Recommendations')}</strong>
              </h2>
              <p>{t('IUnderstandThatIMustUseMyChosenPaymentPlatform')}</p>
            </div>
            <div className='form-item w-100 mb-0'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Checkbox
                    id={'IMadeAPayment'}
                    type={'checkbox'}
                    text={t('IMadeAPayment')}
                    checkboxCallback={(isChecked) => setIsDisableConfirmButton(!isChecked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div
                className='form-item'
                onClick={() => {
                  setIsDisableConfirmButton(true)
                  onClose(false)
                }}
              >
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100'>
                      <span className='btn__text'>{t('Cancel')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div
                className='form-item'
                onClick={() => {
                  onConfirmConfirmSendPaymentModal('updateStatus', DEAL_STATUS.PaymentSystemSent)
                  onClose(false)
                }}
              >
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100' disabled={isDisableConfirmButton}>
                      <span className='btn__text'>{t('ConfirmPayment')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
