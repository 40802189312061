/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
const path = '/animations/phone-animation.glb'

import React, { useEffect, useRef } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

export function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(path)
  const { actions, names } = useAnimations(animations, group)
  const meshRef = useRef()

  useEffect(() => {
    names.slice(0, 8).forEach((name, index) => {
      actions[name].reset().play()
    })

    return () => {
      names.slice(0, 8).forEach((name, index) => {
        actions[name].reset()
      })
    }
  }, [])

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y = THREE.MathUtils.lerp(meshRef.current.rotation.y, props.x * 0.0005 - Math.PI / 8, 0.1)
      meshRef.current.rotation.x = THREE.MathUtils.lerp(meshRef.current.rotation.x, props.y * 0.0003, 0.1)
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={meshRef} name='Scene'>
        <group name='Node_0' position={[1, 10.47, 0]} scale={0.01}>
          <group name='Scene_1'>
            <group
              name='Bitcoin__Rotation_Left'
              position={[298.739, -281.136, -24]}
              rotation={[-0.593, -Math.PI / 9, 0.062]}
            >
              <mesh
                name='Base001'
                geometry={nodes.Base001.geometry}
                material={materials.base}
                position={[0, 0, -0.134]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group name='Node_170'>
                  <mesh
                    name='Mesh_31'
                    geometry={nodes.Mesh_31.geometry}
                    material={materials.base}
                    position={[3.431, 0, 69.831]}
                    rotation={[0, -1.522, 0]}
                  />
                </group>
              </mesh>
              <mesh
                name='Logo'
                geometry={nodes.Logo.geometry}
                material={materials.logo}
                position={[-2.174, -0.335, 8.8]}
                scale={[1.347, 1.347, 1.353]}
              />
              <group name='Ribs_001' rotation={[0.461, 0.516, 0.205]} />
              <mesh name='Ring001' geometry={nodes.Ring001.geometry} material={materials.base} position={[0, 0, -12]} />
            </group>
            <group
              name='Bitcoin__Rotation_Left_2'
              position={[335.872, -772.597, -13.534]}
              rotation={[-0.084, -0.13, -0.601]}
            >
              <mesh
                name='Base'
                geometry={nodes.Base.geometry}
                material={materials.base}
                position={[0, 0, -0.134]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group name='Node_100'>
                  <mesh
                    name='Mesh_27'
                    geometry={nodes.Mesh_27.geometry}
                    material={materials.base}
                    position={[3.431, 0, 69.831]}
                    rotation={[0, -1.522, 0]}
                  />
                </group>
              </mesh>
              <mesh
                name='Curve001'
                geometry={nodes.Curve001.geometry}
                material={materials.logo}
                position={[0, 1.179, 21.688]}
                rotation={[1.571, 0, 0]}
                scale={[500, 1843.984, 500]}
              />
              <group name='Ribs_' rotation={[0.461, 0.516, 0.205]} />
              <mesh name='Ring' geometry={nodes.Ring.geometry} material={materials.base} position={[0, 0, -12]} />
            </group>
            <group
              name='Bitcoin__Rotation_Right'
              position={[-156.535, -816.99, 384.217]}
              rotation={[-0.593, Math.PI / 9, 0.441]}
            >
              <mesh
                name='Base002'
                geometry={nodes.Base002.geometry}
                material={materials.base}
                position={[0, 0, -0.134]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group name='Node_240'>
                  <mesh
                    name='Mesh_35'
                    geometry={nodes.Mesh_35.geometry}
                    material={materials.base}
                    position={[3.431, 0, 69.832]}
                    rotation={[0, -1.522, 0]}
                  />
                </group>
              </mesh>
              <mesh
                name='Object_4'
                geometry={nodes.Object_4.geometry}
                material={materials.logo}
                position={[-2.323, -3.018, 9.309]}
                rotation={[0.002, 0.057, -0.505]}
                scale={[24.53, 24.53, 13.67]}
              />
              <group name='Ribs_002' position={[37.007, 36.964, -31.373]} rotation={[0.461, -0.516, -0.205]} />
            </group>
            <group name='Card' position={[275.455, -530.177, 179.657]} rotation={[-0.043, 0.506, 0.173]} scale={0.913}>
              <group name='Node_23' position={[-82.11, -13.471, 3.221]} scale={1.418}>
                <mesh
                  name='Mesh_12001'
                  geometry={nodes.Mesh_12001.geometry}
                  material={materials.base}
                  position={[30, 0, 0]}
                />
                <mesh
                  name='Mesh_12003'
                  geometry={nodes.Mesh_12003.geometry}
                  material={materials.logo}
                  position={[30, 0, 0]}
                />
              </group>
              <mesh
                name='Rectangle_2002'
                geometry={nodes.Rectangle_2002.geometry}
                material={materials.base}
                position={[0, 20.921, -6.073]}
                scale={1.418}
              />
            </group>
            <group name='Default_Ambient_Light' position={[0, 1, 0]} />
            <group name='Mobile' position={[19.359, -569.485, 77.138]} rotation={[-0.384, 0.297, 0]} scale={3.43}>
              <mesh
                name='Brackets'
                geometry={nodes.Brackets.geometry}
                material={materials.baseGray}
                position={[-0.259, -0.083, -1.914]}
                scale={0.529}
              />
              <mesh
                name='button'
                geometry={nodes.button.geometry}
                material={materials.baseGray}
                position={[-0.56, 58.221, -3.013]}
                scale={0.529}
              />
              <group name='Notch' position={[-0.264, 123.517, 4.882]} scale={[0.36, 0.45, 0.529]}>
                <mesh
                  name='Rectangle'
                  geometry={nodes.Rectangle.geometry}
                  material={materials.baseGray}
                  position={[24.316, -0.797, 1.113]}
                />
                <mesh
                  name='Rectangle_2003'
                  geometry={nodes.Rectangle_2003.geometry}
                  material={materials.base}
                  position={[-0.001, -0.749, -0.015]}
                />
                <mesh
                  name='Rectangle001'
                  geometry={nodes.Rectangle001.geometry}
                  material={materials.baseGray}
                  position={[-0.533, -0.797, 1.113]}
                />
              </group>
              <mesh
                name='Rectangle002'
                geometry={nodes.Rectangle002.geometry}
                material={materials.base}
                position={[-0.264, 0, -6.036]}
                scale={0.529}
              />
              <mesh
                name='Screen'
                geometry={nodes.Screen.geometry}
                material={materials['Material.001']}
                position={[-0.264, 0, 4.582]}
                scale={0.529}
              />
              <mesh
                name='screen'
                geometry={nodes.screen.geometry}
                material={materials.base}
                position={[-0.264, 0, -8.282]}
                scale={0.529}
              />
            </group>
            <group name='Money' position={[-337.647, -652.305, 293.863]} rotation={[0, 0.419, 0.351]}>
              <mesh
                name='Rectangle_3'
                geometry={nodes.Rectangle_3.geometry}
                material={materials.logo}
                position={[-0.596, -19.109, 11.761]}
                scale={[0.907, 0.714, 0.907]}
              />
              <mesh
                name='Rectangle_6'
                geometry={nodes.Rectangle_6.geometry}
                material={materials.base}
                position={[0, 0, -14.041]}
                rotation={[0, 0, Math.PI / 9]}
                scale={0.907}
              />
            </group>
            <group
              name='Tether__Rotation_Left'
              position={[-221.997, -225.131, 435.136]}
              rotation={[-0.593, Math.PI / 9, 0.982]}
            >
              <mesh
                name='Base004'
                geometry={nodes.Base004.geometry}
                material={materials.base}
                position={[0, 0, -0.134]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group name='Node_381'>
                  <mesh
                    name='Mesh_43'
                    geometry={nodes.Mesh_43.geometry}
                    material={materials.base}
                    position={[3.431, 0, 69.831]}
                    rotation={[0, -1.522, 0]}
                  />
                </group>
              </mesh>
              <mesh
                name='Logo001'
                geometry={nodes.Logo001.geometry}
                material={materials.logo}
                position={[0, -6.147, 5.812]}
                scale={[1, 1, 2.06]}
              />
              <group name='Ribs_003' position={[6.107, 20.827, -57.001]} />
              <mesh name='Ring004' geometry={nodes.Ring004.geometry} material={materials.base} position={[0, 0, -12]} />
            </group>
            <group
              name='USDC_Icon__Rotation_Left'
              position={[-301.086, -412.982, 377.272]}
              rotation={[-0.593, Math.PI / 9, 0.436]}
            >
              <mesh
                name='Base003'
                geometry={nodes.Base003.geometry}
                material={materials.base}
                position={[0, 0, -0.134]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <group name='Node_311' />
              </mesh>
              <mesh
                name='Object_2'
                geometry={nodes.Object_2.geometry}
                material={materials.logo}
                position={[-1.736, 1.162, 7.025]}
                rotation={[0.007, 0.01, 0.152]}
                scale={[18.08, 18.079, 43.063]}
              />
              <group name='Ribs' position={[6.102, 0.839, 0.317]} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(path)
