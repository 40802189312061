import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'

import BalanceBlock from 'baseComponents/BalanceBlock/BalanceBlock'
import FormElement from '../FormElement/FormElement'

import formListIcon from './formListIcon.png'
import pages from '../../../pages.module.scss'
import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './Account.module.scss'
import { cssClass, getKeyByValue } from 'helpers/helper'

import authApi from 'auth/api'
import api from 'api'
import { useTranslation } from 'react-i18next'
import VerificationStatus from 'data/VerificationStatus'

const Account = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [user, setUser] = useState(null)
  const [verification, setVerification] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [disabledUpdateNames, setDisabledUpdateNames] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationStatus, setVerificationStatus] = useState('')

  useEffect(() => {
    api.fetchVerification().then((data) => {
      setVerificationStatus(getKeyByValue(VerificationStatus, data?.status))
      setVerification(data)
      setFirstName(data.name)
      setLastName(data.secondName)
      setMiddleName(data.middleName)
    })
  }, [])

  const updateNames = (e) => {
    setDisabledUpdateNames(true)
    verification.name = firstName
    verification.secondName = lastName
    verification.middleName = middleName
    api.saveVerification(verification).then((data) => {
      setDisabledUpdateNames(false)
    })
  }

  const changePassword = (e) => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match')
      return
    }
    authApi.changePassword({ oldPassword, newPassword, confirmPassword }).then((data) => {
      setOldPassword('')
      setNewPassword('')
      setConfirmPassword('')
    })
  }

  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    authApi.fetchUser().then((data) => {
      if (!ignore) setUser(data)
    })
    return () => {
      ignore = true
    }
  }, [])

  return (
    <section className={pages.blockWrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{t('Account')}</h1>
        <BalanceBlock />
      </div>
      <div className={styles.plate}>
        <div className={styles.groupWrapper}>
          <img className={styles.avatar} src='/images/avatar.png' alt='Avatar' />
          <div className={styles.idWrapper}>
            <span>ID:</span>
            <div className={styles.idValueWrapper}>
              <span className={styles.value}>{user?.publicId ?? ''}</span>
              <button
                className={cssClass(styles.copyButton)}
                onClick={() => navigator.clipboard.writeText(user?.publicId ?? '')}
              >
                <img src='/images/icons/copy.svg' alt='Copy button' />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.doubleRow}>
          <span className={styles.firstRow}>{t('Identificator')}:</span>
          <span className={styles.secondRow}>{user?.privateId ?? ''}</span>
        </div>
        <div className={styles.doubleRow}>
          <span className={styles.firstRow}>{t('Verification')}:</span>
          <span className={styles.secondRow}>
            {verificationStatus && t(`VerificationStatus_${verificationStatus}`)}
          </span>
        </div>
        <div className={styles.plateButtonsWrapper}>
          <button
            className={cssClass(baseComponents.button, styles.plateButton, styles.firstPlateButton)}
            onClick={() => history.push(`/${i18n.language}/finance`)}
          >
            {t('Deposit')}
          </button>
          <button
            className={cssClass(baseComponents.button, styles.plateButton)}
            onClick={() => history.push(`/${i18n.language}/finance`)}
          >
            {t('Withdraw')}
          </button>
        </div>
      </div>
      <form className={styles.form}>
        <div className={styles.labelWrapper}>
          <FormElement
            title='FirstName'
            placeholder={t('NamePlaceholder')}
            type='text'
            value={firstName}
            setValue={setFirstName}
          />
          <FormElement
            title='SecondName'
            placeholder={t('MiddleNamePlaceholder')}
            type='text'
            value={middleName}
            setValue={setMiddleName}
          />
          <FormElement
            title='Surname'
            placeholder={t('SurnamePlaceholder')}
            type='text'
            value={lastName}
            setValue={setLastName}
          />
        </div>
        <div className={styles.labelWrapper}>
          <FormElement
            title='E-mail'
            placeholder='ivan.ivanov@mail.ru'
            type='email'
            value={user?.email ?? ''}
            readOnly={true}
          />
          <FormElement
            title='Nickname'
            placeholder='user12345'
            value={user?.nickname ?? ''}
            type='text'
            readOnly={true}
          />
        </div>
        <button
          onClick={updateNames}
          type='submit'
          disabled={disabledUpdateNames}
          className={cssClass(baseComponents.button, styles.saveButton)}
        >
          {t('Save')}
        </button>
      </form>
      <div className={styles.line}></div>
      <h1 className={styles.title}>{t('Password')}</h1>
      <PasswordPart
        oldPassword={oldPassword}
        setOldPassword={setOldPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        changePassword={changePassword}
      />
    </section>
  )
}

export const PasswordPart = ({
  oldPassword,
  setOldPassword,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  changePassword,
  isReset,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {!isReset && <p className={styles.paragraph}>{t('WeWillSendConfirmation')}</p>}
      <form className={styles.form}>
        <div className={cssClass(styles.labelWrapper)}>
          <FormElement
            title='CurrentPassword'
            placeholder=''
            type='password'
            value={oldPassword}
            setValue={setOldPassword}
            isPassword
          />
          <FormElement
            title='NewPassword'
            placeholder=''
            type='password'
            value={newPassword}
            setValue={setNewPassword}
            isPassword
          />
          <FormElement
            title='ConfirmPassword'
            placeholder=''
            type='password'
            value={confirmPassword}
            setValue={setConfirmPassword}
            isPassword
          />
        </div>
        <ul className={styles.text}>
          <li className={cssClass(styles.paragraph, styles.paragraphFirst)}>{t('PasswordRequirements')}:</li>
          <li className={cssClass(styles.paragraph, styles.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeast8characters')}
          </li>
          <li className={cssClass(styles.paragraph, styles.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeastOneUppercase')}
          </li>
          <li className={cssClass(styles.paragraph, styles.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('AtLeastOneDigit')}
          </li>
          <li className={cssClass(styles.paragraph, styles.paragraphOther)}>
            <img src={formListIcon} alt='Cross' />
            {t('ValidCharacters')}
          </li>
        </ul>
        <button onClick={changePassword} type='button' className={cssClass(baseComponents.button, styles.saveButton)}>
          {t(isReset ? 'Reset' : 'Save')}
        </button>
      </form>
    </>
  )
}

export default Account
