import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import '../../../secondaryLayout/scss/main.sass'
import TokenInput from '../../TokenData/TokenInput.jsx'
import TokenIcons from '../../TokenData/TokenIcons.js'

export default function Social({ onInputChange, tokenData }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div id='tokens__socials' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>Social media:</span>
        </div>
        <div className='group__main group__main_secondary'>
          <div className='form__item'>
            <div className='form__main'>
              <div className='form__field form__field_secondary field'>
                <div className='field__header'>
                  <TokenIcons name={'instagramBlackIco'} />
                  <span className='field__title'>Instagram</span>
                </div>
                <div className='field__main'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_secondary']}
                    value={tokenData.socialLink1 || ''}
                    validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                    inputCallback={(value) => {
                      onInputChange('socialLink1', value)
                    }}
                    placeholder={t('TokenAddLink')}
                    onFocus={(e) => {
                      onInputChange('socialLink1', e.target.value.trim())
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <div className='form__field form__field_secondary field'>
                <div className='field__header'>
                  <TokenIcons name={'facebookBlackIco'} />
                  <span className='field__title'>Facebook</span>
                </div>
                <div className='field__main'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_secondary']}
                    value={tokenData.socialLink2 || ''}
                    validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                    inputCallback={(value) => {
                      onInputChange('socialLink2', value)
                    }}
                    placeholder={t('TokenAddLink')}
                    onFocus={(e) => {
                      onInputChange('socialLink2', e.target.value.trim())
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <div className='form__field form__field_secondary field'>
                <div className='field__header'>
                  <TokenIcons name={'telegramBlackIco'} />
                  <span className='field__title'>Telegram</span>
                </div>
                <div className='field__main'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_secondary']}
                    value={tokenData.socialLink3 || ''}
                    validationPattern='^[a-zA-Z0-9.:?&_/-]{0,50}$'
                    inputCallback={(value) => {
                      onInputChange('socialLink3', value)
                    }}
                    placeholder={t('TokenAddLink')}
                    onFocus={(e) => {
                      onInputChange('socialLink3', e.target.value.trim())
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
