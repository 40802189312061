import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './ServicesSection.module.scss'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import React from 'react'
export default function ServicesSection() {
  const { t } = useTranslation()

  return (
    <section className={cssClass(stylesGlobal.layout__section)}>
      <div className={cssClass(stylesGlobal.section__header)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(stylesGlobal.wysiwyg, stylesGlobal.textCenter)}>
            <h1>
              {t('ServicesTitle')} <strong>{t('ServicesTitle2')}</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__main)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(styles.services, styles.services)}>
            <div className={cssClass(stylesGlobal.boxes)}>
              <div className={cssClass(stylesGlobal.boxes__list)}>
                <div className={cssClass(stylesGlobal.row, styles.boxes__row)}>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <NavLink className={cssClass(stylesGlobal.boxes__item)} to={`/${i18n.language}/purchaseAtCost`}>
                      <div className={cssClass(stylesGlobal.boxes__header)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ServicesSubtitle1')}</h2>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <p>{t('ServicesText1')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__header)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ServicesSubtitle2')}</h2>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <p>{t('ServicesText2')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <NavLink className={cssClass(stylesGlobal.boxes__item)} to={`/${i18n.language}/financial-cooperation`}>
                        <div className={cssClass(stylesGlobal.boxes__header)}>
                          <div className={cssClass(stylesGlobal.wysiwyg)}>
                            <h2>{t('ServicesSubtitle3')}</h2>
                          </div>
                        </div>
                        <div className={cssClass(stylesGlobal.boxes__main)}>
                          <div className={cssClass(stylesGlobal.wysiwyg)}>
                            <p>{t('ServicesText3')}</p>
                          </div>
                        </div>
                    </NavLink>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <NavLink className={cssClass(stylesGlobal.boxes__item)} to={`/${i18n.language}/news`}>
                      <div className={cssClass(stylesGlobal.boxes__header)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ServicesSubtitle4')}</h2>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <p>{t('ServicesText4')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <NavLink className={cssClass(stylesGlobal.boxes__item)} to={`/${i18n.language}/tokenization`}>
                      <div className={cssClass(stylesGlobal.boxes__header)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ServicesSubtitle5')}</h2>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <p>{t('ServicesText5')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_4,
                      stylesGlobal.col_mob_12,
                      styles.boxes__col,
                    )}
                  >
                    <NavLink className={cssClass(stylesGlobal.boxes__item)} to={`/${i18n.language}/software`}>
                      <div className={cssClass(stylesGlobal.boxes__header)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ServicesSubtitle6')}</h2>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <p>{t('ServicesText6')}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
