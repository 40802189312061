import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'components/layout/Modal/Modal'
export default function ApplicationCreatedModal({ onConfirmCancel, opened, onClose }) {
  const { t } = useTranslation()
  let ico = (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'>
      <path
        d='M0 24.5C0 37.7265 10.7735 48.5 24 48.5C37.2265 48.5 48 37.7265 48 24.5C48 11.2735 37.2265 0.5 24 0.5C10.7735 0.5 0 11.2735 0 24.5ZM36.5332 21.0332L21.3333 36.2868L11.4668 26.3667L15.2 22.6335L21.3333 28.7132L32.8002 17.3L36.5332 21.0332Z'
        fill='#202639'
      />
    </svg>
  )
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg text-center'>
          {ico}
          <h2>
            <strong>{t('TheApplicationHasBeenCreated')}</strong>
          </h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='data'>
            <div className='data__list data__list_second data__list_column m-0'>
              <div className='data__item'>
                <div className='data__header'>
                  <span className='data__title'>{t('Identificator')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text colorQuartyty500'>1234</span>
                </div>
              </div>
              <div className='data__item'>
                <div className='data__header'>
                  <span className='data__title'>{t('ByTheAddress')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text'>Aksaray, İnkılap Cd. No: 4/a, 34096 Fatih/İstanbul, Турция</span>
                  <span className='data__ico'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
                      <path
                        d='M1.88235 16C1.36471 16 0.921413 15.8432 0.552472 15.5296C0.183531 15.216 -0.000625853 14.8395 1.59792e-06 14.4V3.2H1.88235V14.4H12.2353V16H1.88235ZM5.64706 12.8C5.12941 12.8 4.68612 12.6432 4.31718 12.3296C3.94824 12.016 3.76408 11.6395 3.76471 11.2V1.6C3.76471 1.16 3.94918 0.783201 4.31812 0.469601C4.68706 0.156001 5.13004 -0.000531975 5.64706 1.35824e-06H14.1176C14.6353 1.35824e-06 15.0786 0.156801 15.4475 0.470401C15.8165 0.784001 16.0006 1.16053 16 1.6V11.2C16 11.64 15.8155 12.0168 15.4466 12.3304C15.0776 12.644 14.6347 12.8005 14.1176 12.8H5.64706Z'
                        fill='#202639'
                        fill-opacity='0.5'
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className='data__item m-0'>
                <div className='data__header'>
                  <span className='data__title'>{t('OpeningHours')}</span>
                </div>
                <div className='data__main'>
                  <span className='data__text'>пн-пт 10:00–20:00</span>
                </div>
                <div className='data__main'>
                  <span className='data__text colorQuartyty500'>+90 531 77 458 23</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row justify-center'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn w-100' onClick={onClose}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
