import checkImg from './images/check.svg'
import React, { useState, useEffect } from 'react'
import { StatusToStageIndex } from './ProgressData'
import { DEAL_STATUS, APPEAL_TYPES, GetAppealType } from '../Order/OrderAndDealData'

export function ProgressTracker({ t, deal }) {
  const [currentStageIndex, setCurrentStageIndex] = useState(0)

  const sellerProgressList = ['PaymentIsExpectedFromTheBuyer', 'TransferTheCryptocurrency', 'TheDealIsCompleted']
  const buyerProgressList = [
    'P2PTransferOfPaymentToTheSeller',
    'WaitingForTheSellerToTransferCryptocurrency',
    'TheDealIsCompleted',
  ]
  const isHidden = deal.status === DEAL_STATUS.Canceled
  const [progressList, setProgressList] = useState(deal.isDirectionDealBuying ? buyerProgressList : sellerProgressList)

  useEffect(() => {
    const appealType = GetAppealType(deal)
    const stage =
      appealType !== APPEAL_TYPES.noAppeal ? StatusToStageIndex[appealType] : StatusToStageIndex[deal.status]

    setCurrentStageIndex(stage)
  }, [deal])

  if (currentStageIndex > 2) {
    return null
  }

  return (
    <React.Fragment>
      {!isHidden && (
        <div className='steps'>
          <div className='steps__header'>
            <div className='steps__list'>
              {progressList.map((element, index) => {
                return (
                  <div className={`steps__item ${currentStageIndex >= index ? 'steps__item_active' : ''}`} key={index}>
                    <div className='steps__index index'>
                      {currentStageIndex <= index && <span className='index__text'>{index + 1}</span>}
                      {currentStageIndex > index && <img src={checkImg} alt='Check' />}
                    </div>
                    <div className='steps__title title'>
                      <span className='title__text'>{t(element)}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
export default ProgressTracker
