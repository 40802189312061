import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
const signalR = require('@microsoft/signalr')
import { useAuth } from 'auth/auth'
import api from 'api'
import store from './store'
import { cssClass } from 'helpers/helper'

import Main from 'pages/common/components/Main/Main'
import MarketLimit from './MarketLimit/MarketLimit'
import Chart from './Chart/Chart'
import Deals from './Deals/Deals'
import { MarketLimitPhone, MarketLimitPhoneTable } from './MarketLimitPhone/MarketLimitPhone'
import CurrencyPairs from './CurrencyPairs/CurrencyPairs'

import styles from './TradingPage.module.scss'
import pages from '../pages.module.scss'
import Modal from 'baseComponents/Modal/Modal'
import { useTranslation } from 'react-i18next'
import SearchBar from './MarketLimit/SearchBar/SearchBar'

export default function TradingPage({ forLandingPage = false }) {
  const auth = useAuth()

  const [signalRTimerId, setSignalRTimerId] = useState(null)
  const DefaultPairCode = 'ETH_USDT'
  const location = useLocation()

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/orders`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()
    store.setSignalRConn(hubConnection)
    hubConnection.start().then(() => {
      hubConnection.on('MainOrders', (currencyPairCode) => {
        if (location.pathname.indexOf('/exchange') === -1) {
          disconnectSignalR() // signalR has stuck, disconnect
          return
        }
        if (store.symbol !== currencyPairCode) return
        console.log('SignalR MainOrders', currencyPairCode)
        store.fetchOrders()
        store.fetchDeals()
      })
      hubConnection.on('UserOrders', (currencyPairCode) => {
        console.log('SignalR UserOrders', currencyPairCode)
        if (auth.isSignedIn) {
          store.fetchBalance()
          store.fetchUserOrders()
          store.fetchUserDeals()
        }
      })

      const keepAlivePeriod = 60 * 1000 // 1 minute
      const timerId = setInterval(function () {
        hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
      }, keepAlivePeriod)
      setSignalRTimerId(timerId)

      console.log('SignalR configured', hubConnection)
    })
  }

  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
    if (store.signalRConn) {
      store.signalRConn.stop()
      store.setSignalRConn(null)
    }
  }
  const { i18n } = useTranslation()

  useEffect(() => {
    let ignore = false

    store.setIsSignedIn(auth.isSignedIn)

    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    let symbol = params.symbol || localStorage.getItem('symbol') || DefaultPairCode

    const newSearch = qs.stringify({ ...params, symbol }, { addQueryPrefix: true })
    const url =
      i18n.language + '/' + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    window.history.pushState(null, null, window.location.origin + '/' + url + `${newSearch}`)
    store.setSymbol(symbol)
    store.fetchInstruments()
    store.fetchCommissions()
    store.fetchOrders()
    store.fetchDeals()
    console.log('auth.isSignedIn', auth.isSignedIn)
    if (auth.isSignedIn) {
      store.fetchBalance()
      store.fetchUserOrders()
      store.fetchUserDeals()
    }

    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
      ignore = true
    }
  }, [])

  if (forLandingPage) {
    return (
      <div className={pages.flow}>
        <SearchBar />
        <CurrencyPairs forLandingPage={forLandingPage} />
        <div className={styles.margin15}></div>
        <MarketLimitPhoneTable />
        <div className={styles.phoneHidden}>
          <Chart />
        </div>
      </div>
    )
  } else {
    return (
      <Main>
        <div className={pages.flow}>
          <SearchBar />
          <CurrencyPairs forLandingPage={forLandingPage} />
          <MarketLimitPhone />
          <div className={styles.phoneHidden}>
            <MarketLimit />
          </div>
          <Chart />
          <Deals />
        </div>
      </Main>
    )
  }
}
