import React, { useEffect, useState } from 'react'
import style from '../style/TokenPage.module.scss'
import { cssClass } from 'helpers/helper'
import TokenIcons from '../TokenData/TokenIcons'
import { FormatedDate, LikesCount } from '../TokenData/TokenData'
import description from '../img/description.jpg'

const CommentComponent = ({ t, onLikeOpdateForComment, comment, isHideBtnReply = true }) => {
  // const [showReplies, setShowReplies] = React.useState(false)
  // const toggleReplies = () => setShowReplies(!showReplies)

  useEffect(() => {
    // console.log('comment', comment)
  }, [comment])

  return (
    <div className={cssClass(style.comments__main)}>
      <div className={cssClass(style.comments__list)}>
        <div className={cssClass(style.comments__item, style.comment)}>
          <div className={cssClass(style.comment__media)}>
            <div className={cssClass(style.comment__avatar)}>
              <span className={cssClass(style.information__avatar)}>{comment?.userNickname?.charAt(0) || 'U'}</span>
            </div>
          </div>
          <div className={cssClass(style.comment__header)}>
            <span className={cssClass(style.comment__title)}>{comment.userNickname || 'userNickname'}</span>
            <span className={cssClass(style.comment__subtitle)}>{FormatedDate(comment.dateCreated)}</span>
          </div>
          <div className={cssClass(style.comment__main)}>
            <div className={cssClass(style.wysiwyg)}>
              <p>{comment.text} </p>
            </div>
          </div>
          <div className={cssClass(style.comment__footer)} onClick={() => onLikeOpdateForComment(comment.id)}>
            <div className={cssClass(style.comment__favorite, style.favorite)}>
              <TokenIcons name={'favorite'} />
              <span className={cssClass(style.favorite__text)}>{LikesCount(comment?.commentLikes)}</span>
            </div>
            {/* {!isHideBtnReply && (
                <div className={cssClass(style.comment__answer, style.answer)}>
                  <span className={cssClass(style.answer__text)}>{t('TokenReplyToComment')}</span>
                </div>
              )} */}
          </div>
          {/* {comment.replies && comment.replies.length !== 0 && (
              <div className={cssClass(style.comment__dropdown, style.dropdown)}>
                <div className={cssClass(style.dropdown__header)} onClick={toggleReplies}>
                  <TokenIcons name={showReplies ? 'chevronTop' : 'chevronBottom'} />
                  <span className={cssClass(style.dropdown__text)}>
                    {comment.replies.length} {t('TokenCommentAnswerPlural', { count: comment.replies.length })}{' '}
                  </span>
                </div>

                {showReplies && (
                  <div className={cssClass(style.dropdown__main)}>
                    <div className={cssClass(style.comments__list)}>
                      {comment.replies.map((reply) => (
                        <CommentComponent t={t} key={reply.id} comment={reply} isHideBtnReply={true} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )} */}
        </div>
      </div>
    </div>
  )
}

export default CommentComponent
