import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'components/layout/Modal/Modal'
export default function PassVerificationModal({ onConfirmPassVerification, opened, onClose, isDealUserBuy }) {
  const { t } = useTranslation()
  const message = isDealUserBuy ? 'PassAdvancedVerification' : 'PassBasicVerification'
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg text-center'>
          <h2>
            <strong>{t(message)}</strong>
          </h2>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100' onClick={onClose}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn w-100'
                      onClick={() => {
                        onConfirmPassVerification()
                        onClose()
                      }}
                    >
                      <span className='btn__text'>{t('PassVerification')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
