import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../../../api'
import styles from './PublicID.module.scss'

const PublicID = ({ onPublicIDChange }) => {
  const [publicID, setPublicID] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    api.fetchPublicID().then((data) => {
      setPublicID(data)
      onPublicIDChange(data)
    })
  }, [])

  return (
    <div className={styles.idWrapper}>
      <h3 className={styles.idTitle}>{t('Identificator')}:</h3>
      <p className={styles.idValue}>{publicID}</p>
    </div>
  )
}

export default PublicID
