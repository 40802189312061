import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import VerificationProcess from './VerificationProcess'
import api from 'api'
import BasicVerification from './BasicVerification'
import AdvancedVerification from './AdvancedVerification'
import { USER_VERIFICATION_STAGE } from './VerificationData'

export default function VerificationPage() {
  const { t } = useTranslation()
  const [verificationData, setVerificationData] = useState(null)
  const [verificationStage, setVerificationStage] = useState(null)
  const [status, setStatus] = useState(null)
  const [dateFilled, setDateFilled] = useState(null)
  const [statusMessage, setStatusMessage] = useState(null)
  const [verificationComponent, setVerificationComponent] = useState(null)

  const loadVerification = () => {
    api
      .fetchVerification()
      .then((data) => {
        console.log('fetchVerification', data)
        setVerificationData(data)
        setVerificationStage(data.verificationStage)
        setStatus(data.status)
        setDateFilled(data.dateFilled || null)
        setStatusMessage(data.statusMessage)
      })
      .catch((e) => console.error('Error fetched verification ' + e))
  }

  const handleSendVerification = (data) => {
    if (!data) return
    loadVerification()
  }

  const handlePassVerificationClick = (verificationStage) => {
    if (verificationStage === USER_VERIFICATION_STAGE.BasicVerification)
      setVerificationComponent(
        <BasicVerification
          t={t}
          onHandleBasicVerification={(data) => {
            setVerificationComponent(null)
            handleSendVerification(data)
          }}
          verificationData={verificationData}
        />,
      )
    if (verificationStage === USER_VERIFICATION_STAGE.AdvancedVerification)
      setVerificationComponent(
        <AdvancedVerification
          t={t}
          onHandleAdvancedVerification={(data) => {
            setVerificationComponent(null)
            handleSendVerification(data)
          }}
          verificationData={verificationData}
        />,
      )
  }

  useEffect(() => {
    loadVerification()
  }, [])

  return (
    <React.Fragment>
      <div className='layout__verification verification'>
        {verificationComponent || (
          <VerificationProcess
            t={t}
            status={status}
            verificationStage={verificationStage}
            dateTimeSendVerification={dateFilled}
            onPassVerificationClick={(verificationStage) => handlePassVerificationClick(verificationStage)}
            statusMessage={statusMessage}
          />
        )}
      </div>
    </React.Fragment>
  )
}
