import React from 'react'
import Main from '../../common/components/Main/Main'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './EmailPage.module.scss'

const EmailPage = () => {
  const { t } = useTranslation()
  return (
    <Main>
      <div className={styles.wrapper}>
        <span>
          {t('EmailConfirmationMessage')}{' '}
          <Link className={styles.link} to={'/register'}>
            {t('EmailConfirmationLoginLink')}
          </Link>
        </span>
      </div>
    </Main>
  )
}

export default EmailPage
