import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import arrow from './images/arrow.png'
import { useTranslation } from 'react-i18next'
const navigation = [
  {
    title: 'MainPages',
    list: [
      {
        text: 'Convert',
        link: '/convert',
      },
      {
        text: 'Exchange',
        link: '/exchange',
      },
      {
        text: 'Assets',
        link: '/finance',
      },
      {
        text: 'P2P',
        link: '/P2P',
      },
      {
        text: 'BranchOffices',
        link: '/offices',
      },
      {
        text: 'ServicesSubtitle1',
        link: '/purchaseAtCost',
      },
      {
        text: 'ServicesSubtitle3',
        link: '/financial-cooperation',
      },
      {
        text: 'SoftwareDevelopment',
        link: '/software',
      },
      {
        text: 'TradableAssets',
        link: '/tradable',
      },
      {
        text: 'CreateToken',
        link: '/tokenization',
      },
    ],
  },
  {
    title: 'Documentation',
    list: [
      {
        text: 'CompanyCard',
        link: '/onenews/139',
      },
      {
        text: 'RulesAndFees',
        link: '/rules-and-commissions',
      },
      {
        text: 'PrivacyPolicies',
        link: '/privacy-policy',
      },
      {
        text: 'UserAgreement',
        link: '/user-agreement',
      },
      {
        text: 'CookiePolicy',
        link: '/cookie-policy',
      },
      {
        text: 'UserAgreementApi',
        link: '/user-agreement-api',
      },
    ],
  },
  {
    title: 'Education',
    list: [
      {
        text: 'RegistrationAndVerification',
        link: '/onenews/22',
      },
      {
        text: 'HowToBuy/sellCryptocurrency',
        link: '/onenews/49',
      },
      {
        text: 'ReplenishmentAndWithdrawalOfBalance',
        link: '/onenews/36',
      },
      {
        text: 'ExchangeCryptocurrencyInIstanbul',
        link: '/onenews/83',
      },
      {
        text: 'BuyCryptocurrencyInIstanbul',
        link: '/onenews/48',
      },
      {
        text: 'TransferMoneyFromRussiaToTurkey',
        link: '/onenews/56',
      },
      {
        text: 'TransferMoneyFromTurkeyToOtherCountries',
        link: '/onenews/57',
      },
      {
        text: 'CommentsPerevood',
        link: '/onenews/60',
      },
    ],
  },
]
export default function FooterNavigation() {
  const { t } = useTranslation()
  const [navIndex, setNavIndex] = useState(false)
  return (
    <nav className='footer__nav nav'>
      <div className='nav__groups'>
        {navigation.map((group, i) => (
          <div className={`nav__group ${navIndex === i ? 'nav__group_open' : ''}`} key={i}>
            <div className='nav__header'>
              <div className='nav__title'>
                <span className='title__text h4'>{t(group.title)}</span>
              </div>
              <div className='nav__action' onClick={() => (navIndex === i ? setNavIndex(false) : setNavIndex(i))}>
                <img src={arrow} alt='image description' />
              </div>
            </div>
            <div className='nav__main'>
              <ul className='nav__list'>
                {group.list.map((item, index) => (
                  <li className='nav__item' key={index}>
                    <NavLink to={`/${i18n.language}${item.link}`} className='nav__link'>
                      {t(item.text)}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </nav>
  )
}
