import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { cssClass } from 'helpers/helper'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './Notifications.module.scss'

import exclamationImg from './images/exclamation.svg'
import exitImg from './images/exit.svg'
import checkImg from './images/check.svg'
import smileImg from './images/smile.png'

export const NotificationSmall = ({ text }) => {
  const { t } = useTranslation()
  const [hidden, setHidden] = useState(false)
  return (
    <div
      className={cssClass(
        styles.notificationWrapper,
        styles.notificationSmall,
        hidden && baseComponents.displayNone,
      )}
    >
      <div className={styles.parallelepiped}></div>
      <div className={styles.plateBg}>
        <img className={styles.exclamation} src={exclamationImg} alt='Exclamation' />
        <p>{t(text)}</p>
        <button onClick={() => setHidden(true)} className={styles.exit}>
          <img src={exitImg} alt='Exit' />
        </button>
      </div>
    </div>
  )
}

export const NotificationBig = ({ isVisible, setIsVisible }) => {
  const { t } = useTranslation()
  return (
    <div
      className={cssClass(
        styles.notificationWrapper,
        styles.notificationBig,
        !isVisible && baseComponents.displayNone,
      )}
    >
      <div className={styles.bigBg}></div>
      <div className={styles.plateBgBig}>
        <button onClick={() => setIsVisible(false)} className={styles.exitBig}>
          <img src={exitImg} alt='Exit' />
        </button>
        <img className={styles.imgCheck} src={checkImg} alt='Check' />
        <p>
          {t('NotificationBig1')} <br /> {t('NotificationBig2')}
        </p>
        <img className={styles.imgSmile} src={smileImg} alt='Smile' />
      </div>
    </div>
  )
}

export const NotificationLogout = ({ isVisible, setIsVisible, onConfirmed }) => {
  const { t } = useTranslation()
  return (
    <div
      className={cssClass(
        styles.notificationWrapper,
        styles.notificationLogout,
        !isVisible && baseComponents.displayNone,
      )}
    >
      <div className={styles.logoutBg}></div>
      <div className={styles.plateBgLogout}>
        <button onClick={() => setIsVisible(false)} className={styles.exitBig}>
          <img src={exitImg} alt='Exit' />
        </button>
        <p>{t('ConfirmLogout')}</p>
        <div className={styles.buttonsWrapper}>
          <button onClick={() => setIsVisible(false)} className={baseComponents.buttonOutlined}>
            {t('Stay')}
          </button>
          <button
            onClick={() => {
              onConfirmed()
              setIsVisible(false)
            }}
            className={cssClass(baseComponents.button, baseComponents.buttonActive)}
          >
            {t('Logout')}
          </button>
        </div>
      </div>
    </div>
  )
}
