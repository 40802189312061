import { NavLink, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { element } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'
import i18n from 'i18next'
import api from 'api'
import styles from './Sidebar.module.scss'
import selector from '../Selector/Selector.module.scss'

const menuItems = [
  { title: 'Account', path: '/account' },
  { title: 'Verification', path: '/verification' },
  { title: 'Assets', path: '/finance' },
  { title: 'Order', path: '/active-orders', isOrderNode: true },
  { title: 'History', path: '/history' },
  // { title: 'Referrals', path: '/referrals' },
  { title: 'APIkeys', path: '/apikeys' },
]

const orderItems = [
  { title: 'OpenOrders', path: '/active-orders' },
  { title: 'OrdersHistory', path: '/order-history' },
  { title: 'TradeHistory', path: '/trading-history' },
]

export default function Sidebar() {
  const location = useLocation()
  const { t } = useTranslation()

  const [cashActiveCount, setCashActiveCount] = useState(null)
  useEffect(() => {
    api
      .countActiveCashOperations()
      .then((res) => {
        setCashActiveCount(res)
      })
      .catch((err) => {
        console.log('countActiveCashOperations error', err)
      })
  }, [])

  //если находимся на одной из ссылок Ордеров, то выпадающий список будет открыт,
  let orderCondition = false
  orderItems.map((element) => {
    orderCondition = orderCondition || element.path === location.pathname
  })

  const [orderOpened, setOrderOpened] = useState(orderCondition)
  const [orderDesktopOpened, setOrderDesktopOpened] = useState(orderCondition)
  const listRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setOrderOpened(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <ul className={cssClass(styles.phoneVertHidden, styles.sidebar)}>
        {menuItems.map((item, i) => {
          const isActive = item.path === location.pathname || (item.isOrderNode && orderCondition)
          return (
            <React.Fragment key={item.title}>
              <li className={cssClass(item.path === '/history' && styles.fitLi, isActive ? styles.active : null)}>
                <NavLink to={`/${i18n.language}${item.path}`} activeClassName={cssClass(styles.active)}>
                  {t(item.title)}
                </NavLink>
                {item.isOrderNode && (
                  <>
                    <button
                      className={styles.orderButton}
                      onClick={() => {
                        setOrderOpened((prev) => !prev)
                        setOrderDesktopOpened((prev) => !prev)
                      }}
                    >
                      <svg
                        className={cssClass(styles.desktopOnly, orderDesktopOpened ? null : styles.rotate180)}
                        xmlns='http://www.w3.org/2000/svg'
                        width='9'
                        height='6'
                        viewBox='0 0 9 6'
                        fill='none'
                      >
                        <path
                          d='M1.28729 6C0.725755 6 0.33559 5.73348 0.116798 5.20043C-0.101994 4.66739 -0.0133967 4.19478 0.382592 3.78261L3.62704 0.391304C3.75183 0.26087 3.88701 0.163044 4.0326 0.097827C4.17818 0.0326097 4.33416 0 4.50054 0C4.66693 0 4.82291 0.0326097 4.96849 0.097827C5.11408 0.163044 5.24926 0.26087 5.37405 0.391304L8.6185 3.78261C9.01365 4.19565 9.10184 4.66869 8.88304 5.20174C8.66425 5.73478 8.2745 6.00087 7.71379 6H1.28729Z'
                          fill='#202639'
                        />
                      </svg>
                      <svg
                        className={cssClass(styles.desktopHidden, orderOpened ? null : styles.rotate180)}
                        xmlns='http://www.w3.org/2000/svg'
                        width='9'
                        height='6'
                        viewBox='0 0 9 6'
                        fill='none'
                      >
                        <path
                          d='M7.71271 6C8.27424 6 8.66441 5.73348 8.8832 5.20043C9.10199 4.66739 9.0134 4.19478 8.61741 3.78261L5.37296 0.391304C5.24817 0.26087 5.11299 0.163044 4.9674 0.097827C4.82182 0.0326097 4.66584 0 4.49946 0C4.33307 0 4.17709 0.0326097 4.03151 0.097827C3.88592 0.163044 3.75074 0.26087 3.62595 0.391304L0.381503 3.78261C-0.0136538 4.19565 -0.101836 4.66869 0.116956 5.20174C0.335749 5.73478 0.725498 6.00087 1.28621 6H7.71271Z'
                          fill='#A717FF'
                        />
                      </svg>
                    </button>
                    {orderOpened ? (
                      <div
                        className={cssClass(
                          styles.borderWrapper,
                          selector.selectListWrapper,
                          styles.orderBlock,
                          'zIndex10',
                        )}
                        ref={listRef}
                      >
                        <ul className={cssClass(styles.sidebar, selector.selectList)}>
                          {orderItems.map((order, index) => {
                            const isActive = order.path === location.pathname
                            return (
                              <li className={isActive ? styles.active : null} key={index}>
                                <NavLink
                                  to={`/${i18n.language}${order.path}`}
                                  activeClassName={cssClass(styles.active)}
                                >
                                  {t(order.title)}
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </>
                )}
                {item.path === '/history' && cashActiveCount > 0 && (
                  <div
                    className={cssClass(
                      styles.notification1Figure,
                      cashActiveCount >= 10 ? styles.notificationMoreThan1Figure : null,
                    )}
                  >
                    {cashActiveCount}
                  </div>
                )}
              </li>
              <OrderListDesktop item={item} orderOpened={orderDesktopOpened} />
            </React.Fragment>
          )
        })}
      </ul>
      <SidebarPhone cashActiveCount={cashActiveCount} />
    </>
  )
}

export const SidebarPhone = ({ cashActiveCount }) => {
  const location = useLocation()
  const { t } = useTranslation()

  //если находимся на одной из ссылок Ордеров, то выпадающий список будет открыт,
  let orderCondition = false
  orderItems.map((element) => {
    orderCondition = orderCondition || element.path === location.pathname
  })

  const [orderOpened, setOrderOpened] = useState(orderCondition)

  const listRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setOrderOpened(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <ul className={cssClass(styles.sidebar, styles.phoneVertOnly)}>
        {menuItems.map((item, i) => {
          if (i <= 3) {
            const isActive = item.path === location.pathname
            return (
              <li key={i} className={isActive ? styles.active : null}>
                <NavLink to={`/${i18n.language}${item.path}`} activeClassName={cssClass(styles.active)}>
                  {t(item.title)}
                </NavLink>
                {item.isOrderNode && (
                  <>
                    <button className={styles.orderButton} onClick={() => setOrderOpened((prev) => !prev)}>
                      <svg
                        className={cssClass(orderOpened ? null : styles.rotate180)}
                        xmlns='http://www.w3.org/2000/svg'
                        width='9'
                        height='6'
                        viewBox='0 0 9 6'
                        fill='none'
                      >
                        <path
                          d='M7.71271 6C8.27424 6 8.66441 5.73348 8.8832 5.20043C9.10199 4.66739 9.0134 4.19478 8.61741 3.78261L5.37296 0.391304C5.24817 0.26087 5.11299 0.163044 4.9674 0.097827C4.82182 0.0326097 4.66584 0 4.49946 0C4.33307 0 4.17709 0.0326097 4.03151 0.097827C3.88592 0.163044 3.75074 0.26087 3.62595 0.391304L0.381503 3.78261C-0.0136538 4.19565 -0.101836 4.66869 0.116956 5.20174C0.335749 5.73478 0.725498 6.00087 1.28621 6H7.71271Z'
                          fill='#A717FF'
                        />
                      </svg>
                    </button>
                    {orderOpened ? (
                      <div
                        className={cssClass(
                          styles.borderWrapper,
                          selector.selectListWrapper,
                          styles.orderBlock,
                          styles.phonePosition,
                          'zIndex10',
                        )}
                        ref={listRef}
                      >
                        <ul className={cssClass(styles.sidebar, selector.selectList)}>
                          {orderItems.map((order, index) => {
                            const isActive = order.path === location.pathname
                            return (
                              <li
                                className={isActive ? styles.active : null}
                                key={index}
                                // onClick={() => selectNewItem(element)}
                              >
                                <NavLink
                                  to={`/${i18n.language}${order.path}`}
                                  activeClassName={cssClass(styles.active)}
                                >
                                  {t(order.title)}
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </>
                )}
              </li>
            )
          }
        })}
      </ul>
      <ul className={cssClass(styles.sidebar, styles.phoneVertOnly)}>
        {menuItems.map((item, i) => {
          if (i > 3) {
            const isActive = item.path === location.pathname
            return (
              <li key={i} className={isActive ? styles.active : null}>
                <NavLink to={`/${i18n.language}${item.path}`} activeClassName={cssClass(styles.active)}>
                  {t(item.title)}
                </NavLink>
                {item.path === '/history' && cashActiveCount > 0 && (
                  <div
                    className={cssClass(
                      styles.notification1Figure,
                      cashActiveCount >= 10 ? styles.notificationMoreThan1Figure : null,
                    )}
                  >
                    {cashActiveCount}
                  </div>
                )}
              </li>
            )
          }
        })}
      </ul>
    </>
  )
}

const OrderListDesktop = ({ item, orderOpened }) => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      {item.isOrderNode && orderOpened && (
        <React.Fragment key={'orderWrapper'}>
          {orderItems.map((order) => {
            const isActive = order.path === location.pathname
            return (
              <li key={order.title} className={cssClass(styles.order, isActive ? styles.active : null)}>
                <NavLink to={`/${i18n.language}${order.path}`} activeClassName={cssClass(styles.active)}>
                  {t(order.title)}
                </NavLink>
              </li>
            )
          })}
        </React.Fragment>
      )}
    </>
  )
}
