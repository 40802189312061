import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'
import TokenIcons from '../TokenData/TokenIcons'

const CategoryTabs = {
  Entrepreneur: 'Entrepreneur',
  Investor: 'Investor',
  Bloger: 'Bloger',
  Trader: 'Trader',
}
const OptionStarategyTabs = { First: 'First', Second: 'Second' }
const presetVariables = {
  Entrepreneur: {
    title: 'ExpandYourAudienceAndIncreaseCustomerLoyalty',
    icon: '👨‍💻',
    steps: [
      'ChoosingWhatYouWantToTokenize',
      'CreatingAToken',
      'AttractingAnAudience',
      'ReceivingTheMoney',
      'GrowingTogether',
      'DividendPayment',
    ],
    results: [
      'OwnCoin',
      'AttractingNewAssets',
      'AdvantageAmongCompetitors',
      'Investments',
      'NewAudience',
      'IncreasedLoyalty',
    ],
  },
  Investor: {
    title: 'SimplifyYourInvestmentProcesses',
    icon: '💸',
    steps: ['SearchForProjects', 'ProjectAnalysis', 'Investment', 'Rostproekt', 'ReceivingAProfit'],
    results: [
      'Profit',
      'AccessToOpinionLeaders',
      'Empowerment',
      'PortfolioDiversification',
      'AccessToProjects',
      'DiveIntoTheNewRWATrend',
      'PromotionsOfProjectsAtAnEarlyStage',
    ],
  },
  Bloger: {
    title: 'MonetizeYourAudience',
    icon: '🤳',
    optionStrategies: {
      First: [
        'ChoosingWhatYouWantToTokenize',
        'CreatingAToken',
        'AttractingAnAudience',
        'ReceivingTheMoney',
        'GrowingTogether',
        'DividendPayment',
      ],
      Second: [
        'SearchForAnExistingProject',
        'AgreementWithTheProjectOwners',
        'AttractingYourAudience',
        'YourAudienceHelpsYourBusinessGrow',
        'EveryoneWinsTogether',
      ],
    },
    results: [
      'AudienceMonetization',
      'PassiveIncome',
      'LongTermAdvertisingContracts',
      'OwnCoin',
      'SubscriberGrowth',
      'HypeProjects',
    ],
  },
  Trader: {
    title: 'OpenTheDoorToFinancialFreedom',
    icon: '📊',
    steps: ['SearchForProjects', 'ProjectAnalysis', 'TradingOnHighlyVolatileTokens', 'DividendPayment'],
    results: [
      'OwnCoin',
      'AttractingNewAssets',
      'AdvantageAmongCompetitors',
      'Investments',
      'NewAudience',
      'IncreasedLoyalty',
    ],
  },
}

export default function Categories({ t, navigationFunctions }) {
  const [selectedCategoryTabs, setSelectedCategoryTabs] = useState(CategoryTabs.Entrepreneur)
  const [selectedOptionStrategyTabs, setSelectedOptionStrategyTabs] = useState(OptionStarategyTabs.First)

  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }

  return (
    <React.Fragment>
      <section id='layout__categories' className='layout__section section'>
        <div className='container'>
          <div className='section__header section__header_secondary'>
            <h2 className='section__title'>{t('CheckOutTheStep')}</h2>
          </div>
          <div className='section__main'>
            <div className='layout__categories categories'>
              {RenderSidebar(t, selectedCategoryTabs, setSelectedCategoryTabs)}
              <main className='categories__main layout__box'>
                <MapCategory
                  t={t}
                  categoryType={selectedCategoryTabs}
                  selectedOptionStrategyTabs={selectedOptionStrategyTabs}
                  setSelectedOptionStrategyTabs={setSelectedOptionStrategyTabs}
                  handleGoToPage={() => handleGoToPage()}
                />
              </main>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

const RenderSidebar = (t, selectedCategoryTabs, setSelectedCategoryTabs) => (
  <aside className='categories__sidebar layout__box layout__box_secondary'>
    <span className='categories__title layout__tag'>{t('SelectACategory')}:</span>
    <nav className='categories__nav nav'>
      <ul className='nav__list'>
        {Object.entries(CategoryTabs).map(([key, value]) => (
          <li
            key={key}
            className={`nav__item ${selectedCategoryTabs === value ? 'nav__item_active' : ''}`}
            onClick={() => setSelectedCategoryTabs(value)}
          >
            <span className='nav__ico'>{presetVariables[key].icon}</span>
            <span className='nav__text'>{t(`I${key}`)}</span>
          </li>
        ))}
      </ul>
    </nav>
  </aside>
)

const MapCategory = ({
  t,
  categoryType,
  selectedOptionStrategyTabs,
  setSelectedOptionStrategyTabs,
  handleGoToPage,
}) => {
  const category = presetVariables[categoryType]
  if (!category) return null

  const renderSteps = (steps) => (
    <div className='category__steps steps'>
      <ul className='steps__list'>
        {steps.map((step, index) => (
          <li key={index} className='steps__item'>
            <a className='steps__link'>
              <span className='steps__title'>#{index + 1}</span>
              <TokenIcons name={'arrowTopRightIco'} />
              <span className='steps__text'>{t(step)}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )

  const renderResults = (results) => (
    <div className='category__group resgroupults'>
      <div className='group__header'>
        <span className='results__title h4'>{t('Result')}:</span>
      </div>
      <div className='group__main'>
        <div className='category__results results'>
          <ul className='results__list'>
            {results.map((result, index) => (
              <li key={index} className='results__item'>
                <a className='results__link layout__tag' style={{ cursor: 'default' }}>
                  {t(result)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )

  return (
    <div className='categories__item category'>
      <div className='category__header'>
        <h3 className='category__title'>{t(category.title)}</h3>
        <span className='category__ico'>{category.icon}</span>
      </div>
      <div className='category__main'>
        <div className='category__group group'>
          <div className='group__header'>
            <span className='group__title h4'>{t('StepStrategy')}:</span>
          </div>
          {category.optionStrategies ? (
            <>
              <div className='section__tabs section__tabs_secondary tabs'>
                <ul className='tabs__list'>
                  {Object.keys(category.optionStrategies).map((option) => (
                    <li
                      key={option}
                      className={`tabs__item ${selectedOptionStrategyTabs === option ? 'tabs__item_active' : ''}`}
                      onClick={() => setSelectedOptionStrategyTabs(option)}
                    >
                      {t(`${option}StrategyOption`)}:
                    </li>
                  ))}
                </ul>
              </div>
              <div className='group__main'>{renderSteps(category.optionStrategies[selectedOptionStrategyTabs])}</div>
            </>
          ) : (
            <div className='group__main'>{renderSteps(category.steps)}</div>
          )}
        </div>
        {renderResults(category.results)}
      </div>
      <div className='category__footer'>
        <button className='btn btn_radius-tertiary' onClick={() => handleGoToPage()}>
          <span className='btn__text'>{t('IWantToCreateCoin')}</span>
        </button>
      </div>
    </div>
  )
}
