import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import api from 'api'
import { cssClass, calculateCommission, getCommissionInfoString } from 'helpers/helper'
import CommissionTypes from 'data/CommissionTypes'
import WithdrawalTypeCodes from 'data/WithdrawalTypeCodes'
import styles from './CryptoWithdrawal.module.scss'
import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'

const getAdapterStyleNames = (adapterCode) => {
  if (adapterCode === 'eth') return [styles.buttonGreen, styles.buttonGreenActive]
  if (adapterCode === 'trx') return [styles.buttonRed, styles.buttonRedActive]
  return [styles.buttonYellow, styles.buttonYellowActive] // bnb and others
}

export default function CryptoWithdrawal({ currency, freeBalance, loadBalance, close }) {
  const { t } = useTranslation()
  const history = useHistory()

  const adapters = currency.currencyAdapters.map((_) => _.adapter)
  const [adapter, setAdapter] = useState(adapters[0] ?? {})
  const [commissionEstimate, setCommissionEstimate] = useState(null)
  const [amount, setAmount] = useState(null)
  const [address, setAddress] = useState('')
  const [memo, setMemo] = useState('')
  const [publicID, setPublicID] = useState('')
  const [emailCode, setEmailCode] = useState('')
  const [isEmailBtnDisabled, setEmailDisabled] = useState(false)
  const [commissions, setCommissions] = useState([])

  useEffect(() => {
    let ignore = false
    api.fetchCommissions().then((data) => {
      if (ignore) return
      setCommissions(data?.commissions)
    })
    return () => {
      ignore = true
    }
  }, [])

  const [timer, setTimer] = useState(null) // delay commission call
  useEffect(() => {
    let ignore = false
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    setCommissionEstimate(null)
    if (!amount) return

    api
      .estimateWithdrawalCommission(amount, currency.code, adapter.code)
      .then((data) => {
        //if (ignore) return
        setCommissionEstimate(data)
      })
      .catch((e) => {
        console.log('estimateWithdrawalCommission error', e)
        setCommissionEstimate(null)
      })

    return () => {
      ignore = true
    }
  }, [amount])

  const withdraw = () => {
    if (!amount || amount <= 0 || address.length < 20 || !emailCode) return
    api
      .createWithdrawal(createWithdrawalModel())
      .then((data) => {
        toast.success(t('CreateWithdrawal_Success'))
        console.log('withdrawal done', data)
        loadBalance()
        setAmount(null)
        setAddress('')
        setCommissionEstimate(null)
        close()
      })
      .catch(() => {
        toast.error(t('CreateWithdrawal_Error'))
      })
  }

  const createWithdrawalModel = () => ({
    withdrawalType: WithdrawalTypeCodes.Crypto,
    publicId: publicID,
    address,
    memo,
    currencyCode: currency.code,
    adapterCode: adapter.code,
    amount,
    emailAuthCode: emailCode,
  })
  
  const handleSendEmail = (e) => {
    e.preventDefault()
    setEmailDisabled(true)

    api
      .sendEmailConfirmation(createWithdrawalModel())
      .then(() => {
        toast.success(t('SendEmailConfirmation_Success'))
      })
      .catch(() => {
        toast.error(t('SendEmailConfirmation_Error'))
      })
      .finally(() => {
        setEmailDisabled(false)
      })
  }

  return (
    <div className={styles.block}>
      <div className={styles.body}>
        <div className={styles.infoBody}>
          <h2 className={styles.title}>{t('WithdrawOf', { currency: currency.code })}</h2>
          <p className={styles.description}>{t('WithdrawApplication')}</p>
          <div className={styles.selectTitle}>{t('ChooseNetwork')}</div>
          <div className={styles.buttonBody}>
            {adapters.map((item) => {
              const [className, activeClassName] = getAdapterStyleNames(item.code)
              const isActive = item.code === adapter.code
              return (
                <button
                  key={item.code}
                  onClick={() => setAdapter(item)}
                  className={cssClass(styles.button, className, isActive ? activeClassName : null)}
                >
                  {item.name}
                </button>
              )
            })}
          </div>
          {adapter && adapter.code === 'eth' && (
            <div className={styles.dangerBody}>
              <div className={styles.danger}>{t('WithdrawalsOnlyPossible')}</div>
            </div>
          )}
        </div>
      </div>
      <form className={styles.form}>
        <p className={styles.formTitle}>{t('AvailableForWithdrawal')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
          value={freeBalance.toFixedTrimmed(currency.digits)}
          readOnly={true}
        ></input>
        <p className={styles.formTitle}>{t('WithdrawalAddress')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input)}
          placeholder={t('RecipientAddress')}
          value={address}
          onChange={(e) => setAddress(e.target.value.replace(/[^a-zA-Z0-9]/g, ''))}
        />
        {adapter.code === 'ton' && (
          <>
            <p className={styles.formTitle}>{t('WithdrawalMemo')}</p>
            <input
              className={cssClass(baseComponents.input, styles.input)}
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </>
        )}
        <div className={cssClass(styles.summWrapper)}>
          <p className={cssClass(styles.formTitle, styles.summTitle)}>{t('withdrawalAmount')}</p>
          <p onClick={() => history.push('/commissions')} className={cssClass(styles.formTitle, styles.commission)}>
            {t('Commission')}
          </p>
          <input
            type='number'
            className={cssClass(baseComponents.input, styles.input, styles.summInput)}
            placeholder={t('minimalWithdraw')}
            value={amount || ''}
            onChange={(e) => setAmount(e.target.value)}
          ></input>
          <button
            onClick={(e) => {
              setAmount(freeBalance)
            }}
            type='button'
            className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.summButton)}
          >
            {t('WithdrawAll')}
          </button>
          <input
            className={cssClass(baseComponents.input, styles.commissionDisabledInput)}
            style={{ overflow: 'visible' }}
            readOnly={true}
            placeholder={getCommissionInfoString(commissions, CommissionTypes.Withdrawal, currency.code, adapter.code)}
          ></input>
        </div>

        <p className={styles.formTitle}>{t('PaymentAmount')}</p>
        <input
          className={cssClass(baseComponents.input, styles.input)}
          readOnly={true}
          value={commissionEstimate?.amountAfterCommission || ''}
        ></input>

        <p className={styles.formTitle}>{t('EmailCode')}</p>
        <div className={styles.inputButtonWrapper}>
          <input
            className={cssClass(baseComponents.input, styles.input, styles.fourthInput)}
            placeholder=''
            value={emailCode}
            onChange={(e) => setEmailCode(e.target.value)}
          />
          <button
            onClick={handleSendEmail}
            className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen)}
            disabled={isEmailBtnDisabled}
          >
            {t('SendEmail')}
          </button>
        </div>

        <button type='button' className={cssClass(baseComponents.button, styles.sendButton)} onClick={withdraw}>
          {t('Send')}
        </button>
      </form>
    </div>
  )
}
