import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'components/layout/Modal/Modal'
import { DEAL_STATUS } from 'pages/p2p/Order/OrderAndDealData'
export default function CancelAppealWarningModal({ onConfirmCancel, opened, onClose }) {
  const { t } = useTranslation()
  let ico = (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9147 4.8248C18.3253 1.9424 21.0293 0.5 24 0.5C26.9707 0.5 29.6747 1.94 35.0853 4.8248L36.9147 5.7992C42.3253 8.684 45.0293 10.1264 46.5147 12.5C48 14.876 48 17.756 48 23.5256V25.4744C48 31.2416 48 34.1264 46.5147 36.5C45.0293 38.876 42.3253 40.316 36.9147 43.1984L35.0853 44.1752C29.6747 47.0576 26.9707 48.5 24 48.5C21.0293 48.5 18.3253 47.06 12.9147 44.1752L11.0853 43.1984C5.67467 40.3184 2.97067 38.8736 1.48533 36.5C-1.58946e-07 34.124 0 31.244 0 25.4744V23.5256C0 17.756 -1.58946e-07 14.8736 1.48533 12.5C2.97067 10.124 5.67467 8.684 11.0853 5.7992L12.9147 4.8248ZM26.6667 34.1C26.6667 34.7365 26.3857 35.347 25.8856 35.7971C25.3855 36.2471 24.7072 36.5 24 36.5C23.2928 36.5 22.6145 36.2471 22.1144 35.7971C21.6143 35.347 21.3333 34.7365 21.3333 34.1C21.3333 33.4635 21.6143 32.853 22.1144 32.4029C22.6145 31.9529 23.2928 31.7 24 31.7C24.7072 31.7 25.3855 31.9529 25.8856 32.4029C26.3857 32.853 26.6667 33.4635 26.6667 34.1ZM24 10.7C24.5304 10.7 25.0391 10.8896 25.4142 11.2272C25.7893 11.5648 26 12.0226 26 12.5V26.9C26 27.3774 25.7893 27.8352 25.4142 28.1728C25.0391 28.5104 24.5304 28.7 24 28.7C23.4696 28.7 22.9609 28.5104 22.5858 28.1728C22.2107 27.8352 22 27.3774 22 26.9V12.5C22 12.0226 22.2107 11.5648 22.5858 11.2272C22.9609 10.8896 23.4696 10.7 24 10.7Z'
        fill='#A717FF'
      />
    </svg>
  )
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg text-center'>
          {ico}
          <h2>
            <strong>{t('P2PCancelAppleaWarning')}</strong>
          </h2>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100' onClick={onClose}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn w-100'
                      onClick={() => {
                        onConfirmCancel('cancelAppeal')
                        onClose()
                      }}
                    >
                      <span className='btn__text'>{t('Confirm')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
