import React from 'react'
import Modal from 'components/layout/Modal/Modal'
export default function CreateInBlockchainStubModal({ t, isOpened, onClose }) {
  return (
    <Modal opened={isOpened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg text-center'>
          <h2>
            <strong>{t('TokenInDevelopment')}</strong>
          </h2>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_quartyty w-100' onClick={onClose}>
                      <span className='btn__text'>Ok</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100' onClick={onClose}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
