import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import style from '../../style/TokenPage.module.scss'
import api from 'api'
import { cssClass } from 'helpers/helper'

import styles from './TokenChart.module.scss'

const signalR = require('@microsoft/signalr')

/* Highcharts config */
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
import HighchartsTheme from 'highcharts/themes/brand-light'
HighchartsTheme(Highcharts)

const BarRanges = {
  Year: 1,
  Month: 2,
  Day: 3,
  Hour: 5,
}

const groupingUnits = [
  // TODO is it needed?
  ['day', [1]],
  ['week', [1]],
  ['month', [1, 2, 3, 4, 6]],
]

const mapBar = (b) => ({
  x: new Date(b.date).getTime(),
  open: b.open,
  high: b.max,
  low: b.min,
  close: b.close,
  volume: b.volume,
  //volumeBase: b.volumeBase,
})

const TokenChartElement = observer(({ t, symbol }) => {
  const [chart, setChart] = useState(null)
  const [barRange, setBarRange] = useState(BarRanges.Hour)
  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState(null)
  const [chartTime, setChartTime] = useState(new Date())

  useEffect(() => {
    let ignore = false

    // console.log('useEffect fetchBars', symbol, barRange)
    setChartData([])
    if (!symbol || !barRange) return

    const today = new Date()
    const lastYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
    api
      .fetchBars(barRange, symbol, lastYear, new Date())
      .then((data) => {
        // console.log('TokenChartElement', data)
        if (ignore) return

        setChartData(data.map(mapBar))
      })
      .catch((error) => console.error(error.message))

    return () => {
      ignore = true
    }
  }, [symbol, barRange])

  useEffect(() => {
    const today = new Date()

    setChartOptions({
      time: {
        useUTC: false,
      },
      chart: {
        height: 504,
      },
      xAxis: {
        min: +new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours() - 24),
        max: +new Date(),
      },
      series: [
        {
          // https://www.highcharts.com/demo/stock/areaspline
          // https://api.highcharts.com/highcharts/series.areaspline
          type: 'areaspline',
          threshold: null,
          id: 'stock-ohlc',
          name: `${symbol} Stock Price`,
          data: chartData.map((d) => [d.x, d.close]),
          dataGrouping: {
            units: groupingUnits,
          },
          dashStyle: 'LongDash',
          color: '#BC81DC',
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, '#E7CCF9'],
              [1, Highcharts.color('#E7CCF9').setOpacity(0).get('rgba')],
            ],
          },
        },
      ],
      navigator: {
        enabled: false, // disable bottom range selection
      },
      rangeSelector: {
        selected: 3,
        inputEnabled: false,
        buttons: [
          {
            type: 'day',
            count: 1,
            text: '1d',
            title: 'View 1 day',
          },
          {
            type: 'week',
            count: 1,
            text: '1w',
            title: 'View 1 week',
          },
          {
            type: 'month',
            count: 1,
            text: '1m',
            title: 'View 1 month',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
            title: 'View 3 months',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
            title: 'View 6 months',
          },
          // {
          //   type: 'ytd',
          //   text: 'YTD',
          //   title: 'View year to date',
          // },
          {
            type: 'year',
            count: 1,
            text: '1y',
            title: 'View 1 year',
          },
          // {
          //   type: 'all',
          //   text: 'All',
          //   title: 'View all',
          // },
        ],
      },
    })
  }, [chartData])

  useEffect(() => {
    // update time in chart title
    let timerId = setInterval(() => {
      setChartTime(new Date())
    }, 10 * 1000)
    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    // console.log('chartTime', chartTime)
  }, [chartTime])

  return (
    <div>
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          callback={(chart) => {
            setChart(chart)
          }}
          options={chartOptions}
        />
      )}
      <div className={cssClass(style.graphic__footer)}>
        <span className={cssClass(style.graphic__text)}>
          {t('TokenLastUpdate')}:{' '}
          {chartTime.toLocaleString([], {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          (UTC+0)
        </span>
      </div>
    </div>
  )
})

export default TokenChartElement
