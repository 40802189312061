import Main from 'pages/common/components/Main/Main'
import Search from './components/Search/Search'
import { NewsLine } from 'baseComponents/Line/Line'
import { NewsLeftSection, NewsRightSection } from './components/NewsSection/NewsSection'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'

import newsImage1 from './img/newsImage1.png'
import newsImage2 from './img/newsImage2.png'
import newsImage3 from './img/newsImage3.png'

import pages from '../pages.module.scss'
import news from './NewsPage.module.scss'
import BaseComponents from '../../baseComponents/BaseComponents.module.scss'
import {
  NotificationBig,
  NotificationSmall,
} from 'pages/common/components/Notifications/Notifications'
import { useEffect, useState } from 'react'
import api from '../../api'

const newsSectionData = {
  main: {
    image: newsImage1,
    alt: 'Биткоин и более: главные новости криптовалютного рынка',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Биткоин и более: главные новости криптовалютного рынка',
  },
  firstSide: {
    image: newsImage2,
    alt: '',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Криптовалютный обзор: самые актуальные новости и события',
  },
  secondSide: {
    image: newsImage3,
    alt: '',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Криптовалютная хроника: важные события и тренды рынка',
  },
}
const newsSectionData2 = {
  main: {
    image: newsImage2,
    alt: '',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Криптовалютный обзор: самые актуальные новости и события',
  },
  firstSide: {
    image: newsImage3,
    alt: '',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Криптовалютная хроника: важные события и тренды рынка',
  },
  secondSide: {
    image: newsImage1,
    alt: 'Биткоин и более: главные новости криптовалютного рынка',
    date: '01.04.2023',
    author: 'Александр Третьяков',
    title: 'Биткоин и более: главные новости криптовалютного рынка',
  },
}

export default function NewsPage() {
  const { t } = useTranslation()

  const [newsData, setNewsData] = useState([])

  useEffect(() => {
    api.fetchNews().then((data) => {
      setNewsData(data)
    })
  }, [])

  function chunkArray(arr, size) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  }

  const chunks = chunkArray(newsData, 3);

  return (
    <Main>
      {/*<NotificationSmall text={'NotificationSmall2'} /> */}
      <div className={pages.flow}>
        <div className={news.titleWrapper}>
          <h2 className={pages.title}>{t('LastNews')}</h2>
          <Search />
        </div>
        <NewsLeftSection data={newsData} />
        <NewsLine />
        <div className={news.titleWrapper}>
          <h2 className={pages.title}>{t('PopularNews')}</h2>
        </div>
        {chunks.map((chunk, index) => {
          if (index % 2 === 0) {
            return <NewsLeftSection data={chunk} key={index} />;
          } else {
            return <NewsRightSection data={chunk} key={index} />;
          }
        })}
        {/*<div className={cssClass(news.titleWrapper, news.loadButtonWrapper)}>*/}
        {/*  <button className={cssClass(BaseComponents.button, news.loadButton)}>*/}
        {/*    {t('SeeMore')}*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </Main>
  )
}
