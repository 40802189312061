import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import api from 'api'
import CommissionTypes from 'data/CommissionTypes'
import Main from 'pages/common/components/Main/Main'
import banner__01 from './img/banner__img01.png'

export default function CommissionsPage() {
  const { t } = useTranslation()
  const columnWidth = '33.3%'

  const [commissions, setCommissions] = useState(null)
  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    api.fetchCommissions().then((data) => {
      console.log('fetched commissions', data)
      if (!ignore) setCommissions(data)
    })
    return () => {
      ignore = true
    }
  }, []) // empty array means "run this effect only once"

  const allComms = commissions?.commissions?.filter((c) => !c.currencyCode.includes('TST'))
  const depositComms = orderBy(
    allComms?.filter((_) => _.commissionTypeCode === CommissionTypes.Deposit),
    ['currencyCode', 'currencyAdapterCode'],
    ['asc'],
  )
  const withdrawalComms = orderBy(
    allComms?.filter((_) => _.commissionTypeCode === CommissionTypes.Withdrawal),
    ['currencyCode', 'currencyAdapterCode'],
  )
  const dealBidComms = orderBy(
    allComms?.filter((_) => _.commissionTypeCode === CommissionTypes.DealBid),
    ['currencyCode'],
  )
  const dealAskComms = orderBy(
    allComms?.filter((_) => _.commissionTypeCode === CommissionTypes.DealAsk),
    ['currencyCode'],
  )

  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__banner banner'>
                <div className='row row_m-revers'>
                  <div className='col col_7 col_mob-12'>
                    <div className='banner__layout'>
                      <div className='banner__main'>
                        <div className='wysiwyg'>
                          <h1> {t('Commissions')}</h1>
                          <p>{t('CommissionsText')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_5 col_mob-12'>
                    <div className='banner__media'>
                      <img src={banner__01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1> {t('TradingCommissions')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__content'>
                <div className='layout__table table'>
                  <div className='table__head'>
                    <div className='table__list'>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('Currency')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('InstructionsTab1')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('InstructionsTab2')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {dealBidComms.map(function (comm) {
                    const dealBidPercent = comm.percent
                    const dealAskPercent = dealAskComms.find((c) => c.currencyCode === comm.currencyCode)?.percent
                    return (
                      <div key={comm.currencyCode} className='table__body'>
                        <div className='table__list'>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('Currency')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{comm.currencyCode}</span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('InstructionsTab1')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{dealBidPercent ? `${dealBidPercent}%` : t('No')}</span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('InstructionsTab2')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{dealAskPercent ? `${dealAskPercent}%` : t('No')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1> {t('DepositFees')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__content'>
                <div className='layout__table layout__table_center table'>
                  <div className='table__head'>
                    <div className='table__list'>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('Currency')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('DepositFees')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('MinimumFee')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='table__body'>
                    {depositComms.map(function (comm) {
                      const showAdapter =
                        comm.currencyAdapterCode && comm.currencyAdapterCode.toUpperCase() !== comm.currencyCode
                      return (
                        <div key={`${comm.currencyCode}-${comm.currencyAdapterCode}`} className='table__list'>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('Currency')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>
                                {comm.currencyCode} {showAdapter ? `(${comm.currencyAdapterCode.toUpperCase()})` : ''}
                              </span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('DepositFees')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{comm?.percent ? `${comm?.percent}%` : t('No')}</span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('MinimumFee')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{comm?.minimum ? `${comm?.minimum} ${key}` : t('No')}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1> {t('FeesForWithdrawal')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__content'>
                <div className='layout__table layout__table_center table'>
                  <div className='table__head'>
                    <div className='table__list'>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('Currency')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('FeesForWithdrawal')}</span>
                        </div>
                      </div>
                      <div style={{ width: columnWidth }} className='table__item'>
                        <div className='table__title title'>
                          <span className='title__text'>{t('MinimumFee')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='table__body'>
                    {withdrawalComms.map(function (comm) {
                      const showAdapter =
                        comm.currencyAdapterCode && comm.currencyAdapterCode.toUpperCase() !== comm.currencyCode
                      return (
                        <div key={`${comm.currencyCode}-${comm.currencyAdapterCode}`} className='table__list'>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('Currency')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>
                                {comm.currencyCode} {showAdapter ? `(${comm.currencyAdapterCode.toUpperCase()})` : ''}
                              </span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('FeesForWithdrawal')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>{comm?.percent ? `${comm?.percent}%` : t('No')}</span>
                            </div>
                          </div>
                          <div style={{ width: columnWidth }} className='table__item'>
                            <div className='table__header'>
                              <span className='table__title'>{t('MinimumFee')}</span>
                            </div>
                            <div className='table__main'>
                              <span className='table__text'>
                                {comm?.minimum ? `${comm?.minimum} ${comm.currencyCode}` : t('No')}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
