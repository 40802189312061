import React from 'react'
import { cssClass } from 'helpers/helper'
import style from '../style/TokenPage.module.scss'
import description from '../img/description.jpg'
import QuillHTMLOutput from 'pages/common/components/TextEditor/QuillHTMLOutput'

export default function ProjectDescription({ t, currencyListing, photoImage }) {
  return (
    <React.Fragment>
      <div className={cssClass(style.information__section, style.section)}>
        <div className={cssClass(style.information__description, style.description)}>
          <div className={cssClass(style.description__head)}>
            <span className={cssClass(style.description__title)}>{t('TokenProjectDescription')}:</span>
            <span className={cssClass(style.description__subtitle)}>{t(currencyListing?.tokenName)}</span>
          </div>
          <picture className={cssClass(style.description__media)}>
            <img src={photoImage || null} />
          </picture>
          <header className={cssClass(style.description__header)}>
            <span className={cssClass(style.description__title)}>{currencyListing?.title}</span>
            <span className={cssClass(style.description__subtitle)}>{currencyListing?.subtitle}</span>
          </header>
          <main className={cssClass(style.description__main)}>
            <QuillHTMLOutput html={currencyListing?.content || ''} />
          </main>
        </div>
      </div>
    </React.Fragment>
  )
}
