import React from 'react'

export default function Navigation() {
  const arrowStart = (
    <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' fill='white' />
      <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' stroke='#202639' />
      <path
        d='M15.5 22C15.5 22.1674 15.5535 22.3298 15.6603 22.4872C15.7672 22.6445 15.9097 22.7751 16.0878 22.8789L25.9199 28.6547C26.3117 28.8849 26.8104 29 27.416 29C28.0216 29 28.5204 28.8849 28.9122 28.6547C29.3041 28.4245 29.5 28.1315 29.5 27.7758C29.5 27.42 29.3041 27.1271 28.9122 26.8969L20.5764 22L28.9122 17.1031C29.3041 16.8729 29.5 16.58 29.5 16.2242C29.5 15.8685 29.3041 15.5755 28.9122 15.3453C28.5204 15.1151 28.0216 15 27.416 15C26.8104 15 26.3117 15.1151 25.9199 15.3453L16.0878 21.1211C15.8741 21.2466 15.7223 21.3827 15.6326 21.5291C15.5428 21.6756 15.4986 21.8326 15.5 22Z'
        fill='black'
      />
    </svg>
  )
  const arrowEnd = (
    <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' width='44' height='44' rx='22' fill='white' />
      <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' stroke='#202639' />
      <path
        d='M29.5 22C29.5 22.1674 29.4465 22.3298 29.3397 22.4872C29.2328 22.6445 29.0903 22.7751 28.9122 22.8789L19.0801 28.6547C18.6883 28.8849 18.1896 29 17.584 29C16.9784 29 16.4796 28.8849 16.0878 28.6547C15.6959 28.4245 15.5 28.1315 15.5 27.7758C15.5 27.42 15.6959 27.1271 16.0878 26.8969L24.4236 22L16.0878 17.1031C15.6959 16.8729 15.5 16.58 15.5 16.2242C15.5 15.8685 15.6959 15.5755 16.0878 15.3453C16.4796 15.1151 16.9784 15 17.584 15C18.1896 15 18.6883 15.1151 19.0801 15.3453L28.9122 21.1211C29.1259 21.2466 29.2777 21.3827 29.3674 21.5291C29.4572 21.6756 29.5014 21.8326 29.5 22Z'
        fill='#202639'
      />
    </svg>
  )
  return (
    <div className='section__navigation navigation'>
      <div className='navigation__arrow navigation__arrow_prev'>{arrowStart}</div>
      <div className='navigation__pagination pagination'>
        <div className='pagination__list'>
          <div className='pagination__item pagination__item_active'>
            <span className='pagination__text'>1</span>
          </div>
          <div className='pagination__item'>
            <span className='pagination__text'>2</span>
          </div>
          <div className='pagination__item'>
            <span className='pagination__text'>3</span>
          </div>
          <div className='pagination__item'>
            <span className='pagination__text'>4</span>
          </div>
          <div className='pagination__item'>
            <span className='pagination__text'>5</span>
          </div>
        </div>
      </div>
      <div className='navigation__arrow navigation__arrow_next'>{arrowEnd}</div>
    </div>
  )
}
