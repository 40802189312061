import { useHistory } from 'react-router-dom'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './ClientsSection.module.scss'
import boxesImg01 from './images/clients__img01.png'
import boxesImg02 from './images/clients__img02.png'
import boxesImg03 from './images/clients__img03.png'
import boxesImg04 from './images/clients__img04.png'
import boxesImg05 from './images/clients__img05.png'
import i18n from 'i18next'
import { useAuth } from 'auth/auth'
import React, { useState } from 'react'
export default function boxesSection() {
  let auth = useAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const redirectToRegister = () => {
    if (auth.token) {
      history.push(`/${i18n.language}/convert`)
    }else{
      history.push(`/${i18n.language}/register`)
    }
  }
  return (
    <section className={cssClass(stylesGlobal.layout__section)}>
      <div className={cssClass(stylesGlobal.section__header)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(stylesGlobal.wysiwyg, stylesGlobal.textCenter)}>
            <h1>{t('ClientTitle')}</h1>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__main)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(styles.clients)}>
            <div className={cssClass(stylesGlobal.boxes)}>
              <div className={cssClass(stylesGlobal.boxes__list)}>
                <div className={cssClass(stylesGlobal.row)}>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__media, stylesGlobal.boxes__media_second)}>
                        <img src={boxesImg01} alt='image description' />
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle1')}</h2>
                          <p>{t('ClientText1')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__media, stylesGlobal.boxes__media_second)}>
                        <img src={boxesImg02} alt='image description' />
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle2')}</h2>
                          <p>{t('ClientText2')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__media, stylesGlobal.boxes__media_second)}>
                        <img src={boxesImg03} alt='image description' />
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle3')}</h2>
                          <p>{t('ClientText3')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__media, stylesGlobal.boxes__media_second)}>
                        <img src={boxesImg04} alt='image description' />
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle4')}</h2>
                          <p>{t('ClientText4')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__media, stylesGlobal.boxes__media_second)}>
                        <img src={boxesImg05} alt='image description' />
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle5')}</h2>
                          <p>{t('ClientText5')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cssClass(
                      stylesGlobal.col,
                      stylesGlobal.col_6,
                      stylesGlobal.col_desktop_4,
                      stylesGlobal.col_tab_6,
                      stylesGlobal.col_mob_12,
                    )}
                  >
                    <div className={cssClass(stylesGlobal.boxes__item)}>
                      <div className={cssClass(stylesGlobal.boxes__main)}>
                        <div className={cssClass(stylesGlobal.wysiwyg)}>
                          <h2>{t('ClientSubtitle6')}</h2>
                          <p>{t('ClientText6')}</p>
                        </div>
                      </div>
                      <div className={cssClass(stylesGlobal.boxes__footer)}>
                        <span className={cssClass(stylesGlobal.btn)} onClick={redirectToRegister}>
                          <span className={cssClass(stylesGlobal.btn__text)}>{t('ClientButton')}</span>
                          <svg
                            className={cssClass(stylesGlobal.btn__ico)}
                            xmlns='http://www.w3.org/2000/svg'
                            width='25'
                            height='25'
                            viewBox='0 0 25 25'
                            fill='none'
                          >
                            <path
                              d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
