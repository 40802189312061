import React, { Fragment, useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import api from 'api'
import SupportModal from '../SupportModal/SupportModal'
import HelpdeskTicketStatus from 'data/HelpdeskTicketStatus'
import { cssClass, getKeyByValue } from 'helpers/helper'
import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './Support.module.scss'
import Modal from 'baseComponents/Modal/Modal'

export default function Support() {
  const { t } = useTranslation()
  const [modalCreateOpened, setModalCreateOpened] = useState(false)
  const [tickets, setTickets] = useState(null)

  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    api.fetchHelpdeskTickets().then((data) => {
      if (ignore) return
      if (modalCreateOpened) return // update tickets when modal gets closed
      console.log('fetched tickets', data)
      setTickets(data)
    })
    return () => {
      ignore = true
    }
  }, [modalCreateOpened])

  return (
    <section className={styles.wrapper}>
      <button
        onClick={() => setModalCreateOpened(true)}
        className={cssClass(baseComponents.button, styles.button)}
      >
        {t('Create')}
      </button>
      <div className={styles.table}>
        <div className={styles.titleBg}></div>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>{t('ApplicationSubject')}</h3>
        <h3 className={cssClass(styles.cell, styles.titleCell, styles.phoneVHidden)}>
          {t('LastMessage')}
        </h3>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>{t('Date')}</h3>
        <h3 className={cssClass(styles.cell, styles.titleCell)}>{t('Status')}</h3>
        {tickets &&
          tickets.map((ticket) => {
            const statusKey = getKeyByValue(HelpdeskTicketStatus, ticket.status)
            return (
              <Fragment key={ticket.id}>
                <span className={styles.cell}>{ticket.header}</span>
                <span className={cssClass(styles.cell, styles.phoneVHidden)}>
                  {ticket.lastMessage}
                </span>
                <span className={styles.cell}>
                  {format(new Date(ticket.created), 'dd.MM.yyyy')}
                  <br />
                  {format(new Date(ticket.created), 'HH:mm')}
                </span>
                <span className={cssClass(styles.cell, styles.cellStatus)}>{t(statusKey)}</span>
                <div className={styles.line}></div>
              </Fragment>
            )
          })}
      </div>
      <Modal opened={modalCreateOpened} setOpened={setModalCreateOpened} blockStyle={'modal794px'}>
        <SupportModal setOpened={setModalCreateOpened} />
      </Modal>
    </section>
  )
}
