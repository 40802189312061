import Main from 'pages/common/components/Main/Main'
import { NewsLine } from 'baseComponents/Line/Line'
import { NewsItem } from '../components/NewsItem/NewsItem'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'

import newsImage1 from './img/newsImage1.png'
import newsImage2 from './img/newsImage2.png'
import newsImage3 from './img/newsImage3.png'
import twitterIcon from './img/twitterIcon.svg'
import instagramIcon from './img/instagramIcon.svg'
import facebookIcon from './img/facebookIcon.svg'

import pages from '../../pages.module.scss'
import onenews from './OneNewsPage.module.scss'
import BaseComponents from '../../../baseComponents/BaseComponents.module.scss'
import api from '../../../api'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import moment from 'moment'

export default function OneNewsPage() {
  const [oneNews, setOneNews] = useState({})
  const [newsData, setNewsData] = useState([])
  const params = useParams()

  useEffect(() => {
    api.fetchNewsItem(params.id).then((data) => {
      console.log(data)
      setOneNews(data)
    })
  }, [params])

  useEffect(() => {
    // load other news to show on the side
    api.fetchNews().then((data) => {
      console.log(params.id)
      console.log(data.filter((item) => item.id !== Number(params.id)))
      setNewsData(data.filter((item) => item.id !== Number(params.id)))
    })
  }, [])

  const { t } = useTranslation()
  return (
    <Main>
      <div className={pages.flow}>
        <section className={onenews.wrapper}>
          <div className={onenews.mainNews}>
            <Article data={oneNews} />
          </div>
          <div className={onenews.sideNews}>
            <Side data={newsData} />
            <button className={cssClass(BaseComponents.button, onenews.loadButton)}>{t('SeeMore')}</button>
          </div>
        </section>
      </div>
    </Main>
  )
}

const Article = ({ data }) => {
  const { t } = useTranslation()
  const { dateCreated, author, title, body, imageUrl } = data
  const date = moment(dateCreated).format('DD-MM-YYYY')
  return (
    <>
      <div className={onenews.dateAuthorWrapper}>
        <p className={onenews.aboveLine}>{date}</p>
        <p className={onenews.aboveLine}>{author || ''}</p>
      </div>
      <NewsLine />
      <h1 className={pages.title}>{title}</h1>
      {/*<Text data={content.beforeImage} />*/}
      <img className={onenews.image} src={`${api.ApiUrl}/api${imageUrl?.slice(5)}`} alt={title} />
      <Text data={body} />
    </>
  )
}

const Side = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <p className={cssClass(onenews.aboveLine, onenews.alignRight)}>{t('SeeAlso')}</p>
      <NewsLine />
      <div className={onenews.sideNewsWrapper}>
        {data.slice(0, 3).map((item, index) => (
          <NewsItemWrapper item={item} key={index} />
        ))}
      </div>
    </>
  )
}

const Text = ({ data }) => {
  const createMarkup = () => {
    return { __html: data }
  }
  return (
    <>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </>
  )
}

const NewsItemWrapper = ({ item }) => {
  return (
    <div className={onenews.newsWrapper}>
      <NewsItem data={item} />
    </div>
  )
}
