import React from 'react'
import Main from 'pages/common/components/Main/Main'
import { useTranslation } from 'react-i18next'
import news__img01 from './img/news__img01.jpg'
import Navigation from '../common/components/Navigation/Navigation'
import BreadCrumb from '../common/components/BreadCrumb/BreadCrumb'

export default function TraningDetailPage() {
  const { t } = useTranslation()
  return (
    <div className='layout'>
      <Main>
        <BreadCrumb
          navList={[
            { link: '#', text: t('Home') },
            { link: '#', text: t('Memes') },
            { link: '', text: t('Обмен USDT на наличные доллары в Стамбуле.') },
          ]}
        />
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__news news'>
                <div className='news__article article'>
                  <div className='article__header'>
                    <div className='row'>
                      <div className='col col_6 col_mob-12'>
                        <div className='article__media'>
                          <img src={news__img01} alt='image description' />
                        </div>
                      </div>
                      <div className='col col_6 col_mob-12'>
                        <div className='article__title'>
                          <div className='wysiwyg'>
                            <h1>Обмен USDT на наличные доллары в Стамбуле.</h1>
                          </div>
                        </div>
                      </div>
                      <div className='col col_12'>
                        <div className='row'>
                          <div className='col col_6 col_mob-12'>
                            <div className='article__tags tags'>
                              <div className='tags__list'>
                                <div className='tags__item'>
                                  <span className='tags__text' style={{ backgroundColor: '#202639', color: '#ffffff' }}>
                                    Memes
                                  </span>
                                </div>
                                <div className='tags__item'>
                                  <span className='tags__text' style={{ backgroundColor: '#202639', color: '#ffffff' }}>
                                    Memes
                                  </span>
                                </div>
                                <div className='tags__item'>
                                  <span className='tags__text' style={{ backgroundColor: '#202639', color: '#ffffff' }}>
                                    Memes
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col col_6 col_mob-12'>
                            <div className='article__date date'>
                              <span className='date__text'>09 {t('August')} 2023</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='article__main'>
                    <div className='wysiwyg'>
                      <p>
                        Одной из самых сложных задач инвестирования в криптовалюты является необходимость оценки
                        фактической ценности и полезности этих криптовалют. По мере насыщения криптовалютного рынка
                        мем-коинами, которые часто не имеют реальной стоимости и являются крайне спекулятивными,
                        инвесторам стало ещё сложнее определить, в какие криптовалюты стоит инвестировать.
                      </p>
                      <p>
                        В какие мем-коины лучше всего инвестировать? Какие следующие мем-коины станут популярными? В
                        этой статье мы рассмотрим десять самых популярных мем-коинов на криптовалютном рынке.
                      </p>
                      <p>Ключевые выводы:</p>
                      <ul>
                        <li>
                          Мем-коины — это криптовалюты без собственной ценности или полезности. Они часто основаны на
                          интернет-мемах — изображениях из социальных сетей, которые могут носить юмористический
                          характер.
                        </li>
                        <li>
                          Мем-коины очень спекулятивны и не имеют внутренней стоимости. Таким образом, с этими
                          криптовалютами связано множество рисков, особенно для инвесторов.
                        </li>
                      </ul>
                      <h1>Что такое мем-коины?</h1>
                      <p>
                        Мем-коины — это криптовалюты без собственной ценности или полезности. В большинстве случаев эти
                        шиткойны основаны на интернет-мемах — изображениях из социальных сетей, которые могут носить
                        юмористический характер.
                      </p>
                      <p>
                        В основном мем-коины отличаются от криптовалют тем, что в них нет никакой полезности.
                        Криптовалюты, такие как биткоин (BTC), были созданы для решения проблем, связанных с
                        централизацией, и используются для осуществления одноранговых (P2P) платежей. С другой стороны,
                        Ethereum (ETH) — это платформа, которая позволяет разработчикам создавать серию
                        децентрализованных приложений (DApp) и процветает благодаря росту созданных на её основе
                        DeFi-приложений.
                      </p>
                      <p>
                        Мем-коины обычно привлекают к себе внимание на короткий период времени из-за новостей и акций
                        лидеров общественного мнения. Например, Dogecoin (DOGE) в 2021 году вырос в цене на 12 000%, что
                        в основном обусловлено постоянными твитами Илона Маска. Это привело к сезону мем-коинов в 2021
                        году, в котором другие известные мем-коины, такие как Shiba Inu (SHIB), также значительно
                        выросли в цене. Однако всего через несколько месяцев цена резко упала. Всего несколько месяцев
                        назад DOGE снова резко вырос в цене, когда Илон Маск сменил логотип Twitter на логотип DOGE.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='news__list'>
                  <div className='row'>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='section__footer'>
            <div className='container'>
              <Navigation />
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
