import React from 'react'

const TokenInput = (props) => {
  const defaultValue = props.defaultValue || null
  const handleFocus = (e) => {
    if (e.target.value === defaultValue) {
      props.inputCallback('')
    }
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  const handleBlur = (e) => {
    if (defaultValue && e.target.value === '') {
      props.inputCallback(defaultValue)
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  const handleChange = (e) => {
    let { value } = e.target
    value = replaceSymbols(value, props.replaceSymbols)
    const validationPattern = new RegExp(props.validationPattern)
    if (validationPattern.test(value) || value === '') {
      props.inputCallback(value)
    }
  }
  const inputClasses = ['scope__input', ...(Array.isArray(props.addClasses) ? props.addClasses : [])]
    .filter(Boolean)
    .join(' ')

  const inputStyle = {
    ...(props.isValid === false ? invalidInputStyle : {}),
    ...props.style,
  }

  return (
    <React.Fragment>
      <input
        className={inputClasses}
        style={inputStyle}
        type={`${props.type ? props.type : 'text'}`}
        pattern={props.pattern}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </React.Fragment>
  )
}
const invalidInputStyle = {
  borderBottom: '1px solid red',
  boxShadow: '0 1px 0 0 red',
}

function replaceSymbols(value, replacements) {
  if (Array.isArray(replacements)) {
    replacements.forEach((replacement) => {
      value = value.replace(replacement.from, replacement.to)
    })
  }
  return value
}

export default TokenInput
