import React from 'react'
import { NavLink } from 'react-router-dom'
export default function BreadCrumb(props) {
  return (
    <div className='layout__breadcrumb breadcrumb'>
      <div className='container'>
        <div className='breadcrumb__list'>
          {props.navList.map((nav, index) => (
            <div className='breadcrumb__item' key={index}>
              {nav.link ? (
                <NavLink className='breadcrumb__text' to={nav.link}>
                  {nav.text}
                </NavLink>
              ) : (
                <span className='breadcrumb__text'>{nav.text}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
