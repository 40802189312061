import React, { useState, useEffect } from 'react'
import Button from '../../../components/form/Button/Button'

import VerificationIcons from './VerificationIcons'

import VerificationAdvantages from './VerificationAdvantages'

import {
  BenefitsOfBasicVerification,
  BenefitsOfAdvancedVerification,
  onPassVerificationClick,
} from './BenefitsOfVerification'
import {
  GetPersonalInfoStyles,
  GetInfoBasicStyles,
  GetInfoAdvancedStyles,
  IsHideBasicVerificationButton,
  IsHideAdvancedVerificationButton,
  IsAdvancedVerificationSuccess,
  IsBasicVerificationSuccess,
  USER_VERIFICATION_STAGE,
  IsHideBasicVerificationAdvantages,
  IsHideAdvancedVerificationAdvantages,
} from './VerificationData'
import TimeToSendForVerification from './TimeToSendForVerification'
import RejectionReason from './RejectionReason'

export default function VerificationProcess({
  t,
  status,
  verificationStage,
  dateTimeSendVerification: dateFilled,
  onPassVerificationClick,
  statusMessage,
}) {
  const [personalInfoStyle, setPersonalInfoStyle] = useState({ text: '', className: 'btn' })
  const [infoBasicStyle, setInfoBasicStyle] = useState({ text: '', className: 'btn' })
  const [infoAdvancedStyle, setInfoAdvancedStyle] = useState({ text: '', className: 'btn' })

  useEffect(() => {
    setPersonalInfoStyle(GetPersonalInfoStyles(status, verificationStage))
    setInfoBasicStyle(GetInfoBasicStyles(status, verificationStage))
    setInfoAdvancedStyle(GetInfoAdvancedStyles(status, verificationStage))
  }, [status])

  return (
    <React.Fragment>
      <div className='verification__list'>
        <div className='verification__item'>
          <div className='verification__header'>
            <div className='verification__title'>
              <span className='verification__text h2'>{t('PersonalVerification')}</span>
            </div>
            <div className='verification__action'>
              <div className={personalInfoStyle?.className}>{t(personalInfoStyle?.text)}</div>
            </div>
          </div>
        </div>
        <div className='verification__item'>
          <div className='verification__header'>
            <div className='verification__title'>
              <span className='verification__text h2'>{t('BasicVerification')} </span>
              {IsBasicVerificationSuccess(status, verificationStage) && <VerificationIcons name={'ico04'} />}
            </div>
            <div className='verification__action'>
              <Button
                isHidden={IsHideBasicVerificationButton(status, verificationStage)}
                buttonCallback={() => onPassVerificationClick(USER_VERIFICATION_STAGE.BasicVerification)}
                addClasses={['btn']}
                text={t('PassVerification')}
              />
              {infoBasicStyle && <div className={infoBasicStyle.className}>{t(infoBasicStyle.text)}</div>}{' '}
            </div>
          </div>
          <div className='verification__main'>
            <div className='verification__data'>
              <VerificationAdvantages
                t={t}
                requiredStage={USER_VERIFICATION_STAGE.BasicVerification}
                isHide={IsHideBasicVerificationAdvantages(status, verificationStage)}
              />
              <TimeToSendForVerification
                t={t}
                dateFilled={dateFilled}
                status={status}
                stage={verificationStage}
                requiredStage={USER_VERIFICATION_STAGE.BasicVerification}
              />
              <RejectionReason
                t={t}
                rejectionReasonMessage={statusMessage}
                stage={verificationStage}
                requiredStage={USER_VERIFICATION_STAGE.BasicVerification}
              />
            </div>
            <div className='verification__box box'>
              <div className='box__list'>
                <BenefitsOfBasicVerification t={t} currencyType={'Fiat'} />
                <BenefitsOfBasicVerification t={t} currencyType={'Cryptocurrency'} />
              </div>
            </div>
          </div>
        </div>
        <div className='verification__item'>
          <div className='verification__header'>
            <div className='verification__title'>
              <span className='verification__text h2'>{t('AdvancedVerification')}</span>
              {IsAdvancedVerificationSuccess(status, verificationStage) && <VerificationIcons name={'ico04'} />}
            </div>
            <div className='verification__action'>
              <Button
                isHidden={IsHideAdvancedVerificationButton(status, verificationStage)}
                buttonCallback={() => onPassVerificationClick(USER_VERIFICATION_STAGE.AdvancedVerification)}
                addClasses={['btn']}
                text={t('PassVerification')}
              />
              {infoAdvancedStyle && <div className={infoAdvancedStyle.className}>{t(infoAdvancedStyle.text)}</div>}
            </div>
          </div>
          <div className='verification__main'>
            <RejectionReason
              t={t}
              rejectionReasonMessage={statusMessage}
              stage={verificationStage}
              requiredStage={USER_VERIFICATION_STAGE.AdvancedVerification}
            />
            <div className='verification__data'>
              <VerificationAdvantages
                t={t}
                requiredStage={USER_VERIFICATION_STAGE.AdvancedVerification}
                isHide={IsHideAdvancedVerificationAdvantages(status, verificationStage)}
              />
              <TimeToSendForVerification
                t={t}
                dateFilled={dateFilled}
                status={status}
                stage={verificationStage}
                requiredStage={USER_VERIFICATION_STAGE.AdvancedVerification}
              />
            </div>
            <BenefitsOfAdvancedVerification t={t} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
