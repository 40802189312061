import { Trans, useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'
import { DEAL_CANCEL_DISPUTE_STATUS } from '../Progress/ProgressData'
import { useAuth } from 'auth/auth'
const signalR = require('@microsoft/signalr')
import api from 'api'
import { DealSellerDisputeTimer, CalculateRefreshTime } from './SellerDealCancelDisputeTimer'

export default function SellerDealCancelDisputeModal() {
  const { t } = useTranslation()
  const [ignore, setIgnore] = useState(false)

  const auth = useAuth()
  const [signalRTimerId, setSignalRTimerId] = useState(null)

  const lifetimeDealDisputeMins = 10
  const [dealId, setDealId] = useState(null)
  const [currentDispute, setCurrentDispute] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  const fetchP2PDealCancelDisputes = (isLastDispute = false) => {
    api
      .fetchP2PDealCancelDisputes(DEAL_CANCEL_DISPUTE_STATUS.CreatorDeniesFault)
      .then((data) => {
        if (ignore || currentDispute) return
        if (!data || !Array.isArray(data) || data.length == 0) return
        const sortedByDate = data.sort((a, b) => new Date(a.DateCreated) - new Date(b.DateCreated))
        const dispute = isLastDispute ? sortedByDate[sortedByDate.length - 1] : sortedByDate[0]
        setCurrentDispute(dispute)
        setDealId(dispute.dealId)
      })
      .catch((err) => {
        if (ignore) return
        console.error('Error fetchP2PDealCancelDisputes ', err)
      })
  }

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/p2p`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()

    hubConnection
      .start()
      .then(() => {
        hubConnection.on('P2PSellerDealCancelDispute', (dealId) => {
          if (!location.pathname.toLowerCase().includes('/p2p')) {
            disconnectSignalR() // signalR has stuck, disconnect
            console.log('P2PSellerDealCancelDispute SignalR has stuck, disconnect')
            return
          }

          if (auth.isSignedIn) {
            console.log('signalR update')
            fetchP2PDealCancelDisputes(true)
          }
        })
        hubConnection.on('P2PChat', () => {})
        hubConnection.on('P2PDeal', () => {})

        const keepAlivePeriod = 60 * 1000 // 1 minute
        const timerId = setInterval(function () {
          hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
        }, keepAlivePeriod)
        setSignalRTimerId(timerId)
      })
      .catch((err) => {
        console.log('configureSignalR error', err)
      })
  }
  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
  }
  const handleUserSelection = (status) => {
    api
      .finishP2PDealCancelDispute(dealId, status)
      .then((data) => {
        setCurrentDispute(null)
        fetchP2PDealCancelDisputes(true)
      })
      .catch((err) => {
        console.log('Error finishP2PDealCancelDispute ' + err)
      })
  }

  const getNewDealDisputeByTimer = () => {
    setIsShowModal(false)
    setCurrentDispute(null)
    fetchP2PDealCancelDisputes(true)
  }

  useEffect(() => {
    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
    }
  }, [])

  useEffect(() => {
    fetchP2PDealCancelDisputes()
  }, [])

  useEffect(() => {
    if (!currentDispute || !currentDispute?.dateCreated) return

    const timeLeft = CalculateRefreshTime(currentDispute.dateCreated, lifetimeDealDisputeMins)
    let intervalId
    if (timeLeft <= 0) {
      getNewDealDisputeByTimer()
    } else {
      intervalId = setInterval(getNewDealDisputeByTimer, timeLeft)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
      setIgnore(true)
    }
  }, [currentDispute?.dateCreated])

  useEffect(() => {
    if (!currentDispute) {
      setIsShowModal(false)
      return
    }

    setIsShowModal(true)
    return () => {
      setIgnore(true)
    }
  }, [currentDispute])

  return (
    <Modal opened={isShowModal} blockStyle={'modal640'} modalCallback={setIsShowModal}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('P2PDealDisputePopupTitle')}</h2>
        </div>
        <div>{t('P2PDealDisputePopupDescription1BuyerHasCanceledThe')}</div>
        <br />
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='formular'>
            <div className='form-item w-100'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <div>
                    <strong>{t(currentDispute?.cause)}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {t('P2PDealDisputePopupDescription2BuyersCompletionRates')}
          <div>
            <Trans i18nKey='P2PDealDisputePopupDescription3OrderExpiresIn'>
              <DealSellerDisputeTimer
                initialMinutes={lifetimeDealDisputeMins}
                dateCreated={currentDispute?.dateCreated}
              />
              .
            </Trans>
          </div>
        </div>
        <br />
        <div className='modal__footer'>
          <div className='formular'>
            <div className='row'>
              <div className='col col_6'>
                <div className='form-item'>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button
                        className='btn w-100'
                        onClick={() => handleUserSelection(DEAL_CANCEL_DISPUTE_STATUS.RespondentAdmitsFault)}
                      >
                        <span className='btn__text'>{t('Accept')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col col_6'>
                <div className='form-item'>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button
                        className='btn btn_revert w-100'
                        onClick={() => handleUserSelection(DEAL_CANCEL_DISPUTE_STATUS.RespondentDeniesFault)}
                      >
                        <span className='btn__text'>{t('Reject')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
