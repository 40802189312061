import React, { useContext, createContext, useState } from 'react'
import axios from 'axios'
import api from './api'

const TOKEN_NAME = 'token'

const setTokenHeaders = (token) => {
  const headers = axios.defaults.headers
  const { token_type, access_token } = token
  headers.common['Authorization'] = `${token_type} ${access_token}`
  headers.common['jsapi'] = true
}

function useProvideAuth() {
  const existingToken = JSON.parse(localStorage.getItem(TOKEN_NAME)) || null
  if (existingToken) setTokenHeaders(existingToken)

  const [token, setToken] = useState(existingToken)

  const signin = (username, password, cb) => {
    api.signIn(username, password).then((response) => {
      setToken(response)
      localStorage.setItem(TOKEN_NAME, JSON.stringify(response))
      console.log(` --- signin ${response}`)
      setTokenHeaders(response)
      cb()
    })
  }

  const signout = (cb) => {
    setToken(null)
    console.log(' --- signout')
    localStorage.removeItem(TOKEN_NAME)
    const headers = axios.defaults.headers
    delete headers.common['jsapi']
    delete headers.common['Authorization']
    if (typeof cb === 'function') cb()
  }

  return {
    token,
    isSignedIn: token != null,
    signin,
    signout,
  }
}

/* https://usehooks.com/useAuth/ */
const authContext = createContext()

function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useAuth() {
  return useContext(authContext)
}

export { ProvideAuth, useAuth }
