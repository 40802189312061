import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { Stage1 } from './Stages/Stage1'
import { Stage2 } from './Stages/Stage2'
import { Stage3 } from './Stages/Stage3'
import React, { useEffect, useState } from 'react'
import api from '../../../api'
import ProgressSteps from './Stages/ProgressSteps'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { InitializeStageData, MapToCreateOrderModel } from './InitializeStageData'
import { toast } from 'react-toastify'
import { GetOpenOrderLimitKey, NextStageChecker, PostingStage } from './PostingData'
import NavigationButtons from './NavigationButtons'
import OrderPublishConfirmationModal from './Modals/OrderPublishConfirmationModal'
import PostingLimitsToastMessages from './PostingLimitsToastMessages'

export default function Posting() {
  const { t } = useTranslation()
  const history = useHistory()
  const { orderId } = useParams()
  const location = useLocation()
  const editOrder = location?.state?.order || null

  const [stageData, setStageData] = useState(null)

  const [nextButtonStageDisabled, setNextButtonStageDisabled] = useState({
    next: true,
    posting: true,
    limits: true,
    stage1: true,
    stage2: true,
    stage3: true,
  })
  const [isOrderConfirmationModalOpen, setIsOrderConfirmationModalOpen] = useState(false)

  const [allBalances, setAllBalances] = useState([])

  const functionBack = () => {
    handleDataFromStages({ currentStageIndex: stageData.currentStageIndex - 1 })
  }
  const functionNext = () => {
    handleDataFromStages({ currentStageIndex: stageData.currentStageIndex + 1 })
  }

  useEffect(() => {
    if (editOrder) {
      setStageData(InitializeStageData(editOrder, t))
    } else {
      setStageData(InitializeStageData(null, t))
    }
  }, [orderId, editOrder])

  const handleDataFromStages = (data) => {
    setStageData((prevStageData) => ({
      ...prevStageData,
      ...data,
    }))
  }
  const handleCreateOrder = async () => {
    handleNextButtonDisabled('limits', true)
    const orderData = MapToCreateOrderModel(stageData)
    try {
      editOrder ? await api.cloneP2POrder(editOrder.id, orderData) : await api.createP2POrder(orderData)
      toast.success(t('ActionWasSuccessful'))
      handleDataFromStages({
        userSelectedTradeRate: 0,
        amountOfSelectedExchangeAsset: 0,
        amountOfSelectedOtcAsset: 0,
        selectedPaymentRequisites: [],
        currentStageIndex: PostingStage.STAGE_1_TYPE_AND_PRICE,
      })
      history.push(`/${i18n.language}/p2p/posting/`)
    } catch (error) {
      toast.error(t('ErrorTryLater'))
      handleDataFromStages({ currentStageIndex: PostingStage.STAGE_3_ADDITIONAL_CONDITIONS })
    } finally {
      handleNextButtonDisabled('limits', false)
    }
  }
  const handleNextButtonDisabled = (key, value) => {
    setNextButtonStageDisabled((prev) => {
      if (prev[key] !== value) {
        return { ...prev, [key]: value }
      }
      return prev
    })
  }

  const handleSetSelectedBalances = () => {
    if (!allBalances || allBalances.length == 0) {
      return
    }

    setStageData((prevStageData) => {
      const selectedExchangeAsset = prevStageData.selectedExchangeAsset

      const selectedCryptoBalance = allBalances.balances.find(
        (balance) => balance.currencyCode === selectedExchangeAsset,
      )
      return {
        ...prevStageData,
        balanceOfSelectedExchangeAsset: selectedCryptoBalance ? selectedCryptoBalance.amount : 0,
      }
    })
  }

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencyData = await api.fetchP2PaymentCurrencies()
        setStageData((prevStageData) => ({
          ...prevStageData,
          otcAssetList: currencyData.map((asset) => asset.currencyCode),
        }))
      } catch (error) {
        console.error('Error loading currencies:', error)
      }
    }
    fetchCurrencies()
  }, [])

  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const balanceData = await api.fetchBalance()
        setAllBalances(balanceData)
      } catch (error) {
        console.error('Error fetching balance:', error)
      }
    }
    fetchBalances()
  }, [])

  useEffect(() => {
    handleSetSelectedBalances()
  }, [stageData?.selectedExchangeAsset, stageData?.selectedOtcAsset, allBalances])

  useEffect(() => {
    if (!stageData) return

    if (stageData.currentStageIndex < PostingStage.STAGE_1_TYPE_AND_PRICE) {
      setStageData.currentStageIndex = PostingStage.STAGE_1_TYPE_AND_PRICE
    }
    if (stageData.currentStageIndex > PostingStage.STAGE_4_CONFIRM) {
      setStageData.currentStageIndex = PostingStage.STAGE_3_ADDITIONAL_CONDITIONS
    }
  }, [stageData?.currentStageIndex])

  useEffect(() => {
    if (!stageData) return
    const isDisabled = NextStageChecker(stageData?.currentStageIndex, nextButtonStageDisabled)
    handleNextButtonDisabled('next', isDisabled)
  }, [nextButtonStageDisabled, stageData?.currentStageIndex])

  if (!stageData) return null
  return (
    <React.Fragment>
      <div className='section'>
        <div className='section__main'>
          <div className='container'>
            <div className=' steps'>
              <div className='steps__main'>
                <ProgressSteps t={t} currentStageIndex={stageData.currentStageIndex} />
                {stageData.currentStageIndex === PostingStage.STAGE_1_TYPE_AND_PRICE && (
                  <Stage1
                    onStage1DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage1', value)}
                    stageData={stageData}
                  />
                )}
                {stageData.currentStageIndex === PostingStage.STAGE_2_PAYMENT_REQUISITES && (
                  <Stage2
                    onStage2DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage2', value)}
                    stageData={stageData}
                  />
                )}
                {stageData.currentStageIndex === PostingStage.STAGE_3_ADDITIONAL_CONDITIONS && (
                  <Stage3
                    onStage3DataSubmit={handleDataFromStages}
                    onButtonNextDisabled={(value) => handleNextButtonDisabled('stage3', value)}
                    stageData={stageData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <NavigationButtons
          t={t}
          onBackStage={functionBack}
          onNextStage={functionNext}
          showBackButton={true}
          showNextButton={stageData.currentStageIndex !== PostingStage.STAGE_3_ADDITIONAL_CONDITIONS}
          showSendButton={stageData.currentStageIndex === PostingStage.STAGE_3_ADDITIONAL_CONDITIONS}
          isBackButtonDisabled={stageData.currentStageIndex === 0}
          isNextButtonDisabled={nextButtonStageDisabled.next}
          isSendButtonDisabled={nextButtonStageDisabled.next}
          onSend={() => {
            setIsOrderConfirmationModalOpen(true)
          }}
        />
      </div>
      <OrderPublishConfirmationModal
        opened={isOrderConfirmationModalOpen}
        setOpened={setIsOrderConfirmationModalOpen}
        data={stageData}
        onOrderPublishConfirmed={() => {
          setIsOrderConfirmationModalOpen(false)
          handleCreateOrder()
        }}
      />
      <PostingLimitsToastMessages
        t={t}
        onButtonNextDisabledLimits={(value) => handleNextButtonDisabled('limits', value)}
        stageData={stageData}
        editOrder={editOrder}
      />
    </React.Fragment>
  )
}
