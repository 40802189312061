import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'
import { useAuth } from 'auth/auth'

import Main from 'pages/common/components/Main/Main'
import Sidebar from 'baseComponents/Sidebar/Sidebar'
import Account from './components/Account/Account'

import styles from '../pages.module.scss'
import baseComponents from '../../baseComponents/BaseComponents.module.scss'
import { NotificationLogout } from 'pages/common/components/Notifications/Notifications'


export default function AccountPage() {
  const history = useHistory()
  const auth = useAuth()

  const { t } = useTranslation()
  const [notificationLogoutVisible, setNotificationVisible] = useState(false)

  return (
    <Main>
      <NotificationLogout
        isVisible={notificationLogoutVisible}
        setIsVisible={setNotificationVisible}
        onConfirmed={() => {
          auth.signout()
          history.push('/') // redirect to landing page
        }}
      />
      <div className={styles.flow}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{t('PersonalCabinet')}</h1>
          <button
            className={cssClass(
              baseComponents.button,
              baseComponents.buttonActive,
              baseComponents.buttonLogout,
            )}
            onClick={() => setNotificationVisible(true)}
          >
            {t('SignOut')}
          </button>
        </div>

        <div className={styles.wrapper}>
          <Sidebar />
          <div className={styles.content}>
            <Account />
          </div>
        </div>
      </div>
    </Main>
  )
}
