const Button = ({
  buttonCallback,
  addClasses = [],
  disabled = false,
  isHidden = false,
  ico,
  before,
  after,
  text,
  children,
}) => {
  const buttonStyle = isHidden ? { display: 'none' } : {}

  const renderText = () => {
    if (typeof text === 'function') {
      return text()
    }
    return text
  }

  return (
    <button onClick={buttonCallback} className={addClasses.join(' ')} disabled={disabled} style={buttonStyle}>
      {ico && before && <span className='btn__ico'>{ico}</span>}
      {text && <span className='btn__text'>{renderText()}</span>}
      {children}
      {ico && after && <span className='btn__ico'>{ico}</span>}
    </button>
  )
}

export default Button
