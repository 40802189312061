import React from 'react'
import logo from './images/logo.png'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
export default function HeaderLogo() {
  return (
    <div className='header__logo logo'>
      <NavLink to={`/${i18n.language}/`} className='logo__link' exact={true}>
        <img src={logo} alt='image description' />
      </NavLink>
    </div>
  )
}
