import React, { useEffect, useState } from 'react'
import { cssClass } from 'helpers/helper'
import style from '../../style/TokenPage.module.scss'
import TokenChartElement from './TokenChartElement'

export default function ChartInfo({ t, currencyListing, exchangeInstruments, logoImage }) {
  const [currencyPairs, setCurrencyPairs] = useState([]) //[USDT_LTT, ...]
  const [quotedCurrencies, setQuotedCurrencies] = useState([]) //[USDT, ...]
  const [selectedQuotedCurrency, setSelectedQuotedCurrency] = useState('USDT')
  const [selectedCurrencyPairCode, setSelectedCurrencyPairCode] = useState(null) //'USDT_LTT'
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState(null) //{USDT_LTT}

  useEffect(() => {
    if (!currencyListing || !exchangeInstruments) return
    const currencyPairNames = exchangeInstruments.map(({ currencyPair }) => currencyPair.code)
    setCurrencyPairs(currencyPairNames)

    const quotedCurrencies = currencyPairNames.map((currencyPair) => {
      const [base, quote] = currencyPair.split('_')
      return quote !== currencyListing?.currencyCode ? quote : base
    })
    setQuotedCurrencies(quotedCurrencies)
  }, [exchangeInstruments])

  useEffect(() => {
    if (currencyPairs.length === 0) return
    const selectedCurrencyPairName = currencyPairs.find((currencyPair) => currencyPair.includes(selectedQuotedCurrency))

    if (
      !selectedCurrencyPairName ||
      !selectedCurrencyPairName.includes('_') ||
      selectedCurrencyPairName.split('_').length !== 2
    )
      return

    setSelectedCurrencyPairCode(selectedCurrencyPairName)
  }, [currencyPairs, selectedQuotedCurrency])

  useEffect(() => {
    if (!selectedCurrencyPairCode) return

    const selectedInstrument = exchangeInstruments.find((item) => item.currencyPair.code === selectedCurrencyPairCode)
    setSelectedCurrencyPair(selectedInstrument)
  }, [selectedCurrencyPairCode])

  return (
    <div className={cssClass(style.information__section, style.section)}>
      <div className={cssClass(style.information__graphic, style.graphic)}>
        <div className={cssClass(style.graphic__header)}>
          <div className={cssClass(style.graphic__ico)}>
            <img src={logoImage || null} />
          </div>
          <div className={cssClass(style.graphic__title)}>
            <span className={cssClass(style.graphic__text)}>
              {t('TokenRate')} {t(currencyListing?.tokenName)}
            </span>
          </div>
          <div className={cssClass(style.graphic__subtitle)}>
            <span className={cssClass(style.graphic__text)}>({currencyListing?.currencyCode})</span>
          </div>
          <div className={cssClass(style.graphic__sellect)}>
            {quotedCurrencies && (
              <select
                className={cssClass(style.comments__select)}
                onChange={(data) => setSelectedQuotedCurrency(data.target.value)}
                value={selectedQuotedCurrency}
              >
                {quotedCurrencies.map((quotedCurrency) => (
                  <option key={quotedCurrency} value={quotedCurrency}>
                    {quotedCurrency}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className={cssClass(style.graphic__cost, style.cost)}>
          <span className={cssClass(style.cost__title)}>
            ${parseFloat(selectedCurrencyPair?.price?.toFixed(8)) || '---'}
          </span>
          <span className={cssClass(style.cost__subtitle)}>
            {selectedCurrencyPair?.priceChangePercentage24h?.toFixed(2)}%
          </span>
        </div>
        <div className={cssClass(style.graphic__main)}>
          {/* <div className={cssClass(style.graphic__schedule, style.schedule)}>
            <div className={cssClass(style.schedule__calendar, style.calendar)}> */}
          <TokenChartElement t={t} symbol={selectedCurrencyPairCode} />
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}
