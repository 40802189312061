import api from 'api'
import ProgressIcons from '../ProgressIcons'
import React, { useEffect, useState } from 'react'

const ChatHeader = ({ t, deal, messages }) => {
  const { isDealCreator, order } = deal
  const [verificationOpponentName, setVerificationOpponentName] = useState('Username not specified')
  const userNicknameOpponentName = isDealCreator ? order?.userNickname : deal?.userNickname

  async function getUsersInfo(dealId) {
    try {
      const usersInfo = await api.fetchP2PChatUsersInfo(dealId)
      const verificationOpponentName = isDealCreator
        ? usersInfo.orderCreatorVerificationName
        : usersInfo.dealCreatorVerificationName

      if (verificationOpponentName?.trim()) {
        setVerificationOpponentName(verificationOpponentName)
      }
    } catch (error) {
      console.error('Error loading chat users info', error)
    }
  }

  useEffect(() => {
    if (deal) {
      getUsersInfo(deal.id)
    }
  }, [])

  return (
    <div className='chat__header'>
      <div className='chat__avatar avatar'>
        <span className='avatar__text'>{userNicknameOpponentName?.charAt(0)}</span>
      </div>
      <div className='chat__title'>
        <span className='chat__text'>{userNicknameOpponentName}</span>
      </div>
      <div className='chat__subtitle'>
        <span className='chat__text'>Verified: {verificationOpponentName} </span>
      </div>
    </div>
  )
}

export default ChatHeader
