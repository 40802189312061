import React from 'react'
import telegramIco from './images/telegramIco.png'
import watsappIco from './images/watsappIco.png'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function FooterContact() {
  const { t } = useTranslation()
  const contact = [
    {
      type: 'mail',
      link: 'info@blazarex.com',
      text: 'info@blazarex.com',
    },
    {
      type: 'mail',
      link: 'support@blazarex.com',
      text: 'support@blazarex.com',
    },
    {
      type: 'tel',
      link: '905317745823',
      text: '+90 531 774 58 23',
    },
    {
      type: 'socials',
      icons: [
        { link: 'https://t.me/blazarexsupport', ico: telegramIco },
        { link: 'https://wa.me/905317745823', ico: watsappIco },
      ],
    },
  ]
  return (
    <div className='footer__contact contact'>
      <div className='contact__layout'>
        <div className='contact__header'>
          <div className='wysiwyg'>
            <h4>{t('Contacts')}</h4>
          </div>
        </div>
        <div className='contact__main'>
          <div className='contact__list'>
            {contact.map((item, index) => (
              <div
                className={`contact__item ${item.type === 'tel' ? 'contact__item_second' : ''} ${
                  item.type === 'socials' ? 'contact__item_second' : ''
                }`}
                key={index}
              >
                {item.type === 'mail' && (
                  <a className='contact__link' href={`mailto:` + item.link}>
                    {item.text}
                  </a>
                )}
                {item.type === 'tel' && (
                  <a className='contact__link' href={`tel:` + item.link}>
                    {item.text}
                  </a>
                )}
                {item.type === 'socials' && (
                  <div className='contact__socials socials'>
                    <div className='socials__list'>
                      {item.icons.map((social, i) => (
                        <div className='socials__item' key={i}>
                          <a href={social.link} className={'socials__link'} target='_blank'>
                            <img src={social.ico} alt='image description' />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
