import { useHistory } from 'react-router-dom'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './StepsSection.module.scss'
import ConverterSection from '../ConverterSection/ConverterSection'

export default function StepsSection() {
  const { t } = useTranslation()
  return (
    <section className={cssClass(stylesGlobal.layout__section)}>
      <div className={cssClass(stylesGlobal.section__header)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(stylesGlobal.wysiwyg, stylesGlobal.textCenter)}>
            <h1>{t('StepsTitle')}</h1>
          </div>
        </div>
      </div>
      <div className={cssClass(stylesGlobal.section__main)}>
        <div className={cssClass(stylesGlobal.container)}>
          <div className={cssClass(styles.steps)}>
            <div className={cssClass(stylesGlobal.row)}>
              <div className={cssClass(stylesGlobal.col, stylesGlobal.col_7, stylesGlobal.col_desktop_12)}>
                <div className={cssClass(styles.steps__main)}>
                  <div className={cssClass(stylesGlobal.boxes)}>
                    <div className={cssClass(stylesGlobal.boxes__list)}>
                      <div className={cssClass(stylesGlobal.row)}>
                        <div
                          className={cssClass(
                            stylesGlobal.col,
                            stylesGlobal.col_12,
                            stylesGlobal.col_tab_4,
                            stylesGlobal.col_mob_12,
                          )}
                        >
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__header)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('StepsSubtitle1')}</h2>
                              </div>
                              <div className={cssClass(stylesGlobal.boxes__number)}>
                                <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(1)</span>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <p>{t('StepsText1')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cssClass(
                            stylesGlobal.col,
                            stylesGlobal.col_12,
                            stylesGlobal.col_tab_4,
                            stylesGlobal.col_mob_12,
                          )}
                        >
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__header)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('StepsSubtitle2')}</h2>
                              </div>
                              <div className={cssClass(stylesGlobal.boxes__number)}>
                                <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(2)</span>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <p>{t('StepsText2')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cssClass(
                            stylesGlobal.col,
                            stylesGlobal.col_12,
                            stylesGlobal.col_tab_4,
                            stylesGlobal.col_mob_12,
                          )}
                        >
                          <div className={cssClass(stylesGlobal.boxes__item)}>
                            <div className={cssClass(stylesGlobal.boxes__header)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <h2>{t('StepsSubtitle3')}</h2>
                              </div>
                              <div className={cssClass(stylesGlobal.boxes__number)}>
                                <span className={cssClass(stylesGlobal.number__text, stylesGlobal.h2)}>(3)</span>
                              </div>
                            </div>
                            <div className={cssClass(stylesGlobal.boxes__main)}>
                              <div className={cssClass(stylesGlobal.wysiwyg)}>
                                <p>{t('StepsText3')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cssClass(stylesGlobal.col, stylesGlobal.col_5, stylesGlobal.col_desktop_12)}>
                <ConverterSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
