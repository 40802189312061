import React, { useState, useEffect } from 'react'
import Button from 'components/form/Button/Button'
import { DEAL_STATUS } from '../Order/OrderAndDealData'
import { TextMapForBottomActions } from './ProgressData'
import { useHistory } from 'react-router-dom'
import i18n from 'i18next'
import CommonModals from './Modals/CommonsMadals'
import useModalState from './Modals/useModalState'
import { ShouldButtonBeDisabled, ProgressTimer } from './ProgressTimer'

const timeUntilUnlocking = 10

const ProcessBottomActions = ({ onProcessBottomActions, t, deal, isDisabledBtn }) => {
  const { exchangeCurrencyCode, maxTimeMinutes } = deal.order || {}
  const { isDirectionDealBuying, status, dateCreated } = deal || {}
  const { modals, openModal, closeModal } = useModalState()
  const [isSending, setIsSending] = useState(false)
  const [isProcessConfirmationActions, setIsProcessConfirmationActions] = useState(true)
  const [texts, setTexts] = useState([])
  const history = useHistory()

  const [isAppellationButtonDisabled, setIsAppellationButtonDisabled] = useState(true)

  const handleConfirm = (newStatus) => {
    setIsSending(true)
    onAction('updateStatus', newStatus)
  }
  useEffect(() => {
    const updateButtonDisabledState = () => {
      const disabled = ShouldButtonBeDisabled(dateCreated, timeUntilUnlocking)
      setIsAppellationButtonDisabled(disabled)
    }

    updateButtonDisabledState()
    const intervalId = setInterval(updateButtonDisabledState, 1000)

    return () => clearInterval(intervalId)
  }, [dateCreated, timeUntilUnlocking])

  useEffect(() => {
    const dateCreatedDate = new Date(dateCreated)
    const timeUntilUnlockingMs = timeUntilUnlocking * 60 * 1000 // 10 minutes in milliseconds
    const unlockDate = new Date(dateCreatedDate.getTime() + timeUntilUnlockingMs)

    const intervalId = setInterval(() => {
      if (new Date() > unlockDate) {
        setIsAppellationButtonDisabled(false)
        clearInterval(intervalId)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [dateCreated])

  useEffect(() => {
    const direction = isDirectionDealBuying ? 'buying' : 'selling'
    const info = TextMapForBottomActions[direction][status]
    setTexts(info || [])
    setIsSending(false)
  }, [deal])

  useEffect(() => {
    if (texts[0] === '') {
      setIsProcessConfirmationActions(false)
    } else {
      setIsProcessConfirmationActions(true)
    }
    setIsSending(false)
  }, [texts])

  function renderButtonsForBuyingStatus() {
    if (isDirectionDealBuying && status === DEAL_STATUS.CreatedAwaitingPaymentSystem) {
      return (
        <>
          <div className='col col_6'>
            <Button
              buttonCallback={() => openModal('isConfirmSendPaymentModalOpen')}
              addClasses={['btn w-100']}
              text={t('PaidNext')}
              disabled={isSending}
            />
          </div>
          <div className='col col_6'>
            <Button
              buttonCallback={() => openModal('isCancelOrderWarningModalOpen')}
              addClasses={['btn btn_revert w-100']}
              text={t('CancelOrderSecondary')}
              disabled={isSending || isDisabledBtn}
            />
          </div>
        </>
      )
    }
  }

  function renderButtonsForCompletedOrReleasedStatus() {
    if (status === DEAL_STATUS.CryptoReleased || status === DEAL_STATUS.PaymentSystemConfirmed) {
      return (
        <>
          <div className='col col_6'>
            <Button
              buttonCallback={() => history.push(`/${i18n.language}/finance`)}
              addClasses={['btn w-100']}
              text={isDirectionDealBuying ? t('GoToAssets') : t('ViewBalance')}
            />
          </div>
          <div className='col col_6'>
            <Button
              buttonCallback={() => openModal('isNeededHelpModalOpen')}
              addClasses={['btn btn_revert w-100']}
              text={t('HelpIsNeeded')}
            />
          </div>
        </>
      )
    }
  }

  function renderButtonsForPaymentSystemSentStatus() {
    if (!isDirectionDealBuying && status === DEAL_STATUS.PaymentSystemSent) {
      return (
        <div className='row align-center'>
          <div className='col col_4 col_tab-6'>
            <Button
              buttonCallback={() => openModal('isConfirmPaymentModalOpen')}
              addClasses={['btn w-100']}
              text={t('PaymentReceived')}
              disabled={isSending}
            />
          </div>
          <div className='col col_5 col_tab-6'>
            <div className='wysiwyg'>
              <Button
                buttonCallback={() => {
                  openModal('isNeededHelpModalOpen')
                }}
                addClasses={['btn', 'w-100']}
                text={isAppellationButtonDisabled ? t('FileAnAppealVia') : t('SubmitAnAppeal')}
                disabled={isAppellationButtonDisabled || deal.isAppealed || deal.isAppealedAfterFinish}
              >
                {isAppellationButtonDisabled && (
                  <ProgressTimer initialMinutes={timeUntilUnlocking} dateCreated={dateCreated} />
                )}
              </Button>
            </div>
          </div>
        </div>
      )
    }
  }

  if (!texts || texts.length === 0) return null
  return (
    <React.Fragment>
      {isProcessConfirmationActions && (
        <div className='group'>
          <div className='group__main group__main_secondary'>
            <div className='wysiwyg text-center'>
              <h2>{t(texts[0], { currency: exchangeCurrencyCode })} </h2>
              {texts[1] !== '' && (
                <p>
                  <small className='colorPrimary700'>{t(texts[1], { currency: exchangeCurrencyCode })}</small>
                </p>
              )}
            </div>
            <div className='row'>
              {renderButtonsForBuyingStatus()}
              {renderButtonsForCompletedOrReleasedStatus()}
              {renderButtonsForPaymentSystemSentStatus()}
            </div>
          </div>
        </div>
      )}
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        deal={deal}
        onConfirmCommonModals={(actionType, payload) => {
          onProcessBottomActions(actionType, payload)
        }}
      />
    </React.Fragment>
  )
}

const ProcessUpActions = ({ onProcessUpActions, t, deal, isDisabledBtn }) => {
  const { isDirectionDealBuying, status } = deal || {}
  const { modals, openModal, closeModal } = useModalState()

  return (
    <React.Fragment>
      {(status === DEAL_STATUS.PaymentSystemSent || status === DEAL_STATUS.Canceled || deal.IsAppealed) && (
        <div className='group'>
          <div className='row justify-center'>
            <div className='col col_3 col_tab-5 col_mobp-8'>
              <Button
                buttonCallback={() => openModal('isNeededHelpModalOpen')}
                addClasses={['btn w-100']}
                text={t('HelpIsNeeded')}
              />
            </div>
            {isDirectionDealBuying && status !== DEAL_STATUS.CryptoReleased && status !== DEAL_STATUS.Canceled && (
              <div className='col col_3 col_tab-5 col_mobp-8'>
                <Button
                  buttonCallback={() => openModal('isDealCancelDisputeModalOpen')}
                  addClasses={['btn btn_revert w-100']}
                  text={t('CancelOrderSecondary')}
                  disabled={isDisabledBtn}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <CommonModals
        modals={modals}
        closeModal={closeModal}
        openModal={openModal}
        deal={deal}
        onConfirmCommonModals={(actionType, payload) => {
          onProcessUpActions(actionType, payload)
        }}
      />
    </React.Fragment>
  )
}
export { ProcessBottomActions, ProcessUpActions }
