import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { cssClass, calculateCommission, getCommissionInfoString } from 'helpers/helper'
import Selector from 'baseComponents/Selector/Selector'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './CashWithdrawal.module.scss'
import api from 'api'
import CommissionTypes from 'data/CommissionTypes'
import PublicID from '../PublicID/PublicID'
import WithdrawalTypeCodes from 'data/WithdrawalTypeCodes'

export default function CashWithdrawal({ currency, close }) {
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const selectItems = ['CashIstanbul' /*'CodeBlazarex'*/]
  const [method, setMethod] = useState(selectItems[0])
  const [expand, setExpand] = useState(false)
  const [sum, setSum] = useState(0)
  const [commission, setCommission] = useState(null)
  const [commissionArr, setCommissionArr] = useState([])
  const [publicID, setPublicID] = useState('')
  const [emailCode, setEmailCode] = useState('')
  const [isDisabled, setDisabled] = useState(true)
  const [isEmailBtnDisabled, setEmailDisabled] = useState(false)

  useEffect(() => {
    setExpand(false)
  }, [method])

  useEffect(() => {
    const { commAmount } = calculateCommission(commissionArr, sum, CommissionTypes.Withdrawal, currency?.code, null)
    setCommission(commAmount)
  }, [sum, commissionArr])

  useEffect(() => {
    api.fetchCommissions().then((data) => {
      setCommissionArr(data?.commissions)
    })
  }, [])

  const createWithdrawalModel = () => ({
    publicId: publicID,
    currencyCode: currency.code,
    amount: sum,
    address: 'CashIstanbul',
    withdrawalType: WithdrawalTypeCodes.Cash,
    emailAuthCode: emailCode,
  })

  const handleSetWithdrawAll = (e) => {
    e.preventDefault()
    setSum(currency.free.toString().split('.')[0])
  }

  const handleSendEmail = (e) => {
    e.preventDefault()
    setEmailDisabled(true)
    api
      .sendEmailConfirmation(createWithdrawalModel())
      .then(() => {
        toast.success(t('SendEmailConfirmation_Success'))
      })
      .catch(() => {
        toast.error(t('SendEmailConfirmation_Error'))
      })
      .finally(() => {
        setEmailDisabled(false)
      })
  }

  const handleCashWithdraw = (e) => {
    e.preventDefault()
    setDisabled(true)
    api
      .createWithdrawal(createWithdrawalModel())
      .then(() => {
        toast.success(t('CreateCashWithdrawal_Success'))
        setSum(0)
        close()
      })
      .catch(() => {
        toast.error(t('CreateCashWithdrawal_Error'))
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  return (
    <div className={styles.block}>
      <h2 className={styles.title}>
        {t('Withdraw')} {currency.code}
      </h2>
      <div className={styles.methodWrapper}>
        <h3 className={cssClass(styles.methodTitle, styles.title1)}>{t('MethodOfOutput')}</h3>
        <h3 className={cssClass(styles.methodTitle, styles.title2)}>{t('Instruction')}</h3>
        <Selector list={selectItems} value={method} selectNewItem={(value) => setMethod(value)} />

        {t(method) === t(selectItems[0]) ? <WithdrawalStambulInstructions /> : <WithdrawalBlazarexTRYInstructions />}
      </div>
      <form className={styles.withdrawalStambulWrapper}>
        <h3 className={cssClass(styles.methodTitle, styles.firstTitle)}>{t('SumOfWithdrawal')}</h3>
        <h3
          onClick={() => history.push('/commissions')}
          className={cssClass(styles.methodTitle, styles.secondTitle, baseComponents.pointer)}
        >
          {t('Commission')}
        </h3>
        <input
          className={cssClass(baseComponents.input, styles.input, styles.firstInput)}
          placeholder='1 000 000'
          type='number'
          value={sum}
          onChange={(e) => setSum(e.target.value.toString().split('.')[0])}
        />
        <button
          onClick={handleSetWithdrawAll}
          className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.firstButton)}
        >
          {t('WithdrawAll')}
        </button>
        <input
          disabled
          className={cssClass(styles.disabledInput, styles.secondInput)}
          style={{ overflow: 'visible' }}
          value={getCommissionInfoString(commissionArr, CommissionTypes.Withdrawal, currency?.code, null)}
        />
        <h3 className={cssClass(styles.methodTitle, styles.thirdTitle)}>{t('PaymentAmount')}</h3>
        <input
          disabled
          className={cssClass(styles.disabledInput, styles.thirdInput)}
          value={sum && commission !== null ? sum - commission : ''}
        />
        <h3 className={cssClass(styles.methodTitle, styles.fourthTitle)}>{t('EmailCode')}</h3>
        <input
          className={cssClass(baseComponents.input, styles.input, styles.fourthInput)}
          placeholder=''
          value={emailCode}
          onChange={(e) => setEmailCode(e.target.value)}
        />

        <button
          onClick={handleSendEmail}
          className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.secondButton)}
          disabled={isEmailBtnDisabled}
        >
          {t('SendEmail')}
        </button>

        <button
          onClick={handleCashWithdraw}
          disabled={sum && commission !== null && emailCode.length > 0 ? '' : isDisabled}
          className={cssClass(baseComponents.buttonColored, baseComponents.buttonGreen, styles.thirdButton)}
        >
          {t('Confirm')}
        </button>
      </form>

      <PublicID onPublicIDChange={(id) => setPublicID(id)} />
    </div>
  )
}

const WithdrawalStambulInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.instructionsWrapper}>
      <p className={styles.instructionsItem}>{t('WorkSchedule')}</p>
      <p className={styles.instructionsItem}>{t('WithdrawalStambulInstructions')}</p>
    </div>
  )
}

const WithdrawalBlazarexTRYInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.instructionsWrapper}>
      <p className={styles.instructionsItem}>{t('WithdrawalBlazarexInstructions')}</p>
    </div>
  )
}
