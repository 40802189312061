import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from '../../../api'
import { BuySellTabIndex, GetOpenOrderLimitKey } from './PostingData'

function PostingLimitsToastMessages({ t, onButtonNextDisabledLimits, stageData, editOrder }) {
  const [toastId, setToastId] = useState(null)
  const [messagesAboutLimits, setMessagesAboutLimits] = useState(null)
  const [isOrderLimitsRequestSent, setIsOrderLimitsRequestSent] = useState(null)

  const checkNextButtonDisabled = () => {
    return !termsTextarea || termsTextarea === ''
  }

  const handleNextButtonDisabled = (buttonValue) => {
    onButtonNextDisabledLimits(buttonValue)
  }
  useEffect(() => {
    let ignore = false
    const isEmptyOrZero = (value) =>
      !value || value === '0' || parseFloat(value) === 0 || value.toString().trim() === ''

    if (!stageData || isEmptyOrZero(stageData.userSelectedTradeRate) || isOrderLimitsRequestSent) {
      return
    }
    setIsOrderLimitsRequestSent(true)
    api
      .fetchP2PUserOrderOpeningLimits(
        stageData.selectedBuySellTabIndex === BuySellTabIndex.Buy,
        stageData.selectedExchangeAsset,
        stageData.selectedOtcAsset,
        stageData.userSelectedTradeRate,
        stageData.selectedPaymentRequisites && stageData.selectedPaymentRequisites.length !== 0
          ? stageData.selectedPaymentRequisites.filter((method) => method.selected === true).map((method) => method.id)
          : null,
        editOrder?.id ?? null,
      )
      .then((userOrderLimits) => {
        if (ignore) return

        let falseKey
        for (const key in userOrderLimits) {
          if (userOrderLimits[key] === false) {
            falseKey = key
            break
          }
        }
        if (!falseKey) {
          setMessagesAboutLimits(null)
          return
        }
        const textLimitMessages = GetOpenOrderLimitKey(falseKey, stageData.selectedBuySellTabIndex)
        setMessagesAboutLimits(textLimitMessages)
      })
      .catch((e) => console.error('Error fetching user order limits. Error: ', e))
      .finally(() => setIsOrderLimitsRequestSent(false))

    return () => {
      ignore = true
    }
  }, [
    stageData?.currentStageIndex,
    stageData?.selectedBuySellTabIndex,
    stageData?.selectedExchangeAsset,
    stageData?.selectedOtcAsset,
    stageData?.userSelectedTradeRate,
    stageData?.selectedPaymentRequisites,
  ])

  useEffect(() => {
    if (messagesAboutLimits && !toastId) {
      const id = toast.error(t(messagesAboutLimits), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        // closeButton: false,
        closeOnClick: false,
        // draggable: false,
      })
      setToastId(id)
      handleNextButtonDisabled(true)
    } else if (!messagesAboutLimits) {
      toast.dismiss(toastId)
      setToastId(null)
      handleNextButtonDisabled(false)
    }
  }, [messagesAboutLimits, toastId])

  useEffect(() => {
    return () => {
      setMessagesAboutLimits(null)
      toast.dismiss(toastId)
      setToastId(null)
    }
  }, [])
  return <div />
}

export default PostingLimitsToastMessages
