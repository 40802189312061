import axios from 'axios'
import { computed, makeObservable, observable, action, reaction, configure } from 'mobx'
import max from 'lodash/max'
import min from 'lodash/min'
import api from 'api'
import { toast } from 'react-toastify'
import i18n from 'i18next'

configure({
  enforceActions: 'never',
})

class Store {
  isSignedIn = false
  symbol = null
  symbolBase = ''
  symbolQuote = ''

  instruments = null
  commissions = null
  orders = null
  userOrders = null
  deals = null
  balance = null
  userDeals = null
  signalRConn = null

  constructor() {
    makeObservable(this, {
      symbol: observable,
      symbolBase: observable,
      symbolQuote: observable,
      instrument: computed,
      instruments: observable,
      commissions: observable,
      orders: observable,
      userOrders: observable,
      deals: observable,
      userDeals: observable,
      balance: observable,
      signalRConn: observable,
      getInstruments: action.bound,
      getPairDigits: action.bound,
      setIsSignedIn: action.bound,
      setSymbol: action.bound,
      setSignalRConn: action.bound,
      fetchInstruments: action.bound,
      fetchCommissions: action.bound,
      fetchOrders: action.bound,
      fetchDeals: action.bound,
      createOrder: action.bound,
      fetchUserOrders: action.bound,
      deleteOrder: action.bound,
      deleteAllOrders: action.bound,
      fetchUserDeals: action.bound,
    })
  }
  translate(key) {
    return i18n.t(key)
  }

  get instrument() {
    return this.instruments && this.symbol ? this.instruments.find((i) => i.code === this.symbol) : null
  }

  getInstruments() {
    return this.instruments
  }

  getPairDigits(pairCode) {
    const pair = this.instruments?.find((i) => i.code === pairCode)
    if (!pair) return { digitsAmount: 5, digitsPrice: 5, digitsTotal: 5 }
    return {
      digitsAmount: pair.digitsAmount,
      digitsPrice: pair.digitsPrice,
      digitsTotal: Math.max(pair.digitsAmount, pair.digitsPrice),
    }
  }

  getFreeBalance(currencyCode) {
    return this.balance?.balances.find((_) => _.currencyCode == currencyCode)?.amount ?? null
  }

  getRate(isBuy) {
    if (!this.orders) return null
    if (isBuy) {
      const bestAskPrice = min(this.orders.filter((o) => !o.isBid).map((o) => o.price))
      return bestAskPrice ?? null
    } else {
      const bestBidPrice = max(this.orders.filter((o) => o.isBid).map((o) => o.price))
      return bestBidPrice ?? null
    }
  }

  setIsSignedIn(isSignedIn) {
    this.isSignedIn = isSignedIn
  }

  setSymbol(symbol) {
    if (this.symbol === symbol) return

    this.symbol = symbol
    this.symbolBase = symbol.split('_')[0]
    this.symbolQuote = symbol.split('_')[1]

    this.orders = null
    this.userOrders = null
    this.deals = null
    this.userDeals = null

    if (!symbol || symbol.length < 6) return // invalid symbol

    localStorage.setItem('symbol', symbol)
    store.fetchOrders()
    store.fetchDeals()
    if (this.isSignedIn) {
      store.fetchBalance()
      store.fetchUserOrders()
      store.fetchUserDeals()
    }
  }

  setSignalRConn(conn) {
    this.signalRConn = conn
  }

  fetchInstruments() {
    api.fetchInstruments().then((data) => {
      const result = data.map((i) => ({
        ...i.currencyPair,
        price: i.price,
        price24hAgo: i.price24hAgo,
        volume24h: i.volume24h,
      }))
      //console.log('fetch instruments:', result)
      this.instruments = result
    })
  }

  fetchCommissions() {
    api.fetchCommissions().then((data) => {
      this.commissions = data
      window.commissions = data
      //console.log('fetch commissions:', data)
    })
  }

  fetchOrders() {
    api.fetchOrders(this.symbol).then((data) => {
      this.orders = data
      //console.log('fetch orders:', data)
    })
  }

  fetchDeals() {
    api.fetchDeals(this.symbol).then((deals) => {
      this.deals = deals
      //console.log('fetch deals:', deals)
    })
  }

  fetchBalance() {
    api
      .fetchBalance()
      .then((data) => {
        this.balance = data
        //console.log('fetch balance:', data)
      })
      .catch((err) => {})
  }

  createOrder(data) {
    const order = { ...data, currencyPairCode: this.symbol }

    console.log('createOrder', order)
    return api
      .createOrder(order)
      .then(() => {
        //console.log('Order created', data)
        toast.success(this.translate('OrderCreation_NotificationSuccess'))
      })
      .catch(() => {
        toast.error(this.translate('OrderCreation_NotificationError'))
      })
  }

  fetchUserOrders() {
    api
      .fetchUserOrders(this.symbol)
      .then((data) => {
        this.userOrders = data
        //console.log('fetch userOrders:', data)
      })
      .catch((err) => {})
  }

  deleteOrder(id) {
    return api
      .deleteOrder(id)
      .then(() => {
        //console.log('Order canceled', id)
        toast.success(this.translate('OrderDelete_NotificationSuccess'))
      })
      .catch(() => {
        toast.error(this.translate('OrderDelete_NotificationError'))
      })
  }

  deleteAllOrders() {
    return api.deleteAllOrders(this.symbol).then(() => {})
  }

  fetchUserDeals() {
    return api
      .fetchUserDeals(this.symbol)
      .then((data) => {
        this.userDeals = data
        //console.log('fetch userDeals:', data)
      })
      .catch((err) => {})
  }
}

const store = new Store()

export default store
