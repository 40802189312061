import moment from 'moment/moment'
import { STATUS_VERIFICATION_USER } from './VerificationData'
import { IsHideRejectionReason } from './VerificationData'

export default function TimeToSendForVerification({ t, dateFilled, status, stage, requiredStage, isHided }) {
  if (IsHideRejectionReason(stage, requiredStage)) return null
  const formattedDateTime = dateFilled ? moment(dateFilled).format('YYYY-MM-DD HH:mm:ss') : null
  if (status !== STATUS_VERIFICATION_USER.OnVerification) return null
  return (
    <>
      {
        <div className='verification__time time'>
          <span className='time__title'>{t('SubmissionTimeForReview')}</span>
          <span className='time__value'>{formattedDateTime}</span>
        </div>
      }
    </>
  )
}
