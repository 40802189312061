import cooperation__img01 from '../../secondaryLayout/img/cooperation__img01.png'
import cooperation__img02 from '../../secondaryLayout/img/cooperation__img02.png'
import cooperation__img03 from '../../secondaryLayout/img/cooperation__img03.png'
import cooperation__img04 from '../../secondaryLayout/img/cooperation__img04.png'
import cooperation__img05 from '../../secondaryLayout/img/cooperation__img05.png'
import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'

export default function Cooperation({ t, navigationFunctions }) {
  const [scroll, setScroll] = useState(false)
  const [scrollCheck, setScrollCheck] = useState(false)
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }

  const handleScroll = () => {
    const cooperationMain = document.getElementById('cooperation__main')
    const bannerWrapper = document.getElementById('banner__wrapper')
    const layoutCategories = document.getElementById('layout__categories')

    if (cooperationMain && bannerWrapper && layoutCategories) {
      setScroll(cooperationMain.getBoundingClientRect().top < 0)

      if (window.pageYOffset + bannerWrapper.offsetHeight + bannerWrapper.offsetTop > layoutCategories.offsetTop + 60) {
        setScrollCheck(true)
      }

      if (layoutCategories.getBoundingClientRect().top > window.innerHeight) {
        setScrollCheck(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__header section__header_secondary'>
            <h2 className='section__title'>{t('BecomeOursPartnerToday')}</h2>
          </div>
          <div className='section__main'>
            <div className='layout__cooperation cooperation'>
              <div id='cooperation__banner' className='cooperation__banner'>
                <div
                  id='banner__wrapper'
                  className={`banner__wrapper ${scroll ? 'banner__wrapper_fixed' : ''} ${
                    scrollCheck ? 'banner__wrapper_bottom' : ''
                  }`}
                >
                  <div className='banner__background'>
                    <img className='banner__img' src={cooperation__img01} alt='image description' />
                  </div>

                  <div className='banner__header'>
                    <h3 className='banner__title'>{t('WeProvideTheBestConditions')}</h3>
                  </div>
                  <div className='banner__footer'>
                    <a className='btn btn_tertiary' onClick={() => handleGoToPage()}>
                      <span className='btn__text'>{t('StartNow')}</span>
                    </a>
                  </div>
                </div>
              </div>

              <div id='cooperation__main' className='cooperation__main'>
                <ul className='cooperation__list'>
                  <li className='cooperation__item layout__box layout__box_secondary'>
                    <span className='cooperation__title layout__tag'>{t('Entrepreneurs')}</span>

                    <span className='cooperation__text'>{t('CreateYourOwnCoinAndGainAccess')}</span>
                    <img className='cooperation__img' src={cooperation__img02} alt='image description' />
                  </li>
                  <li className='cooperation__item layout__box layout__box_secondary'>
                    <span className='cooperation__title layout__tag'>{t('Investors')}</span>

                    <span className='cooperation__text'>{t('SimplifyYourInvestmentProcesses')}</span>
                    <img className='cooperation__img' src={cooperation__img03} alt='image description' />
                  </li>
                  <li className='cooperation__item layout__box layout__box_secondary'>
                    <span className='cooperation__title layout__tag'>{t('BloggersAndContentCreators')}</span>

                    <span className='cooperation__text'>{t('StartUsingNewWaysToMonetizeContent')}</span>
                    <img className='cooperation__img' src={cooperation__img04} alt='image description' />
                  </li>
                  <li className='cooperation__item layout__box layout__box_secondary'>
                    <span className='cooperation__title layout__tag'>{t('TradersList')}</span>

                    <span className='cooperation__text'>{t('OpenTheDoorToFinancial')}</span>
                    <img className='cooperation__img' src={cooperation__img05} alt='image description' />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
