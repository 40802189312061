import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'

import baseComponents from '../../../../baseComponents/BaseComponents.module.scss'
import styles from './FormElement.module.scss'

import passwordFieldIcon from './passwordFieldIcon.svg'

const FormElement = ({ title, placeholder, type, value, setValue, readOnly, isPassword }) => {
  const { t } = useTranslation()
  const [isRevealed, setIsRevealed] = useState(false)
  return (
    <div className={styles.wrapper}>
      <span>{t(title)}</span>
      <div className={cssClass(styles.inputBorder, styles.password)}>
        <input
          className={cssClass(baseComponents.input, styles.input)}
          placeholder={placeholder}
          type={type === 'password' && isRevealed ? 'text' : type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          readOnly={readOnly}
        />
        {isPassword ? (
          <img
            className={styles.passwordFieldIcon}
            src={passwordFieldIcon}
            alt='Closed eye icon'
            onClick={() => setIsRevealed(!isRevealed)}
          />
        ) : null}
      </div>
    </div>
  )
}

export default FormElement
