import React, { useState } from 'react'
import { cssClass } from 'helpers/helper'
import style from '../style/TokenPage.module.scss'
import ChartInfo from './Chart/ChartInfo'
import ProjectDescription from './ProjectDescription'
import ProjectGoal from './ProjectGoal'
import ProjectInformation from './ProjectInformation'

export default function BasicInformationTab({ t, currencyListing, exchangeInstruments, logoImage, photoImage }) {
  return (
    <div className={cssClass(style.tabs__item)}>
      <div className={cssClass(style.project__information, style.information)}>
        <div className={cssClass(style.container)}>
          <main className={cssClass(style.information__main)}>
            <ChartInfo
              t={t}
              currencyListing={currencyListing}
              exchangeInstruments={exchangeInstruments}
              logoImage={logoImage}
            />
            <ProjectDescription t={t} currencyListing={currencyListing} photoImage={photoImage} />
          </main>
          <aside className={cssClass(style.information__sidebar, style.sidebar)}>
            <ProjectGoal t={t} currencyListing={currencyListing} />
            <ProjectInformation t={t} currencyListing={currencyListing} exchangeInstruments={exchangeInstruments} />
          </aside>
        </div>
      </div>
    </div>
  )
}
