import React, { useState, useEffect } from 'react'
import Input from '../../../components/form/Input/Input'
import Checkbox from '../../../components/form/Checkbox/Checkbox'
import Button from '../../../components/form/Button/Button'
import Select from '../../../components/form/Select/Select'
import VerificationIcons from './VerificationIcons'
import api from 'api'
import { format } from 'date-fns'
import moment from 'moment/moment'

import examples__img01 from './images/examples__img01.jpg'
import examples__img02 from './images/examples__img02.jpg'
import examples__img03 from './images/examples__img03.jpg'
import { STATUS_VERIFICATION_USER, USER_VERIFICATION_STAGE } from './VerificationData'

const BasicVerification = ({ t, onHandleBasicVerification, verificationData }) => {
  const [basicVerificationNewData, setBasicVerificationNewData] = useState({
    documentNumber: verificationData.documentNumber || null,
    documentDateIssued: verificationData.documentDateIssued || null,
    documentDateExpire: verificationData.documentDateExpire || null,
    countryResidenceId: verificationData.countryResidenceId || null,
    city: verificationData.city || null,
    identityCardFileName: verificationData.identityCardFileName || null,
    identityCardBacksideFileName: verificationData.identityCardBacksideFileName || null,
    photoWithDocument: verificationData.photoWithDocument || null,
  })

  const [iHaveReadPrivacyPolicyCheckbox, setIHaveReadPrivacyPolicyCheckbox] = useState(false)
  const [countryResidenceNameEn, setCountryResidenceNameEn] = useState(
    verificationData.countriesResidence.find((item) => item.id === basicVerificationNewData.countryResidenceId)
      ?.name_EN || null,
  )
  const [documentDateIssued, setDocumentDateIssued] = useState(
    format(new Date(basicVerificationNewData?.documentDateIssued), 'dd.MM.yyyy') || null,
  )
  const [documentDateExpire, setDocumentDateExpire] = useState(
    format(new Date(basicVerificationNewData?.documentDateIssued), 'dd.MM.yyyy') || null,
  )

  function formatDateInput(value) {
    if (value.length === 2) {
      value = value.slice(0, 2) + '.' + value.slice(2)
    }
    if (value.length === 5) {
      value = value.slice(0, 5) + '.' + value.slice(5)
    }
    if (value.length > 10) {
      value = value.slice(0, 10)
    }
    return value
  }
  function isValidDate(value) {
    if (value.length < 10) return false
    const isValidDate = moment(value, ['DD.MM.YYYY'], true).isValid()
    return isValidDate
  }
  function formatDateFromModel(value) {
    if (!isValidDate(value)) return null
    const formattedDate = value.split('.').reverse().join('-')
    return formattedDate
  }
  const isEmptyFields = (data, exclusions = []) => {
    const result = Object.entries(data).some(
      ([key, value]) => !exclusions.includes(key) && (value === null || value === ''),
    )
    return result
  }

  const handleVerificationDataChange = (value, fieldName) => {
    setBasicVerificationNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
  }

  const uploadFile = (file, docType, fieldName) => {
    if (!file) return
    const validFormats = [
      'image/bmp',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
      'image/png',
      'image/tiff',
      'image/webp',
    ]
    if (!validFormats.includes(file.type)) {
      console.error('Invalid file format:', file.type)
      return
    }

    api
      .saveVerificationFile(file, docType)
      .then((fileId) => {
        handleVerificationDataChange(fileId, fieldName)
      })
      .catch((error) => {
        console.error('Error uploading file:', error)
      })
  }

  const onSendClick = () => {
    const isEmpty = isEmptyFields(basicVerificationNewData, [
      'city',
      'identityCardBacksideFileName',
      'documentDateExpire',
    ])
    if (!isEmpty) {
      const model = {
        ...verificationData,
        documentNumber: basicVerificationNewData.documentNumber?.trim(),
        documentDateIssued: format(new Date(basicVerificationNewData.documentDateIssued), 'yyyy-MM-dd'),
        documentDateExpire: format(new Date(basicVerificationNewData.documentDateExpire), 'yyyy-MM-dd'),
        countryResidenceId: basicVerificationNewData.countryResidenceId,
        city: basicVerificationNewData.city?.trim() || null,
        identityCardFileName: basicVerificationNewData.identityCardFileName?.trim(),
        identityCardBacksideFileName: basicVerificationNewData.identityCardBacksideFileName?.trim() || null,
        photoWithDocument: basicVerificationNewData.photoWithDocument?.trim(),
        verificationStage: USER_VERIFICATION_STAGE.BasicVerification,
      }
      api
        .saveVerification(model)
        .then((data) => {
          onHandleBasicVerification(data)
        })
        .catch((error) => {
          console.error('save verification error!', error)
        })
    }
  }

  useEffect(() => {
    if (!countryResidenceNameEn) return
    const id = verificationData.countriesResidence.find((item) => item.name_EN === countryResidenceNameEn).id
    handleVerificationDataChange(id, 'countryResidenceId')
  }, [countryResidenceNameEn])

  return (
    <div className='verification__list'>
      <div className='verification__item'>
        <div className='verification__header'>
          <div className='verification__title'>
            <span className='verification__text h2'>{t('BasicVerification')}</span>
          </div>
        </div>
        <div className='verification__main'>
          <div className='account__tooltip'>
            <div className='tooltip__list'>
              <div className='tooltip__item tooltip__item_tertiary'>
                <span className='tooltip__text'>{t('PleaseNoteThatIdentificationInformationCannot')}</span>
              </div>
            </div>
          </div>
          <div className='verification__formular formular'>
            <div className='formular__group'>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>
                    {t('DocumentNumber')} <span className='form-item__sup'>*</span>
                  </span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      value={basicVerificationNewData.documentNumber}
                      placeholder={'XXXX XXXXXX'}
                      type='text'
                      inputCallback={(value) => handleVerificationDataChange(value, 'documentNumber')}
                      addClasses={['w-100']}
                    />
                  </div>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>
                    {t('DateOfIssueOfTheDocument')} <span className='form-item__sup'>*</span>
                  </span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      value={documentDateIssued}
                      placeholder={'DD.MM.YYYY'}
                      replaceSymbols={[{ from: '..', to: '.' }]}
                      inputCallback={(value) => {
                        setDocumentDateIssued(formatDateInput(value))
                        const date = formatDateFromModel(value)
                        handleVerificationDataChange(date ? date : null, 'documentDateIssued')
                      }}
                      addClasses={['w-100', isValidDate(documentDateIssued) ? '' : 'invalid-input']}
                    />
                  </div>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>{t('DocumentEndDate')}</span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      value={documentDateExpire}
                      placeholder={'DD.MM.YYYY'}
                      replaceSymbols={[{ from: '..', to: '.' }]}
                      inputCallback={(value) => {
                        setDocumentDateExpire(formatDateInput(value))
                        const date = formatDateFromModel(value)
                        handleVerificationDataChange(date ? date : null, 'documentDateExpire')
                      }}
                      addClasses={[
                        'w-100',
                        !documentDateExpire || isValidDate(documentDateExpire) ? '' : 'invalid-input',
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>
                    {t('CountryOfResidence')} <span className='form-item__sup'>*</span>
                  </span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Select
                      list={verificationData.countriesResidence.map((item) => item.name_EN)}
                      value={countryResidenceNameEn}
                      addClasses={['w-100']}
                      filter={true}
                      multiple={false}
                      selectCallback={(value) => {
                        setCountryResidenceNameEn(value)
                      }}
                      localizationFilename={'countries'}
                    />
                  </div>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__header'>
                  <span className='form-item__title'>{t('CityOfResidence')}</span>
                </div>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Input
                      type='text'
                      value={basicVerificationNewData.city}
                      inputCallback={(value) => {
                        handleVerificationDataChange(value, 'city')
                      }}
                      addClasses={['w-100']}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='formular__group'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <div className='form-item__attach attach'>
                      <label className='attach__label'>
                        <input
                          type='file'
                          className='attach__input'
                          onChange={(event) =>
                            uploadFile(event.target.files[0], 'IdentityCard', 'identityCardFileName')
                          }
                        />
                        <VerificationIcons name={basicVerificationNewData.identityCardFileName ? 'ico06' : 'ico05'} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-item__footer text-center'>
                  <span className='form-item__title'>
                    <strong>{t('FrontEnd')}</strong>
                    <span className='form-item__sup'>*</span>
                  </span>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <div className='form-item__attach attach'>
                      <label className='attach__label'>
                        <input
                          type='file'
                          className='attach__input'
                          onChange={(event) =>
                            uploadFile(event.target.files[0], 'IdentityCardBackside', 'identityCardBacksideFileName')
                          }
                        />
                        <VerificationIcons
                          name={basicVerificationNewData.identityCardBacksideFileName ? 'ico06' : 'ico05'}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-item__footer text-center'>
                  <span className='form-item__title'>
                    <strong>{t('RearEnd')}</strong>
                  </span>
                </div>
              </div>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <div className='form-item__attach attach'>
                      <label className='attach__label'>
                        <input
                          type='file'
                          className='attach__input'
                          onChange={(event) =>
                            uploadFile(event.target.files[0], 'PhotoWithDocument', 'photoWithDocument')
                          }
                        />
                        <VerificationIcons name={basicVerificationNewData.photoWithDocument ? 'ico06' : 'ico05'} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-item__footer text-center'>
                  <span className='form-item__title'>
                    <strong>{t('PhotoWithPassportInHand')}</strong>
                    <span className='form-item__sup'>*</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='verification__information'>
            <div className='wysiwyg'>
              <p>{t('UploadedImagesIncludeTheFrontPhoto')}</p>
            </div>
          </div>
          <div className='verification__examples examples'>
            <div className='examples__header'>
              <div className='wysiwyg'>
                <h3>{t('ExampleOfYourID')}:</h3>
              </div>
            </div>
            <div className='examples__main'>
              <div className='examples__list'>
                <div className='examples__item'>
                  <div className='examples__media'>
                    <img src={examples__img01} alt='image description' />
                  </div>
                  <div className='examples__footer'>
                    <span className='examples__text'>{t('FrontEnd')}</span>
                  </div>
                </div>
                <div className='examples__item'>
                  <div className='examples__media'>
                    <img src={examples__img02} alt='image description' />
                  </div>
                  <div className='examples__footer'>
                    <span className='examples__text'>{t('RearEnd')}</span>
                  </div>
                </div>
                <div className='examples__item'>
                  <div className='examples__media'>
                    <img src={examples__img03} alt='image description' />
                  </div>
                  <div className='examples__footer'>
                    <span className='examples__text'>{t('PhotoWithPassportInHand')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='account__tooltip'>
            <div className='tooltip__list'>
              <div className='tooltip__item tooltip__item_tertiary'>
                <span className='tooltip__text'>{t('PleaseNoteThatIdentificationInformationCannot')}</span>
              </div>
            </div>
          </div>
          <div className='verification__formular formular'>
            <div className='form-item'>
              <div className='form-item__main'>
                <div className='form-item__field'>
                  <Checkbox
                    type={'checkbox'}
                    name={'iHaveRead'}
                    text={'IHaveReadPrivacyPolicy'}
                    useHtmlForText={true}
                    checkboxCallback={() => setIHaveReadPrivacyPolicyCheckbox((prev) => !prev)}
                    checked={iHaveReadPrivacyPolicyCheckbox}
                  />
                </div>
              </div>
            </div>
            <div className='formular__group'>
              <div className='form-item'>
                <Button
                  buttonCallback={() => onHandleBasicVerification()}
                  addClasses={['btn btn_revert w-100']}
                  text={t('Back')}
                />
              </div>
              <div className='form-item'>
                <Button
                  disabled={
                    isEmptyFields(basicVerificationNewData, [
                      'city',
                      'identityCardBacksideFileName',
                      'documentDateExpire',
                    ]) || !iHaveReadPrivacyPolicyCheckbox
                  }
                  buttonCallback={() => onSendClick()}
                  addClasses={['btn w-100']}
                  text={t('Send')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicVerification
