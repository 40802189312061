import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import TokenIcons from '../TokenData/TokenIcons'
import { useHistory } from 'react-router-dom'

export default function Roadmap({ t, navigationFunctions }) {
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(1)

  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  return (
    <React.Fragment>
      <section className='layout__section layout__section_secondary section'>
        <div className='container'>
          <div className='section__header'>
            <h2 className='section__title'>{t('CheckOutTheDevelopmentRoadmap')}</h2>
            <div className='section__tabs section__tabs_secondary tabs'>
              <ul className='tabs__list'>
                <li
                  className={`tabs__item ${selectedTab == 1 ? 'tabs__item_active' : ''}`}
                  onClick={() => setSelectedTab(1)}
                >
                  {t('Tokenization')}
                </li>
                <li
                  className={`tabs__item ${selectedTab == 2 ? 'tabs__item_active' : ''}`}
                  onClick={() => setSelectedTab(2)}
                >
                  {t('CryptoExchange')}
                </li>
                <li
                  className={`tabs__item ${selectedTab == 3 ? 'tabs__item_active' : ''}`}
                  onClick={() => setSelectedTab(3)}
                >
                  {t('BattleOfTraders')}
                </li>
              </ul>
            </div>
          </div>
          <div className='section__main'>
            {selectedTab === 1 ? (
              <div className='layout__roadmap roadmap'>
                <div className='roadmap__list'>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2022 Q4</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenDevelopmentOfTheExchange')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenKernelDevelopment')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenTeamRecruitment')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenMarketResearch')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenRefiningTheConcept')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q1</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CompleteRedesign')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FirstClients')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CreatingTokensManually')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TheConceptIsFullyFormed')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q2</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('IntegrationWithTON')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('MiniApplicationInTelegram')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('Farmilovka')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('AutomaticTokenCreation')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('Tokenization')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FullScaleStart')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('ConclusionOfPartnerships')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q3</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('DigitalProfileOfAPerson')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('ConnectingNewBlockchains')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('PluginsForStreamingPlatforms')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q4</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RealEstatePlatform')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CommunicationInfluencerBusiness')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('MonetizationOfHumanSocialNetworks')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('GameFi')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2025 Q1</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RealTimePeopleManagementPlatform')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('OpeningOfficesAroundTheWorld')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenizationOfLargeCompanies')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmap__action'>
                  <button className='btn' onClick={() => goToTokensRoadMapPage()}>
                    <span className='btn__text'>{t('FindOutMore')}</span>
                  </button>
                </div>
              </div>
            ) : null}
            {selectedTab === 2 ? (
              <div className='layout__roadmap roadmap'>
                <div className='roadmap__list'>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2023 Q4</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('GettingStartedInP2PArbitrationInTurkey')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('WeEarnMoreThanWeSpend')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('DevelopmentOfCashExchange')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q1</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('OccupyingP2PTurkey')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('AgreementsWithBanksOnLargeTurnovers')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RegistrationOfACompany')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('ExceedingTurnoverPerMonth')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('20PeopleInTheTeam')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FirstClientsForTokenization')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q2</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('StartInteractingWithCoinTR')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('ObtainingACryptocurrencyLicenseInTheEU')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('LaunchOfP2PPlatform')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('StartFarming')}</span>
                            </a>
                          </li>
                          <li className='events__item events__item_secondary'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('WeAreHere')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('LaunchOfTokenization')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('PartnershipWithTONAndTelegram')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CapturingTheMarketInTheEU')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q3</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('DevelopmentOfP2PPaymentSystemInTurkey')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RecruitingAClientBase')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('LaunchOfTheBattleOfTraders')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('EnteringTheCIS')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TurnoverPerDay')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RealRstateTokenizationPlatform')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q4</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FinalizationOfThePlatform')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('DevelopmentOfAGlobalCryptosystemForBRICS')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('OpeningOfNewCashOffices')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CapturingTheGlobalMarket')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('CreationOfAnInvestmentFund')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2025 Q1</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('SearchAndCreationOfNewMarketsAndProducts')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('IntegrationWithStates')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('IntegrationWithMajorFinancialPlayers')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmap__action'>
                  <button className='btn' onClick={() => goToTokensRoadMapPage()}>
                    <span className='btn__text'>{t('FindOutMore')}</span>
                  </button>
                </div>
              </div>
            ) : null}
            {selectedTab === 3 ? (
              <div className='layout__roadmap roadmap'>
                <div className='roadmap__list'>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2023 Q2-Q3</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FormationIdeaBattle')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('EarningAndSavingMoneyToStartAProject')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('WorkingOutTheLogic')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q1</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('FirstLayouts')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TeamFormation')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q2</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('StartFarming')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RaisingFunding')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('MVPDevelopment')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q3</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('Clans')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('ClanBattles')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('Tournaments')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('15MinuteTournaments')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TradersStore')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('RatingSystem')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('BetsOnProfessionalGames')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('TokenizationOfClansAndPlayers')}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='roadmap__item'>
                    <div className='roadmap__header'>
                      <span className='roadmap__title'>2024 Q4</span>
                    </div>
                    <div className='roadmap__main'>
                      <div className='roadmap__events events'>
                        <ul className='events__list'>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('NewMarketsStocksForexAndOthers')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('NewModes')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('EnteringTheMarketsOfNewCountries')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('PaymentOfDividendsToInvestors')}</span>
                            </a>
                          </li>
                          <li className='events__item'>
                            <a className='events__link'>
                              <TokenIcons name={'eventIco'} />
                              <span className='events__text'>{t('MajorTournaments')}</span>
                              <TokenIcons name={'eventArrowIco'} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='roadmap__action'>
                  <button className='btn' onClick={() => goToTokensRoadMapPage()}>
                    <span className='btn__text'>{t('FindOutMore')}</span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
