import React from 'react'
import Main from 'pages/common/components/Main/Main'
import banner__01 from './img/banner__img01.png'
import img01 from './img/img01.png'
import img02 from './img/img02.png'
import img03 from './img/img03.png'
import img04 from './img/img04.png'
import img05 from './img/img05.png'
import img06 from './img/img06.png'
import img07 from './img/img07.png'
import { useTranslation } from 'react-i18next'
export default function PurchaseAtCostPage() {
  const { t } = useTranslation()
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__banner banner'>
                <div className='row row_m-revers'>
                  <div className='col col_6 col_mob-12'>
                    <div className='banner__layout'>
                      <div className='banner__main'>
                        <div className='wysiwyg'>
                          <h1>{t('SoftwareDevelopment')}</h1>
                          <p>{t('OurTeamOfProfessionalsSpecializesInSoftwareDevelopment')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_6 col_mob-12'>
                    <div className='banner__media'>
                      <img src={banner__01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('ProjectsUnderDevelopment')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row'>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img01} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('P2Pplatform')}</h2>
                            <p>{t('OurTeamOfSpecialistsIsReadyToDevelopForYouAnIndividualP2PPlatform')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('WeWillProvideYourP2PPlatformWithAnIntuitiveInterfaceAndModernSecurityTools')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img02} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('ExOffice')}</h2>
                            <p>
                              {t('WeOfferTheDevelopmentOfACryptocurrencyExchangeOfficeThatWillMeetAllYourRequirements')}
                            </p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('YourExchangeOfficeWillBeEquippedWithAFunctionalAndUnderstandableInterfaceach')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img03} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('CryptocurrencyExchange')}</h2>
                            <p>{t('CreatingAFullFledgedCryptocurrencyExchangeRequiresDeepKnowledgeAndExperience')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>
                                {t(
                                  'YourCryptocurrencyExchangeWillBeEquippedWithEverythingYouNeedToConductFastAndSecureTransactions',
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img04} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Cryptoprocessing')}</h2>
                            <p>{t('TheDevelopmentOfACryptoProcessingSystemWillAllowYouToEnsureEfficient')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('OurCryptoProcessingSystemWillIntegrateWithYourCurrentBusinessProcesses')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img05} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('CreatingAForkOfAnotherProject')}</h2>
                            <p>{t('IfYouAreInterestedInCreatingAForkOfAnExistingBlockchainProject')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('OurExperienceInTheFieldOfBlockchainTechnologies')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__header'>
            <div className='container'>
              <div className='wysiwyg text-center'>
                <h1>{t('DevelopmentAndDesign')}</h1>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__groups groups'>
                <div className='groups__list'>
                  <div className='row'>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img06} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('BlockchainDevelopment')}</h2>
                            <p>{t('InAdditionToOurRangeOfSpecializedCryptocurrencyServices')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('WeAreReadyToTakeOnTheAevelopmentOfUniqueBlockchainSolutions')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col col_6 col_mob-12'>
                      <div className='groups__item'>
                        <div className='groups__header'>
                          <div className='groups__media groups__media_large'>
                            <img src={img07} alt='image description' />
                          </div>
                        </div>
                        <div className='groups__main'>
                          <div className='wysiwyg'>
                            <h2>{t('Design')}</h2>
                            <p>{t('OurTeamOfProfessionalDesignersIsReadyToDevelop')}</p>
                          </div>
                        </div>
                        <div className='groups__footer'>
                          <div className='groups__info'>
                            <div className='wysiwyg'>
                              <p>{t('WeWorkCloselyWithOurClientsToEnsureTheDesignFullyAligns')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
