import React from 'react'
import { cssClass } from 'helpers/helper'
import style from '../style/TokenPage.module.scss'
import TokenIcons from '../TokenData/TokenIcons'

export default function ProjectGoal({ t, currencyListing }) {
  return (
    <div className={cssClass(style.sidebar__group, style.group)}>
      <div className={cssClass(style.group__header)}>
        <span className={cssClass(style.group__title)}>{t('TokenProjectGoal')}:</span>
        <span className={cssClass(style.group__subtitle)}>{currencyListing?.projectGoal}</span>
      </div>
      <div className={cssClass(style.group__main)}>
        <ul className={cssClass(style.group__list)}>
          <li className={cssClass(style.group__item)}>
            <span className={cssClass(style.group__title)}>{t('TokenInvestmentConditions')}:</span>
            <span className={cssClass(style.group__subtitle)}>
              {currencyListing?.investmentCondition}
              <div className={cssClass(style.group__tooltip, style.tooltip)}>
                <div className={cssClass(style.tooltip__ico)}>
                  <TokenIcons name={'information'} />
                </div>
                <div className={cssClass(style.tooltip__dropdown)}>
                  <p> {currencyListing?.investmentConditionHint}</p>
                </div>
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}
