import React, { useState, useEffect } from 'react'
import Button from '../../../components/form/Button/Button'
import VerificationIcons from './VerificationIcons'
import steps from './images/steps.png'
import api from 'api'
import { STATUS_VERIFICATION_USER, USER_VERIFICATION_STAGE } from './VerificationData'

const AdvancedVerification = ({ t, onHandleAdvancedVerification, verificationData }) => {
  const [advancedVerificationNewData, setAdvancedVerificationNewData] = useState({
    userAuthenticationVideoFileName: null,
  })

  const [videoVerificationCode, setVideoVerificationCode] = useState(null)

  const handleVerificationDataChange = (value, fieldName) => {
    setAdvancedVerificationNewData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
  }

  const uploadFile = (file, docType, fieldName) => {
    if (!file) return

    const validFormats = ['video/mp4', 'video/ogg', 'video/webm', 'video/3gp', 'video/mov']
    if (!validFormats.includes(file.type)) {
      console.error('Invalid file format:', file.type)
      return
    }

    const maxFileSize = 50 * 1024 * 1024 // 50 MB
    if (file.size > maxFileSize) {
      console.error('File is too large:', file.size)
      return
    }
    api
      .saveVerificationFile(file, docType)
      .then((fileId) => {
        handleVerificationDataChange(fileId, fieldName)
      })
      .catch((error) => {
        console.error('Error uploading file:', error)
      })
  }
  const isEmptyFields = (data, exclusions = []) => {
    const result = Object.entries(data).some(
      ([key, value]) => !exclusions.includes(key) && (value === null || value === ''),
    )
    return result
  }
  const onSendClick = () => {
    if (!isEmptyFields(advancedVerificationNewData)) {
      const model = {
        ...verificationData,
        userAuthenticationVideoFileName: advancedVerificationNewData.userAuthenticationVideoFileName?.trim(),
        verificationStage: USER_VERIFICATION_STAGE.AdvancedVerification,
      }
      api
        .saveVerification(model)
        .then((data) => {
          onHandleAdvancedVerification(data)
        })
        .catch((error) => {
          console.error('save verification error!', error)
        })
    }
  }
  useEffect(() => {
    const codeArray = verificationData.verificationCode.split('').map(Number)
    setVideoVerificationCode(codeArray)
  }, [verificationData.verificationCode])

  return (
    <div className='verification__list'>
      <div className='verification__item'>
        <div className='verification__header'>
          <div className='verification__title'>
            <span className='verification__text h2'>{t('AdvancedVerification')}</span>
          </div>
        </div>
        <div className='verification__main'>
          <div className='account__tooltip'>
            <div className='tooltip__list'>
              <div className='tooltip__item tooltip__item_tertiary'>
                <span className='tooltip__text'>{t('PleaseNoteThatIdentificationInformationCannot')}</span>
              </div>
            </div>
          </div>

          <div className='account__instructions instructions'>
            <div className='instructions__header'>
              <div className='instructions__title'>
                <div className='wysiwyg'>
                  <h3>{t('CertificationInstructions')}:</h3>
                </div>
              </div>
              <div className='instructions__description'>
                <div className='wysiwyg'>
                  <p>
                    1. {t('PleaseRecordAVideoOnYourPhone')}
                    <strong> {videoVerificationCode}</strong>
                  </p>
                  <p>2. {t('DownloadOnlineAfterRecording')}</p>
                </div>
              </div>
            </div>
            <div className='instructions__main'>
              <div className='instructions__steps steps'>
                <div className='steps__list'>
                  {videoVerificationCode &&
                    videoVerificationCode.map((c, index) => (
                      <div key={index} className='steps__item'>
                        <span className='steps__text'>{c}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='instructions__information'>
                <div className='wysiwyg'>
                  <p>* {t('OnceTheVideoHasBeenSuccessfully')}</p>
                </div>
              </div>
              <div className='instructions__formular formular'>
                <div className='form-item'>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <div className='form-item__attach attach'>
                        <label className='attach__label'>
                          <input
                            type='file'
                            className='attach__input'
                            onChange={(event) =>
                              uploadFile(
                                event.target.files[0],
                                'UserAuthenticationVideo',
                                'userAuthenticationVideoFileName',
                              )
                            }
                          />
                          <VerificationIcons
                            name={advancedVerificationNewData.userAuthenticationVideoFileName ? 'ico06' : 'ico05'}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='form-item__footer text-center'>
                    <span className='form-item__title'>
                      <strong>
                        {t('UploadAuthenticationVideo')} <span className='form-item__sup'>*</span>
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='instructions__media'>
              <img src={steps} alt='image description' />
            </div>
          </div>
          <div className='verification__formular formular'>
            <div className='formular__group'>
              <div className='form-item'>
                <Button
                  buttonCallback={() => onHandleAdvancedVerification()}
                  addClasses={['btn btn_revert w-100']}
                  text={t('Back')}
                />
              </div>
              <div className='form-item'>
                <Button
                  disabled={isEmptyFields(advancedVerificationNewData)}
                  buttonCallback={() => onSendClick()}
                  addClasses={['btn w-100']}
                  text={t('Send')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedVerification
