import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
const signalR = require('@microsoft/signalr')
import { cssClass, findCommission, calculateCommission } from 'helpers/helper'
import { useAuth } from 'auth/auth'
import api from 'api'
import store from '../../trading/store'
import basePairs, { allUsedCurrencies } from 'data/TradingPairs'
import CommissionTypes from 'data/CommissionTypes'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './ConverterSection.module.scss'

import { showCurrencyIcon, TimerMaxSeconds } from './ConverterCommon'
import ConverterCurrencyModal from './ConverterCurrencyModal'
import ConverterCartModal from './ConverterCartModal'
import i18n from 'i18next'

import useCurrencyData from 'pages/tokens/TokenData/useCurrencyData'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'
import { toJS } from 'mobx'

export default function ConverterSection() {
  const auth = useAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const [signalRTimerId, setSignalRTimerId] = useState(null)
  const [currencyModalOpened, setCurrencyModalOpened] = useState(false)
  const [currencyModalIsTop, setCurrencyModalIsTop] = useState(true)
  const [convertBtnDisabled, setConvertBtnDisabled] = useState(false)
  const [cartModalOpened, setCartModalOpened] = useState(false)
  const [cartModalSeconds, setCartModalSeconds] = useState(TimerMaxSeconds + 1)
  const [cartModalTimerId, setCartModalTimerId] = useState(null)
  const [tabCurrent, setTabCurrent] = useState(1)
  const [isBuy, setIsBuy] = useState(false)
  const [rate, setRate] = useState(0)
  const [rateWithFee, setRateWithFee] = useState(0)
  const [rateInputed, setRateInputed] = useState('')
  const [priceSpreads, setPriceSpreads] = useState([])
  const [amountTop, setAmountTop] = useState(0)
  const [amountBottom, setAmountBottom] = useState(0)
  const [isChangingAmountTop, setIsChangingAmountTop] = useState(true) // or AmountBottom

  // calculateRate from setInterval has isBuy closure for some reason, so we use ref to get actual value
  const isBuyRef = useRef(false)
  isBuyRef.current = isBuy
  const cartModalSecondsRef = useRef(TimerMaxSeconds + 1)
  cartModalSecondsRef.current = cartModalSeconds
  const cartModalTimerIdRef = useRef(null)
  cartModalTimerIdRef.current = cartModalTimerId

  const DefaultPairCode = 'ETH_USDT'

  const isLimitTab = tabCurrent === 2

  const redirectToRegister = () => {
    history.push(`/${i18n.language}/register`)
  }

  const redirectToFinance = (currency, method) => {
    history.push({
      pathname: `/${i18n.language}/finance`,
      params: {
        currency: currency,
        method: method,
      },
    })
  }

  const configureSignalR = () => {
    const { token_type, access_token } = auth.token || {}
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${api.ApiUrl}/sr/orders`, { accessTokenFactory: () => access_token })
      .configureLogging(signalR.LogLevel.Information)
      .build()
    store.setSignalRConn(hubConnection)
    hubConnection.start().then(() => {
      hubConnection.on('MainOrders', (currencyPairCode) => {
        // converter is shown in landing and /convert page
        if (location.pathname !== '/' && location.pathname !== '/convert') {
          disconnectSignalR() // signalR has stuck, disconnect
          return
        }
        //console.log('hubConnection isBuy', isBuy)
        if (store.symbol !== currencyPairCode) return
        //console.log('SignalR MainOrders', currencyPairCode)
        store.fetchOrders()
      })

      const keepAlivePeriod = 60 * 1000 // 1 minute
      const timerId = setInterval(function () {
        hubConnection.invoke('KeepAlive', '').catch((err) => console.log('KeepAlive Error: ' + err))
      }, keepAlivePeriod)
      setSignalRTimerId(timerId)

      console.log('SignalR configured', hubConnection)
    })
  }

  const disconnectSignalR = () => {
    if (signalRTimerId) {
      clearInterval(signalRTimerId)
      setSignalRTimerId(null)
    }
    if (store.signalRConn) {
      store.signalRConn.stop()
      store.setSignalRConn(null)
    }
  }

  const { digitsAmount, digitsPrice, digitsTotal } = store.getPairDigits(store.symbol)
  const digitsTop = isBuy ? digitsTotal : digitsAmount
  const digitsBottom = isBuy ? digitsAmount : digitsTotal
  console.log('digits', digitsAmount, digitsPrice, digitsTotal)

  const currencyTop = isBuy ? store.symbolQuote : store.symbolBase
  const currencyBottom = isBuy ? store.symbolBase : store.symbolQuote
  const commCurrencyCode = isBuy ? store.symbolBase : store.symbolQuote
  const commType = isBuy ? CommissionTypes.DealAsk : CommissionTypes.DealBid

  const [allPairs, setAllPairs] = useState(basePairs)

  let { commAmount, percent, minimum } = calculateCommission(
    store.commissions?.commissions,
    0,
    commType,
    commCurrencyCode,
    null,
    store.commissions?.userNoTradeCommissions,
  )

  useEffect(() => {
    let ignore = false

    store.setIsSignedIn(auth.isSignedIn)

    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    let symbol = params.symbol || localStorage.getItem('symbol') || DefaultPairCode
    let newIsBuy = params.isBuy || localStorage.getItem('isBuy') || true
    console.log('useEffect init', symbol, newIsBuy)
    updateSymbol(symbol, newIsBuy)

    store.fetchInstruments()
    store.fetchCommissions()
    console.log('auth.isSignedIn', auth.isSignedIn)
    if (auth.isSignedIn) {
      store.fetchBalance()
    }

    fetchPriceSpreads(basePairs)

    setTimeout(() => {
      configureSignalR()
    }, 2000)

    return () => {
      disconnectSignalR()
      ignore = true
    }
  }, [])

  useEffect(() => {
    // recalculate rate
    let timerId = setInterval(() => {
      calculateRate()
    }, 2 * 1000)
    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    const { symbol } = store
    const symbols = symbol.split('_')
    if (symbols.length !== 2 || symbols.some((s) => s === '') || priceSpreads.length === 0) return
    const isExist = priceSpreads.some((item) => item.currencyPair === symbol)
    if (isExist) return
    fetchPriceSpreads(symbol)
  }, [store.symbol])

  useEffect(() => {
    if (!store.instruments) return
    const instruments = toJS(store.getInstruments())

    const allPairSet = new Set(allPairs)
    const allInstrumentsPairCodes = instruments.map((pair) => pair.code)

    const newInstruments = allInstrumentsPairCodes.filter((pair) => !allPairSet.has(pair))

    const updatedAllPairs = [...allPairs, ...newInstruments]
    setAllPairs(updatedAllPairs)
  }, [store.instruments])

  useEffect(() => {
    // clear data on tab or currency change
    setAmountBottom(0)
    setAmountTop(0)
    setRateInputed('')
    setRate(0)
    setRateWithFee(0)
  }, [tabCurrent, store.symbolBase, store.symbolQuote])

  const fetchPriceSpreads = (pairs) => {
    api
      .fetchPriceSpreads(pairs)
      .then((data) => {
        if (ignore) return
        setPriceSpreads(...priceSpreads, ...data)
      })
      .catch((err) => console.log('fetchPriceSpreads error', err))
  }

  const updateSymbol = (symbol, newIsBuy) => {
    console.log('updateSymbol', symbol, newIsBuy)
    setIsBuy(newIsBuy)
    store.setSymbol(symbol)
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newSearch = qs.stringify({ ...params, symbol, isBuy: newIsBuy }, { addQueryPrefix: true })
    const url =
      i18n.language + '/' + window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
    window.history.pushState(null, null, window.location.origin + '/' + url + `${newSearch}`)
  }

  const calculateRate = () => {
    const currentRate = store.getRate(isBuyRef.current)
    console.log('calculateRate', currentRate, isBuyRef.current)
    if (!currentRate) {
      setRate(0)
      return
    }
    setRate(isBuyRef.current ? (1 / currentRate).toFixedTrimmed() : currentRate)
  }

  const changeCurrency = async (isTop, currency) => {
    console.log('changeCurrency', isTop, currency)
    const oppossing = isTop ? currencyBottom : currencyTop

    if (isTop) {
      // setting top currency
      if (!oppossing) {
        updateSymbol(`${currency}_`, isBuy)
        return
      }
      const q = allPairs
      const allInstruments = await store.getInstruments()
      if (allPairs.find((pair) => pair.currencyCode === `${currency}_${oppossing}`)) {
        updateSymbol(`${currency}_${oppossing}`, false)
      } else if (allPairs.find((pair) => pair === `${oppossing}_${currency}`)) {
        updateSymbol(`${oppossing}_${currency}`, true)
      } else {
        updateSymbol(`${currency}_`, false)
      }
    } else {
      // setting bottom currency
      if (!oppossing) {
        updateSymbol(`_${currency}`, isBuy)
        return
      }
      if (allPairs.find((pair) => pair === `${oppossing}_${currency}`)) {
        updateSymbol(`${oppossing}_${currency}`, false)
      } else if (allPairs.find((pair) => pair === `${currency}_${oppossing}`)) {
        updateSymbol(`${currency}_${oppossing}`, true)
      } else {
        updateSymbol(`_${currency}`, false)
      }
    }
  }

  const onChangeAmountTop = (e) => {
    setIsChangingAmountTop(true)
    const valueNum = Number(e.target.value)
    if (valueNum === NaN) return
    setAmountTop(e.target.value)
    const price = isLimitTab ? rateInputed : rate
    if (!price) return
    const rateWithFee1 = price * (1 - percent / 100)
    setRateWithFee(rateWithFee1)
    setAmountBottom((valueNum * rateWithFee1).toFixedTrimmed(digitsBottom))
  }

  const onChangeAmountBottom = (e) => {
    setIsChangingAmountTop(false)
    const valueNum = Number(e.target.value)
    if (valueNum === NaN) return
    setAmountBottom(e.target.value)
    const price = isLimitTab ? rateInputed : rate
    if (!price) return
    const rateWithFee1 = price * (1 - percent / 100)
    setRateWithFee(rateWithFee1)
    setAmountTop((valueNum / rateWithFee1).toFixedTrimmed(digitsTop))
  }

  const onChangeRateInputed = (e) => {
    const valueNum = Number(e.target.value)
    if (valueNum === NaN) return
    setRateInputed(e.target.value)
  }

  useEffect(() => {
    // recalculate amountBottom or amountTop when rateInputed is changed
    if (isChangingAmountTop) onChangeAmountTop({ target: { value: amountTop } })
    else onChangeAmountBottom({ target: { value: amountBottom } })
  }, [rateInputed])

  // if market tab then start countdown when cart is opened
  useEffect(() => {
    if (cartModalTimerIdRef.current) {
      // clear old timer if exists
      clearInterval(cartModalTimerId)
      setCartModalTimerId(null)
    }

    if (!isLimitTab && cartModalOpened) {
      setCartModalSeconds(TimerMaxSeconds)
      const timerId = setInterval(() => {
        console.log('interval cartModalSeconds', cartModalSecondsRef.current)
        setCartModalSeconds(cartModalSecondsRef.current - 1)
        if (cartModalSecondsRef.current <= 1) {
          console.log('interval close modal', cartModalSecondsRef.current)
          clearInterval(cartModalTimerIdRef.current)
          setCartModalTimerId(null)
          setCartModalOpened(false)
        }
      }, 1000)
      setCartModalTimerId(timerId)
    }
  }, [cartModalOpened])

  const addOrder = () => {
    if (!(Number(amountTop) > 0 && Number(amountBottom) > 0)) return

    let amountInputed = isChangingAmountTop ? amountTop : amountBottom
    let currencyImputed = isChangingAmountTop ? currencyTop : currencyBottom
    let price = isLimitTab ? rateInputed : rate
    if (price <= 0) {
      console.log('invalid price', price)
      return
    }

    let amountForBackend
    if (store.symbolBase === currencyImputed) amountForBackend = amountInputed
    else amountForBackend = amountInputed * price
    amountForBackend = Number(amountForBackend).toFixedTrimmed(digitsAmount)

    if (isLimitTab && store.symbolBase === currencyBottom) {
      // invert price back if it was inverted
      price = Number(1 / price).toFixedTrimmed(digitsPrice)
    }

    let order = {
      isMarket: !isLimitTab,
      isBid: currencyBottom === store.symbolBase,
      amount: Number(amountForBackend),
      price: isLimitTab ? Number(price) : 0,
    }

    setConvertBtnDisabled(true)
    store
      .createOrder(order)
      .then(() => {
        // notification is handled in store method
        setAmountTop('')
        setAmountBottom('')
        setConvertBtnDisabled(false)
        setCartModalOpened(false)

        // update balances now and a little later because order takes time
        store.fetchBalance()
        setTimeout(() => {
          store.fetchBalance()
        }, 4000)
      })
      .catch(() => {
        setConvertBtnDisabled(false)
        store.fetchBalance()
      })
  }

  const onBlurInput = (e, setter) => {
    if (Number(e.target.value) == NaN) return
    setter(Number(e.target.value).toFixedTrimmed(digitsAmount))
  }

  const numToString = (num) => {
    const result = isNaN(num) || num === undefined || num === null ? '' : num.toString()
    return result
  }

  return (
    <div className={cssClass(styles.converter)}>
      <div className={cssClass(stylesGlobal.tabs)}>
        <div className={cssClass(stylesGlobal.tabs__header, stylesGlobal.tabs__header_second)}>
          <div className={cssClass(stylesGlobal.tabs__list)}>
            <div
              className={cssClass(
                stylesGlobal.tabs__item,
                tabCurrent === 1 ? stylesGlobal.tabs__item_active : stylesGlobal.tabs__item_disabled,
              )}
              onClick={() => setTabCurrent(1)}
            >
              <span className={cssClass(stylesGlobal.tabs__title)}>{t('ConverterTabMarket')}</span>
            </div>
            <div
              className={cssClass(
                stylesGlobal.tabs__item,
                isLimitTab ? stylesGlobal.tabs__item_active : stylesGlobal.tabs__item_disabled,
              )}
              onClick={() => setTabCurrent(2)}
            >
              <span className={cssClass(stylesGlobal.tabs__title)}>{t('ConverterTabLimit')}</span>
            </div>
          </div>
        </div>

        <div className={cssClass(stylesGlobal.buttons)}>
          <div className={cssClass(stylesGlobal.buttons__list, stylesGlobal.justifyCenter)}>
            <div
              className={cssClass(stylesGlobal.buttons__item)}
              onClick={auth.token ? () => redirectToFinance(currencyTop, 'deposit') : redirectToRegister}
            >
              <span className={cssClass(stylesGlobal.btn, stylesGlobal.btn_tertiaty, stylesGlobal.btn_tiny)}>
                <span className={cssClass(stylesGlobal.btn__text)}>
                  {currencyTop} {t('Deposit')}
                </span>
              </span>
            </div>
            <div
              className={cssClass(stylesGlobal.buttons__item)}
              onClick={auth.token ? () => redirectToFinance(currencyBottom, 'withdrawal') : redirectToRegister}
            >
              <span className={cssClass(stylesGlobal.btn, stylesGlobal.btn_tertiaty, stylesGlobal.btn_tiny)}>
                <span className={cssClass(stylesGlobal.btn__text)}>
                  {currencyBottom} {t('Withdraw')}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={cssClass(stylesGlobal.tabs__body)}>
          <div className={cssClass(stylesGlobal.tabs__list)}>
            {tabCurrent === 1 && (
              <div className={cssClass(stylesGlobal.tabs__item, stylesGlobal.tabs__item_active)}>
                <div className={cssClass(stylesGlobal.converter__formular, stylesGlobal.formular)}>
                  <form className={cssClass(stylesGlobal.converter__form)}>
                    <div className={cssClass(styles.formular__group)}>
                      <div className={cssClass(styles.converter__order, styles.order)}>
                        <div className={cssClass(styles.order__group)}>
                          <div className={cssClass(styles.group__list)}>
                            <div className={cssClass(styles.group__item)}>
                              <div className={cssClass(styles.order__header)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{t('ConverterYouGive')}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>
                                          {t('Balance')}: {store.getFreeBalance(currencyTop) ?? ''} {currencyTop}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={cssClass(styles.order__main)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(styles.order__price, styles.price)}>
                                      <div className={cssClass(styles.price__main)}>
                                        <input
                                          className={cssClass(styles.price__input)}
                                          type='text'
                                          value={numToString(amountTop)}
                                          onInput={onChangeAmountTop}
                                          onBlur={(e) => onBlurInput(e, setAmountTop)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div
                                      className={cssClass(styles.order__symbol, styles.symbol)}
                                      onClick={() => {
                                        setCurrencyModalIsTop(true)
                                        setCurrencyModalOpened(true)
                                      }}
                                    >
                                      <div className={cssClass(styles.symbol__media)}>
                                        <CurrencyIcon currencyCode={currencyTop} />
                                      </div>
                                      <div className={cssClass(styles.symbol__title)}>
                                        <span className={cssClass(styles.symbol__text)}>{currencyTop}</span>
                                      </div>
                                      <div className={cssClass(styles.symbol__action)}>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='12'
                                          height='7'
                                          viewBox='0 0 12 7'
                                          fill='none'
                                        >
                                          <path
                                            d='M1.64295 0.69526L6.00346 5.05573L10.3639 0.69526C10.6243 0.434913 11.0464 0.434913 11.3067 0.69526C11.5671 0.955613 11.5671 1.37772 11.3067 1.63807L6.47486 6.46993C6.34979 6.595 6.18026 6.6652 6.00346 6.6652C5.82666 6.6652 5.65706 6.595 5.53206 6.46993L0.700144 1.63807C0.667598 1.60553 0.639125 1.57045 0.614718 1.53349C0.443865 1.27472 0.472338 0.923067 0.700144 0.69526C0.960491 0.434913 1.3826 0.434913 1.64295 0.69526Z'
                                            fill='#202639'
                                            fillOpacity='0.5'
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cssClass(styles.order__revert)}
                          onClick={(e) => updateSymbol(store.symbol, !isBuy)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='18'
                            viewBox='0 0 21 18'
                            fill='none'
                          >
                            <path
                              d='M15.5 1L15.5 17M15.5 17L11.5 13M15.5 17L19.5 13M5.5 17L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5'
                              stroke='#202639'
                              strokeOpacity='0.5'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <div className={cssClass(styles.order__group)}>
                          <div className={cssClass(styles.group__list)}>
                            <div className={cssClass(styles.group__item)}>
                              <div className={cssClass(styles.order__header)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{t('ConverterYouReceive')}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>
                                          {t('Balance')}: {store.getFreeBalance(currencyBottom) ?? ''} {currencyBottom}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={cssClass(styles.order__main)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(styles.order__price, styles.price)}>
                                      <div className={cssClass(styles.price__main)}>
                                        <input
                                          className={cssClass(styles.price__input)}
                                          type='text'
                                          value={numToString(amountBottom)}
                                          onInput={onChangeAmountBottom}
                                          onBlur={(e) => onBlurInput(e, setAmountBottom)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div
                                      className={cssClass(styles.order__symbol, styles.symbol)}
                                      onClick={() => {
                                        setCurrencyModalIsTop(false)
                                        setCurrencyModalOpened(true)
                                      }}
                                    >
                                      <div className={cssClass(styles.symbol__media)}>
                                        <CurrencyIcon currencyCode={currencyBottom} />
                                      </div>
                                      <div className={cssClass(styles.symbol__title)}>
                                        <span className={cssClass(styles.symbol__text)}>{currencyBottom}</span>
                                      </div>
                                      <div className={cssClass(styles.symbol__action)}>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='12'
                                          height='7'
                                          viewBox='0 0 12 7'
                                          fill='none'
                                        >
                                          <path
                                            d='M1.64295 0.69526L6.00346 5.05573L10.3639 0.69526C10.6243 0.434913 11.0464 0.434913 11.3067 0.69526C11.5671 0.955613 11.5671 1.37772 11.3067 1.63807L6.47486 6.46993C6.34979 6.595 6.18026 6.6652 6.00346 6.6652C5.82666 6.6652 5.65706 6.595 5.53206 6.46993L0.700144 1.63807C0.667598 1.60553 0.639125 1.57045 0.614718 1.53349C0.443865 1.27472 0.472338 0.923067 0.700144 0.69526C0.960491 0.434913 1.3826 0.434913 1.64295 0.69526Z'
                                            fill='#202639'
                                            fillOpacity='0.5'
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={cssClass(styles.formular__group)}>
                      <span
                        className={cssClass(stylesGlobal.btn)}
                        onClick={() => {
                          if (amountTop > 0 && amountBottom > 0) setCartModalOpened(true)
                        }}
                      >
                        <span className={cssClass(stylesGlobal.btn__text)}>{t('ConverterBtnEnterAmount')}</span>
                        <svg
                          className={cssClass(stylesGlobal.btn__ico)}
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='25'
                          viewBox='0 0 25 25'
                          fill='none'
                        >
                          <path
                            d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                            fill='white'
                          />
                        </svg>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {isLimitTab && (
              <div className={cssClass(stylesGlobal.tabs__item)}>
                <div className={cssClass(stylesGlobal.converter__formular, stylesGlobal.formular)}>
                  <form className={cssClass(stylesGlobal.converter__form)}>
                    <div className={cssClass(styles.formular__group)}>
                      <div className={cssClass(styles.converter__order, styles.order)}>
                        <div className={cssClass(styles.order__group)}>
                          <div className={cssClass(styles.group__list)}>
                            <div className={cssClass(styles.group__item)}>
                              <div className={cssClass(styles.order__header)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{t('ConverterYouGive')}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>
                                          {t('Balance')}: {store.getFreeBalance(currencyTop) ?? ''} {currencyTop}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={cssClass(styles.order__main)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(styles.order__price, styles.price)}>
                                      <div className={cssClass(styles.price__main)}>
                                        <input
                                          className={cssClass(styles.price__input)}
                                          type='text'
                                          value={numToString(amountTop)}
                                          onInput={onChangeAmountTop}
                                          onBlur={(e) => onBlurInput(e, setAmountTop)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div
                                      className={cssClass(styles.order__symbol, styles.symbol)}
                                      onClick={() => {
                                        setCurrencyModalIsTop(true)
                                        setCurrencyModalOpened(true)
                                      }}
                                    >
                                      <div className={cssClass(styles.symbol__media)}>
                                        <CurrencyIcon currencyCode={currencyTop} />
                                      </div>
                                      <div className={cssClass(styles.symbol__title)}>
                                        <span className={cssClass(styles.symbol__text)}>{currencyTop}</span>
                                      </div>
                                      <div className={cssClass(styles.symbol__action)}>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='12'
                                          height='7'
                                          viewBox='0 0 12 7'
                                          fill='none'
                                        >
                                          <path
                                            d='M1.64295 0.69526L6.00346 5.05573L10.3639 0.69526C10.6243 0.434913 11.0464 0.434913 11.3067 0.69526C11.5671 0.955613 11.5671 1.37772 11.3067 1.63807L6.47486 6.46993C6.34979 6.595 6.18026 6.6652 6.00346 6.6652C5.82666 6.6652 5.65706 6.595 5.53206 6.46993L0.700144 1.63807C0.667598 1.60553 0.639125 1.57045 0.614718 1.53349C0.443865 1.27472 0.472338 0.923067 0.700144 0.69526C0.960491 0.434913 1.3826 0.434913 1.64295 0.69526Z'
                                            fill='#202639'
                                            fillOpacity='0.5'
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={cssClass(styles.group__item)}>
                              <div className={cssClass(styles.order__header)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{t('ConverterPrice')}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>
                                          {t('ConverterMarketRate')}: {rate} {currencyBottom}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={cssClass(styles.order__main)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(styles.order__price, styles.price)}>
                                      <div className={cssClass(styles.price__header)}>
                                        <span className={cssClass(styles.price__title)}>1 {currencyTop} = </span>
                                      </div>
                                      <div className={cssClass(styles.price__main)}>
                                        <input
                                          className={cssClass(styles.price__input)}
                                          type='text'
                                          value={numToString(rateInputed)}
                                          onInput={onChangeRateInputed}
                                          onBlur={(e) => onBlurInput(e, setRateInputed)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item, styles.order__item_second)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{currencyBottom}</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cssClass(styles.order__revert)}
                          onClick={(e) => updateSymbol(store.symbol, !isBuy)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='18'
                            viewBox='0 0 21 18'
                            fill='none'
                          >
                            <path
                              d='M15.5 1L15.5 17M15.5 17L11.5 13M15.5 17L19.5 13M5.5 17L5.5 1M5.5 1L1.5 5M5.5 1L9.5 5'
                              stroke='#202639'
                              strokeOpacity='0.5'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                        <div className={cssClass(styles.order__group)}>
                          <div className={cssClass(styles.group__list)}>
                            <div className={cssClass(styles.group__item)}>
                              <div className={cssClass(styles.order__header)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>{t('ConverterYouReceive')}</strong>
                                      </p>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(stylesGlobal.wysiwyg)}>
                                      <p>
                                        <strong>
                                          {t('Balance')}: {store.getFreeBalance(currencyBottom) ?? ''} {currencyBottom}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={cssClass(styles.order__main)}>
                                <div className={cssClass(styles.order__list)}>
                                  <div className={cssClass(styles.order__item)}>
                                    <div className={cssClass(styles.order__price, styles.price)}>
                                      <div className={cssClass(styles.price__main)}>
                                        <input
                                          className={cssClass(styles.price__input)}
                                          type='text'
                                          value={numToString(amountBottom)}
                                          onInput={onChangeAmountBottom}
                                          onBlur={(e) => onBlurInput(e, setAmountBottom)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cssClass(styles.order__item)}>
                                    <div
                                      className={cssClass(styles.order__symbol, styles.symbol)}
                                      onClick={() => {
                                        setCurrencyModalIsTop(false)
                                        setCurrencyModalOpened(true)
                                      }}
                                    >
                                      <div className={cssClass(styles.symbol__media)}>
                                        <CurrencyIcon currencyCode={currencyBottom} />
                                      </div>
                                      <div className={cssClass(styles.symbol__title)}>
                                        <span className={cssClass(styles.symbol__text)}>{currencyBottom}</span>
                                      </div>
                                      <div className={cssClass(styles.symbol__action)}>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='12'
                                          height='7'
                                          viewBox='0 0 12 7'
                                          fill='none'
                                        >
                                          <path
                                            d='M1.64295 0.69526L6.00346 5.05573L10.3639 0.69526C10.6243 0.434913 11.0464 0.434913 11.3067 0.69526C11.5671 0.955613 11.5671 1.37772 11.3067 1.63807L6.47486 6.46993C6.34979 6.595 6.18026 6.6652 6.00346 6.6652C5.82666 6.6652 5.65706 6.595 5.53206 6.46993L0.700144 1.63807C0.667598 1.60553 0.639125 1.57045 0.614718 1.53349C0.443865 1.27472 0.472338 0.923067 0.700144 0.69526C0.960491 0.434913 1.3826 0.434913 1.64295 0.69526Z'
                                            fill='#202639'
                                            fillOpacity='0.5'
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={cssClass(styles.formular__group)}>
                      <span className={cssClass(stylesGlobal.btn)} onClick={() => setCartModalOpened(true)}>
                        <span className={cssClass(stylesGlobal.btn__text)}>{t('ConverterBtnEnterAmount')}</span>
                        <svg
                          className={cssClass(stylesGlobal.btn__ico)}
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='25'
                          viewBox='0 0 25 25'
                          fill='none'
                        >
                          <path
                            d='M8.61561 7.35156H16.4514L5.43059 18.3724C5.35136 18.4516 5.2882 18.5408 5.24112 18.636C5.11416 18.8926 5.10409 19.1923 5.21092 19.4558C5.25974 19.5761 5.33296 19.6889 5.43059 19.7866C5.82111 20.1771 6.45428 20.1771 6.8448 19.7866L17.8656 8.76577V16.6015C17.8656 17.1538 18.3133 17.6015 18.8656 17.6015C19.4179 17.6015 19.8656 17.1538 19.8656 16.6015V6.35156C19.8656 6.31704 19.8639 6.28293 19.8605 6.24931C19.8104 5.75641 19.4024 5.36933 18.9004 5.35215C18.8889 5.35176 18.8773 5.35156 18.8656 5.35156H8.61561C8.06333 5.35156 7.61562 5.79927 7.61562 6.35156C7.61561 6.90384 8.06333 7.35156 8.61561 7.35156Z'
                            fill='white'
                          />
                        </svg>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ConverterCurrencyModal
        isOpened={currencyModalOpened}
        setOpened={setCurrencyModalOpened}
        isTop={currencyModalIsTop}
        changeCurrency={changeCurrency}
        priceSpreads={priceSpreads}
        allPairs={allPairs}
      />

      <ConverterCartModal
        isOpened={cartModalOpened}
        setOpened={setCartModalOpened}
        currencyTop={currencyTop}
        currencyBottom={currencyBottom}
        amountTop={amountTop}
        amountBottom={amountBottom}
        rate={isLimitTab ? rateInputed : rateWithFee}
        digitsPrice={digitsPrice}
        isAmountSufficient={store.getFreeBalance(currencyTop) >= amountTop}
        cartModalSeconds={cartModalSeconds}
        convertBtnDisabled={convertBtnDisabled}
        addOrder={addOrder}
      />
    </div>
  )
}
