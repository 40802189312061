import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/form/Button/Button'
import { toast } from 'react-toastify'
import api from '../../../api'
import ModalPayment from './Modals/ModalPayment'

export default function UserRequisites() {
  const { t } = useTranslation()
  const [modalPayment, setModalPayment] = useState(false)
  const [requisites, setRequisites] = useState([])
  const [updateRequisite, setUpdateRequisite] = useState(false)

  const loadRequsites = (ignore = false) => {
    api.fetchP2PRequisites().then((data) => {
      if (ignore) return
      setRequisites(data)
    })
  }

  const deleteRequisite = (id) => {
    api
      .deleteP2PRequisite(id)
      .then(() => {
        loadRequsites()
      })
      .catch(() => {
        console.log('deleteRequisite error', err)
        toast.error(t('ErrorTryLater'))
      })
  }

  useEffect(() => {
    let ignore = false
    loadRequsites(ignore)
    return () => {
      ignore = true
    }
  }, [modalPayment])

  return (
    <React.Fragment>
      <ModalPayment
        opened={modalPayment}
        setOpened={() => {
          setModalPayment(false)
        }}
        requisiteForUpdating={updateRequisite}
      />
      <div className='user__payments payments'>
        <div className='payments__header'>
          <div className='row'>
            <div className='col col_8 col_mob-12'>
              <div className='payments__description'>
                <div className='wysiwyg'>
                  <h2>{t('ConverterPaymentMethod')}</h2>
                  <p>{t('ThePaymentMethodYouAddWill')}</p>
                </div>
              </div>
            </div>
            <div className='col col_4 col_mob-12'>
              <div className='payments__action'>
                <Button
                  buttonCallback={() => setModalPayment(true)}
                  addClasses={['btn btn_secondary-padding']}
                  text={t('AddApaymentMethod')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='payments__main'>
          <div className='payments__list'>
            {requisites.map((requisite) => (
              <div className='payments__item payment' key={requisite.id}>
                <div className='payment__layout'>
                  <div className='payment__header'>
                    <div className='payment__title title'>
                      <span className='title__text'>
                        {requisite.paymentSystem.name} ({requisite.paymentMethodCurrencyCode})
                      </span>
                    </div>
                  </div>
                  <div className='payment__main'>
                    <div className='payment__data data'>
                      <div className='data__list'>
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('LastNameFirstName')}</span>
                          </div>
                          <div className='data__main'>
                            <span className='data__text'>{requisite.ownerName}</span>
                          </div>
                        </div>
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('P2PPaymentDetails')}</span>
                          </div>
                          <div className='data__main'>
                            <span className='data__text'>{requisite.cardNumber}</span>
                          </div>
                        </div>
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('AccountOpeningDepartment')}</span>
                          </div>
                          <div className='data__main'>
                            <span className='data__text'>{requisite.bankBranch}</span>
                          </div>
                        </div>
                        <div className='data__item'>
                          <div className='data__header'>
                            <span className='data__title'>{t('BankName')}</span>
                          </div>
                          <div className='data__main'>
                            <span className='data__text'>{requisite.bankName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='payment__action action'>
                  <div className='action__list'>
                    <div className='action__item'>
                      <Button
                        addClasses={['btn btn_secondary-padding']}
                        text={t('Refresh')}
                        buttonCallback={() => {
                          setUpdateRequisite(requisite)
                          setModalPayment(true)
                        }}
                      />
                    </div>
                    <div className='action__item'>
                      <Button
                        addClasses={['btn btn_revert btn_secondary-padding']}
                        text={t('Delete')}
                        buttonCallback={() => deleteRequisite(requisite.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
