import React, { useState, useEffect } from 'react'

const SellerDealCancelDisputeTimer = ({ initialMinutes, dateCreated }) => {
  const [timeLeft, setTimeLeft] = useState({ minutesLeft: null, secondsLeft: 0 })

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date()
      const dateCreatedDate = new Date(dateCreated)
      const maxTimeMs = initialMinutes * 60 * 1000
      const timePassedMs = now - dateCreatedDate
      const timeLeftMs = maxTimeMs - timePassedMs
      if (timeLeftMs < 0) {
        clearInterval(interval)
        return { minutesLeft: 0, secondsLeft: 0 }
      }

      const minutesLeft = Math.floor(timeLeftMs / (60 * 1000))
      const secondsLeft = Math.floor((timeLeftMs % (60 * 1000)) / 1000)

      return { minutesLeft, secondsLeft }
    }

    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(interval)
  }, [dateCreated, initialMinutes])

  if (!timeLeft?.minutesLeft && !timeLeft?.secondsLeft)
    return (
      <>
        <strong>--</strong>
        <span className='clock__delivery'>:</span>
        <strong>--</strong>
      </>
    )

  return (
    <>
      <strong>{String(timeLeft.minutesLeft).padStart(2, '0')}</strong>
      <span className='clock__delivery'>:</span>
      <strong>{String(timeLeft.secondsLeft).padStart(2, '0')}</strong>
    </>
  )
}

const CalculateRefreshTime = (dateCreated, lifetimeMins) => {
  if (!dateCreated || !lifetimeMins) return false
  const dateCreatedDate = new Date(dateCreated)
  const updateDate = new Date(dateCreatedDate.getTime() + lifetimeMins * 60 * 1000)
  return updateDate - new Date().getTime()
}

export { SellerDealCancelDisputeTimer as DealSellerDisputeTimer, CalculateRefreshTime }
