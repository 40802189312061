import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'components/layout/Modal/Modal'
import SubmitAndContactButtons from './SubmitAndContactButtons'

export default function HelpOtherModal({ onConfirmOtherModal, opened, onClose, deal }) {
  if (!deal) return null

  const { t } = useTranslation()
  const cause = 'P2PCauseOther'

  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('GetHelp')}</h2>
          <p>
            <strong>{t(cause)}</strong>
            <br />
            <small className='transparentHalf'>{t('TryToResolveTheIssueByFollowingTheseSteps')}</small>
          </p>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='wysiwyg text-center'>
            <p>
              <strong>{t('TryContactingTheCounterpartyToSeeIfYouCanResolveTheIssue')}</strong>
            </p>
          </div>
        </div>
        <div className='wysiwyg text-center transparentHalf'>
          <p>
            <strong>{t('IfTheIssueCannotBeResolvedYouCanFileAnAppeal')}</strong>
          </p>
        </div>
      </div>
      <SubmitAndContactButtons
        onContact={() => {
          onClose()
          onConfirmOtherModal('onContact')
        }}
        onSubmitAnAppeal={() => {
          onClose()
          onConfirmOtherModal('onSubmitAnAppeal', cause)
        }}
        onClose={onClose}
        deal={deal}
      />
    </Modal>
  )
}
