import React, { useEffect, useState } from 'react'
import Header from '../../common/components/Header/Header.jsx'
import Footer from '../../common/components/Footer/Footer.jsx'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import '../scss/main.sass'
import investments__img01 from '../img/investments__img01.png'
import partners__img01 from '../img/partners__img01.png'
import about__img01 from '../img/about__img01.png'
import banner__img01 from '../img/banner__img01.png'
import cooperation__img01 from '../img/cooperation__img01.png'
import cooperation__img02 from '../img/cooperation__img02.png'
import cooperation__img03 from '../img/cooperation__img03.png'
import cooperation__img04 from '../img/cooperation__img04.png'
import cooperation__img05 from '../img/cooperation__img05.png'

import table__ico01 from '../img/table__ico01.png'
import table__ico02 from '../img/table__ico02.png'
import table__ico03 from '../img/table__ico03.png'
import table__ico04 from '../img/table__ico04.png'
import table__ico05 from '../img/table__ico05.png'

import steps__img01 from '../img/steps__img01.png'
import steps__img02 from '../img/steps__img02.png'
import steps__img03 from '../img/steps__img03.png'
import steps__img04 from '../img/steps__img04.png'
import steps__img05 from '../img/steps__img05.png'
import steps__img06 from '../img/steps__img06.png'
import steps__img07 from '../img/steps__img07.png'
import steps__img08 from '../img/steps__img08.png'
import poster__img01 from '../img/poster__img01.jpg'
import { useTranslation } from 'react-i18next'
let ico_chevronRight = (
    <svg className='link__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'>
      <path
        d='M12.3584 9.40825L8.82507 5.87492C8.74761 5.79681 8.65544 5.73481 8.55389 5.69251C8.45234 5.6502 8.34342 5.62842 8.23341 5.62842C8.1234 5.62842 8.01448 5.6502 7.91293 5.69251C7.81138 5.73481 7.71921 5.79681 7.64174 5.87492C7.48653 6.03105 7.39941 6.24226 7.39941 6.46242C7.39941 6.68257 7.48653 6.89378 7.64174 7.04992L10.5917 9.99992L7.64174 12.9499C7.48653 13.1061 7.39941 13.3173 7.39941 13.5374C7.39941 13.7576 7.48653 13.9688 7.64174 14.1249C7.71961 14.2022 7.81195 14.2633 7.91349 14.3047C8.01502 14.3462 8.12374 14.3672 8.23341 14.3666C8.34308 14.3672 8.4518 14.3462 8.55333 14.3047C8.65486 14.2633 8.74721 14.2022 8.82507 14.1249L12.3584 10.5916C12.4365 10.5141 12.4985 10.4219 12.5408 10.3204C12.5831 10.2188 12.6049 10.1099 12.6049 9.99992C12.6049 9.88991 12.5831 9.78099 12.5408 9.67944C12.4985 9.57789 12.4365 9.48572 12.3584 9.40825Z'
        fill='#1E1E1E'
      />
    </svg>
  ),
  ico_eventIco = (
    <svg className='events__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M20.4898 7.51996C20.4841 7.49359 20.4841 7.46632 20.4898 7.43996C20.485 7.41688 20.485 7.39304 20.4898 7.36996V7.27996L20.4298 7.12996C20.4054 7.08903 20.3751 7.05196 20.3398 7.01996L20.2498 6.93996H20.1998L16.2598 4.44996L12.5398 2.14996C12.4538 2.08169 12.3553 2.03075 12.2498 1.99996H12.1698C12.0804 1.98504 11.9892 1.98504 11.8998 1.99996H11.7998C11.6837 2.02565 11.5723 2.06952 11.4698 2.12996L3.99982 6.77996L3.90982 6.84996L3.81982 6.92996L3.71982 6.99996L3.66982 7.05996L3.60982 7.20996V7.29996V7.35996C3.60011 7.42627 3.60011 7.49365 3.60982 7.55996V16.29C3.60948 16.4599 3.65246 16.6271 3.7347 16.7759C3.81693 16.9246 3.93571 17.0499 4.07982 17.14L11.5798 21.78L11.7298 21.84H11.8098C11.979 21.8936 12.1606 21.8936 12.3298 21.84H12.4098L12.5598 21.78L19.9998 17.21C20.1439 17.1199 20.2627 16.9946 20.345 16.8459C20.4272 16.6971 20.4702 16.5299 20.4698 16.36V7.62996C20.4698 7.62996 20.4898 7.55996 20.4898 7.51996ZM11.9998 4.16996L13.7798 5.26996L8.18982 8.72996L6.39982 7.62996L11.9998 4.16996ZM10.9998 19.17L5.49982 15.81V9.41996L10.9998 12.82V19.17ZM11.9998 11.06L10.0898 9.90996L15.6798 6.43996L17.5998 7.62996L11.9998 11.06ZM18.4998 15.78L12.9998 19.2V12.82L18.4998 9.41996V15.78Z' />
    </svg>
  ),
  ico_eventArrow = (
    <svg className='events__arrow' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
      <path d='M14.9333 5.51667C14.8487 5.31304 14.6869 5.15123 14.4833 5.06667C14.3831 5.02397 14.2755 5.00132 14.1666 5H5.83328C5.61227 5 5.40031 5.0878 5.24403 5.24408C5.08775 5.40036 4.99995 5.61232 4.99995 5.83333C4.99995 6.05435 5.08775 6.26631 5.24403 6.42259C5.40031 6.57887 5.61227 6.66667 5.83328 6.66667H12.1583L5.24162 13.575C5.16351 13.6525 5.10151 13.7446 5.05921 13.8462C5.0169 13.9477 4.99512 14.0567 4.99512 14.1667C4.99512 14.2767 5.0169 14.3856 5.05921 14.4871C5.10151 14.5887 5.16351 14.6809 5.24162 14.7583C5.31908 14.8364 5.41125 14.8984 5.5128 14.9407C5.61435 14.9831 5.72327 15.0048 5.83328 15.0048C5.94329 15.0048 6.05221 14.9831 6.15376 14.9407C6.25531 14.8984 6.34748 14.8364 6.42495 14.7583L13.3333 7.84167V14.1667C13.3333 14.3877 13.4211 14.5996 13.5774 14.7559C13.7336 14.9122 13.9456 15 14.1666 15C14.3876 15 14.5996 14.9122 14.7559 14.7559C14.9122 14.5996 14.9999 14.3877 14.9999 14.1667V5.83333C14.9986 5.72444 14.976 5.61685 14.9333 5.51667Z' />
    </svg>
  ),
  ico_arrowTopRight = (
    <svg className='steps__arrow' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
      <path d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z' />
    </svg>
  ),
  ico_plus = (
    <svg className='partners__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' fill='none'>
      <rect x='0.5' y='0.5' width='51' height='51' rx='25.5' fill='white' fill-opacity='0.1' />
      <rect x='0.5' y='0.5' width='51' height='51' rx='25.5' stroke='white' />
      <rect x='25' y='18' width='2' height='16' rx='1' fill='white' />
      <rect x='34' y='25' width='2' height='16' rx='1' transform='rotate(90 34 25)' fill='white' />
    </svg>
  )

export default function LandingPageToken() {
  const [tabsPrimary, setTabsPrimary] = useState(1)
  const [tabsSecondary, setTabsSecondary] = useState(1)
  const [tabsTertiary, setTabsTertiary] = useState(1)
  const [tabsFourth, setTabsFourth] = useState(1)
  const [tabsFifty, setTabsFifty] = useState(1)
  const [scroll, setScroll] = useState(false)
  const [scrollCheck, setScrollCheck] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(document.getElementById('cooperation__main').getBoundingClientRect().top < 0)
      if (
        window.pageYOffset +
          document.getElementById('banner__wrapper').offsetHeight +
          document.getElementById('banner__wrapper').offsetTop >
        document.getElementById('layout__categories').offsetTop - 60
      ) {
        setScrollCheck(true)
      }
      if (document.getElementById('layout__categories').getBoundingClientRect().top > window.innerHeight)
        setScrollCheck(false)
    })
  }, [])

  return (
    <React.Fragment>
      <div className='layout_Tokens'>
        <Header />
        <main className='layout__main'>
          {/*  Investments */}
          <section className='layout__section section pb-0'>
            <div className='container'>
              <div className='section__main'>
                <div className='layout__investments investments'>
                  <div className='investments__layout'>
                    <div className='investments__main layout__box'>
                      <h1 className='investments__title'>
                        {t('InvestmentsInDigital')}
                        <strong>
                          &nbsp; {t('For')}&nbsp;
                          <ul class='animet-text'>
                            <li>
                              <span>{t('Everyone')}</span>
                            </li>
                            <li>
                              <span>{t('Investors')}</span>
                            </li>
                            <li>
                              <span>{t('Bloggers')}</span>
                            </li>
                            <li>
                              <span>{t('Traders')}</span>
                            </li>
                            <li>
                              <span>{t('Startups')}</span>
                            </li>
                            <li>
                              <span>{t('Creators')}</span>
                            </li>
                          </ul>
                        </strong>
                      </h1>
                      <span className='investments__text'>{t('CreateYourOwnUniqueToken')}</span>
                    </div>
                    <div className='investments__footer'>
                      <a className='btn btn_large' href='#'>
                        <span className='btn__text'>{t('CreateAToken')}</span>
                      </a>
                      <a className='btn btn_large btn_tertiary' href='#'>
                        <span className='btn__text'>{t('SelectExisting')}</span>
                      </a>
                    </div>
                  </div>
                  <div className='investments__media layout__box'>
                    <img className='table__ico' src={investments__img01} alt='image description' />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  Statistics */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__main'>
                <div className='layout__statistics statistics'>
                  <ul className='statistics__list'>
                    <li className='statistics__item layout__box layout__box_secondary'>
                      <span className='statistics__title layout__tag'>{t('NumberOfProjects')}:</span>
                      <span className='statistics__text'>200</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_secondary'>
                      <span className='statistics__title layout__tag'>{t('Capitalization')}:</span>
                      <span className='statistics__text'>$2M+</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_secondary'>
                      <span className='statistics__title layout__tag'>{t('DiscordCommunity')}:</span>
                      <span className='statistics__text'>10k</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_secondary'>
                      <span className='statistics__title layout__tag'>{t('TelegramCommunity')}:</span>
                      <span className='statistics__text'>2k</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/*  Partners */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__header'>
                <h3 className='section__title'>{t('InnovativeCompanies')}</h3>
              </div>
              <div className='section__main'>
                <div className='layout__partners partners'>
                  <ul className='partners__list'>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item'>
                      <a className='partners__link layout__box' href='#'>
                        <img className='partners__img' src={partners__img01} alt='image description' />
                      </a>
                    </li>
                    <li className='partners__item partners__item_secondary'>
                      <a className='partners__link layout__box' href='#'>
                        {ico_plus}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/*  Investors */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__main'>
                <div className='layout__investors investors'>
                  <div className='investors__layout'>
                    <div className='investors__header'>
                      <h2 className='investors__title'>{t('StartEarning')}</h2>
                    </div>
                    <div className='investors__main'>
                      <span className='investors__text'>{t('TradeCoinsCreated')}</span>
                    </div>
                    <div className='investors__footer'>
                      <a className='btn' href='#'>
                        <span className='btn__text'>{t('StartEarningMoney')}</span>
                      </a>
                    </div>
                  </div>

                  <div className='investors__tabs tabs layout__box'>
                    <div className='tabs__header'>
                      <ul className='tabs__list'>
                        <li
                          className={`tabs__item ${tabsPrimary == 1 ? 'tabs__item_active' : ''}`}
                          onClick={() => setTabsPrimary(1)}
                        >
                          <span className='tabs__title'>Popular</span>
                        </li>
                        <li
                          className={`tabs__item ${tabsPrimary == 2 ? 'tabs__item_active' : ''}`}
                          onClick={() => setTabsPrimary(2)}
                        >
                          <span className='tabs__title'>New Listing</span>
                        </li>
                        <li
                          className={`tabs__item ${tabsPrimary == 3 ? 'tabs__item_active' : ''}`}
                          onClick={() => setTabsPrimary(3)}
                        >
                          <span className='tabs__title'>Top Gainers</span>
                        </li>
                      </ul>
                    </div>
                    <div className='tabs__main'>
                      <div className='tabs__list'>
                        {tabsPrimary === 1 ? (
                          <div className='tabs__item'>
                            <div className='layout__markets markets'>
                              <div className='markets__section'>
                                <div className='markets__main'>
                                  <div className='markets__item markets__item_secondary market'>
                                    <div className='market__main'>
                                      <div className='market__table table'>
                                        <div className='table__header'>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <span className='table__title table__title_secondary'>
                                                Tranding Pairs
                                              </span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__title table__title_secondary'>
                                                Last <span className='table__subtitle'>Traided</span> Price
                                              </span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__title table__title_secondary'>24H Change</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__title table__title_secondary'>Trade</span>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className='table__main'>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <img className='table__ico' src={table__ico01} alt='image description' />
                                              <span className='table__text'>TKN</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text'>8,944.32</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text table__text_secondary'>+1.8%</span>
                                            </li>
                                            <li className='table__item'>
                                              <a className='btn btn_transparent btn_tiny' href='#'>
                                                <span className='btn__text'>Торговать</span>
                                              </a>
                                            </li>
                                          </ul>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <img className='table__ico' src={table__ico02} alt='image description' />
                                              <span className='table__text'>TKN</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text'>8,944.32</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text table__text_tertiary'>-0.22%</span>
                                            </li>
                                            <li className='table__item'>
                                              <a className='btn btn_transparent btn_tiny' href='#'>
                                                <span className='btn__text'>Торговать</span>
                                              </a>
                                            </li>
                                          </ul>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <img className='table__ico' src={table__ico03} alt='image description' />
                                              <span className='table__text'>TKN</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text'>8,944.32</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text table__text_secondary'>+1.8%</span>
                                            </li>
                                            <li className='table__item'>
                                              <a className='btn btn_transparent btn_tiny' href='#'>
                                                <span className='btn__text'>Торговать</span>
                                              </a>
                                            </li>
                                          </ul>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <img className='table__ico' src={table__ico04} alt='image description' />
                                              <span className='table__text'>TKN</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text'>8,944.32</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text table__text_secondary'>+1.8%</span>
                                            </li>
                                            <li className='table__item'>
                                              <a className='btn btn_transparent btn_tiny' href='#'>
                                                <span className='btn__text'>Торговать</span>
                                              </a>
                                            </li>
                                          </ul>
                                          <ul className='table__list table__list_tertiary'>
                                            <li className='table__item'>
                                              <img className='table__ico' src={table__ico05} alt='image description' />
                                              <span className='table__text'>TKN</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text'>8,944.32</span>
                                            </li>
                                            <li className='table__item'>
                                              <span className='table__text table__text_secondary'>+1.8%</span>
                                            </li>
                                            <li className='table__item'>
                                              <a className='btn btn_transparent btn_tiny' href='#'>
                                                <span className='btn__text'>Торговать</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {tabsPrimary === 2 ? <div className='tabs__item'>2</div> : null}
                        {tabsPrimary === 3 ? <div className='tabs__item'>3</div> : null}
                      </div>
                    </div>
                    <div className='tabs__footer'>
                      <a className='tabs__link link' href='#'>
                        <span className='link__text'>See all 350+ tokens</span>
                        {ico_chevronRight}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  About */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__main'>
                <div className='layout__about about'>
                  <div className='about__banner banner'>
                    <div className='banner__background'>
                      <img className='banner__img' src={about__img01} alt='image description' />
                    </div>
                    <div className='banner__header'>
                      <h2 className='banner__title'>{t('WeAreTheFirstTokenization')}</h2>
                    </div>
                    <div className='banner__footer'>
                      <a className='btn btn_tertiary' href='#'>
                        <span className='btn__text'>{t('StartEarningMoney')}</span>
                      </a>
                    </div>
                  </div>

                  <div className='about__main'>
                    <ul className='about__list'>
                      <li className='about__item layout__box layout__box_secondary'>
                        <span className='about__title layout__tag'>{t('FreeEntry')}</span>
                        <span className='about__text'>{t('CreateYourOwnTokenAbsolutelyFree')}</span>
                      </li>
                      <li className='about__item layout__box layout__box_secondary'>
                        <span className='about__title layout__tag'>{t('Investments')}</span>
                        <span className='about__text'>{t('InvestInOtherProjects')}</span>
                      </li>

                      <li className='about__item layout__box layout__box_secondary'>
                        <span className='about__title layout__tag'>{t('Verification')}</span>
                        <span className='about__text'>{t('ExploreCompleteInformationAbout')}</span>
                      </li>
                      <li className='about__item layout__box layout__box_secondary'>
                        <span className='about__title layout__tag'>{t('Convenience')}</span>
                        <span className='about__text'>{t('BecomeAnInvestorAndOwner')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*  Steps */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__header'>
                <div className='section__tabs tabs'>
                  <ul className='tabs__list'>
                    <li
                      className={`tabs__item ${tabsSecondary == 1 ? 'tabs__item_active' : ''}`}
                      onClick={() => setTabsSecondary(1)}
                    >
                      {t('IWantToCreateCoin')}
                    </li>
                    <li
                      className={`tabs__item ${tabsSecondary == 2 ? 'tabs__item_active' : ''}`}
                      onClick={() => setTabsSecondary(2)}
                    >
                      {t('IWantToStartInvesting')}
                    </li>
                  </ul>
                </div>
                {tabsSecondary === 1 ? (
                  <h2 className='section__title'>{t('CreateYourCoinAndStartAttracting')}</h2>
                ) : null}
                {tabsSecondary === 2 ? <h2 className='section__title'>{t('Become4SimpleSteps')}</h2> : null}
              </div>
              <div className='section__main'>
                {tabsSecondary === 1 ? (
                  <div className='layout__steps steps'>
                    <div className='steps__list'>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('FirstStep')}</span>
                        <span className='steps__text'>{t('RegisterAnAccountAndGoThroughVerification')}</span>
                        <img className='steps__img' src={steps__img01} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('SecondStep')}</span>
                        <span className='steps__text'>{t('CreateYourCoinByFillingOut')}</span>
                        <img className='steps__img' src={steps__img02} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('ThirdStep')}</span>
                        <span className='steps__text'>{t('PromoteTheCoinPage')}</span>
                        <img className='steps__img' src={steps__img03} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('FourthStep')}</span>
                        <span className='steps__text'>{t('EarnFromYourPartnersInvestments')}</span>
                        <img className='steps__img' src={steps__img04} alt='image description' />
                      </div>
                    </div>
                    <div className='steps__action'>
                      <a className='btn' href='#'>
                        <span className='btn__text'>{t('CreateAccount')}</span>
                      </a>
                    </div>
                  </div>
                ) : null}
                {tabsSecondary === 2 ? (
                  <div className='layout__steps steps'>
                    <div className='steps__list'>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('FirstStep')}</span>
                        <span className='steps__text'>{t('RegisterAnAccountAndGoThroughVerification')}</span>
                        <img className='steps__img' src={steps__img05} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('SecondStep')}</span>
                        <span className='steps__text'>{t('SelectACoinAndStudyTheProjectInformation')}</span>
                        <img className='steps__img' src={steps__img06} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('ThirdStep')}</span>
                        <span className='steps__text'>{t('TopUpYourAccountWith')}</span>
                        <img className='steps__img' src={steps__img07} alt='image description' />
                      </div>
                      <div className='steps__item layout__box layout__box_secondary'>
                        <span className='steps__title layout__tag'>{t('FourthStep')}</span>
                        <span className='steps__text'>{t('BuyCoinSharesStarting')}</span>
                        <img className='steps__img' src={steps__img08} alt='image description' />
                      </div>
                    </div>
                    <div className='steps__action'>
                      <a className='btn' href='#'>
                        <span className='btn__text'>{t('CreateAccount')}</span>
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
          {/*  Poster */}
          {/* <section className='layout__section section'>
            <div className='container'>
              <div className='section__header'>
                <h2 className='section__title'>Изучите, как работает наша платформа</h2>
              </div>
              <div className='section__main'>
                <div className='layout__poster poster'>
                  <div className='poster__media'>
                    <img src={poster__img01} alt='image description' />
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*  Cooperation */}
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__header section__header_secondary'>
                <h2 className='section__title'>{t('BecomeOursPartnerToday')}</h2>
              </div>
              <div className='section__main'>
                <div className='layout__cooperation cooperation'>
                  <div id='cooperation__banner' className='cooperation__banner'>
                    <div
                      id='banner__wrapper'
                      className={`banner__wrapper ${scroll ? 'banner__wrapper_fixed' : ''} ${
                        scrollCheck ? 'banner__wrapper_bottom' : ''
                      }`}
                    >
                      <div className='banner__background'>
                        <img className='banner__img' src={cooperation__img01} alt='image description' />
                      </div>

                      <div className='banner__header'>
                        <h3 className='banner__title'>{t('WeProvideTheBestConditions')}</h3>
                      </div>
                      <div className='banner__footer'>
                        <a className='btn btn_tertiary' href='#'>
                          <span className='btn__text'>{t('StartNow')}</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div id='cooperation__main' className='cooperation__main'>
                    <ul className='cooperation__list'>
                      <li className='cooperation__item layout__box layout__box_secondary'>
                        <span className='cooperation__title layout__tag'>{t('Entrepreneurs')}</span>

                        <span className='cooperation__text'>{t('CreateYourOwnCoinAndGainAccess')}</span>
                        <img className='cooperation__img' src={cooperation__img02} alt='image description' />
                      </li>
                      <li className='cooperation__item layout__box layout__box_secondary'>
                        <span className='cooperation__title layout__tag'>{t('Investors')}</span>

                        <span className='cooperation__text'>{t('SimplifyYourInvestmentProcesses')}</span>
                        <img className='cooperation__img' src={cooperation__img03} alt='image description' />
                      </li>
                      <li className='cooperation__item layout__box layout__box_secondary'>
                        <span className='cooperation__title layout__tag'>{t('BloggersAndContentCreators')}</span>

                        <span className='cooperation__text'>{t('StartUsingNewWaysToMonetizeContent')}</span>
                        <img className='cooperation__img' src={cooperation__img04} alt='image description' />
                      </li>
                      <li className='cooperation__item layout__box layout__box_secondary'>
                        <span className='cooperation__title layout__tag'>{t('TradersList')}</span>

                        <span className='cooperation__text'>{t('OpenTheDoorToFinancial')}</span>
                        <img className='cooperation__img' src={cooperation__img05} alt='image description' />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Categories */}
          <section id='layout__categories' className='layout__section section'>
            <div className='container'>
              <div className='section__header section__header_secondary'>
                <h2 className='section__title'>{t('CheckOutTheStep')}</h2>
              </div>
              <div className='section__main'>
                <div className='layout__categories categories'>
                  <aside className='categories__sidebar layout__box layout__box_secondary'>
                    <span className='categories__title layout__tag'>{t('SelectACategory')}:</span>

                    <nav className='categories__nav nav'>
                      <ul className='nav__list'>
                        <li
                          className={`nav__item ${tabsTertiary == 1 ? 'nav__item_active' : ''}`}
                          onClick={() => setTabsTertiary(1)}
                        >
                          <span className='nav__ico'>👨‍💻</span>
                          <span className='nav__text'>{t('IBusinessman')}</span>
                        </li>

                        <li
                          className={`nav__item ${tabsTertiary == 2 ? 'nav__item_active' : ''}`}
                          onClick={() => setTabsTertiary(2)}
                        >
                          <span className='nav__ico'>💸</span>
                          <span className='nav__text'>{t('IInvestor')}</span>
                        </li>
                        <li
                          className={`nav__item ${tabsTertiary == 3 ? 'nav__item_active' : ''}`}
                          onClick={() => setTabsTertiary(3)}
                        >
                          <span className='nav__ico'>🤳</span>
                          <span className='nav__text'>{t('IBlogger')}</span>
                        </li>
                        <li
                          className={`nav__item ${tabsTertiary == 4 ? 'nav__item_active' : ''}`}
                          onClick={() => setTabsTertiary(4)}
                        >
                          <span className='nav__ico'>📊</span>
                          <span className='nav__text'>{t('ITrader')}</span>
                        </li>
                      </ul>
                    </nav>
                  </aside>
                  <main className='categories__main layout__box'>
                    {tabsTertiary === 1 ? (
                      <div className='categories__item category'>
                        <div className='category__header'>
                          <h3 className='category__title'>{t('ExpandYourAudienceAndIncreaseCustomerLoyalty')}</h3>
                          <span className='category__ico'>👨‍💻</span>
                        </div>
                        <div className='category__main'>
                          <div className='category__group group'>
                            <div className='group__header'>
                              <span className='group__title h4'> {t('StepStrategy')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__steps steps'>
                                <ul className='steps__list'>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#1</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('ChoosingWhatYouWantToTokenize')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#2</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('CreatingAToken')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#3</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('AttractingAnAudience')}</span>
                                    </a>
                                  </li>

                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#4</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('ReceivingTheMoney')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#5</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('GrowingTogether')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#6</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('DividendPayment')}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className='category__group resgroupults'>
                            <div className='group__header'>
                              <span className='results__title h4'> {t('Result')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__results results'>
                                <ul className='results__list'>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('OwnCoin')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AttractingNewAssets')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AdvantageAmongCompetitors')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('Investments')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('NewAudience')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('IncreasedLoyalty')}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='category__footer'>
                          <a className='btn btn_radius-tertiary' href='#'>
                            <span className='btn__text'>{t('IWantToCreateCoin')}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                    {tabsTertiary === 2 ? (
                      <div className='categories__item category'>
                        <div className='category__header'>
                          <h3 className='category__title'>{t('SimplifyYourInvestmentProcesses')}</h3>
                          <span className='category__ico'>💸</span>
                        </div>
                        <div className='category__main'>
                          <div className='category__group group'>
                            <div className='group__header'>
                              <span className='group__title h4'> {t('StepStrategy')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__steps steps'>
                                <ul className='steps__list'>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#1</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('SearchForProjects')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#2</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('ProjectAnalysis')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#3</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('Investment')}</span>
                                    </a>
                                  </li>

                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#4</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('Rostproekt')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#5</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('ReceivingAProfit')}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className='category__group resgroupults'>
                            <div className='group__header'>
                              <span className='results__title h4'> {t('Result')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__results results'>
                                <ul className='results__list'>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('Profit')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AccessToOpinionLeaders')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('Empowerment')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('PortfolioDiversification')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AccessToProjects')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('DiveIntoTheNewRWATrend')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('PromotionsOfProjectsAtAnEarlyStage')}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='category__footer'>
                          <a className='btn btn_radius-tertiary' href='#'>
                            <span className='btn__text'>{t('IWantToCreateCoin')}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                    {tabsTertiary === 3 ? (
                      <div className='categories__item category'>
                        <div className='category__header'>
                          <h3 className='category__title'>{t('MonetizeYourAudience')}</h3>
                          <span className='category__ico'>🤳</span>
                        </div>
                        <div className='category__main'>
                          <div className='category__group group'>
                            <div className='group__header'>
                              <span className='group__title h4'> {t('StepStrategy')}:</span>
                            </div>

                            <div className='section__tabs section__tabs_secondary tabs'>
                              <ul className='tabs__list'>
                                <li
                                  className={`tabs__item ${tabsFifty == 1 ? 'tabs__item_active' : ''}`}
                                  onClick={() => setTabsFifty(1)}
                                >
                                  {t('FirstStrategyOption')}:
                                </li>
                                <li
                                  className={`tabs__item ${tabsFifty == 2 ? 'tabs__item_active' : ''}`}
                                  onClick={() => setTabsFifty(2)}
                                >
                                  {t('SecondStrategyOption')}:
                                </li>
                              </ul>
                            </div>

                            <div className='group__main'>
                              {tabsFifty === 1 ? (
                                <div className='category__steps steps'>
                                  <ul className='steps__list'>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#1</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('ChoosingWhatYouWantToTokenize')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#2</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('CreatingAToken')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#3</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('AttractingAnAudience')}</span>
                                      </a>
                                    </li>

                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#4</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('ReceivingTheMoney')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#5</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('GrowingTogether')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#6</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('DividendPayment')}</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                              {tabsFifty === 2 ? (
                                <div className='category__steps steps'>
                                  <ul className='steps__list'>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#1</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('SearchForAnExistingProject')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#2</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('AgreementWithTheProjectOwners')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#3</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('AttractingYourAudience')}</span>
                                      </a>
                                    </li>

                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#4</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('YourAudienceHelpsYourBusinessGrow')}</span>
                                      </a>
                                    </li>
                                    <li className='steps__item'>
                                      <a className='steps__link' href='#'>
                                        <span className='steps__title'>#5</span>
                                        {ico_arrowTopRight}
                                        <span className='steps__text'>{t('EveryoneWinsTogether')}</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className='category__group resgroupults'>
                            <div className='group__header'>
                              <span className='results__title h4'> {t('Result')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__results results'>
                                <ul className='results__list'>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AudienceMonetization')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('PassiveIncome')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('LongTermAdvertisingContracts')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('OwnCoin')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('SubscriberGrowth')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('HypeProjects')}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='category__footer'>
                          <a className='btn btn_radius-tertiary' href='#'>
                            <span className='btn__text'>{t('IWantToCreateCoin')}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                    {tabsTertiary === 4 ? (
                      <div className='categories__item category'>
                        <div className='category__header'>
                          <h3 className='category__title'>{t('OpenTheDoorToFinancialFreedom')}</h3>
                          <span className='category__ico'>📊</span>
                        </div>
                        <div className='category__main'>
                          <div className='category__group group'>
                            <div className='group__header'>
                              <span className='group__title h4'> {t('StepStrategy')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__steps steps'>
                                <ul className='steps__list'>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#1</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('SearchForProjects')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#2</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('ProjectAnalysis')}</span>
                                    </a>
                                  </li>
                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#3</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('TradingOnHighlyVolatileTokens')}</span>
                                    </a>
                                  </li>

                                  <li className='steps__item'>
                                    <a className='steps__link' href='#'>
                                      <span className='steps__title'>#4</span>
                                      {ico_arrowTopRight}
                                      <span className='steps__text'>{t('DividendPayment')}</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className='category__group resgroupults'>
                            <div className='group__header'>
                              <span className='results__title h4'> {t('Result')}:</span>
                            </div>
                            <div className='group__main'>
                              <div className='category__results results'>
                                <ul className='results__list'>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('OwnCoin')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AttractingNewAssets')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('AdvantageAmongCompetitors')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('Investments')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('NewAudience')}
                                    </a>
                                  </li>
                                  <li className='results__item'>
                                    <a className='results__link layout__tag' href='#'>
                                      {t('IncreasedLoyalty')}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='category__footer'>
                          <a className='btn btn_radius-tertiary' href='#'>
                            <span className='btn__text'>{t('IWantToCreateCoin')}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </main>
                </div>
              </div>
            </div>
          </section>
          {/* Roadmap */}
          <section className='layout__section layout__section_secondary section'>
            <div className='container'>
              <div className='section__header'>
                <h2 className='section__title'>{t('CheckOutTheDevelopmentRoadmap')}</h2>
                <div className='section__tabs section__tabs_secondary tabs'>
                  <ul className='tabs__list'>
                    <li
                      className={`tabs__item ${tabsFourth == 1 ? 'tabs__item_active' : ''}`}
                      onClick={() => setTabsFourth(1)}
                    >
                      {t('Tokenization')}
                    </li>
                    <li
                      className={`tabs__item ${tabsFourth == 2 ? 'tabs__item_active' : ''}`}
                      onClick={() => setTabsFourth(2)}
                    >
                      {t('CryptoExchange')}
                    </li>
                    <li
                      className={`tabs__item ${tabsFourth == 3 ? 'tabs__item_active' : ''}`}
                      onClick={() => setTabsFourth(3)}
                    >
                      {t('BattleOfTraders')}
                    </li>
                  </ul>
                </div>
              </div>
              <div className='section__main'>
                {tabsFourth === 1 ? (
                  <div className='layout__roadmap roadmap'>
                    <div className='roadmap__list'>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2022 Q4</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Развитие биржи')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Разработка ядра')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Набор команды')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Изучение рынка')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Доработка концепции')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q1</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CompleteRedesign')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FirstClients')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CreatingTokensManually')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TheConceptIsFullyFormed')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q2</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('IntegrationWithTON')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('MiniApplicationInTelegram')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Farmilovka')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('AutomaticTokenCreation')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Tokenization')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FullScaleStart')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('ConclusionOfPartnerships')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q3</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('DigitalProfileOfAPerson')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('ConnectingNewBlockchains')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('PluginsForStreamingPlatforms')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q4</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RealEstatePlatform')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CommunicationInfluencerBusiness')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('MonetizationOfHumanSocialNetworks')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('GameFi')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2025 Q1</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RealTimePeopleManagementPlatform')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('OpeningOfficesAroundTheWorld')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TokenizationOfLargeCompanies')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__action'>
                      <NavLink className='btn' to={`/${i18n.language}/secondaryLayout/roadmapPage`} exact={true}>
                        <span className='btn__text'>{t('FindOutMore')}</span>
                      </NavLink>
                    </div>
                  </div>
                ) : null}
                {tabsFourth === 2 ? (
                  <div className='layout__roadmap roadmap'>
                    <div className='roadmap__list'>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2023 Q4</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('GettingStartedInP2PArbitrationInTurkey')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('WeEarnMoreThanWeSpend')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('DevelopmentOfCashExchange')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q1</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('OccupyingP2PTurkey')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('AgreementsWithBanksOnLargeTurnovers')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RegistrationOfACompany')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('ExceedingTurnoverPerMonth')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('20PeopleInTheTeam')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FirstClientsForTokenization')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q2</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('StartInteractingWithCoinTR')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('ObtainingACryptocurrencyLicenseInTheEU')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('LaunchOfP2PPlatform')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('StartFarming')}</span>
                                </a>
                              </li>
                              <li className='events__item events__item_secondary'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('WeAreHere')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('LaunchOfTokenization')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('PartnershipWithTONAndTelegram')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CapturingTheMarketInTheEU')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q3</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('DevelopmentOfP2PPaymentSystemInTurkey')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RecruitingAClientBase')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('LaunchOfTheBattleOfTraders')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('EnteringTheCIS')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TurnoverPerDay')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RealRstateTokenizationPlatform')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q4</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FinalizationOfThePlatform')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('DevelopmentOfAGlobalCryptosystemForBRICS')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('OpeningOfNewCashOffices')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CapturingTheGlobalMarket')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('CreationOfAnInvestmentFund')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2025 Q1</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('SearchAndCreationOfNewMarketsAndProducts')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('IntegrationWithStates')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('IntegrationWithMajorFinancialPlayers')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__action'>
                      <NavLink className='btn' to={`/${i18n.language}/secondaryLayout/roadmapPage`} exact={true}>
                        <span className='btn__text'>{t('FindOutMore')}</span>
                      </NavLink>
                    </div>
                  </div>
                ) : null}
                {tabsFourth === 3 ? (
                  <div className='layout__roadmap roadmap'>
                    <div className='roadmap__list'>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2023 Q2-Q3</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FormationIdeaBattle')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('EarningAndSavingMoneyToStartAProject')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('WorkingOutTheLogic')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q1</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('FirstLayouts')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TeamFormation')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q2</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('StartFarming')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RaisingFunding')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('MVPDevelopment')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q3</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Clans')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('ClanBattles')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('Tournaments')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('15MinuteTournaments')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TradersStore')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('RatingSystem')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('BetsOnProfessionalGames')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('TokenizationOfClansAndPlayers')}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='roadmap__item'>
                        <div className='roadmap__header'>
                          <span className='roadmap__title'>2024 Q4</span>
                        </div>
                        <div className='roadmap__main'>
                          <div className='roadmap__events events'>
                            <ul className='events__list'>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('NewMarketsStocksForexAndOthers')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('NewModes')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('EnteringTheMarketsOfNewCountries')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('PaymentOfDividendsToInvestors')}</span>
                                </a>
                              </li>
                              <li className='events__item'>
                                <a className='events__link' href='#'>
                                  {ico_eventIco}
                                  <span className='events__text'>{t('MajorTournaments')}</span>
                                  {ico_eventArrow}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__action'>
                      <NavLink className='btn' to={`/${i18n.language}/secondaryLayout/roadmapPage`} exact={true}>
                        <span className='btn__text'>{t('FindOutMore')}</span>
                      </NavLink>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
          {/*  Statistics */}
          <section className='layout__section layout__section_secondary section pb-0'>
            <div className='container'>
              <div className='section__header'>
                <h2 className='section__title'>{t('BecomePartOfOurCommunity')}</h2>
              </div>
              <div className='section__main'>
                <div className='layout__statistics layout__statistics_secondary statistics'>
                  <ul className='statistics__list'>
                    <li className='statistics__item layout__box layout__box_light'>
                      <span className='statistics__title h3'>{t('TotalNumberOfProjects')}</span>
                      <span className='statistics__text'>200</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_light'>
                      <span className='statistics__title h3'>{t('TotalCapitalizationOfProjects')}</span>
                      <span className='statistics__text'>$2M+</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_light'>
                      <span className='statistics__title h3'>{t('CommunityOnDiscord')}</span>
                      <span className='statistics__text'>10k</span>
                    </li>
                    <li className='statistics__item layout__box layout__box_light'>
                      <span className='statistics__title h3'>{t('TelegramCommunity')}</span>
                      <span className='statistics__text'>2k</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/*  Banner */}
          <section className='layout__section layout__section_secondary section'>
            <div className='container'>
              <div className='section__main'>
                <div className='layout__banner banner'>
                  <div className='banner__media'>
                    <img src={banner__img01} alt='image description' />
                  </div>
                  <div className='banner__header'>
                    <h3 className='banner__title'>{t('StartUsingTheEasiestWay')}</h3>
                  </div>
                  <div className='banner__footer'>
                    <a className='btn btn_tertiary' href='#'>
                      <span className='btn__text'>{t('StartEarningMoney')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </React.Fragment>
  )
}
