import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Icon from './OrderIcons'
import padStart from 'lodash/padStart'
import { TabIndex } from './OrderAndDealData'
import userModalState from '../Progress/Modals/useModalState'
import CancelMyOrderWarningModal from './CancelMyOrderWarningModal'

const RenderMyOrder = ({ onCancelOrder, onEditOrder, orders, selectedTabIndex }) => {
  const { t } = useTranslation()
  const { modals, openModal, closeModal } = userModalState()
  const [orderIdToCancel, setOrderIdToCancel] = useState(null)
  if (!orders || orders.length === 0) {
    return <div className='empty_space'>{t('P2PTheresNothingHereYet')}</div>
  }
  return (
    <React.Fragment>
      <div>
        {orders
          .slice()
          .sort((a, b) => moment(b.dateCreated).diff(moment(a.dateCreated)))
          .map((order) => {
            const {
              id,
              isBuyingOnExchange,
              exchangeCurrencyCode,
              requisites,
              price,
              totalOrderAmount,
              filledOrderAmount,
              minDealAmount,
              maxDealAmount,
              dateCreated,
              maxTimeMinutes,
              paymentCurrencyCode,
              displayId,
            } = order

            const formattedDateCreated = dateCreated ? moment(dateCreated).format('YYYY-MM-DD HH:mm:ss') : ''
            const isBuyingColor = isBuyingOnExchange ? 'colorSecondary500' : 'colorTertiary500'

            return (
              <div key={order.id} className='wrapper'>
                <div className='group'>
                  <div className='group__main group__main_transparent'>
                    <div className='datatext datatext'>
                      <div className='datatext__list'>
                        <div className='datatext__item'>
                          <div className='datatext__title'>
                            <span className={`datatext__text ${isBuyingColor} h2`}>
                              {t(isBuyingOnExchange ? 'Buy' : 'Sell')}
                            </span>
                          </div>
                        </div>
                        <div className='datatext__item'>
                          <div className='datatext__title'>
                            <span className='datatext__text small colorPrimary300 h4'>{formattedDateCreated} </span>
                          </div>
                        </div>
                        <div className='datatext__item ml-m-auto'>
                          <div className='datatext__title'>
                            <span className='datatext__text small colorPrimary300 h4'>
                              № {padStart(displayId.toString(), 15, '0')}
                            </span>
                          </div>
                          <div className='datatext__media'>
                            <Icon name='copyIco' textToCopy={padStart(displayId.toString(), 15, '0')} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='group'>
                  <div className='group__main group__main_transparent'>
                    <div className='row'>
                      <div className='col col_6'>
                        <div className='data'>
                          <div className='data__list data__list_column'>
                            <div className='row'>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Pair')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {exchangeCurrencyCode} / {paymentCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Available')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {(totalOrderAmount - filledOrderAmount).toFixed(2)} {exchangeCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('ConverterPaymentMethod')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {requisites.map((requisites) => (
                                        <div key={requisites.id}>
                                          {requisites.paymentSystem.name} ({requisites.paymentMethodCurrencyCode})
                                        </div>
                                      ))}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__main'>
                                    <span className='title__text'>{t('Rate')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {price} {exchangeCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('Limits')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {minDealAmount} - {maxDealAmount} {paymentCurrencyCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_4 col_mob-12'>
                                <div className='data__item'>
                                  <div className='data__header'>
                                    <span className='data__title'>{t('PaymentTerm')}</span>
                                  </div>
                                  <div className='data__main'>
                                    <span className='data__text'>
                                      {maxTimeMinutes} {t('P2PMinutes')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col col_6'>
                        <div className='p2p__chat_second chat'>
                          <div className='chat__widget'>
                            <div className='chat__control control'>
                              <div className='control__list'>
                                <div className='control__item'>
                                  <div className='control__media' style={{ cursor: 'pointer' }}>
                                    <span onClick={() => onEditOrder(order)}>
                                      <Icon name='editIco' />
                                    </span>
                                  </div>
                                </div>
                                {selectedTabIndex !== TabIndex.P2PMyAdsAll && (
                                  <div className='control__item'>
                                    <div className='control__media' style={{ cursor: 'pointer' }}>
                                      <span
                                        onClick={() => {
                                          setOrderIdToCancel(id)
                                          openModal('isCancelMyOrderWarningModalOpen')
                                        }}
                                      >
                                        <Icon name='closeIco' />
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <CancelMyOrderWarningModal
        opened={modals.isCancelMyOrderWarningModalOpen}
        onClose={() => closeModal('isCancelMyOrderWarningModalOpen')}
        onConfirmCancel={() => onCancelOrder(orderIdToCancel)}
      />
    </React.Fragment>
  )
}
export default RenderMyOrder
