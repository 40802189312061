import Main from 'pages/common/components/Main/Main'
import Sidebar from 'baseComponents/Sidebar/Sidebar'
import Order from './Order/Order'

import styles from '../pages.module.scss'
import { useTranslation } from 'react-i18next'

export default function OrderPage() {
  const { t } = useTranslation()
  return (
    <Main>
      <div className={styles.flow}>
        <h1 className={styles.title}>{t('PersonalCabinet')}</h1>
        <div className={styles.wrapper}>
          <Sidebar />
          <div className={styles.content}>
            <Order />
          </div>
        </div>
      </div>
    </Main>
  )
}
