import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'auth/auth'
import authApi from 'auth/api'
import icoAccount from './images/icoAccount.png'

export default function HeaderAccount() {
  const { t } = useTranslation()
  const auth = useAuth()

  const [user, setUser] = useState(null)

  useEffect(() => {
    let ignore = false // flag to prevent race conditions
    if (auth.token) {
      authApi.fetchUser().then((data) => {
        if (!ignore) setUser(data)
      })
    }
    return () => {
      ignore = true
    }
  }, [])

  return (
    <div className='header__account account'>
      {auth.token ? (
        <NavLink to={`/${i18n.language}/account`} className='account__link account__link_login'>
          <div className='account__title title'>
            <span className='title__text'>{user?.email}</span>
          </div>
          <div className='account__media'>
            <img src={icoAccount} alt='image description' />
          </div>
        </NavLink>
      ) : (
        <NavLink to={`/${i18n.language}/register`} className='account__link account__link_registration'>
          <span className='btn'>
            <span className='btn__text'>{t('Registration')}</span>
          </span>
        </NavLink>
      )}
    </div>
  )
}
