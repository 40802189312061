import React, { useEffect, useState } from 'react'
import Header from '../../common/components/Header/Header.jsx'
import Footer from '../../common/components/Footer/Footer.jsx'
import { useTranslation } from 'react-i18next'
import '../scss/main.sass'
let ico_eventIco = (
    <svg className='events__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M20.4898 7.51996C20.4841 7.49359 20.4841 7.46632 20.4898 7.43996C20.485 7.41688 20.485 7.39304 20.4898 7.36996V7.27996L20.4298 7.12996C20.4054 7.08903 20.3751 7.05196 20.3398 7.01996L20.2498 6.93996H20.1998L16.2598 4.44996L12.5398 2.14996C12.4538 2.08169 12.3553 2.03075 12.2498 1.99996H12.1698C12.0804 1.98504 11.9892 1.98504 11.8998 1.99996H11.7998C11.6837 2.02565 11.5723 2.06952 11.4698 2.12996L3.99982 6.77996L3.90982 6.84996L3.81982 6.92996L3.71982 6.99996L3.66982 7.05996L3.60982 7.20996V7.29996V7.35996C3.60011 7.42627 3.60011 7.49365 3.60982 7.55996V16.29C3.60948 16.4599 3.65246 16.6271 3.7347 16.7759C3.81693 16.9246 3.93571 17.0499 4.07982 17.14L11.5798 21.78L11.7298 21.84H11.8098C11.979 21.8936 12.1606 21.8936 12.3298 21.84H12.4098L12.5598 21.78L19.9998 17.21C20.1439 17.1199 20.2627 16.9946 20.345 16.8459C20.4272 16.6971 20.4702 16.5299 20.4698 16.36V7.62996C20.4698 7.62996 20.4898 7.55996 20.4898 7.51996ZM11.9998 4.16996L13.7798 5.26996L8.18982 8.72996L6.39982 7.62996L11.9998 4.16996ZM10.9998 19.17L5.49982 15.81V9.41996L10.9998 12.82V19.17ZM11.9998 11.06L10.0898 9.90996L15.6798 6.43996L17.5998 7.62996L11.9998 11.06ZM18.4998 15.78L12.9998 19.2V12.82L18.4998 9.41996V15.78Z' />
    </svg>
  ),
  ico_eventArrow = (
    <svg className='events__arrow' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
      <path d='M14.9333 5.51667C14.8487 5.31304 14.6869 5.15123 14.4833 5.06667C14.3831 5.02397 14.2755 5.00132 14.1666 5H5.83328C5.61227 5 5.40031 5.0878 5.24403 5.24408C5.08775 5.40036 4.99995 5.61232 4.99995 5.83333C4.99995 6.05435 5.08775 6.26631 5.24403 6.42259C5.40031 6.57887 5.61227 6.66667 5.83328 6.66667H12.1583L5.24162 13.575C5.16351 13.6525 5.10151 13.7446 5.05921 13.8462C5.0169 13.9477 4.99512 14.0567 4.99512 14.1667C4.99512 14.2767 5.0169 14.3856 5.05921 14.4871C5.10151 14.5887 5.16351 14.6809 5.24162 14.7583C5.31908 14.8364 5.41125 14.8984 5.5128 14.9407C5.61435 14.9831 5.72327 15.0048 5.83328 15.0048C5.94329 15.0048 6.05221 14.9831 6.15376 14.9407C6.25531 14.8984 6.34748 14.8364 6.42495 14.7583L13.3333 7.84167V14.1667C13.3333 14.3877 13.4211 14.5996 13.5774 14.7559C13.7336 14.9122 13.9456 15 14.1666 15C14.3876 15 14.5996 14.9122 14.7559 14.7559C14.9122 14.5996 14.9999 14.3877 14.9999 14.1667V5.83333C14.9986 5.72444 14.976 5.61685 14.9333 5.51667Z' />
    </svg>
  )

export default function RoadMapPage() {
  const { t } = useTranslation()
  const [tabsFourth, setTabsFourth] = useState(1)
  return (
    <div className='layout_Tokens layout_Tokens_secodnary'>
      <Header />
      <main className='layout__main'>
        {/* Roadmap */}
        <section className='layout__section layout__section_secondary section'>
          <div className='container'>
            <div className='section__header'>
              <h2 className='section__title'>{t('CheckOutTheDevelopmentRoadmap')}</h2>
              <div className='section__tabs section__tabs_secondary tabs'>
                <ul className='tabs__list'>
                  <li
                    className={`tabs__item ${tabsFourth == 1 ? 'tabs__item_active' : ''}`}
                    onClick={() => setTabsFourth(1)}
                  >
                    {t('Tokenization')}
                  </li>
                  <li
                    className={`tabs__item ${tabsFourth == 2 ? 'tabs__item_active' : ''}`}
                    onClick={() => setTabsFourth(2)}
                  >
                    {t('CryptoExchange')}
                  </li>
                  <li
                    className={`tabs__item ${tabsFourth == 3 ? 'tabs__item_active' : ''}`}
                    onClick={() => setTabsFourth(3)}
                  >
                    {t('BattleOfTraders')}
                  </li>
                </ul>
              </div>
            </div>
            <div className='section__main'>
              {tabsFourth === 1 ? (
                <div className='layout__roadmap roadmap'>
                  <div className='roadmap__list'>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2020-2022</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FormationOfAnIdea')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('EarningAndSavingMoneyToStartAProject')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2022 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstTokenizationOptions')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2022 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Развитие биржи')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Разработка ядра')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Набор команды')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Изучение рынка')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Доработка концепции')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CompleteRedesign')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstClients')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CreatingTokensManually')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TheConceptIsFullyFormed')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('IntegrationWithTON')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('MiniApplicationInTelegram')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Farmilovka')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('AutomaticTokenCreation')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Tokenization')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FullScaleStart')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ConclusionOfPartnerships')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('DigitalProfileOfAPerson')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ConnectingNewBlockchains')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('PluginsForStreamingPlatforms')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RealEstatePlatform')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CommunicationInfluencerBusiness')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('MonetizationOfHumanSocialNetworks')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('GameFi')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2025 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RealTimePeopleManagementPlatform')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('OpeningOfficesAroundTheWorld')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TokenizationOfLargeCompanies')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2025 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('AgreementsWithNationalGovernments')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('PaymentServicesAndHumanTokens')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2025 Q3-Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>
                                  {t('WeAreTheLargestTokenizationExchangeOnThePlanet')}
                                </span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TurnoverOfBillionPerDay')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2026</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Токенизация Африканского государства')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tabsFourth === 2 ? (
                <div className='layout__roadmap roadmap'>
                  <div className='roadmap__list'>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2019-2022</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('EarningAndSavingMoneyToStartAProject')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2022 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FormationOfAnIdea')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ChoiceOfJurisdiction')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('MarketResearchAndPlanDevelopment')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2022 Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RegistrationOfCompaniesInTurkey')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('LegalizationOfBusiness')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TeamRecruitment')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2022 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('OpeningAnExchangeOfficeInAntalya')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>
                                  {t('DevelopmentAndIncreaseInInternationalCashFlows')}
                                </span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstTokenizationOptions')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TheExchangeHasBeenLaunchedInATestFormat')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('OfficeOpensInIstanbul')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TheExchangeIsLaunched')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstClients')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('LaunchOfAServiceForExchanging')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstThousandClients')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FormationIdeaBattle')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('P2PPlatformDevelopmentStart')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ImprovementOfTheSite')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('DevelopmentOfSocialNetworks')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstTurnoverPerMonth')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('AddedConverterForQuick')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ConstructionOfBankingInfrastructure')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('GettingStartedInP2PArbitrationInTurkey')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('WeEarnMoreThanWeSpend')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('DevelopmentOfCashExchange')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('OccupyingP2PTurkey')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('AgreementsWithBanksOnLargeTurnovers')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RegistrationOfACompany')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ExceedingTurnoverPerMonth')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('20PeopleInTheTeam')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstClientsForTokenization')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('StartInteractingWithCoinTR')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ObtainingACryptocurrencyLicenseInTheEU')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('LaunchOfP2PPlatform')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('StartFarming')}</span>
                              </a>
                            </li>
                            <li className='events__item events__item_secondary'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('WeAreHere')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('LaunchOfTokenization')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('PartnershipWithTONAndTelegram')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CapturingTheMarketInTheEU')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('DevelopmentOfP2PPaymentSystemInTurkey')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RecruitingAClientBase')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('LaunchOfTheBattleOfTraders')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('EnteringTheCIS')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TurnoverPerDay')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RealRstateTokenizationPlatform')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FinalizationOfThePlatform')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('DevelopmentOfAGlobalCryptosystemForBRICS')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('OpeningOfNewCashOffices')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CapturingTheGlobalMarket')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('CreationOfAnInvestmentFund')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2025 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('SearchAndCreationOfNewMarketsAndProducts')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('IntegrationWithStates')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('IntegrationWithMajorFinancialPlayers')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2025 и дальше</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>
                                  {t('EverythingChangesQuicklyInTheCryptocurrencyMarket')}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {tabsFourth === 3 ? (
                <div className='layout__roadmap roadmap'>
                  <div className='roadmap__list'>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2023 Q2-Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FormationIdeaBattle')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('EarningAndSavingMoneyToStartAProject')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('WorkingOutTheLogic')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q1</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('FirstLayouts')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TeamFormation')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q2</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('StartFarming')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RaisingFunding')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('MVPDevelopment')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q3</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Clans')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('ClanBattles')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('Tournaments')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('15MinuteTournaments')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TradersStore')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('RatingSystem')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('BetsOnProfessionalGames')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('TokenizationOfClansAndPlayers')}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='roadmap__item'>
                      <div className='roadmap__header'>
                        <span className='roadmap__title'>2024 Q4</span>
                      </div>
                      <div className='roadmap__main'>
                        <div className='roadmap__events events'>
                          <ul className='events__list'>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('NewMarketsStocksForexAndOthers')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('NewModes')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('EnteringTheMarketsOfNewCountries')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('PaymentOfDividendsToInvestors')}</span>
                              </a>
                            </li>
                            <li className='events__item'>
                              <a className='events__link' href='#'>
                                {ico_eventIco}
                                <span className='events__text'>{t('MajorTournaments')}</span>
                                {ico_eventArrow}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
