import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './auth'

export default function PrivateRoute({ component: Component, ...rest }) {
  let auth = useAuth()
  console.log(` --- PrivateRoute '${auth.token}'`)

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/register', state: { from: props.location } }} />
        )
      }
    />
  )
}

