import React from 'react'

const Input = (props) => {
  const defaultValue = props.defaultValue || null
  const handleFocus = (e) => {
    if (e.target.value === defaultValue) {
      props.inputCallback('')
    }
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  const handleBlur = (e) => {
    if (defaultValue && e.target.value === '') {
      props.inputCallback(defaultValue)
    }
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  const handleChange = (e) => {
    let { value } = e.target
    value = replaceSymbols(value, props.replaceSymbols)
    const validationPattern = new RegExp(props.validationPattern)
    if (validationPattern.test(value) || value === '') {
      props.inputCallback(value)
    }
  }

  return (
    <React.Fragment>
      <div className={`form-item__scope scope ${props.disabled ? 'scope_disabled' : ''}`}>
        {props.before && <span className='scope__before' dangerouslySetInnerHTML={{ __html: props.before }}></span>}
        <input
          className={`scope__input ${Array.isArray(props.addClasses) ? props.addClasses.join(' ') : ''}`}
          type={`${props.type ? props.type : 'text'}`}
          pattern={props.pattern}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {props.after && (
          <span
            className='scope__after'
            dangerouslySetInnerHTML={{ __html: props.after }}
            onClick={props.onClickAfter}
            style={{ cursor: props.onClickAfter ? 'pointer' : 'default' }}
          ></span>
        )}
      </div>
    </React.Fragment>
  )
}

function replaceSymbols(value, replacements) {
  if (Array.isArray(replacements)) {
    replacements.forEach((replacement) => {
      value = value.replace(replacement.from, replacement.to)
    })
  }
  return value
}

export default Input
