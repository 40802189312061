import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { toast } from 'react-toastify'

import { useAuth } from 'auth/auth'
import api from 'api'

import Modal from 'components/layout/Modal/Modal'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'

let check = (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      d='M0 6C0 9.30662 2.69338 12 6 12C9.30662 12 12 9.30662 12 6C12 2.69338 9.30662 0 6 0C2.69338 0 0 2.69338 0 6ZM9.13329 5.13329L5.33333 8.94671L2.86671 6.46667L3.8 5.53338L5.33333 7.05329L8.20004 4.2L9.13329 5.13329Z'
      fill='#00D09E'
    />
  </svg>
)

export const GetOpenDealLimitKey = (key) => {
  switch (key) {
    case 'canOpenDealWithinTotalLimits':
      return `P2PCanOpenDealWithinTotalLimits`
  }
}
export default function ModalBuySell({ opened, setOpened, isDealUserBuy, order, balances }) {
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAuth()

  const isBuy = isDealUserBuy

  const [requisites, setRequisites] = useState([])
  const [requisiteId, setRequisiteId] = useState(null)
  const [cryptoAmount, setCryptoAmount] = useState('')
  const [fiatAmount, setFiatAmount] = useState('')
  const [isDisabledBtn, setIsDisabledBtn] = useState(true)
  const [maxAvailableOrderCryptoAmount, setMaxAvailableOrderCryptoAmount] = useState(0)

  const [toastId, setToastId] = useState(null)
  const [messagesAboutLimits, setMessagesAboutLimits] = useState(null)

  useEffect(() => {
    let ignore = false
    if (order != null && auth.isSignedIn) {
      if (isBuy) {
        const orderRequisites = order?.requisites
        setRequisites(orderRequisites)
        if (orderRequisites?.length > 0) setRequisiteId(orderRequisites[0].id)
      } else {
        // load user requisites
        api.fetchP2PRequisites().then((requisitesData) => {
          if (ignore) return
          const orderPaymentSystemIds = order?.requisites.map((requisite) => requisite.paymentSystem.id)

          const allowedRequisites = requisitesData.filter((requisite) =>
            orderPaymentSystemIds.includes(requisite.paymentSystem.id),
          )
          setRequisites(allowedRequisites)
          if (allowedRequisites.length > 0) {
            setRequisiteId(allowedRequisites[0].id)
          }
        })
      }
    }
    setMaxAvailableOrderCryptoAmount(Math.min(order?.totalOrderAmount, order?.maxDealAmount))
    return () => {
      ignore = true
    }
  }, [order])

  useEffect(() => {
    if (order == null) return
    setCryptoAmount(order.minDealAmount)
    setFiatAmount(order.minDealAmount * order.price)
  }, [order])

  const paymentMethodColor = isBuy ? 'rgba(0,208,158,.08)' : '#ffebf1'

  const balanceObj = order && balances?.find((balance) => balance.currencyCode === order?.exchangeCurrencyCode)
  const balanceAmount = balanceObj?.amount ?? 0
  const digitsCrypto = balanceObj?.digits ?? 5
  const digitsFiat = 2

  const setAmount = (value, isFiat) => {
    const valueNum = Number(value)
    if (valueNum === NaN) return
    const maxAvailableFiatAmount = maxAvailableOrderCryptoAmount * order.price

    let cryptoAmount, fiatAmount
    if (isFiat) {
      fiatAmount = Math.min(valueNum, maxAvailableFiatAmount)
      cryptoAmount = Math.min(fiatAmount / order.price, maxAvailableOrderCryptoAmount)
    } else {
      cryptoAmount = Math.min(valueNum, maxAvailableOrderCryptoAmount)
      fiatAmount = Math.min(cryptoAmount * order.price, maxAvailableFiatAmount)
    }
    setFiatAmount(fiatAmount.toFixedTrimmed(digitsFiat))
    setCryptoAmount(cryptoAmount.toFixedTrimmed(digitsCrypto))
  }

  const setMaxAmount = () => {
    if (isBuy) return // only when selling on exchange can specify max crypto amount
    setCryptoAmount(balanceAmount)
    setFiatAmount((balanceAmount / order.price).toFixedTrimmed(digitsFiat))
  }

  const onBlurInput = (e, setter, digits) => {
    if (Number(e.target.value) == NaN) return
    setter(Number(e.target.value).toFixedTrimmed(digits))
  }

  const createDeal = async () => {
    if (!auth.isSignedIn || !order || !requisiteId || cryptoAmount <= 0) return

    const dealCreateDto = {
      orderId: order.id,
      requisiteId: requisiteId,
      cryptoAmount: cryptoAmount,
    }
    setIsDisabledBtn(true)

    api
      .createP2PDeal(dealCreateDto)
      .then((data) => {
        toast.success(t('ActionWasSuccessful'))
        history.push(`/${i18n.language}/P2P/progress/${data.id}`)
      })
      .catch((err) => {
        toast.error(t('ErrorTryLater'))
        setIsDisabledBtn(false)
      })
  }

  useEffect(() => {
    if (!opened) return
    api.fetchP2PUserDealOpeningLimits().then((limits) => {
      if (limits.canOpenDealWithinTotalLimits) return
      setMessagesAboutLimits(GetOpenDealLimitKey('canOpenDealWithinTotalLimits'))
    })
  }, [opened])

  useEffect(() => {
    if (messagesAboutLimits) {
      const id = toast.error(t(messagesAboutLimits), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        // closeButton: false,
        closeOnClick: false,
        // draggable: false,
      })
      setToastId(id)
      setIsDisabledBtn(true)
    } else if (!messagesAboutLimits) {
      toast.dismiss(toastId)
      setToastId(null)
      setIsDisabledBtn(false)
    }
  }, [messagesAboutLimits])

  return (
    <Modal opened={opened} blockStyle={'modal768'} modalCallback={() => setOpened((prev) => setOpened(false))}>
      <div className='modal__main'>
        <div className='modal__trading trading'>
          <div className='row'>
            <div className='col col_6 col_mob-12'>
              <div className='trading__information information'>
                <div className='information__about about'>
                  <div className='about__header'>
                    <div className='information__avatar avatar'>
                      <span className='avatar__text h2'>{order?.userNickname.slice(0, 1)}</span>
                    </div>
                    <div className='about__data'>
                      <div className='information__title'>
                        <h2>
                          <strong>{order?.userNickname}</strong>
                        </h2>
                      </div>
                      <div className='information__subtitle'>
                        <div className='wysiwyg'>
                          <p>
                            <small className='transparentHalf'>
                              {order?.userInfo?.completedRecentDealsCount} {t('Deal')} |{' '}
                              {(order?.userInfo?.completedRecentDealsPercentage * 100).toFixed(2)}% {t('done')}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='about__protection protection'>
                    <div className='protection__list'>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>Email</span>
                      </div>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>SMS</span>
                      </div>
                      <div className='protection__item'>
                        {check}
                        <span className='protection__text'>{t('IdentityVerification')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='information__main'>
                  <div className='data'>
                    <div className='data__list data__list_row'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Price')}</span>
                        </div>
                        <div className='data__main'>
                          <span className={`data__text h2 ${isBuy ? 'colorSecondary500' : 'colorTertiary600'}`}>
                            {order?.price} {order?.paymentCurrencyCode}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Available')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {(order?.totalOrderAmount - order?.filledOrderAmount).toFixed(2)}{' '}
                            {order?.exchangeCurrencyCode}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('Limits')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {order?.minDealAmount} - {order?.maxDealAmount}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('PaymentTerm')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>
                            {order?.maxTimeMinutes} {t('minutes')}
                          </span>
                        </div>
                      </div>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('ConverterPaymentMethod')}</span>
                        </div>
                        <div className='data__main'>
                          <div className='tags tags_secondary'>
                            <div className='tags__list justify-end'>
                              {order?.requisites.map((r, index) => (
                                <div className='tags__item' key={index}>
                                  <span className='tags__text' style={{ backgroundColor: paymentMethodColor }}>
                                    {r.paymentSystem.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='data'>
                    <div className='data__list data__list_column'>
                      <div className='data__item'>
                        <div className='data__header'>
                          <span className='data__title'>{t('TermsOfTheTransaction')}</span>
                        </div>
                        <div className='data__main'>
                          <span className='data__text'>{order?.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6 col_mob-12'>
              <div className='trading__formular'>
                <div className='formular'>
                  <div className='formular__main'>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t('IWantToSell')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            value={isBuy ? fiatAmount : cryptoAmount}
                            inputCallback={(value) => {
                              setAmount(value, isBuy)
                            }}
                            onBlur={(e) =>
                              onBlurInput(e, isBuy ? setFiatAmount : setCryptoAmount, isBuy ? digitsFiat : digitsCrypto)
                            }
                            after={
                              `<span className='colorPrimary500'>${
                                isBuy ? order?.paymentCurrencyCode : order?.exchangeCurrencyCode
                              }</span>` + `<span className='colorQuartyty500'>${isBuy ? '' : t('All')}</span>`
                            }
                            onClickAfter={() => setMaxAmount()}
                            placeholder={'0.00'}
                            addClasses={['w-100']}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {t('Balance')}: {balanceAmount} {order?.exchangeCurrencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t('IWillGet')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Input
                            value={isBuy ? cryptoAmount : fiatAmount}
                            inputCallback={(value) => {
                              setAmount(value, !isBuy)
                            }}
                            onBlur={(e) =>
                              onBlurInput(e, isBuy ? setCryptoAmount : setFiatAmount, isBuy ? digitsCrypto : digitsFiat)
                            }
                            after={`<span className='colorPrimary500'>${
                              isBuy ? order?.exchangeCurrencyCode : order?.paymentCurrencyCode
                            }</span>`}
                            placeholder={'0.00'}
                            addClasses={['w-100']}
                          />
                        </div>
                      </div>
                      <div className='form-item__footer'>
                        <div className='form-item__data data'>
                          <div className='data__item data__item_after'>
                            <span className='data__text'>
                              {t('Commission')}: 0% ~0.00000000 {order?.exchangeCurrencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-item__header'>
                        <span className='form-item__title'>{t('ConverterPaymentMethod')}</span>
                      </div>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <Select
                            list={requisites.map((r) => `${r.paymentSystem.name} ${r.cardNumber}`)}
                            value={
                              requisites
                                .filter((r) => r.id === requisiteId)
                                .map((r) => `${r.paymentSystem.name} ${r.cardNumber}`)[0]
                            }
                            addClasses={['w-100']}
                            localizeOptions={false}
                            filter={true}
                            tagsColor={paymentMethodColor}
                            multiple={false}
                            selectCallback={(value) => {
                              const psText = value.split(' ')
                              const requisite = requisites.find((r) => r.cardNumber === psText[psText.length - 1])
                              setRequisiteId(requisite.id)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='formular__footer'>
                    <div className='form-item'>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <button
                            className={`btn w-100 ${isBuy ? 'btn_secondary' : 'btn_tertiary'}`}
                            onClick={() => createDeal()}
                            disabled={
                              isDisabledBtn ||
                              cryptoAmount > maxAvailableOrderCryptoAmount ||
                              cryptoAmount < order?.minDealAmount
                            }
                          >
                            <span className='btn__text'>
                              {t(isBuy ? 'Buy' : 'Sell')} {order?.exchangeCurrencyCode}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='form-item'>
                      <div className='form-item__main'>
                        <div className='form-item__field'>
                          <button
                            className='btn btn_revert w-100'
                            onClick={() => {
                              setMessagesAboutLimits(null)
                              setOpened((prevState) => !prevState)
                            }}
                          >
                            <span className='btn__text'>{t('Close')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
