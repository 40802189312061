import { useState, useRef, useEffect } from 'react'
import { cssClass } from 'helpers/helper'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper'
import baseComponents from '../BaseComponents.module.scss'
import styles from './Selector.module.scss'
import triangleImg from './images/triangle.svg'
const Selector = ({ list, value, selectNewItem, triangle = false, arrowText = null, scrollbar, addClasses = [] }) => {
  let selectArrow = <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none"><path d="M7.71271 1.90735e-06C8.27424 1.90735e-06 8.66441 0.266523 8.8832 0.799567C9.10199 1.33261 9.0134 1.80522 8.61741 2.21739L5.37296 5.6087C5.24817 5.73913 5.11299 5.83696 4.9674 5.90217C4.82182 5.96739 4.66584 6 4.49946 6C4.33307 6 4.17709 5.96739 4.03151 5.90217C3.88592 5.83696 3.75074 5.73913 3.62595 5.6087L0.381503 2.21739C-0.0136538 1.80435 -0.101836 1.33131 0.116956 0.798263C0.335749 0.26522 0.725498 -0.000867367 1.28621 1.90735e-06H7.71271Z" fill="#202639"/></svg>;
  const { t, i18n } = useTranslation();
  const [expand, setExpand] = useState(false);
  const selectRef = useRef(null);
  const listRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        listRef.current &&
        !listRef.current.contains(event.target)
      ) {
        setExpand(false)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);
  useEffect(() => {
    setExpand(false)
  }, [value]);
  return (
    <div className={cssClass(styles.select)}>
      <button
        ref={selectRef}
        className={cssClass(styles.select__btn, ...addClasses)}
        type='button'
        onClick={() => setExpand((prev) => !prev)}
      >
          <div className={cssClass(styles.select__preview)}>
              <img className={cssClass(styles.select__media)}
                   src={`/images/currencies/${value}.svg`}
                   onError={(e) => {e.target.style = 'display: none;'}}
                   onLoad={(e) => {e.target.style = 'display: block;'}}
                   alt='image description'/>

              <span className={cssClass(styles.select__title)}>{i18n.exists(value) ? t(value) : value}</span>
              {arrowText && <span onClick={() => selectNewItem(list[0])}>{t('Any')}</span>}
              {triangle ? (
                  <img
                      className={cssClass(styles.triangleImg, expand ? styles.imgRotate : null)}
                      src={triangleImg}
                      alt='Triangle'
                  />
              ) : (
                  <span className={cssClass(styles.select__arrow)}>{selectArrow}</span>
              )}
          </div>
        {expand ? (
          <div ref={listRef} className={cssClass(styles.select__dropdown)}>
            <ul className={cssClass(styles.select__list, scrollbar ? styles.scrollbarList : null)}>
              {scrollbar ? (
                <Swiper
                  direction={'vertical'}
                  slidesPerView={'auto'}
                  freeMode={true}
                  scrollbar={{
                    verticalClass: baseComponents.scrollbarRight3px,
                    dragClass: baseComponents.scrollbarDragPurple,
                    draggable: true,
                    snapOnRelease: true,
                    enabled: true,
                  }}
                  mousewheel={{ sensitivity: 0.5, releaseOnEdges: true, forceToAxis: true }}
                  modules={[FreeMode, Scrollbar, Mousewheel]}
                  className={baseComponents.swiperWrapper}
                  spaceBetween={10}
                >
                  <SwiperSlide />
                  {list.map((element, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <li
                            className={cssClass(styles.select__item, value === element ? styles.select__item_active : null)}
                          onClick={() => selectNewItem(element)}
                        >
                          {i18n.exists(element) ? t(element) : element}
                        </li>
                      </SwiperSlide>
                    )
                  })}
                  <SwiperSlide />
                </Swiper>
              ) : (
                <>
                  {list.map((element, index) => {
                    return (
                      <li
                        key={index}
                        className={cssClass(styles.select__item, value === element ? styles.select__item_active : null)}
                        onClick={() => selectNewItem(element)}
                      >
                          <img src={`/images/currencies/${element}.svg`}
                               className={cssClass(styles.select__media)}
                               onError={(e) => {e.target.style = 'display: none;'}}
                               onLoad={(e) => {e.target.style = 'display: block;'}}
                               alt='image description'/>
                          <span className={cssClass(styles.select__title)}>{i18n.exists(element) ? t(element) : element}</span>
                      </li>
                    )
                  })}
                </>
              )}
            </ul>
          </div>
        ) : null}
      </button>
    </div>
  )
};
export default Selector