import { USER_VERIFICATION_STAGE } from './VerificationData'
import VerificationIcons from './VerificationIcons'

const VerificationAdvantages = ({ t, requiredStage, isHide }) => {
  if (isHide) return null
  const text = requiredStage === USER_VERIFICATION_STAGE.BasicVerification ? 'PhotoVerification' : 'VideoVerification'
  return (
    <div className='verification__advantages advantages'>
      <div className='advantages__list'>
        <div className='advantages__item'>
          <VerificationIcons name={'ico01'} />
          <span className='advantages__text'>{t(text)}</span>
        </div>
        <div className='advantages__item'>
          <VerificationIcons name={'ico02'} />
          <span className='advantages__text'>{t('ReviewPeriod')}</span>
        </div>
      </div>
    </div>
  )
}
export default VerificationAdvantages
