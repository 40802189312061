import React from 'react'
import { useTranslation } from 'react-i18next'
const Tooltip = (props) => {
  const { t } = useTranslation()
  const icoTooltip = (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        d='M7.2 12.5H8.8V7.7H7.2V12.5ZM8 6.1C8.22667 6.1 8.4168 6.0232 8.5704 5.8696C8.724 5.716 8.80053 5.52613 8.8 5.3C8.8 5.07333 8.7232 4.8832 8.5696 4.7296C8.416 4.576 8.22613 4.49947 8 4.5C7.77333 4.5 7.5832 4.5768 7.4296 4.7304C7.276 4.884 7.19947 5.07387 7.2 5.3C7.2 5.52667 7.2768 5.7168 7.4304 5.8704C7.584 6.024 7.77387 6.10053 8 6.1ZM8 16.5C6.89333 16.5 5.85333 16.2899 4.88 15.8696C3.90667 15.4493 3.06 14.8795 2.34 14.16C1.62 13.44 1.05013 12.5933 0.6304 11.62C0.210667 10.6467 0.000533333 9.60667 0 8.5C0 7.39333 0.210133 6.35333 0.6304 5.38C1.05067 4.40667 1.62053 3.56 2.34 2.84C3.06 2.12 3.90667 1.55013 4.88 1.1304C5.85333 0.710667 6.89333 0.500533 8 0.5C9.10667 0.5 10.1467 0.710133 11.12 1.1304C12.0933 1.55067 12.94 2.12053 13.66 2.84C14.38 3.56 14.9501 4.40667 15.3704 5.38C15.7907 6.35333 16.0005 7.39333 16 8.5C16 9.60667 15.7899 10.6467 15.3696 11.62C14.9493 12.5933 14.3795 13.44 13.66 14.16C12.94 14.88 12.0933 15.4501 11.12 15.8704C10.1467 16.2907 9.10667 16.5005 8 16.5ZM8 14.9C9.78667 14.9 11.3 14.28 12.54 13.04C13.78 11.8 14.4 10.2867 14.4 8.5C14.4 6.71333 13.78 5.2 12.54 3.96C11.3 2.72 9.78667 2.1 8 2.1C6.21333 2.1 4.7 2.72 3.46 3.96C2.22 5.2 1.6 6.71333 1.6 8.5C1.6 10.2867 2.22 11.8 3.46 13.04C4.7 14.28 6.21333 14.9 8 14.9Z'
        fill='#202639'
        fillOpacity='0.5'
      />
    </svg>
  )
  return (
    <React.Fragment>
      <div className='form-item__tooltip tooltip'>
        <div className='tooltip__preview'>
          <div className='tooltip__title title'>
            <span className='title__text'>{t(props.title)}</span>
          </div>
          <div className='tooltip__media'>{icoTooltip}</div>
        </div>
        <div className='tooltip__dropdown dropdown'>
          <div className='dropdown__main'>
            <div className='wysiwyg'>
              {props.info.map((element, index) => {
                return <p key={index}>{element}</p>
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Tooltip
