import { useTranslation } from 'react-i18next'

const VerificationIcons = ({ name }) => {
  const { t } = useTranslation()

  switch (name) {
    case 'ico01':
      return (
        <svg className='advantages__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 16' fill='none'>
          <path
            d='M8.10279 5.64844C7.20144 5.64844 6.4707 6.48213 6.4707 7.51059C6.4707 8.539 7.2014 9.37273 8.10279 9.37273C9.00415 9.37273 9.73489 8.53904 9.73489 7.51059C9.73489 6.48213 9.00419 5.64844 8.10279 5.64844ZM8.10279 8.48596C7.63064 8.48596 7.24789 8.04925 7.24789 7.51054C7.24789 6.97183 7.63064 6.53513 8.10279 6.53513C8.57495 6.53513 8.9577 6.97183 8.9577 7.51054C8.9577 8.04925 8.57495 8.48596 8.10279 8.48596Z'
            fill='#202639'
            fillOpacity='0.5'
          />
          <path
            d='M16.438 1.39095L4.50341 0.0165477C4.2744 -0.0164692 4.04109 4.27268e-05 3.81883 0.064998C3.59656 0.129953 3.39036 0.241881 3.21379 0.393424C3.03707 0.532401 2.88983 0.706136 2.78091 0.904215C2.67198 1.10229 2.60362 1.32064 2.5799 1.54616L2.36134 3.36397H1.68369C0.72192 3.36397 0.000590707 4.22851 0.000590707 5.20392V14.2707C-0.0236335 15.2009 0.700206 15.9749 1.61738 15.9994C1.63947 16 1.6616 16.0001 1.68369 15.9998H13.6838C14.6456 15.9998 15.5199 15.2461 15.5199 14.2707V13.916C15.8181 13.8576 16.101 13.7368 16.3506 13.5614C16.7047 13.259 16.9315 12.8307 16.9844 12.3643L17.9899 3.36397C18.0924 2.3863 17.4006 1.50683 16.438 1.39095ZM14.6456 14.2707C14.6456 14.7584 14.1647 15.1131 13.6838 15.1131H1.68369C1.24929 15.1261 0.886804 14.7794 0.874043 14.3388C0.873374 14.3161 0.873667 14.2934 0.874922 14.2707V12.6303L4.26296 10.1031C4.66996 9.78618 5.24323 9.81436 5.61818 10.1696L8.00073 12.2977C8.36255 12.6058 8.818 12.7781 9.29039 12.7854C9.65973 12.79 10.023 12.6903 10.3396 12.4973L14.6457 9.97008V14.2707H14.6456ZM14.6456 8.92817L9.88051 11.7435C9.68091 11.8635 9.44938 11.9173 9.2182 11.8974C8.98702 11.8775 8.76772 11.785 8.59086 11.6327L6.18647 9.48237C5.49735 8.88184 4.49014 8.84501 3.7602 9.39369L0.874922 11.5218V5.20392C0.874922 4.71621 1.20281 4.2507 1.68369 4.2507H13.6838C14.1976 4.2723 14.6125 4.68354 14.6456 5.20392V8.92817ZM17.1164 3.24427C17.1161 3.2472 17.1159 3.25016 17.1156 3.25309L16.0882 12.2534C16.0891 12.3686 16.0639 12.4824 16.0147 12.5861C15.9654 12.6899 15.8934 12.7808 15.8041 12.8519C15.7166 12.9406 15.5199 12.985 15.5199 13.0293V5.20392C15.4854 4.19401 14.6799 3.38684 13.6838 3.36397H3.23563L3.43235 1.63484C3.47503 1.41094 3.59047 1.208 3.76024 1.05845C3.95195 0.924035 4.18376 0.86134 4.41601 0.881089L16.3288 2.27768C16.8094 2.32397 17.1621 2.75673 17.1164 3.24427Z'
            fill='#202639'
            fillOpacity='0.5'
          />
        </svg>
      )
    case 'ico02':
      return (
        <svg className='advantages__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
          <path
            d='M8 8.03125V9.90625H9.875'
            stroke='#202639'
            strokeOpacity='0.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <mask id='mask0_3617_32477' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'>
            <path d='M0 0H16V16H0V0Z' fill='white' />
          </mask>
          <g mask='url(#mask0_3617_32477)'>
            <path
              d='M5.69816 15.0386C3.74088 14.1573 2.375 12.1884 2.375 9.90625C2.375 9.54875 2.40056 9.19122 2.44681 8.83697C2.88569 5.47531 5.1875 2.875 5.1875 2.875C5.1875 2.875 5.1875 4.75 6.125 4.75C7.53125 4.75 7.53125 0.468752 8.9375 0.468752C8.9375 3.28125 13.625 6.15625 13.625 9.90625C13.625 13.008 11.1018 15.5312 8 15.5312C7.18037 15.5313 6.40112 15.355 5.69816 15.0386Z'
              stroke='#202639'
              strokeOpacity='0.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.75 9.90625C11.75 11.9773 10.0711 13.6562 8 13.6562C5.92894 13.6562 4.25 11.9773 4.25 9.90625C4.25 7.83519 5.92894 6.15625 8 6.15625C10.0711 6.15625 11.75 7.83519 11.75 9.90625Z'
              stroke='#202639'
              strokeOpacity='0.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </svg>
      )
    case 'ico03':
      return (
        <svg className='advantages__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 16' fill='none'>
          <path
            d='M14.6524 0H3.34758C1.49966 0.00190625 0.00214453 1.33303 0 2.97563V13.0244C0.00214453 14.667 1.49966 15.9981 3.34758 16H14.6524C16.5003 15.9981 17.9979 14.667 18 13.0244V2.97563C17.9979 1.33303 16.5003 0.00190625 14.6524 0ZM16.7574 2.97563V3.94034H13.6095L11.2553 1.10453H14.6525C15.8144 1.10569 16.7561 1.94272 16.7574 2.97563ZM9.71093 1.10453L12.0652 3.94034H8.28907L5.9348 1.10453H9.71093ZM1.2426 2.97563C1.2439 1.94275 2.18556 1.10572 3.34758 1.10453H4.39049L6.74476 3.94034H1.2426V2.97563ZM14.6524 14.8955H3.34758C2.18559 14.8943 1.24393 14.0573 1.2426 13.0244V5.04491H16.7574V13.0244C16.7561 14.0573 15.8144 14.8943 14.6524 14.8955Z'
            fill='#202639'
            fill-opacity='0.5'
          />
          <path
            d='M12.3151 9.49508L7.39973 6.97252C7.10255 6.82002 6.72259 6.91052 6.55102 7.17467C6.49649 7.25864 6.46778 7.35388 6.46777 7.45083V12.496C6.46777 12.801 6.74593 13.0483 7.08905 13.0483C7.19811 13.0483 7.30527 13.0228 7.39973 12.9743L12.3151 10.4517C12.6123 10.2992 12.7141 9.96142 12.5425 9.69727C12.488 9.6133 12.4096 9.54357 12.3151 9.49508ZM7.71037 11.5394V8.40736L10.7619 9.97336L7.71037 11.5394Z'
            fill='#202639'
            fill-opacity='0.5'
          />
        </svg>
      )
    case 'ico04':
      return (
        <svg className='verification__ico' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 25' fill='none'>
          <path
            d='M0 12.5C0 19.1132 5.38676 24.5 12 24.5C18.6132 24.5 24 19.1132 24 12.5C24 5.88676 18.6132 0.5 12 0.5C5.38676 0.5 0 5.88676 0 12.5ZM18.2666 10.7666L10.6667 18.3934L5.73342 13.4333L7.6 11.5668L10.6667 14.6066L16.4001 8.9L18.2666 10.7666Z'
            fill='#00D09E'
          />
        </svg>
      )
    case 'ico05':
      return (
        <svg
          className='attach__ico'
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='25'
          viewBox='0 0 25 25'
          fill='none'
        >
          <rect x='0.5' y='0.25' width='24' height='24' rx='12' fill='#202639' />
          <path d='M6 12.25L19 12.25M12.5 5.75L12.5 18.75' stroke='white' />
        </svg>
      )
    case 'ico06':
      return (
        <svg
          className='attach__ico'
          width='49'
          height='49'
          viewBox='0 0 49 49'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.5 24.75C0.5 37.9765 11.2735 48.75 24.5 48.75C37.7265 48.75 48.5 37.9765 48.5 24.75C48.5 11.5235 37.7265 0.75 24.5 0.75C11.2735 0.75 0.5 11.5235 0.5 24.75ZM37.0332 21.2832L21.8333 36.5368L11.9668 26.6167L15.7 22.8835L21.8333 28.9632L33.3002 17.55L37.0332 21.2832Z'
            fill='#202639'
          />
        </svg>
      )
  }
}

export default VerificationIcons
