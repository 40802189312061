import { Link, NavLink } from 'react-router-dom'
import styles from './NewsItem.module.scss'
import api from '../../../../api'
import { ApiUrl } from '../../../../config'
import moment from 'moment'
import i18n from 'i18next'
export const NewsImage = ({ data }) => {
  const { imageUrl, id } = data
  return (
    <NavLink to={`/${i18n.language}/onenews/${id}`}>
      <img className={styles.image} src={`${api.ApiUrl}/api${imageUrl?.slice(5)}`} alt={'news-image'} />
    </NavLink>
  )
}

export const NewsText = ({ data }) => {
  const { id, dateCreated, author, title } = data
  const formattedDate = moment(dateCreated).format('DD-MM-YYYY')
  return (
    <div className={styles.textWrapper}>
      <p className={styles.date}>{formattedDate}</p>
      <p className={styles.author}>{author || ''}</p>
      <h3 className={styles.title}>
        <NavLink to={`/${i18n.language}/onenews/${id}`}>{title}</NavLink>
      </h3>
    </div>
  )
}

export const NewsItem = ({ data }) => {
  return (
    <>
      <NewsImage data={data} />
      <NewsText data={data} />
    </>
  )
}
