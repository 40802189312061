import axios from 'axios'
import qs from 'qs'
import i18n from 'i18next'

import { ApiUrl, ApiAuthUrl } from 'config.js'
import mainApi from '../api.js'
const errorHandler = mainApi.errorHandler

const BaseApi = {
  get: (url, options) =>
    axios
      .get(url, options)
      .then(({ data }) => data)
      .catch(errorHandler),
  post: (url, data, options) =>
    axios
      .post(url, data, options)
      .then(({ data }) => data)
      .catch(errorHandler),
}

export default {
  signIn: (login, password) =>
    BaseApi.post(
      `${ApiAuthUrl}/connect/token`,
      qs.stringify({
        client_id: 'api1',
        client_secret: 'secret',
        scope: 'api1 openid',
        grant_type: 'password',
        username: login,
        password: password,
      }),
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    ),
  register: (email, password, culture) =>
    BaseApi.post(`${ApiAuthUrl}/api/account/register-with-password`, { email, password, culture }),
  sendEmailResetPassword: (email) => BaseApi.post(`${ApiAuthUrl}/api/account/forgot-password`, { email }),
  // allows to change password without logging in
  resetPassword: (email, password, confirmPassword, code) =>
    BaseApi.post(`${ApiAuthUrl}/api/account/reset-password`, {
      email,
      password,
      confirmPassword,
      code,
    }),
  changePassword: (model) => BaseApi.post(`${ApiAuthUrl}/api/manage/change-password`, model),

  fetchUser: () => BaseApi.get(`${ApiAuthUrl}/api/manage/user-info`),
  updateLanguage: (lang) => BaseApi.get(`${ApiAuthUrl}/api/manage/set-language?culture=${lang}`),
}
