import React, { useState } from 'react'
import Main from 'pages/common/components/Main/Main'
import { useTranslation } from 'react-i18next'
import banner__01 from './img/banner__img01.png'
import news__img01 from './img/news__img01.jpg'
import Navigation from '../common/components/Navigation/Navigation.jsx'
import Input from 'components/form/Input/Input'
import Slider from 'react-slick'
function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <div className='slick-arrow slick-prev' onClick={onClick}>
      <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' fill='white' />
        <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' stroke='#202639' />
        <path
          d='M15.5 22C15.5 22.1674 15.5535 22.3298 15.6603 22.4872C15.7672 22.6445 15.9097 22.7751 16.0878 22.8789L25.9199 28.6547C26.3117 28.8849 26.8104 29 27.416 29C28.0216 29 28.5204 28.8849 28.9122 28.6547C29.3041 28.4245 29.5 28.1315 29.5 27.7758C29.5 27.42 29.3041 27.1271 28.9122 26.8969L20.5764 22L28.9122 17.1031C29.3041 16.8729 29.5 16.58 29.5 16.2242C29.5 15.8685 29.3041 15.5755 28.9122 15.3453C28.5204 15.1151 28.0216 15 27.416 15C26.8104 15 26.3117 15.1151 25.9199 15.3453L16.0878 21.1211C15.8741 21.2466 15.7223 21.3827 15.6326 21.5291C15.5428 21.6756 15.4986 21.8326 15.5 22Z'
          fill='black'
        />
      </svg>
    </div>
  )
}
function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <div className='slick-arrow slick-next' onClick={onClick}>
      <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.5' width='44' height='44' rx='22' fill='white' />
        <rect x='-0.5' y='0.5' width='43' height='43' rx='21.5' transform='matrix(-1 0 0 1 43.5 0)' stroke='#202639' />
        <path
          d='M29.5 22C29.5 22.1674 29.4465 22.3298 29.3397 22.4872C29.2328 22.6445 29.0903 22.7751 28.9122 22.8789L19.0801 28.6547C18.6883 28.8849 18.1896 29 17.584 29C16.9784 29 16.4796 28.8849 16.0878 28.6547C15.6959 28.4245 15.5 28.1315 15.5 27.7758C15.5 27.42 15.6959 27.1271 16.0878 26.8969L24.4236 22L16.0878 17.1031C15.6959 16.8729 15.5 16.58 15.5 16.2242C15.5 15.8685 15.6959 15.5755 16.0878 15.3453C16.4796 15.1151 16.9784 15 17.584 15C18.1896 15 18.6883 15.1151 19.0801 15.3453L28.9122 21.1211C29.1259 21.2466 29.2777 21.3827 29.3674 21.5291C29.4572 21.6756 29.5014 21.8326 29.5 22Z'
          fill='#202639'
        />
      </svg>
    </div>
  )
}
export default function TraningPage() {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')
  const slickSettings = {
    dots: false,
    infinite: true,
    variableWidth: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  }
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__banner banner'>
                <div className='row row_m-revers'>
                  <div className='col col_7 col_mob-12'>
                    <div className='banner__layout'>
                      <div className='banner__main'>
                        <div className='wysiwyg'>
                          <h1> {t('AllAboutCryptocurrency')}</h1>
                          <p>{t('ExpandYourKnowledgeOfCryptocurrencies')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_5 col_mob-12'>
                    <div className='banner__media'>
                      <img src={banner__01} alt='image description' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout__section section'>
          <div className='section__filter filter'>
            <div className='container'>
              <div className='filter__tags tags'>
                <Slider {...slickSettings} className='tags__list'>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Blockchain')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Crypto')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Altcoins')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Trading')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Investment')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Metaverse')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('NFT')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Bid')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Indicators')}</span>
                  </div>
                  <div className='tags__item'>
                    <span className='tags__text'> {t('Memes')}</span>
                  </div>
                </Slider>
              </div>
              <div className='filter__search'>
                <div className='row justify-end'>
                  <div className='col col_4 col_mob-12'>
                    <div className='formular'>
                      <div className='form-item'>
                        <div className='form-item__main'>
                          <div className='form-item__field'>
                            <Input
                              before={
                                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M14.7502 14.7502L11.4929 11.4929M11.4929 11.4929C12.0501 10.9357 12.492 10.2743 12.7936 9.54631C13.0951 8.81834 13.2503 8.03811 13.2503 7.25015C13.2503 6.4622 13.0951 5.68197 12.7936 4.95399C12.492 4.22602 12.0501 3.56457 11.4929 3.0074C10.9357 2.45024 10.2743 2.00827 9.54631 1.70673C8.81834 1.4052 8.03811 1.25 7.25015 1.25C6.4622 1.25 5.68197 1.4052 4.95399 1.70673C4.22602 2.00827 3.56457 2.45024 3.0074 3.0074C1.88216 4.13265 1.25 5.65881 1.25 7.25015C1.25 8.84149 1.88216 10.3677 3.0074 11.4929C4.13265 12.6182 5.65881 13.2503 7.25015 13.2503C8.84149 13.2503 10.3677 12.6182 11.4929 11.4929Z" stroke="#202639" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>'
                              }
                              value={searchValue}
                              inputCallback={() => setSearchValue()}
                              addClasses={['w-100']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__news news'>
                <div className='news__list'>
                  <div className='row'>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col col_4 col_tab-6 col_mob-12'>
                      <div className='news__item'>
                        <a className='news__link' href='#'>
                          <div className='news__media'>
                            <img src={news__img01} alt='image description' />
                          </div>
                          <div className='news__layout'>
                            <div className='news__main'>
                              <div className='wysiwyg'>
                                <h3>Обмен USDT на наличные доллары в Стамбуле</h3>
                              </div>
                            </div>
                            <div className='news__footer'>
                              <div className='news__date date'>
                                <span className='date__text'>09 {t('August')} 2023</span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='section__footer'>
            <div className='container'>
              <Navigation />
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
