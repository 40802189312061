import Main from 'pages/common/components/Main/Main'
import Sidebar from 'baseComponents/Sidebar/Sidebar'
import Verification from './Verification/Verification'
import { useTranslation } from 'react-i18next'
export default function VerificationPage() {
  const { t } = useTranslation()
  return (
    <Main>
      <div className='layout__account account'>
        <div className='container'>
          <div className='account__header'>
            <div className='wysiwyg'>
              <h1>{t('PersonalCabinet')}</h1>
            </div>
          </div>
          <Sidebar className='account__sidebar' />
          <div className='account__main'>
            <Verification />
          </div>
        </div>
      </div>
    </Main>
  )
}
