import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import '../../../secondaryLayout/scss/main.sass'
import TokenInput from '../../TokenData/TokenInput.jsx'
import TokenIcons from '../../TokenData/TokenIcons.js'
import { ImageType } from 'pages/tokens/TokenData/TokenData'
import api from 'api'

export default function MainInfo({ onInputChange, tokenData }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div id='tokens__info' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>{t('TokenMainInformation')}:</span>
        </div>
        <div className='group__main'>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.investmentCondition || ''}
                  validationPattern='^ *([a-zA-Z0-9]+ ?){1,20}$'
                  inputCallback={(value) => {
                    onInputChange('investmentCondition', value)
                  }}
                  placeholder={t('TokenWriteTheInvestmentConditions')}
                  onFocus={(e) => {
                    const trimmedValue = e.target.value.trim()
                    onInputChange('investmentCondition', trimmedValue || '')
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenBriefDescriptionOfInvestmentConditions')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.investmentConditionHint || ''}
                  validationPattern='^ *([a-zA-Z0-9]+ ?){1,20}$'
                  inputCallback={(value) => {
                    onInputChange('investmentConditionHint', value)
                  }}
                  placeholder={t('TokenWriteTheInvestmentConditionsHint')}
                  onFocus={(e) => {
                    const trimmedValue = e.target.value.trim()
                    onInputChange('investmentConditionHint', trimmedValue || '')
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenBriefDescriptionOfInvestmentConditions')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.category || ''}
                  validationPattern='^ *([a-zA-Z0-9]?){0,30}$'
                  inputCallback={(value) => {
                    onInputChange('category', value)
                  }}
                  placeholder={t('TokenProjectCategory')}
                  onFocus={(e) => {
                    const trimmedValue = e.target.value.trim()
                    onInputChange('category', trimmedValue || '')
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenIndicateWhichCategoryYour')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <input
                  className='form__input form__input_primary'
                  type='text'
                  value={tokenData.tokenDecimalPlaces}
                  disabled={true}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>
                {t('TokenTheDecimalPrecisionOfYourTokenWillBe')} {tokenData.tokenDecimalPlaces}
              </span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input', 'form__input_primary']}
                  value={tokenData.location || ''}
                  validationPattern='^ *([a-zA-Z0-9]?){0,30}$'
                  inputCallback={(value) => {
                    onInputChange('location', value)
                  }}
                  placeholder={t('TokenEnterProjectLocation')}
                  onFocus={(e) => {
                    const trimmedValue = e.target.value.trim()
                    onInputChange('location', trimmedValue || '')
                  }}
                />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenPleaseIndicateWhereYourProjectIsLocated')}</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
