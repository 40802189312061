import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import api from 'api'
import { cssClass } from 'helpers/helper'

import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import styles from './SupportModal.module.scss'
import modal from '../../../baseComponents/Modal/Modal.module.scss'

export default function SupportModal({setOpened }) {
  const { t } = useTranslation()
  const [header, setHeader] = useState('')
  const [message, setMessage] = useState('')

  const submit = (e) => {
    e.preventDefault()
    api.createHelpdeskTicket({ header, message }).then((data) => {
      setOpened(false)
    })
  }

  return (
    <>
      <h1 className={modal.title}>{t('MakingANewApplication')}</h1>
      <form className={styles.form}>
        <div className={styles.fieldWrapper}>
          <span>{t('ApplicationSubject')}</span>
          <input
            type='text'
            className={baseComponents.input}
            value={header}
            onChange={(e) => setHeader(e.target.value)}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <span>{t('RequestDescription')}</span>
          <textarea
            className={cssClass(baseComponents.input, styles.textarea)}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button onClick={submit} className={cssClass(baseComponents.button, styles.formButton)}>
          {t('Create')}
        </button>
      </form>
    </>
  )
}
