import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Input from 'components/form/Input/Input'
import Select from 'components/form/Select/Select'
import { BuySellTabIndex } from '../PostingData'

export function Stage1({ onStage1DataSubmit, onButtonNextDisabled, stageData }) {
  const { t } = useTranslation()
  const [currentStageIndex, setCurrentStageIndex] = useState(stageData.currentStageIndex)
  const [selectedExchangeAsset, setSelectedExchangeAsset] = useState(stageData.selectedExchangeAsset)
  const [selectedOtcAsset, setSelectedOtcAsset] = useState(stageData.selectedOtcAsset)
  const [selectedBuySellTabIndex, setSelectedBuySellTabIndex] = useState(stageData.selectedBuySellTabIndex)
  const [userSelectedTradeRate, setUserSelectedTradeRate] = useState(stageData.userSelectedTradeRate)
  const [amountOfSelectedExchangeAsset, setAmountOfSelectedExchangeAsset] = useState(
    stageData.amountOfSelectedExchangeAsset,
  )
  const [amountOfSelectedOtcAsset, setAmountOfSelectedOtcAsset] = useState(stageData.amountOfSelectedOtcAsset)
  const [isInputValueChanged, setIsInputValueChanged] = useState(false)

  const handleFocus = (event) => {
    event.target.dataset.initialValue = event.target.value
  }

  const handleBlur = (event) => {
    if (event.target.dataset.initialValue !== event.target.value) {
      setIsInputValueChanged(true)
    }
    delete event.target.dataset.initialValue
  }

  const shouldDisableNextButton = () => {
    const result =
      parseFloat(userSelectedTradeRate) === 0 ||
      parseFloat(amountOfSelectedExchangeAsset) === 0 ||
      parseFloat(amountOfSelectedOtcAsset) === 0 ||
      !selectedOtcAsset ||
      selectedOtcAsset === '' ||
      (selectedBuySellTabIndex === BuySellTabIndex.Sell &&
        (stageData.balanceOfSelectedExchangeAsset === null ||
          parseFloat(amountOfSelectedExchangeAsset) > parseFloat(stageData.balanceOfSelectedExchangeAsset)))

    return result
  }

  const formDataToSubmit = () => {
    const dataToSubmit = {
      // currentStageIndex,
      selectedExchangeAsset,
      selectedOtcAsset,
      selectedBuySellTabIndex,
      userSelectedTradeRate,
      amountOfSelectedExchangeAsset,
      amountOfSelectedOtcAsset,
    }
    onStage1DataSubmit(dataToSubmit)
  }

  const handleExchangeAssetChange = (value) => {
    const maxValue =
      selectedBuySellTabIndex === BuySellTabIndex.Sell &&
      stageData.balanceOfSelectedExchangeAsset &&
      value > stageData.balanceOfSelectedExchangeAsset
        ? stageData.balanceOfSelectedExchangeAsset
        : value

    const finalValue = isFinite(maxValue) && !isNaN(maxValue) ? maxValue : 0
    setAmountOfSelectedExchangeAsset(finalValue)
  }

  const handleOtcAssetChange = (value) => {
    const rate = userSelectedTradeRate

    const maxValue =
      selectedBuySellTabIndex === BuySellTabIndex.Sell &&
      stageData.balanceOfSelectedExchangeAsset &&
      value / rate > stageData.balanceOfSelectedExchangeAsset
        ? stageData.balanceOfSelectedExchangeAsset * rate
        : value

    const finalValue = isFinite(maxValue) && !isNaN(maxValue) ? maxValue : 0
    setAmountOfSelectedOtcAsset(finalValue)
  }

  const roundInput = (value, setFunc) => {
    const finalValue = isFinite(value) && !isNaN(value) ? parseFloat(value).toFixed(2) : 0
    setFunc(finalValue)
  }

  useEffect(() => {
    formDataToSubmit()
  }, [currentStageIndex, selectedExchangeAsset, selectedOtcAsset, selectedBuySellTabIndex])

  useEffect(() => {
    if (!isInputValueChanged) return
    formDataToSubmit()
    setIsInputValueChanged(false)
  }, [isInputValueChanged])

  useEffect(() => {
    onButtonNextDisabled(shouldDisableNextButton())
  }, [
    selectedBuySellTabIndex,
    selectedExchangeAsset,
    selectedOtcAsset,
    amountOfSelectedExchangeAsset,
    amountOfSelectedOtcAsset,
    userSelectedTradeRate,
    stageData.balanceOfSelectedExchangeAsset,
  ])

  return (
    <React.Fragment>
      <div className='steps__main'>
        <div className=' tabs'>
          <BuySellTabsHeader
            t={t}
            selectedBuySellTabIndex={selectedBuySellTabIndex}
            setSelectedBuySellTabIndex={setSelectedBuySellTabIndex}
            isEnableSelection={stageData.isEnableSelection}
          />
          <div className=' tabs__main'>
            <div className=' tabs__list'>
              <div className=' tabs__item'>
                <div className='wrapper'>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <SelectedExchangeAsset
                          t={t}
                          selectedExchangeAsset={selectedExchangeAsset}
                          setSelectedExchangeAsset={setSelectedExchangeAsset}
                          selectedBuySellTabIndex={selectedBuySellTabIndex}
                          isEnableSelection={stageData.isEnableSelection}
                        />
                        <SelectedOtcAsset
                          t={t}
                          otcAssetList={stageData.otcAssetList}
                          selectedOtcAsset={selectedOtcAsset}
                          setSelectedOtcAsset={setSelectedOtcAsset}
                          selectedBuySellTabIndex={selectedBuySellTabIndex}
                          isEnableSelection={stageData.isEnableSelection}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='group'>
                    <div className='group__main'>
                      <div className='formular'>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__header'>
                                    <span className='form-item__title'>{t('YourCourse')}</span>
                                  </div>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedOtcAsset}
                                        value={userSelectedTradeRate}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          const rate = value
                                          setUserSelectedTradeRate(rate)
                                          handleOtcAssetChange(rate * amountOfSelectedExchangeAsset)
                                        }}
                                        addClass
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                          roundInput(amountOfSelectedOtcAsset, setAmountOfSelectedOtcAsset)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col col_4 col_desktop-6 col_tab-8 col_mob-12'>
                            <div className='row'>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedExchangeAsset}
                                        value={amountOfSelectedExchangeAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        inputCallback={(value) => {
                                          handleExchangeAssetChange(value)
                                          handleOtcAssetChange(value * userSelectedTradeRate)
                                        }}
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                          roundInput(amountOfSelectedOtcAsset, setAmountOfSelectedOtcAsset)
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='form-item__footer'>
                                    <div className='form-item__data data'>
                                      <div className='data__item data__item_before'>
                                        <span className='data__text'>{t('Balance')}:</span>
                                      </div>
                                      <div className='data__item data__item_after'>
                                        <span className='data__text'>
                                          {stageData.balanceOfSelectedExchangeAsset ?? 'Loading...'}{' '}
                                          {selectedExchangeAsset}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col col_6'>
                                <div className='form-item'>
                                  <div className='form-item__main'>
                                    <div className='form-item__field'>
                                      <Input
                                        defaultValue='0'
                                        after={selectedOtcAsset}
                                        value={amountOfSelectedOtcAsset}
                                        pattern={'[0-9]+([.,][0-9]{1,2})?'}
                                        validationPattern='^([1-9][0-9]*|0)([.,][0-9]*)?$'
                                        replaceSymbols={[
                                          { from: /,/, to: '.' },
                                          { from: /^(00)/, to: '0.0' },
                                        ]}
                                        inputCallback={(value) => {
                                          handleOtcAssetChange(value)
                                          handleExchangeAssetChange(value / userSelectedTradeRate)
                                        }}
                                        addClasses={['w-100']}
                                        onFocus={handleFocus}
                                        onBlur={(e) => {
                                          handleBlur(e)
                                          roundInput(amountOfSelectedOtcAsset, setAmountOfSelectedOtcAsset)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const BuySellTabsHeader = ({ t, selectedBuySellTabIndex, setSelectedBuySellTabIndex, isEnableSelection }) => {
  const buySellTabs = ['WantToBuy', 'WantToSell']

  return (
    <div className=' tabs__header'>
      <div className='row'>
        <div className='col col_5 col_mob-8'>
          <div className=' tabs__list'>
            {buySellTabs.map((element, index) => {
              return (
                <button
                  className={`tabs__item w-100  ${
                    element === buySellTabs[selectedBuySellTabIndex] && 'tabs__item_active'
                  } ${element === 'WantToBuy' && 'tabs__item_buy'} ${element === 'WantToSell' && 'tabs__item_sell'}`}
                  onClick={() => setSelectedBuySellTabIndex(index)}
                  key={index}
                  disabled={!isEnableSelection}
                >
                  <span className='tabs__title'>{t(element)}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
const SelectedExchangeAsset = ({
  t,
  selectedExchangeAsset,
  setSelectedExchangeAsset,
  selectedBuySellTabIndex,
  isEnableSelection,
}) => {
  const exchangeAssetList = isEnableSelection ? ['USDT', 'USD', 'TRY'] : [selectedExchangeAsset]
  return (
    <div className='row'>
      <div className='col col_2 col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
              <span className='form-item__title'>{t('ReceiveActive')}</span>
            )}
            {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
              <span className='form-item__title'>{t('GivesActive')}</span>
            )}
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={exchangeAssetList}
                value={selectedExchangeAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  setSelectedExchangeAsset(value)
                }}
                disabled={!isEnableSelection}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const SelectedOtcAsset = ({
  t,
  otcAssetList,
  selectedOtcAsset,
  setSelectedOtcAsset,
  selectedBuySellTabIndex,
  isEnableSelection,
}) => {
  return (
    <div className='row'>
      <div className='col col_2  col_desktop-3 col_tab-4 col_mob-6'>
        <div className='form-item'>
          <div className='form-item__header'>
            {selectedBuySellTabIndex === BuySellTabIndex.Buy && (
              <span className='form-item__title'>{t('GivesFiat')}</span>
            )}
            {selectedBuySellTabIndex === BuySellTabIndex.Sell && (
              <span className='form-item__title'>{t('ReceiveFiat')}</span>
            )}
          </div>
          <div className='form-item__main'>
            <div className='form-item__field'>
              <Select
                list={otcAssetList}
                value={selectedOtcAsset}
                addClasses={['w-100']}
                filter={true}
                image={true}
                multiple={false}
                selectCallback={(value) => {
                  setSelectedOtcAsset(value)
                }}
                disabled={!isEnableSelection}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
