import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import authApi from '../../../auth/api'
import { toast } from 'react-toastify'
import Input from '../../../components/form/Input/Input'
import Button from '../../../components/form/Button/Button'
export default function ForgotPWModal({ setOpened }) {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const submit = async () => {
    try {
      if (!validateEmail(email)) {
        toast.error(t('InvalidEmailFormat'))
        return
      }
      await authApi.sendEmailResetPassword(email)
      toast.success(t('ForgotPW_Success'))
      setOpened(false)
    } catch (e) {
      toast.error(t('ForgotPW_Fail'))
      setOpened(false)
    }
  }
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }
  return (
    <>
      <div className='modal__header'>
        <div className='wysiwyg'>
          <h4>{t('ForgotPassword')}</h4>
        </div>
      </div>
      <div className='modal__main'>
        <div className='formular'>
          <div className='form-item'>
            <div className='form-item__header'>
              <span className='form-item__title'>{t('EnterYourEMailNextClick')}</span>
            </div>
            <div className='form-item__main'>
              <div className='form-item__field'>
                <Input
                  value={email}
                  placeholder='e-mail'
                  type='email'
                  inputCallback={(e) => setEmail(e)}
                  required={true}
                  addClasses={['w-100']}
                />
              </div>
            </div>
          </div>
          <div className='form-item'>
            <div className='form-item__main'>
              <div className='form-item__content'>
                <div className='form-item__wysiwyg'>
                  <p>{t('YouWillReceiveAlink')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='form-item'>
            <div className='row justify-center'>
              <div className='col col_6 col_mobp-8'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <Button buttonCallback={() => submit()} addClasses={['btn w-100']} text={t('Send')} type={'Submit'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}