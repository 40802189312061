import Main from 'pages/common/components/Main/Main'
import AboutSection from './AboutSection/AboutSection'
import ClientsSection from './ClientsSection/ClientsSection'
import StepsSection from './StepsSection/StepsSection'
import ServicesSection from './ServicesSection/ServicesSection'
import InstructionsSection from './InstructionsSection/InstructionsSection'
import { cssClass } from 'helpers/helper'
import styles from './LandingPage.module.scss'

export default function LandingPage() {
  return (
    <div className={cssClass(styles.layout)}>
      <Main>
        <header></header>
        <div className={cssClass(styles.layout__main)}>
          <AboutSection />
          <ClientsSection />
          <StepsSection />
          <ServicesSection />
          <InstructionsSection />
        </div>
        <footer></footer>
      </Main>
    </div>
  )
}
