import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { cssClass } from 'helpers/helper'
import store from '../../trading/store'
import Modal from 'components/layout/Modal/Modal'
import pairs, { allUsedCurrencies } from 'data/TradingPairs'
import stylesGlobal from '../LandingPage.module.scss'
import styles from './ConverterSection.module.scss'

import { showCurrencyIcon } from './ConverterCommon'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

export default function ConverterCurrencyModal({ isOpened, setOpened, isTop, changeCurrency, priceSpreads, allPairs }) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const getUsdtEquivalent = (code, amount) => {
    let rate = null
    if (code === 'USDT') {
      rate = 1
    } else {
      let spread = priceSpreads.find((_) => _.currencyPair === `${code}_USDT`)
      if (spread) {
        rate = spread.average
      } else {
        spread = priceSpreads.find((_) => _.currencyPair === `USDT_${code}`)
        if (spread) rate = spread.average ? 1 / spread.average : null
      }
    }
    const digits = store.balance?.balances?.find((_) => _.currencyCode === code)?.digits
    return rate ? (rate * amount).toFixedTrimmed(digits) : null
  }

  const currencies = allUsedCurrencies(allPairs)
    .filter((code) => !search || code.toUpperCase().includes(search.toUpperCase()))
    .map((code) => ({
      currencyCode: code,
      currencyName: store.balance?.balances?.find((_) => _.currencyCode === code)?.currencyName ?? '',
      balance: store.getFreeBalance(code),
      usdtEquivalent: getUsdtEquivalent(code, store.getFreeBalance(code)),
    }))

  return (
    <Modal opened={isOpened} modalCallback={setOpened} blockStyle={'modal480'}>
      <div className={cssClass(stylesGlobal.modal__layout)}>
        <div className={cssClass(stylesGlobal.modal__header)}>
          <span className={cssClass(stylesGlobal.modal__title)}>{t('ConverterSelectCurrency')}</span>
        </div>
        <div className={cssClass(stylesGlobal.modal__main)}>
          <div className={cssClass(styles.converter, stylesGlobal.p_0)}>
            <div className={cssClass(styles.converter__currency, stylesGlobal.currency)}>
              <div className={cssClass(styles.currency__search)}>
                <div className={cssClass(stylesGlobal.formItem, stylesGlobal.formItem_before, stylesGlobal.m_0)}>
                  <div className={cssClass(stylesGlobal.formItem__main)}>
                    <div className={cssClass(stylesGlobal.formItem__field, stylesGlobal.formItem__field_before)}>
                      <input
                        id='text-1'
                        className={cssClass(stylesGlobal.formItem__input)}
                        type='text'
                        placeholder={t('Search')}
                        required
                        value={search}
                        onChange={(e) => setSearch(e.target.value.trim())}
                      />
                    </div>
                    <div className={cssClass(stylesGlobal.formItem__media)}>
                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                        <path
                          d='M21.0002 21.0002L16.6572 16.6572M16.6572 16.6572C17.4001 15.9143 17.9894 15.0324 18.3914 14.0618C18.7935 13.0911 19.0004 12.0508 19.0004 11.0002C19.0004 9.9496 18.7935 8.90929 18.3914 7.93866C17.9894 6.96803 17.4001 6.08609 16.6572 5.34321C15.9143 4.60032 15.0324 4.01103 14.0618 3.60898C13.0911 3.20693 12.0508 3 11.0002 3C9.9496 3 8.90929 3.20693 7.93866 3.60898C6.96803 4.01103 6.08609 4.60032 5.34321 5.34321C3.84288 6.84354 3 8.87842 3 11.0002C3 13.122 3.84288 15.1569 5.34321 16.6572C6.84354 18.1575 8.87842 19.0004 11.0002 19.0004C13.122 19.0004 15.1569 18.1575 16.6572 16.6572Z'
                          stroke='#202639'
                          strokeOpacity='0.5'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cssClass(styles.currency__groups)}>
                {currencies.map((currency) => (
                  <div
                    key={currency.currencyCode}
                    className={cssClass(styles.currency__group)}
                    onClick={() => {
                      changeCurrency(isTop, currency.currencyCode)
                      setSearch('')
                      setOpened(false)
                    }}
                  >
                    <div className={cssClass(styles.currency__media)}>
                      <CurrencyIcon currencyCode={currency.currencyCode} />
                    </div>
                    <div className={cssClass(styles.currency__main)}>
                      <div className={cssClass(styles.currency__list)}>
                        <div className={cssClass(styles.currency__item, styles.currency__item_second)}>
                          <span className={cssClass(styles.currency__text)}>{currency.currencyCode}</span>
                          <span className={cssClass(styles.currency__text)}>{currency.balance}</span>
                        </div>
                        <div className={cssClass(styles.currency__item)}>
                          <span className={cssClass(styles.currency__text)}>{currency.currencyName}</span>
                          <span className={cssClass(styles.currency__text)}>=${currency.usdtEquivalent}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
