import partners__img01 from '../../secondaryLayout/img/partners__img01.png'
import React, { useEffect, useState } from 'react'
import '../../secondaryLayout/scss/main.sass'
import TokenIcons from '../TokenData/TokenIcons'
import { SortPairs, SortPairCriteria } from '../TokenData/TokenData'
import api from 'api'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

const First_N_Pair = 5

export default function ExistingTokens({ t, navigationFunctions, enrichedTradablePairs }) {
  const [sortedEnrichTradablePairs, setSortedEnrichTradablePairs] = useState([])
  const {
    isUserAuth,
    goToTokensPage,
    goToTokensTradePage,
    goToTokensRoadMapPage,
    goToTokensCreatePage,
    goToTokensMarketsPage,
    goToRegistrationPage,
    handleAuthAction,
  } = navigationFunctions

  const handleGoToPage = () => {
    handleAuthAction(goToTokensCreatePage)
  }
  const handleGoToTokensPage = (currencyCode) => {
    if (!currencyCode || currencyCode.trim() === '') return
    goToTokensPage(currencyCode)
  }

  useEffect(() => {
    if (!enrichedTradablePairs || enrichedTradablePairs.length === 0) return
    const displayOrderedPairs = enrichedTradablePairs.filter((pair) => pair.marketPageDisplayOrder !== 0)
    const sortedPairs = SortPairs(displayOrderedPairs, SortPairCriteria.MarketPageDisplayOrder, First_N_Pair, 'asc')

    if (sortedPairs.length < 7) {
      for (let i = sortedPairs.length; i < 7; i++) {
        sortedPairs.push({ currrencyCode: null })
      }
    }

    setSortedEnrichTradablePairs(sortedPairs)
  }, [enrichedTradablePairs])

  useEffect(() => {
    if (enrichedTradablePairs && enrichedTradablePairs.length !== 0) return
    const emptyPairs = []

    for (let i = 0; i < 7; i++) {
      emptyPairs.push({ currrencyCode: null })
    }
    setSortedEnrichTradablePairs(emptyPairs)
  }, [])

  return (
    <React.Fragment>
      <section className='layout__section section'>
        <div className='container'>
          <div className='section__header'>
            <h3 className='section__title'>{t('InnovativeCompanies')}</h3>
          </div>
          <div className='section__main'>
            <div className='layout__partners partners'>
              <ul className='partners__list'>
                {sortedEnrichTradablePairs?.map((pair, index) => (
                  <li key={index} className='partners__item'>
                    <a
                      className='partners__link layout__box'
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleGoToTokensPage(pair.currencyCode)}
                    >
                      <span>
                        {pair.currencyCode ? (
                          <CurrencyIcon size={60} currencyCode={pair.currencyCode} className='partners__img' />
                        ) : (
                          <img className='partners__img' src={partners__img01} alt='' />
                        )}
                      </span>
                    </a>
                  </li>
                ))}
                <li className='partners__item partners__item_secondary'>
                  <a
                    className='partners__link layout__box'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleGoToPage()}
                  >
                    <TokenIcons name={'plusIco'} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
