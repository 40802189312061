const pairs = ['USDT_USD', 'USDT_TRY', 'BTC_USDT', 'ETH_USDT', 'ETH_BTC', 'USDC_USDT']

const allUsedCurrencies = (pairs) => {
  return pairs
    .map((pair) => pair.split('_'))
    .flat()
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort()
}

export default pairs
export { allUsedCurrencies }
