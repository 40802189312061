import { useState, useEffect, useRef } from 'react'
import api from 'api'
import CurrencyListingStatuses from 'data/CurrencyListingStatuses'

const useCurrencyData = (currencyListingStatus = CurrencyListingStatuses.Accepted) => {
  const [enrichedTradablePairs, setEnrichedTradablePairs] = useState([])
  const imageRequestsRef = useRef(new Set())

  const fetchListingsAndPairs = async () => {
    const [listingsData, pairsData] = await Promise.all([
      api.fetchAllCurrencyListings(currencyListingStatus),
      api.fetchInstruments(),
    ])
    return { listingsData, pairsData }
  }

  const filterUsdtPairs = (pairsData) => {
    return pairsData.filter((pair) => pair.currencyPair.code.includes('USDT'))
  }

  const enrichTradablePairs = (usdtPairsData, listingsData) => {
    const listingsMap = new Map(listingsData.map((listing) => [listing.currencyCode, listing]))
    const enrichedPairs = new Map()

    for (const pair of usdtPairsData) {
      const [firstCurrency, secondCurrency] = pair.currencyPair.code.split('_')
      const listing = listingsMap.get(firstCurrency) || listingsMap.get(secondCurrency)
      if (listing && !enrichedPairs.has(pair.currencyPair.code)) {
        enrichedPairs.set(pair.currencyPair.code, {
          ...pair,
          currencyCode: listing.currencyCode,
          tokenName: listing.tokenName,
          marketPageDisplayOrder: listing.marketPageDisplayOrder,
          logoImageId: listing.logoImageId || null,
          logoImageUrl: null,
        })
      }
    }
    return Array.from(enrichedPairs.values())
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { listingsData, pairsData } = await fetchListingsAndPairs()
        const usdtPairsData = filterUsdtPairs(pairsData)
        const tempEnrichedTradablePairs = enrichTradablePairs(usdtPairsData, listingsData)
        setEnrichedTradablePairs(tempEnrichedTradablePairs)
      } catch (error) {
        console.error('Error fetching currency data:', error)
      }
    }
    fetchData()
  }, [])

  return { enrichedTradablePairs }
}

export default useCurrencyData
