import React, { useEffect, useState } from 'react'

const ProgressTradeDetails = ({ t, deal }) => {
  const { price, fiatAmount, cryptoAmount, order } = deal || {}
  const { paymentCurrencyCode, exchangeCurrencyCode } = order || {}
  const [commissionArr, setCommissionArr] = useState([0])

  return (
    <React.Fragment>
      <div className='group'>
        <div className='wysiwyg'>
          <h2>{t('OrderInformation')}</h2>
        </div>
        <div className='group__main'>
          <div className='data'>
            <div className='data__list data__list_column'>
              <div className='row'>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Price')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {price} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Volume')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text colorQuartyty500'>
                        {fiatAmount} {paymentCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>{t('Amount')}</span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {cryptoAmount} {exchangeCurrencyCode}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col col_3 col_mob-6'>
                  <div className='data__item'>
                    <div className='data__header'>
                      <span className='data__title'>
                        {t('Commission')} {commissionArr[0]} % {/*todo*/}
                      </span>
                    </div>
                    <div className='data__main'>
                      <span className='data__text'>
                        {' '}
                        {commissionArr[0]} {exchangeCurrencyCode} {/*todo*/}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressTradeDetails
