import { IsHideRejectionReason } from './VerificationData'

const RejectionReason = ({ t, rejectionReasonMessage = null, stage, requiredStage }) => {
  if (rejectionReasonMessage === null) return null
  if (IsHideRejectionReason(stage, requiredStage)) return null
  return (
    <div className='account__tooltip'>
      <div className='tooltip__list'>
        <div className='tooltip__item tooltip__item_tertiary'>
          <span className='tooltip__text'>{t(rejectionReasonMessage)}</span>
        </div>
      </div>
    </div>
  )
}
export default RejectionReason
