export const STATUS_VERIFICATION_USER = {
  OnFilling: 0,
  OnVerification: 1,
  Failed: 2,
  Success: 3,
}
export const USER_VERIFICATION_STAGE = {
  NoVerification: 0,
  BasicVerification: 10,
  AdvancedVerification: 20,
}
export const GetPersonalInfoStyles = (status, stage) => {
  if (status === null || stage === null || stage === USER_VERIFICATION_STAGE.NoVerification) {
    return VerificationStatusStyles['NotVerified']
  }

  if (status === STATUS_VERIFICATION_USER.OnVerification) {
    return VerificationStatusStyles['DocumentsUnderConsideration']
  }

  if (stage === USER_VERIFICATION_STAGE.BasicVerification) {
    return status === STATUS_VERIFICATION_USER.Success
      ? VerificationStatusStyles['BasicVerification']
      : VerificationStatusStyles['NotVerified']
  }

  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification) {
    return status === STATUS_VERIFICATION_USER.Success
      ? VerificationStatusStyles['AdvancedVerification']
      : VerificationStatusStyles['BasicVerification']
  }

  return VerificationStatusStyles['NotVerified']
}

export const GetInfoBasicStyles = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification) return null
  if (status === STATUS_VERIFICATION_USER.OnVerification) return VerificationStatusStyles['DocumentsUnderConsideration']
  if (status === STATUS_VERIFICATION_USER.Failed) return VerificationStatusStyles['NotApproved']
}
export const GetInfoAdvancedStyles = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.NoVerification) return VerificationStatusStyles['NotAvailable']
  if (stage === USER_VERIFICATION_STAGE.BasicVerification && status !== STATUS_VERIFICATION_USER.Success)
    return VerificationStatusStyles['NotAvailable']

  return GetInfoBasicStyles(status)
}

export const IsHideBasicVerificationButton = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.NoVerification) return false

  if (
    stage === USER_VERIFICATION_STAGE.BasicVerification &&
    (status === STATUS_VERIFICATION_USER.OnFilling || status === STATUS_VERIFICATION_USER.Failed)
  )
    return false

  return true
}
export const IsHideAdvancedVerificationButton = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.BasicVerification && status === STATUS_VERIFICATION_USER.Success) return false
  if (
    stage === USER_VERIFICATION_STAGE.AdvancedVerification &&
    (status === STATUS_VERIFICATION_USER.OnFilling || status === STATUS_VERIFICATION_USER.Failed)
  )
    return false

  return true
}
export const IsHideBasicVerificationAdvantages = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.BasicVerification && status === STATUS_VERIFICATION_USER.Success) return true
  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification) return true
  return false
}
export const IsHideAdvancedVerificationAdvantages = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification && status === STATUS_VERIFICATION_USER.Success) return true
  return false
}
export const IsHideRejectionReason = (stage, requiredStage) => {
  if (stage !== requiredStage) return true
  return false
}

export const VerificationStatusStyles = {
  NotVerified: {
    text: 'P2PNotVerified',
    className: 'btn btn_tertiary btn_revert',
  },
  DocumentsUnderConsideration: {
    text: 'DocumentsUnderConsideration',
    className: 'btn btn_quartyty btn_revert',
  },
  PassVerification: {
    text: 'PassVerification',
    className: 'btn',
  },
  BasicVerification: {
    text: 'BasicVerification',
    className: 'btn btn_sixty btn_revert',
  },
  AdvancedVerification: {
    text: 'AdvancedVerification',
    className: 'btn btn_sixty btn_revert',
  },
  NotApproved: {
    text: 'NotApproved',
    className: 'btn btn btn_tertiary btn_revert',
  },
  NotAvailable: {
    text: 'NotAvailable',
    className: 'btn btn btn_tertiary btn_revert',
  },
}

export const IsBasicVerificationSuccess = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.BasicVerification && status === STATUS_VERIFICATION_USER.Success) return true
  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification) return true

  return false
}

export const IsAdvancedVerificationSuccess = (status, stage) => {
  if (stage === USER_VERIFICATION_STAGE.AdvancedVerification && status === STATUS_VERIFICATION_USER.Success) return true

  return false
}
