import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect, NavLink } from 'react-router-dom'
import i18n from 'i18next'
import CacheBuster from 'react-cache-buster'
import { ProvideAuth, useAuth } from 'auth/auth'
import PrivateRoute from './auth/PrivateRoute'

import { isProdEnv } from 'helpers/helper.js'
import 'helpers/extensions'
import ScrollToTop from 'scrollToTop'

import TradingPage from 'pages/trading/TradingPage'
import BalancePage from './pages/balance/BalancePage'
import AccountPage from './pages/account/AccountPage'
import NewsPage from 'pages/news/NewsPage'
import OneNewsPage from 'pages/news/oneNews/OneNewsPage'
import RegisterPage from './pages/register/RegisterPage'
import VerificationPage from 'pages/verification/VerificationPage'
import ReferralsPage from 'pages/referrals/ReferralsPage'
import OrderPage from 'pages/order/OrderPage'
import Page404 from 'pages/page404/Page404'
import ApiKeysPage from 'pages/apikeys/ApiKeysPage'
import SupportPage from 'pages/support/SupportPage'
import LandingPage from 'pages/landing/LandingPage'
import P2pPage from 'pages/p2p/P2pPage'
import EmailPage from './pages/register/email-confirmation/EmailPage'
import ResetPWPage from 'pages/ResetPW/ResetPWPage'
import HistoryPage from 'pages/history/HistoryPage'
import RulesAndCommissions from './pages/legal-documents/RulesAndComissions'
import PrivacyPolicies from './pages/legal-documents/PrivacyPolicies'
import UserAgreement from './pages/legal-documents/UserAgreement'
import CookiePolicy from './pages/legal-documents/CookiePolicy'
import UserAgreementApi from './pages/legal-documents/UserAgreementApi'
import OfficesPage from 'pages/offices/OfficesPage'
import ConvertPage from 'pages/convert/ConvertPage'
import TrainingPage from 'pages/training/TrainingPage'
import TrainingDetailPage from 'pages/trainingDetail/TrainingDetailPage'
import PurchaseAtCost from 'pages/purchaseAtCost/PurchaseAtCostPage'
import Tokenization from 'pages/tokenization/TokenizationPage'
import Commissions from 'pages/commissions/CommissionsPage'
import Tradable from 'pages/tradable/TradablePage'
import Software from 'pages/software/SoftwarePage'
import FinancialCooperation from 'pages/financialCooperation/FinancialCooperation'
import ProjectPage from 'pages/projectPage/ProjectPage'
import Tokens from 'pages/tokens/TokenPage'
import TokensPage from 'pages/secondaryLayout/tokensPage/TokensPage'
import TokenPage from 'pages/secondaryLayout/tokenPage/TokenPage'
import MarketsPage from 'pages/secondaryLayout/marketsPage/MarketsPage'
import LandingPageToken from 'pages/secondaryLayout/landingPage/LandingPageToken'
import RoadmapPage from 'pages/secondaryLayout/roadmapPage/RoadmapPage'
import CreateTokenPage from 'pages/tokens/CreateAndEditToken/CreateTokenPage'
import EditTokenPage from 'pages/tokens/CreateAndEditToken/Edit/EditTokenPage'
import TokenMarketsPage from 'pages/tokens/Markets/TokenMarketsPage'
import TokenLandingPage from 'pages/tokens/Landing/TokenLandingPage'
class App extends Component {
  render() {
    const lang = '/:locale(ru|en|tr)?'

    return (
      <CacheBuster
        currentVersion={'1.0.4'} // can't import version from package.json, security risk
        isEnabled={isProdEnv} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <ProvideAuth>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route path={`${lang}/secondaryLayout/roadmapPage`} component={RoadmapPage} />
                <Route path={`${lang}/secondaryLayout/landingPage`} component={LandingPageToken} />
                <Route path={`${lang}/secondaryLayout/marketsPage`} component={MarketsPage} />
                <Route path={`${lang}/secondaryLayout/tokenPage`} component={TokenPage} />
                <Route path={`${lang}/secondaryLayout/tokensPage`} component={TokensPage} />
                <Route path={`${lang}/projectPage`} component={ProjectPage} />
                <Route exact path={`${lang}/tokens/markets`} component={TokenMarketsPage} />
                <Route exact path={`${lang}/tokens/landing`} component={TokenLandingPage} />
                <Route exact path={`${lang}/tokens/create`} component={CreateTokenPage} />
                <Route exact path={`${lang}/tokens/roadmap`} component={RoadmapPage} />
                <Route exact path={`${lang}/tokens/edit/:code`} component={EditTokenPage} />
                <Route exact path={`${lang}/tokens/view/:code`} component={Tokens} />
                <Route path={`${lang}/financial-cooperation`} component={FinancialCooperation} />
                <Route path={`${lang}/software`} component={Software} />
                <Route path={`${lang}/tradable`} component={Tradable} />
                <Route path={`${lang}/commissions`} component={Commissions} />
                <Route path={`${lang}/tokenization`} component={Tokenization} />
                <Route path={`${lang}/purchaseAtCost`} component={PurchaseAtCost} />
                <Route path={`${lang}/training`} component={TrainingPage} />
                <Route path={`${lang}/trainingDetail`} component={TrainingDetailPage} />
                <Route path={`${lang}/exchange`} component={TradingPage} />
                <PrivateRoute path={`${lang}/finance`} component={BalancePage} />{' '}
                <PrivateRoute path={`${lang}/account`} component={AccountPage} />
                <PrivateRoute path={`${lang}/verification`} component={VerificationPage} />
                <PrivateRoute path={`${lang}/referrals`} component={ReferralsPage} />
                <PrivateRoute path={`${lang}/active-orders`} component={OrderPage} />
                <PrivateRoute path={`${lang}/order-history`} component={OrderPage} />
                <PrivateRoute path={`${lang}/trading-history`} component={OrderPage} />
                <PrivateRoute path={`${lang}/history`} component={HistoryPage} />
                <Route path={`${lang}/convert`} component={ConvertPage} />
                <PrivateRoute path={`${lang}/apikeys`} component={ApiKeysPage} />
                <PrivateRoute path={`${lang}/support`} component={SupportPage} />
                <Route path={`${lang}/register`} component={RegisterPage} />
                <Route path={`${lang}/P2P`} component={P2pPage} />
                <Route path={`${lang}/offices`} component={OfficesPage} />
                <Route path={`${lang}/news`} component={NewsPage} />
                <Route path={`${lang}/onenews/:id`} component={OneNewsPage} />
                <Route path={`${lang}/email-success`} component={EmailPage} />
                <Route path={`${lang}/reset-password`} component={ResetPWPage} />
                <Route path={`${lang}/rules-and-commissions`} component={RulesAndCommissions} />
                <Route path={`${lang}/privacy-policy`} component={PrivacyPolicies} />
                <Route path={`${lang}/user-agreement`} component={UserAgreement} />
                <Route path={`${lang}/cookie-policy`} component={CookiePolicy} />
                <Route path={`${lang}/user-agreement-api`} component={UserAgreementApi} />
                <Route exact path={`${lang}/`} component={LandingPage} />
                <Route component={Page404} />
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </ProvideAuth>
      </CacheBuster>
    )
  }
}

export default App
