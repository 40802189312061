import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import store from 'pages/trading/store'
import api from 'api'
import i18n from 'i18next'
import OrderRequestStatuses from 'data/OrderRequestStatuses'
import Selector from 'baseComponents/Selector/Selector'
import { cssClass } from 'helpers/helper'

import pages from '../../pages.module.scss'
import styles from './Order.module.scss'

const Order = observer(() => {
  const location = useLocation()
  const { t } = useTranslation()

  const selectPairs = ['Pair', ...(store.instruments?.map((i) => i.code) ?? [])]
  const selectSide = ['BuySell', 'Buy', 'Sell']
  const [pair, setPair] = useState('Pair')
  const [side, setSide] = useState('BuySell')
  const [orders, setOrders] = useState([])
  const isTableNoCanceled = location.pathname === '/trading-history'

  const reloadOrders = () => {
    const pairCode = t(pair) === t(selectPairs[0]) ? '' : t(pair)
    if (location.pathname === `/${i18n.language}/active-orders`) {
      api.fetchUserOrders(pairCode).then((data) => {
        console.log('fetch active userOrders:', data)
        setOrders(data)
      })
    } else if (location.pathname === `/${i18n.language}/order-history`) {
      api.fetchUserOrders(pairCode, OrderRequestStatuses.All).then((data) => {
        console.log('fetch all userOrders', data)
        setOrders(data)
      })
    } else if (location.pathname === `/${i18n.language}/trading-history`) {
      api.fetchUserDeals(pairCode).then((data) => {
        console.log('fetch userTrades:', data)
        setOrders(
          data.map((item) => ({
            date: item.dealDateUtc,
            currencyPairCode: item.currencyPairCode,
            isBid: item.isBuy,
            price: item.price,
            amount: item.volume,
          })),
        )
      })
    }
  }

  const onCancel = (id) => {
    store.deleteOrder(id).then(() => {
      reloadOrders()
    })
  }

  useEffect(() => {
    let ignore = false // flag to prevent race conditions

    if (!store.instruments) store.fetchInstruments()
    reloadOrders()

    return () => {
      ignore = true
    }
  }, [pair, side, location.pathname])

  let isBid = null
  if (side === selectSide[1]) isBid = true
  if (side === selectSide[2]) isBid = false

  return (
    <section className={pages.blockWrapper}>
      <div className={styles.firstRow}>
        <h1 className={styles.title}>
          {t(
            (location.pathname === `/${i18n.language}/active-orders` && 'OpenOrders') ||
              (location.pathname === `/${i18n.language}/order-history` && 'OrdersHistory') ||
              (location.pathname === `/${i18n.language}/trading-history` && 'TradeHistory'),
          )}
        </h1>
        <div className={styles.selectorsWrapper}>
          <Selector list={selectPairs} value={pair} selectNewItem={setPair} arrowText={'все'} scrollbar />
          <Selector list={selectSide} value={side} selectNewItem={setSide} arrowText={'все'} />
        </div>
      </div>
      <div className={cssClass(styles.table, isTableNoCanceled ? styles.tableNoCancel : styles.tableCancel)}>
        <div className={cssClass(styles.cell, styles.tableTitle, styles.phoneVertHidden)}>{t('Time')}</div>
        <div className={cssClass(styles.cell, styles.tableTitle)}>{t('Pair')}</div>
        <div className={cssClass(styles.cell, styles.tableTitle)}>{t('Price')}</div>
        <div className={cssClass(styles.cell, styles.tableTitle, styles.phoneHidden)}>{t('Type')}</div>
        <div className={cssClass(styles.cell, styles.tableTitle)}>
          <span className={styles.phoneVertHidden}>{t('Amount')}</span>
          <span className={styles.phoneVertOnly}>{t('AmountShort')}</span>
        </div>
        <div className={cssClass(styles.cell, styles.tableTitle, styles.phoneVertHidden)}>{t('Total')}</div>
        {!isTableNoCanceled && (
          <div className={cssClass(styles.cell, styles.tableTitle, styles.actionTitlePhone)}>{t('Action')}</div>
        )}

        <div className={styles.line}></div>

        {orders
          .filter((o) => isBid === null || o.isBid === isBid)
          .map((o, i) => {
            return (
              <Row
                key={i}
                coloredBg={(i % 2) - 1}
                data={o}
                onCancel={() => {
                  onCancel(o.id)
                }}
                isTableNoCanceled={isTableNoCanceled}
              />
            )
          })}
      </div>
    </section>
  )
})

const Row = ({ data, coloredBg, onCancel, isTableNoCanceled }) => {
  const { t } = useTranslation()
  const { date, isMarket, currencyPairCode, price, isBid, amount, initialAmount, isCanceled } = data
  console.log('row data:', data)
  let amountStr = `${amount.toFixedTrimmed()}`
  if (initialAmount)
    // deals don't have initialAmount
    amountStr += ` / ${initialAmount.toFixedTrimmed()}`
  const priceStr = isMarket && price === 0 ? 'Market' : price.toFixedTrimmed()
  const total = (price * amount).toFixedTrimmed()
  const isCancelable = initialAmount && !isCanceled && amount > 0

  return (
    <>
      <CellOrdinaryPhoneVertHidden coloredBg={coloredBg} value={format(new Date(date), 'dd.MM.yyyy HH:mm')} />
      <CellOrdinary coloredBg={coloredBg} value={currencyPairCode} />
      <CellColoredPhoneOnly coloredBg={coloredBg} direction={isBid} value={priceStr} />
      <CellColored coloredBg={coloredBg} direction={isBid} value={isBid ? t('Buy') : t('Sell')} />
      <CellOrdinary coloredBg={coloredBg} value={amountStr} />
      <CellOrdinaryPhoneVertHidden coloredBg={coloredBg} value={total} />
      {isCancelable ? (
        <CellButton
          coloredBg={coloredBg}
          value={t('Cancel')}
          onClick={() => {
            onCancel()
          }}
        />
      ) : (
        !isTableNoCanceled && <CellOrdinary coloredBg={coloredBg} value={''} />
      )}
    </>
  )
}

const CellOrdinary = ({ coloredBg, value }) => {
  return <div className={cssClass(styles.cell, coloredBg && styles.cellColoredBg)}>{value}</div>
}
const CellOrdinaryPhoneVertHidden = ({ coloredBg, value }) => {
  return <div className={cssClass(styles.cell, coloredBg && styles.cellColoredBg, styles.phoneVertHidden)}>{value}</div>
}

const CellColored = ({ coloredBg, direction, value }) => {
  const coloredStyle = direction ? styles.green : styles.red
  return (
    <div className={cssClass(styles.cell, coloredBg && styles.cellColoredBg, coloredStyle, styles.phoneHidden)}>
      {value}
    </div>
  )
}

const CellColoredPhoneOnly = ({ coloredBg, direction, value }) => {
  const coloredStyle = direction ? styles.phoneGreen : styles.phoneRed
  return <div className={cssClass(styles.cell, coloredBg && styles.cellColoredBg, coloredStyle)}>{value}</div>
}

const CellButton = ({ coloredBg, value, onClick }) => {
  return (
    <button className={cssClass(styles.cell, coloredBg && styles.cellColoredBg, styles.red)} onClick={onClick}>
      {value}
    </button>
  )
}

export default Order
