import React from 'react'

import styles from './Chart.module.scss'
import ChartElement from './ChartElement'
import Orderbook from '../Orderbook/Orderbook'

export default function Chart() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.chartTable}>
        <ChartElement isPhone={false} />
        <Orderbook />
      </div>
      <div className={styles.phoneOnly}>
        <ChartElement isPhone={true} />
      </div>
    </div>
  )
}
