import Main from 'pages/common/components/Main/Main.jsx'
import { useTranslation } from 'react-i18next'
import React from 'react'
import mapImg from './images/map.jpg'
const data = {
  link: 'https://maps.app.goo.gl/3H9sWejhxRQGgftv5',
  address: 'Aksaray, İnkılap Cd. No: 4/a, 34096 Fatih/İstanbul',
  email: 'support@blazarex.com',
  tel: '+90 (531) 774 58 23',
  time: '10:00 до 20:00',
  map: mapImg,
}

export default function OfficesPage() {
  const { t } = useTranslation()
  return (
    <div className='layout'>
      <Main>
        <div className='layout__section section'>
          <div className='section__main'>
            <div className='container'>
              <div className='layout__offices offices'>
                <div className='row row_secondary row_t-revers'>
                  <div className='col col_4 col_mob-12'>
                    <div className='offices__map map'>
                      <a className='map__link' href={data.link} target='_blank'>
                        <div className='map__preview'>
                          <div className='map__media'>
                            <img src={data.map} alt='image description' />
                          </div>
                        </div>
                        <div className='map__action'>
                          <span className='btn'>
                            <span className='btn__text'>Google Maps</span>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='col col_8 col_mob-12'>
                    <div className='offices__layout'>
                      <div className='offices__header'>
                        <span className='btn btn_quartyty btn_secondary-radius'>
                          <span className='btn__text'>{t('OfficeAddressIstanbulTurkey')}</span>
                        </span>
                      </div>
                      <div className='offices__main'>
                        <div className='wysiwyg'>
                          <p>
                            {t('Address')}: {data.address}
                          </p>
                          <p>
                            {t('E-mail')}: {data.email}
                          </p>
                          <p>
                            {t('Phone')}: {data.tel}
                          </p>
                          <p>
                            {t('EveryDayWith')} {data.time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}