import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import Modal from 'components/layout/Modal/Modal'
import Checkbox from 'components/form/Checkbox/Checkbox'

export default function IsNeededHelpModal({ onConfirmIsNeededHelpModal, opened, onClose, isDirectionDealBuying }) {
  const { t } = useTranslation()
  let chatIco = (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' fill='none'>
      <path
        d='M7 1C3.13359 1 0 3.34883 0 6.25C0 8.30078 1.57227 10.0727 3.8582 10.9367L2.33242 15L6.72109 11.4891C6.81406 11.4918 6.9043 11.5 7 11.5C10.8664 11.5 14 9.15117 14 6.25C14 3.34883 10.8664 1 7 1ZM3.5 7.41758C2.85742 7.41758 2.33242 6.89531 2.33242 6.25C2.33242 5.60469 2.85469 5.08242 3.5 5.08242C4.14258 5.08242 4.66758 5.60469 4.66758 6.25C4.66758 6.89531 4.14258 7.41758 3.5 7.41758ZM7 7.41758C6.35742 7.41758 5.83242 6.89531 5.83242 6.25C5.83242 5.60469 6.35469 5.08242 7 5.08242C7.64258 5.08242 8.16758 5.60469 8.16758 6.25C8.16758 6.89531 7.64258 7.41758 7 7.41758ZM10.5 7.41758C9.85742 7.41758 9.33242 6.89531 9.33242 6.25C9.33242 5.60469 9.85469 5.08242 10.5 5.08242C11.1426 5.08242 11.6676 5.60469 11.6676 6.25C11.6676 6.89531 11.1426 7.41758 10.5 7.41758Z'
        fill='#202639'
      />
      <circle cx='13.5' cy='2.5' r='2.5' fill='#00D09E' />
    </svg>
  )
  const [selectedCheckbox, setSelectedCheckbox] = useState(null)
  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckbox(checkboxId)
  }
  return (
    <Modal opened={opened} blockStyle={'modal640'} modalCallback={onClose}>
      <div className='modal__main'>
        <div className='wysiwyg'>
          <h2>{t('HelpIsNeeded')}</h2>
        </div>
        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#ebfbf7' }}>
          <div className='wysiwyg'>
            <p>
              <strong>{t('RestAssuredThatBlazarexPlaces')}</strong>
            </p>
          </div>
        </div>

        <div className='p2p__tooltip tooltip' style={{ backgroundColor: '#F6F6F6' }}>
          <div className='wysiwyg'>
            <p>
              <strong>{t('PleaseTryToResolveTheIssueWithYourCounterparty')}</strong>
            </p>
          </div>
          <div className='modal__chat chat' onClick={onClose}>
            {chatIco}
            <span className='chat__text'>{t('ContactNow')}</span>
          </div>
        </div>
        <div className='formular'>
          <div className='form-item w-100'>
            <div className='form-item__header'>
              <span className='form-item__title form-item__title_secondary'>{t('WhatIsTheProblem')}</span>
            </div>
            <div className='form-item__main'>
              {isDirectionDealBuying ? (
                <>
                  <div className='form-item__field'>
                    <Checkbox
                      type={'radio'}
                      text={t('P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins')}
                      checkboxCallback={() => {
                        handleCheckboxChange('P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins')
                      }}
                      checked={
                        selectedCheckbox === 'P2PCauseICompletedThePaymentButTheCounterpartyDidNotReleaseTheCoins'
                      }
                    />
                  </div>
                  <div className='form-item__field'>
                    <Checkbox
                      type={'radio'}
                      text={t('P2PCauseIPaidMoreThanRequired')}
                      checkboxCallback={() => handleCheckboxChange('P2PCauseIPaidMoreThanRequired')}
                      checked={selectedCheckbox === 'P2PCauseIPaidMoreThanRequired'}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='form-item__field'>
                    <Checkbox
                      type={'radio'}
                      text={t('IHaveNotReceivedAnyPayments')}
                      checkboxCallback={() => handleCheckboxChange('IHaveNotReceivedAnyPayments')}
                      checked={selectedCheckbox === 'IHaveNotReceivedAnyPayments'}
                    />
                  </div>
                  <div className='form-item__field'>
                    <Checkbox
                      type={'radio'}
                      text={t('P2PCauseTheCounterpartyPaidLessThanRequired')}
                      checkboxCallback={() => handleCheckboxChange('P2PCauseTheCounterpartyPaidLessThanRequired')}
                      checked={selectedCheckbox === 'P2PCauseTheCounterpartyPaidLessThanRequired'}
                    />
                  </div>
                </>
              )}
              <div className='form-item__field'>
                <Checkbox
                  type={'radio'}
                  text={t('P2PCauseOther')}
                  checkboxCallback={() => handleCheckboxChange('Other')}
                  checked={selectedCheckbox === 'Other'}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='formular'>
            <div className='row'>
              <div className='col col_6'>
                <div className='form-item' onClick={() => onClose()}>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button className='btn btn_revert w-100'>
                        <span className='btn__text'>{t('Cancel')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col col_6'>
                <div className='form-item'>
                  <div className='form-item__main'>
                    <div className='form-item__field'>
                      <button
                        className='btn w-100'
                        disabled={!selectedCheckbox}
                        onClick={() => onConfirmIsNeededHelpModal(selectedCheckbox)}
                      >
                        <span className='btn__text'>{t('GetHelp')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
